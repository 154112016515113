import { Page, Document } from "@react-pdf/renderer";
import React from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { pdfStyles } from "components/PatientForms/FormPDFElements/styles";

type Props = {
  children: React.ReactNode;
  title: string;
  practice: PracticeInfoVO;
  subject?: string;
};
export const PDFDocument: React.FC<Props> = ({ children, title, practice, subject }) => {
  return (
    <Document title={title} author={practice.name} subject={subject}>
      <Page size="LETTER" style={pdfStyles.page}>
        {children}
      </Page>
    </Document>
  );
};
