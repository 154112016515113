import React from "react";

import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import notFoundErrorUrl from "assets/images/404Error.svg";
import { paths } from "router/paths";

export const NotFoundRoute = () => {
  return (
    <div
      className={`
        flex
        flex-col
        items-center
        justify-center
        space-y-8
        h-[90vh]
        px-2
      `}
    >
      <img alt="Not Found" src={notFoundErrorUrl} className="w-1/2 max-w-xl" />
      <div className="text-secondaryTheme font-sansSemiBold text-xl">Page not found</div>

      <div className="font-sans text-base mt-4 mb-8 text-center">
        The requested URL was not found on this server.
      </div>
      <ButtonInternalLink className="w-2/3 max-w-sm" to={paths.home()}>
        Go Home
      </ButtonInternalLink>
    </div>
  );
};
