import React from "react";
import { useTranslation } from "react-i18next";
import { FormConsentElementVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatAsISODateTime } from "@libs/utils/date";
import { isDefined } from "@libs/utils/types";
import { Checkbox, CheckState } from "@libs/components/UI/Checkbox";
import { RichTextContent } from "components/UI/RichTextContent";
import { useHandleError } from "api/handleErrorResponse";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";
import { PatientFormValidation } from "components/PatientForms/hooks/usePatientFormValidation";
import { SignaturePad } from "components/UI/SignaturePad";

type Props = {
  element: FormConsentElementVO;
  responsesById: PatientResponses;
  onChangeResponse: ResponseChangedCallback;
  validation: PatientFormValidation;
  edit?: boolean;
};
export const PatientFormConsent: React.FC<Props> = ({
  element,
  validation,
  onChangeResponse,
  edit,
  responsesById,
}) => {
  const { t } = useTranslation();
  const { content: lexicalJson, uuid, statement, acceptsInitials } = element;
  const response = responsesById[uuid];
  const handleError = useHandleError();
  const consentResponse = response?.type === "CONSENT" ? response : undefined;

  const error = validation[uuid]?.error;
  const checked = isDefined(consentResponse?.consentedAt) && consentResponse.consentedAt !== "";
  const signatureRef = React.useRef<HTMLInputElement>(null);

  return (
    <div className="flex flex-col gap-3">
      {lexicalJson && (
        <RichTextContent
          initialEditorState={lexicalJson}
          initialConfig={{
            editable: true,
            namespace: "CustomForms",
            onError: handleError,
          }}
        />
      )}
      {error && <div className="text-red text-sm">{error}</div>}
      {edit ? (
        <Checkbox
          name={uuid}
          id={uuid}
          disabled={!edit}
          checked={checked}
          onChange={(e) => {
            if (e.target.checked) {
              signatureRef.current?.focus();
            }

            onChangeResponse(uuid, {
              type: "CONSENT",
              signature: consentResponse?.signature ?? "",
              consentedAt: e.target.checked ? formatAsISODateTime(new Date()) : "",
            });
          }}
        >
          <div className={cx(error && "text-red")}>{statement}</div>
        </Checkbox>
      ) : (
        <div className="flex gap-2 items-center">
          <CheckState
            checked={checked}
            className={cx("w-5 h-5", checked ? "text-greyDark" : "text-greyLight")}
          />
          <div className="text-sm md:text-xs">{statement}</div>
        </div>
      )}

      <SignaturePad
        id={`consent-signature-${uuid}`}
        error={error}
        onChangeSignature={(text: string) =>
          onChangeResponse(uuid, {
            type: "CONSENT",
            signature: text,
            consentedAt: consentResponse?.consentedAt ?? "",
          })
        }
        placeholder={acceptsInitials ? t("Enter initials") : t("Type your signature here")}
        edit={edit}
        ref={signatureRef}
        className={cx(acceptsInitials && "w-40")}
        value={consentResponse?.signature}
      />
    </div>
  );
};
