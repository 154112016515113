import { ReactNode, useRef, MutableRefObject } from "react";
import { cx } from "@libs/utils/cx";
import { Menu, MenuProps } from "@libs/components/UI/Menu";

export type ButtonProps = {
  onMouseDown: Func;
  ref: MutableRefObject<HTMLButtonElement | null>;
  type: "button";
};

type ShadowType = keyof typeof cxShadowStyles;
export interface ButtonMenuProps extends Omit<MenuProps, "triggerRef" | "children"> {
  isOpen: boolean;
  onRequestOpen: Func;
  menuContent: ReactNode;
  className?: string;
  shadow?: ShadowType;
  includeDarkMode?: boolean;
  children: (buttonProps: ButtonProps) => ReactNode;
}

const cxShadowStyles = {
  default: "shadow-main",
  heavy: `
    shadow-[0px_10px_8px_rgba(0,0,0,0.4),0px_4px_3px_rgba(0,0,0,0.1)]
  `,
};

export const cxButtonMenuStyles = {
  container: ({ includeDarkMode, shadow = "default" }: { includeDarkMode: boolean; shadow?: ShadowType }) =>
    cx(
      "border rounded bg-white border-greyLighter p-1 print:hidden",
      includeDarkMode &&
        `dark:bg-slate-800
       dark:text-greyLighter
       dark:border-none
       dark:shadow-none`,
      cxShadowStyles[shadow]
    ),
};
export const ButtonMenu: React.FC<ButtonMenuProps> = ({
  isOpen,
  onRequestOpen,
  menuContent,
  children,
  className,
  shadow = "default",
  includeDarkMode = true,
  ...menuProps
}) => {
  const triggerRef = useRef<HTMLButtonElement | null>(null);

  return (
    <div className={className}>
      {children({
        onMouseDown: onRequestOpen,
        ref: triggerRef,
        type: "button",
      })}
      {isOpen ? (
        <Menu
          {...menuProps}
          className={cxButtonMenuStyles.container({ includeDarkMode, shadow })}
          triggerRef={triggerRef}
        >
          {menuContent}
        </Menu>
      ) : null}
    </div>
  );
};
