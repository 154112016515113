export const semibold24 = "text-2xl leading-snug font-sansSemiBold";
export const semibold20 = "text-xl leading-snug font-sansSemiBold";
export const semibold16 = "text-base leading-snug font-sansSemiBold";
export const semibold14 = "text-sm leading-snug font-sansSemiBold";
export const semibold12 = "text-xs leading-snug font-sansSemiBold";

export const normal20 = "font-normal text-xl leading-snug font-sans";
export const normal16 = "font-normal text-base leading-snug font-sans";
export const normal14 = "font-normal text-sm leading-snug font-sans";
export const normal12 = "font-normal text-xs leading-snug font-sans";
export const normal10 = "font-normal text-[.7rem] leading-snug font-sans";

export const signature24 = "font-dafoeRegular text-2xl";
