import { forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { BaseOptionInputProps, OptionInput } from "@libs/components/UI/OptionInput";
import { ReactComponent as CheckedIcon } from "@libs/assets/icons/checked-checkbox.svg";
import { ReactComponent as UnCheckedIcon } from "@libs/assets/icons/unchecked-checkbox.svg";
import { ReactComponent as IntermediateCheckedIcon } from "@libs/assets/icons/multiple-selected-checkbox.svg";

type CheckboxSize = "md" | "lg";

export interface CheckboxProps extends BaseOptionInputProps {
  checkboxSize?: CheckboxSize;
  indeterminate?: boolean;
  textEllipsis?: boolean;
}

const cxStyles = {
  container: ({ disabled }: { disabled?: boolean }) =>
    cx("flex relative items-center", !disabled && "cursor-pointer"),
  fake: `
    inline
    align-bottom
    z-0
    rounded-full
    fill-greyLight
    peer-disabled:fill-greyLighter
    peer-hover:fill-greyDark
    peer-focus:fill-greyDark
    peer-checked:fill-primaryTheme
    peer-checked:peer-hover:fill-primaryTheme
    peer-checked:peer-focus:fill-primaryTheme
    peer-checked:peer-disabled:fill-greyLight
  `,
  darkFake: `
    dark:peer-disabled:fill-greyMedium
    dark:peer-hover:fill-archyBlue-100
    dark:peer-focus:fill-archyBlue-200
    dark:peer-checked:fill-archyBlue-300
    dark:peer-checked:peer-hover:fill-archyBlue-300
    dark:peer-checked:peer-focus:fill-archyBlue-200
    dark:peer-checked:peer-disabled:fill-slate-600
  `,
  content: (textEllipsis: boolean) =>
    cx(
      `block
       ml-1.5
       flex-1
       md:text-xs
       text-sm
       peer-disabled:text-greyLight`,
      textEllipsis && "overflow-hidden whitespace-nowrap text-ellipsis"
    ),
  darkContent: "dark:text-greyLightest",

  size: (size: CheckboxSize) => (size === "md" ? "w-5 h-5" : "w-6 h-6"),
};

export const CheckState = ({
  checked,
  className,
  indeterminate,
}: {
  checked?: boolean;
  className: string;
  indeterminate?: boolean;
}) => {
  return checked && indeterminate ? (
    <IntermediateCheckedIcon className={className} />
  ) : checked ? (
    <CheckedIcon className={className} />
  ) : (
    <UnCheckedIcon className={className} />
  );
};

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(
  (
    { className, checkboxSize = "md", indeterminate, textEllipsis = false, includeDarkMode = true, ...props },
    ref
  ) => {
    return (
      <OptionInput
        className={cx(cxStyles.container({ disabled: props.disabled }), className)}
        contentClassName={cx(cxStyles.content(textEllipsis), includeDarkMode && cxStyles.darkContent)}
        customContent={
          <CheckState
            checked={props.checked}
            indeterminate={indeterminate}
            className={cx(cxStyles.fake, includeDarkMode && cxStyles.darkFake, cxStyles.size(checkboxSize))}
          />
        }
        ref={ref}
        type="checkbox"
        {...props}
      />
    );
  }
);
