import React, { useMemo } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getLatestFormSubmissionQuery, getPatientPublishedFormQuery, UUIDOrSlug } from "api/forms/queries";
import { OnboardingLoading } from "components/Onboarding/OnboardingLoading";
import { LoadedPatientForm } from "components/PatientForms/LoadedPatientForm";
import { useAuthorizedFormSubmission } from "components/PatientForms/hooks/useAuthorizedFormSubmission";
import { useCurrentPatient } from "contexts/PatientContext";
import { ExistingPatientInfo } from "components/PatientForms/FormElements/types";

type Props = {
  uuidOrSlug: UUIDOrSlug;
  edit?: boolean;
  onSuccess?: Func;
  onPrevious?: Func;
  fixedFooter?: boolean;
  reconsent?: boolean;
};

export const PatientForm: React.FC<Props> = (props) => {
  const { id: patientId, practiceId } = useAccount();
  const practice = useCurrentPractice();
  const patient = useCurrentPatient();

  const [latestSubmissionQuery, publishedFormQuery] = useApiQueries([
    getLatestFormSubmissionQuery({ args: { practiceId, uuidOrSlug: props.uuidOrSlug, patientId } }),
    getPatientPublishedFormQuery({ args: { practiceId, patientId, uuidOrSlug: props.uuidOrSlug } }),
  ]);
  const { data: formData } = publishedFormQuery;
  const { data: responseData } = latestSubmissionQuery;
  const { handleSubmit, isSubmitting } = useAuthorizedFormSubmission({
    uuidOrSlug: props.uuidOrSlug,
    onSuccess: props.onSuccess,
    practiceId,
    patientId,
    onboardingState: patient.onboardingState,
  });

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const patientAttributes: ExistingPatientInfo = useMemo(
    () => ({
      ...patient.personalDetails,
      hasAddress: Boolean(patient.contactDetails.addressDetails),
      hasSsn: Boolean(patient.personalDetails.ssnLastFour),
      hasContact: patient.contact.relation !== "SELF",
      hasReferredBy: Boolean(patient.additionalInformation?.referredBy),
    }),
    [patient]
  );

  return (
    <QueryResult queries={[latestSubmissionQuery, publishedFormQuery]} loading={<OnboardingLoading />}>
      {formData && responseData && (
        <LoadedPatientForm
          practice={practice}
          formData={props.edit ? formData : responseData.form}
          showInWizard={patient.onboardingState !== "COMPLETED"}
          responses={responseData.responses}
          patient={patientAttributes}
          onSubmit={handleSubmit}
          isSubmitting={isSubmitting}
          warnWhenNavigating
          referralOptions={[]}
          {...props}
        />
      )}
    </QueryResult>
  );
};
