import { Circle, Path, Svg, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { colors } from "@libs/design.config";
import { pdfStyles } from "components/PatientForms/FormPDFElements/styles";

const PDFRadioIcon: React.FC<{
  width?: string;
  height?: string;
  checked?: boolean;
  style?: Style;
}> = ({ height = "10", width = "10", checked = false, style }) => {
  return (
    <Svg width={width} height={height} viewBox="0 0 10 10" style={style}>
      {checked ? (
        <Path
          d="M4.99995 0.200012C2.35467 0.200012 0.199951 2.35473 0.199951 5.00001C0.199951 7.64529 2.35467 9.80001 4.99995 9.80001C7.64523 9.80001 9.79995 7.64529 9.79995 5.00001C9.79995 2.35473 7.64523 0.200012 4.99995 0.200012ZM4.99995 1.16001C7.12641 1.16001 8.83995 2.87355 8.83995 5.00001C8.83995 7.12647 7.12641 8.84001 4.99995 8.84001C2.87349 8.84001 1.15995 7.12647 1.15995 5.00001C1.15995 2.87355 2.87349 1.16001 4.99995 1.16001ZM4.99995 2.12001C4.23613 2.12001 3.50359 2.42344 2.96348 2.96355C2.42338 3.50365 2.11995 4.23619 2.11995 5.00001C2.11995 5.76384 2.42338 6.49638 2.96348 7.03648C3.50359 7.57659 4.23613 7.88001 4.99995 7.88001C5.76377 7.88001 6.49632 7.57659 7.03642 7.03648C7.57652 6.49638 7.87995 5.76384 7.87995 5.00001C7.87995 4.23619 7.57652 3.50365 7.03642 2.96355C6.49632 2.42344 5.76377 2.12001 4.99995 2.12001Z"
          fill={colors.greyDark}
        />
      ) : (
        <Circle cx="4.99995" cy="5.00001" r="4.15" fill="white" stroke={colors.greyLight} strokeWidth="1.3" />
      )}
    </Svg>
  );
};

type Props = {
  checked?: boolean;
  children: React.ReactNode;
  style?: Style;
};
export const PDFRadio: React.FC<Props> = ({ checked = false, children, style }) => {
  return (
    <View style={[pdfStyles.option, style ?? {}]}>
      <PDFRadioIcon checked={checked} style={{ marginRight: 4 }} />
      {children}
    </View>
  );
};
