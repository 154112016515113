import React from "react";
import { useTranslation } from "react-i18next";

export const usePronounOptions = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return ["HE_HIM" as const, "SHE_HER" as const, "THEY_THEM" as const].map((value) => ({
      value,
      label: t(`pronounOption.${value}`),
    }));
  }, [t]);
};
