import { Link, LinkProps } from "react-router-dom";
import { cx } from "@libs/utils/cx";

type Props = {
  to: string;
  children: React.ReactNode;
  className?: string;
};

const cxLinkStyles = {
  base: "text-primaryTheme font-sansSemiBold hover:text-opacity-70",
};

export const InternalLink: React.FC<Props & LinkProps> = ({ to, children, className, ...rest }) => {
  return (
    <Link to={to} className={cx(cxLinkStyles.base, className)} {...rest}>
      {children}
    </Link>
  );
};

export const ExternalLink: React.FC<Props> = ({ to, children, className }) => {
  return (
    <a href={to} className={cx(cxLinkStyles.base, className)} target="_blank">
      {children}
    </a>
  );
};
