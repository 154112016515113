import { FormSectionVO } from "@libs/api/generated-api";
import { PatientFormTextBlock } from "components/PatientForms/FormElements/PatientFormTextBlock";
import { PatientFormConsent } from "components/PatientForms/FormElements/PatientFormConsent";
import { PatientFormInputElement } from "components/PatientForms/FormElements/PatientFormInputElement";
import { PatientFormValidation } from "components/PatientForms/hooks/usePatientFormValidation";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";
import { isInputElement } from "components/PatientForms/utils";

type Props = {
  element: FormSectionVO;
  edit: boolean;
  responsesById: PatientResponses;
  onChangeResponse: ResponseChangedCallback;
  validation: PatientFormValidation;
};
export const PatientFormSection: React.FC<Props> = ({
  element,
  edit,
  responsesById,
  onChangeResponse,
  validation,
}) => {
  const { title, content } = element;
  const sharedProps = {
    responsesById,
    onChangeResponse,
    edit,
  };

  return (
    <div className="flex flex-col gap-7">
      <div>
        <div className="font-sansBold">{title}</div>
      </div>
      {content.map((item) => {
        if (isInputElement(item)) {
          return (
            <PatientFormInputElement
              key={item.uuid}
              validation={validation}
              element={item}
              {...sharedProps}
            />
          );
        }

        switch (item.type) {
          case "TEXT_BLOCK": {
            return <PatientFormTextBlock key={item.uuid} element={item} />;
          }
          case "CONSENT": {
            return (
              <PatientFormConsent {...sharedProps} validation={validation} key={item.uuid} element={item} />
            );
          }

          default: {
            return null;
          }
        }
      })}
    </div>
  );
};
