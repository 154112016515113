import { useCallback, useMemo, useState, useRef, ReactNode } from "react";

// Validated input types
type FieldKey = string | number;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type LeafStructures = Date | Set<any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Fields = Record<FieldKey, any>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type List = any[];

// Schema types
type ValidationFunc = (value: unknown) => boolean;
type ValidationOptions = {
  $validate?: boolean;
  $ignore?: boolean;
};
type Validation = {
  $v: ValidationFunc;
  $error?: ReactNode;
} & ValidationOptions;
export type Validations = Validation[];
type ValidationsConfig = { $validations: Validations } & ValidationOptions;
type FieldSchema = ValidationsConfig | Validations;
type SubSchema<Input extends Fields> = Partial<{
  [K in keyof Input]: Schema<Input[K]>;
}> & { $validate?: boolean; $ignore?: boolean };

type ListSchema<L extends List> = {
  $validations?: Validations;
  $items: Schema<ListItem<L>>[] | Schema<ListItem<L>>;
  $validate?: boolean;
  $ignore?: boolean;
};

export type Schema<Input> = Input extends LeafStructures
  ? FieldSchema
  : Input extends List
    ? ListSchema<Input> | FieldSchema
    : Input extends Fields
      ? SubSchema<Input> | FieldSchema
      : FieldSchema;

// Validation types
export type FieldSchemaValidation = { $isValid?: boolean; $error?: string };
type SubSchemaValidation<I extends Fields, S extends Schema<I>> = {
  $isValid?: boolean;
} & {
  [K in Extract<keyof S, FieldKey>]: S[K] extends Schema<I[K]> ? ValidationResult<I[K], S[K]> : never;
};

type ListSchemaValidation<Input extends List, S extends ListSchema<Input>> = {
  $isValid?: boolean;
  $error?: string;
  $items: S["$items"] extends Schema<ListItem<Input>>[]
    ? ValidationResult<ListItem<Input>, S["$items"][0]>[]
    : S["$items"] extends Schema<ListItem<Input>>
      ? ValidationResult<ListItem<Input>, S["$items"]>[]
      : never;
};

// if input is leaf structure and schema is field validator return it
// if schema is subsc
export type ValidationResult<I, S extends Schema<I>> = I extends LeafStructures
  ? S extends FieldSchema
    ? FieldSchemaValidation
    : never
  : I extends List
    ? S extends ListSchema<I>
      ? ListSchemaValidation<I, S>
      : FieldSchemaValidation
    : S extends FieldSchema
      ? FieldSchemaValidation
      : I extends Fields
        ? S extends SubSchema<I>
          ? SubSchemaValidation<I, S>
          : never
        : never;

type ValidationContext<I, S extends Schema<I>> = {
  cache: { input?: I; schema?: S; validation?: ValidationResult<I, S>; isStale: boolean };
} & Required<ValidationOptions>;

const reservedKeys = new Set(["$ignore", "$validate"]);

const canUseCachedValidation = <I, S extends Schema<I>>(
  input: I,
  schema: S,
  context: ValidationContext<I, S>
) => {
  return (
    context.cache.validation &&
    input === context.cache.input &&
    schema === context.cache.schema &&
    !context.cache.isStale
  );
};

const getMergedValidationOptions = (context: Required<ValidationOptions>, schema: ValidationOptions) => {
  return {
    $validate: "$validate" in schema ? Boolean(context.$validate || schema.$validate) : context.$validate,
    $ignore: "$ignore" in schema ? Boolean(context.$ignore || schema.$ignore) : context.$ignore,
  };
};

const resolveListItemsSchema = <Input extends List>(
  schema: Schema<ListItem<Input>>[] | Schema<ListItem<Input>>,
  index: number
) => {
  return Array.isArray(schema) && !("$v" in schema[0]) ? schema[index] : schema;
};

const getListSchemaValidation = <Input extends List, S extends Schema<Input>>(
  input: Input,
  schema: S,
  context: ValidationContext<Input, S>
) => {
  const listSchema = schema as ListSchema<Input>;
  const mergedContext = getMergedValidationOptions(context, listSchema);
  let hasUnknown = false;
  let hasInvalid = false;
  const results: ValidationResult<ListItem<Input>, Schema<ListItem<Input>>>[] = [];

  for (const [index, item] of input.entries()) {
    const cachedSubSchema = context.cache.schema as ListSchema<Input>;
    const cachedValidation = context.cache.validation as
      | ListSchemaValidation<Input, ListSchema<Input>>
      | undefined;
    const subContext = {
      ...mergedContext,
      cache: {
        ...context.cache,
        input: context.cache.input?.[index] as unknown,
        schema: resolveListItemsSchema(cachedSubSchema.$items, index),
        validation: cachedValidation ? cachedValidation.$items[index] : undefined,
      },
    };

    const validation = getValidationWithContext(
      item,
      resolveListItemsSchema(listSchema.$items, index) as FieldSchema,
      subContext as ValidationContext<unknown, FieldSchema>
    ) as ValidationResult<ListItem<Input>, Schema<ListItem<Input>>>;

    const $isValid = validation.$isValid;

    hasUnknown = hasUnknown || $isValid === undefined;
    hasInvalid = hasInvalid || $isValid === false;
    results.push(validation);
  }

  const fieldValidation = listSchema.$validations
    ? getFieldValidation(listSchema.$validations, input, mergedContext)
    : { $isValid: true };

  hasInvalid = hasInvalid || fieldValidation.$isValid === false;
  hasUnknown = hasUnknown || fieldValidation.$isValid === undefined;

  const result = {
    $items: results,
    $isValid: hasInvalid ? false : hasUnknown ? undefined : true,
  };

  if (fieldValidation.$error) {
    return {
      ...result,
      $error: fieldValidation.$error,
    };
  }

  return result;
};

const getSubSchemaValidation = <Input extends Fields, S extends Schema<Input>>(
  input: Input,
  schema: S,
  context: ValidationContext<Input, S>
) => {
  const subSchema = schema as SubSchema<Input>;
  const fieldNames = Object.keys(subSchema);
  const mergedContext = getMergedValidationOptions(context, subSchema);

  let hasUnknown = false;
  let hasInvalid = false;
  const results: Record<string, ValidationResult<Fields, Schema<Fields>>> = {};

  for (const field of fieldNames) {
    if (reservedKeys.has(field)) {
      continue;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const value = (input ?? {})[field] as unknown;
    const subSchemaValue = subSchema[field] as FieldSchema;
    const subContext = {
      ...mergedContext,
      cache: {
        ...context.cache,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        input: (context.cache.input as Fields)?.[field] as unknown,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        schema: (context.cache.schema as SubSchema<Fields>)?.[field] as FieldSchema,
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        validation: (context.cache.validation as SubSchemaValidation<Fields, SubSchema<Fields>>)?.[field],
      },
    };
    const validation = getValidationWithContext(value, subSchemaValue, subContext);
    const $isValid = validation.$isValid;

    hasUnknown = hasUnknown || $isValid === undefined;
    hasInvalid = hasInvalid || $isValid === false;
    results[field] = validation;
  }

  return {
    ...results,
    $isValid: hasInvalid ? false : hasUnknown ? undefined : true,
  };
};

// If any validation returns false, the field is not valid
// If some validations are not active we cannot return whehter or not the field is active
// If all of the fields are either valid or skipped we can say the field is valid
const getFieldValidation = (
  validators: Validation[],
  value: unknown,
  context: Required<ValidationOptions>
) => {
  let hasUnknown = false;

  for (const validator of validators) {
    const merged = getMergedValidationOptions(context, validator);

    if (!merged.$validate || merged.$ignore) {
      if (!merged.$validate) {
        hasUnknown = true;
      }

      continue;
    }

    const isValid = validator.$v(value);

    if (!isValid) {
      return {
        $isValid: isValid,
        $error: validator.$error,
      };
    }
  }

  if (hasUnknown) {
    return {};
  }

  return {
    $isValid: true,
  };
};

const getValidationWithContext = <I, S extends Schema<I>>(
  input: I,
  schema: S,
  context: ValidationContext<I, S>
): ValidationResult<I, S> => {
  if (canUseCachedValidation(input, schema, context)) {
    return context.cache.validation as ValidationResult<I, S>;
  }

  if (Array.isArray(schema)) {
    return getFieldValidation(schema, input, context) as ValidationResult<I, S>;
  }

  if (Array.isArray(input) && "$items" in schema) {
    return getListSchemaValidation(
      input,
      schema as Schema<List>,
      context as ValidationContext<List, Schema<List>>
    ) as ValidationResult<I, S>;
  }

  if ("$validations" in schema) {
    const config = schema as ValidationsConfig;
    const subContext = getMergedValidationOptions(context, schema);

    return getFieldValidation(config.$validations, input, subContext) as ValidationResult<I, S>;
  }

  return getSubSchemaValidation(
    input as Fields,
    schema as SubSchema<Fields>,
    context as ValidationContext<Fields, SubSchema<Fields>>
  ) as ValidationResult<I, S>;
};

export const getValidation = <I, S extends Schema<I>>(input: I, schema: S) =>
  getValidationWithContext(input, schema, {
    $validate: true,
    $ignore: false,
    cache: {
      input,
      schema,
      validation: undefined,
      isStale: true,
    },
  });

export const useValidation = <I, S extends Schema<I>>(
  input: I,
  schema: S,
  options?: { isValidating?: boolean }
) => {
  const [isValidating, setIsValidating] = useState(options?.isValidating ?? false);
  const isValidatingRef = useRef(isValidating);
  const inputRef = useRef<I>(input);
  const schemaRef = useRef<S>(schema);
  const validationRef = useRef<ValidationResult<I, S> | undefined>();

  const validate = useCallback(() => {
    setIsValidating(true);

    return getValidationWithContext(input, schema, {
      $validate: true,
      $ignore: false,
      cache: {
        input: inputRef.current,
        schema: schemaRef.current,
        validation: validationRef.current,
        isStale: isValidatingRef.current !== true,
      },
    });
  }, [input, schema]);

  const reset = useCallback(() => {
    setIsValidating(false);
  }, []);

  const validation = useMemo(() => {
    return getValidationWithContext(input, schema, {
      $validate: isValidating,
      $ignore: false,
      cache: {
        input: inputRef.current,
        schema: schemaRef.current,
        validation: validationRef.current,
        isStale: isValidating !== isValidatingRef.current || schemaRef.current !== schema,
      },
    });
  }, [input, schema, isValidating]);

  isValidatingRef.current = isValidating;
  schemaRef.current = schema;
  inputRef.current = input;
  validationRef.current = validation;

  return {
    isValidating,
    validate,
    reset,
    result: validation,
  };
};
