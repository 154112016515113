import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import enTranslations from "./locales/en/translations.json";
import esTranslations from "./locales/es/translations.json";

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    fallbackLng: "en",
    lng: "en",
    returnNull: false,
    resources: {
      en: {
        translations: enTranslations,
      },
      es: {
        translations: esTranslations,
      },
    },
    ns: ["translations"],
    defaultNS: "translations",
  });

  i18n.languages = ["en", "es"];
};
