import { useMemo } from "react";
import { useTranslation } from "react-i18next";

export const useYesNoOptions = () => {
  const { t } = useTranslation();

  return useMemo(
    () => [
      { label: t("Yes"), value: "true" as const },
      { label: t("No"), value: "false" as const },
    ],
    [t]
  );
};
