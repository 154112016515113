import { useId, useMemo } from "react";
import { MenuPlacement } from "react-select";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { Select, SelectProps, DisplayValue, findSelectedOption } from "@libs/components/UI/Select";
import { createSelectStyles, mergeSelectStyles } from "@libs/components/UI/selectStyles";
import { cx } from "@libs/utils/cx";

type MenuFontSize = "xxs" | "xs" | "sm";

export type FormFieldSelectProps<V extends SelectOptionValue, T extends SelectOption<V>> = FormFieldProps &
  Omit<SelectProps<V, T>, "isDisabled" | "className"> & {
    emptyReadOnlyValue?: string;
    menuFontSize?: MenuFontSize;
  };

export const getSelectLayoutStyles = () => {
  const dimension = "1rem";

  const xPadding = "0.75rem";
  const verticalPadding = "0.1875rem";

  return {
    dropdownIndicator: () => ({
      height: dimension,
      width: dimension,
    }),
    clearIndicator: () => ({
      height: dimension,
      width: dimension,
    }),
    control: () => ({
      minHeight: "2rem",
      paddingTop: verticalPadding,
      paddingBottom: verticalPadding,
      paddingRight: xPadding,
      paddingLeft: xPadding,
    }),
  };
};

export const getFormFieldSelectStyles = <
  V extends SelectOptionValue,
  T extends SelectOption<V>,
  IsMulti extends boolean,
>(
  menuPlacement?: MenuPlacement,
  menuFontSize?: MenuFontSize
) => {
  const fontSize = menuFontSize === "xxs" ? "0.625rem" : menuFontSize === "xs" ? "0.75rem" : "0.875rem";

  return createSelectStyles<V, T, IsMulti>({
    ...getSelectLayoutStyles(),
    menu: () => ({
      marginBottom: menuPlacement === "top" ? "1.5rem" : "0.5rem",
      fontSize,
    }),
    valueContainer: () => ({
      fontSize,
    }),
  });
};

export const FormFieldSelect = <V extends SelectOptionValue, T extends SelectOption<V>>({
  disabled,
  required,
  label,
  error,
  className,
  styles,
  id,
  edit = true,
  menuPlacement = "auto",
  menuFontSize = "xs",
  emptyReadOnlyValue,
  displayErrorMessage,
  description,
  inputClassName,
  containerClassName,
  ...props
}: FormFieldSelectProps<V, T>) => {
  const isCreatable = Boolean(props.onCreateOption);
  const fieldId = useEnsureId({ customId: id });

  const errorId = useId();

  const selectStyles = useMemo(() => {
    const commonStyles = getFormFieldSelectStyles<V, T, false>(menuPlacement, menuFontSize);

    return mergeSelectStyles(commonStyles, styles);
  }, [menuPlacement, menuFontSize, styles]);

  const selectedOption = useMemo(
    () => findSelectedOption(props.options, props.value),
    [props.options, props.value]
  );

  return (
    <FormField
      disabled={disabled}
      required={required}
      label={label}
      error={error}
      className={className}
      containerClassName={containerClassName}
      displayErrorMessage={displayErrorMessage}
      edit={edit}
      errorId={errorId}
      description={description}
      id={fieldId}
    >
      {edit ? (
        <div className={cx(inputClassName, cxFormFieldStyle.wrapper)}>
          <Select
            inputId={fieldId}
            isClearable={!required}
            {...props}
            styles={selectStyles}
            isDisabled={disabled}
            menuPlacement={menuPlacement}
            aria-invalid={error ? "true" : undefined}
            aria-errormessage={error ? errorId : undefined}
          />
        </div>
      ) : (
        <span aria-labelledby={fieldId} className={cxFormFieldStyle.controlValueOnly}>
          {selectedOption || (isCreatable && props.value) ? (
            <DisplayValue
              display={props.display}
              option={selectedOption ?? ({ value: props.value, label: props.value } as T)}
            />
          ) : (
            emptyReadOnlyValue ?? "-"
          )}
        </span>
      )}
    </FormField>
  );
};
