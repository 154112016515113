import { getActivityStorage, useActivityStorage } from "@libs/storage/activity";
import { SIGNED_IN_IDLE_TIMEOUT } from "config/react";

export const getPatientActivityStorage = (storage: Storage) => {
  return getActivityStorage(storage, SIGNED_IN_IDLE_TIMEOUT);
};

export const usePatientActivityStorage = () => {
  return useActivityStorage(SIGNED_IN_IDLE_TIMEOUT);
};
