import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { paths } from "router/paths";

type Props = {
  email?: string;
};
export const AlreadyHaveAnAccount: React.FC<Props> = ({ email }) => {
  const { t } = useTranslation();

  return (
    <div className="text-sm">
      <span>{t("app.page.labels.alreadyHaveAccount")}</span>
      <Link
        className="text-primaryTheme font-sansSemiBold ml-2 hover:opacity-70"
        to={paths.signIn({ email })}
      >
        {t("app.page.labels.signin")}
      </Link>
    </div>
  );
};
