import { HOUR_IN_MINUTES, MINUTE_IN_SECONDS, SECOND_IN_MS } from "@libs/utils/date";

import { wrapStorage } from "@libs/storage/wrapStorage";
import { StorageNamespaces } from "storage/namespaces";

export type SelfBookingV1 = {
  existingPatient?: boolean;
  isoDate?: string;
  isoTime?: string;
  providerId?: number;
  providerName?: string;
  firstName?: string;
  lastName?: string;
  dob?: string;
  email?: string;
  phone?: string;
  comments?: string;
  seenSooner?: boolean;
  categoryId?: number;
  hasInsurance?: boolean;
  carrierId?: number;
  carrierName?: string;
};

const TWO_HOURS = 2;
const expires = TWO_HOURS * HOUR_IN_MINUTES * MINUTE_IN_SECONDS * SECOND_IN_MS;

const version = "v1";
const key = "selfBooking";

export const getSelfBookingStorage = (sessionStorage: Storage) => {
  const storage = wrapStorage<Partial<SelfBookingV1>, StorageNamespaces>(
    StorageNamespaces.selfBooking,
    sessionStorage
  );

  return {
    getSelfBookingData() {
      return storage.get(key, version);
    },
    setSelfBookingData(selfBookingData: Partial<SelfBookingV1> | null) {
      return selfBookingData
        ? storage.set(key, selfBookingData, {
            expires,
            version,
          })
        : storage.clear(key);
    },
  };
};
