import React from "react";
import Skeleton from "react-loading-skeleton";
import { InvoiceVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { InvoiceCard } from "components/Billing/InvoiceCard";
import { EmptyContent } from "components/Billing/InvoicesRoute/EmptyContent";

const LoadingState = () => <Skeleton count={5} className="h-16 my-2" />;

export const InvoiceList: React.FC<{
  invoicesQuery: UseInfiniteApiQueryResult<InvoiceVO[]>;
  className?: string;
  emptyContent?: string;
}> = ({ invoicesQuery, className, emptyContent }) => {
  const invoices = useFlattenPages(invoicesQuery.data);
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: invoicesQuery });

  return (
    <div ref={rootRef} className={cx("flex flex-col gap-4", className)}>
      {invoicesQuery.isFetched && invoices?.length === 0 ? (
        <EmptyContent>{emptyContent}</EmptyContent>
      ) : (
        <ScrollableInfiniteQueryResult
          infiniteQuery={invoicesQuery}
          loadMoreClassName="w-full"
          scrollRef={scrollRef}
          loading={<LoadingState />}
          loadMore={<LoadingState />}
        >
          {invoices?.map((item) => {
            return <InvoiceCard key={item.uuid} invoice={item} />;
          })}
        </ScrollableInfiniteQueryResult>
      )}
    </div>
  );
};
