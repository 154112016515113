import { useTranslation } from "react-i18next";
import { Button } from "@libs/components/UI/Button";

export const ResendCode: React.FC<{ onClick: Func }> = ({ onClick }) => {
  const { t } = useTranslation();

  return (
    <Button className="text-sm" onClick={onClick} theme="link">
      {t("app.page.labels.resendCode")}
    </Button>
  );
};
