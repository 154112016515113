import { cx } from "@libs/utils/cx";
import { AsyncButton } from "@libs/components/UI/AsyncButton";

type Props = {
  form?: string;
  onClickPrimary?: Func;
  onClickSecondary?: Func;
  primaryText: string;
  secondaryText?: string;
  isSubmitting?: boolean;
  primaryButtonDisabled?: boolean;
  secondaryButtonDisabled?: boolean;
  isSubmittingSecondary?: boolean;
  primaryButtonClassName?: string;
  className?: string;
};
export const StickySubmissionFooter: React.FC<Props> = ({
  form,
  onClickPrimary,
  onClickSecondary,
  primaryText,
  secondaryText,
  isSubmitting = false,
  primaryButtonDisabled = false,
  secondaryButtonDisabled = false,
  isSubmittingSecondary = false,
  primaryButtonClassName,
  className,
}) => {
  return (
    <div
      className={cx(
        `bottom-0
         left-0
         right-0
         w-full
         flex
         gap-8
         z-10
         items-center
         justify-center
         bg-white
         shadow-[2px_-2px_4px_rgba(188,188,188,0.25)]
         px-4
         h-20`,
        className
      )}
    >
      {onClickSecondary && secondaryText && (
        <AsyncButton
          disabled={secondaryButtonDisabled}
          isLoading={isSubmittingSecondary}
          theme="secondary"
          className="min-w-button"
          onClick={onClickSecondary}
        >
          {secondaryText}
        </AsyncButton>
      )}
      <AsyncButton
        type={form ? "submit" : "button"}
        form={form}
        isLoading={isSubmitting}
        theme="primary"
        className={cx("min-w-button", primaryButtonClassName)}
        disabled={primaryButtonDisabled}
        onClick={onClickPrimary}
      >
        {primaryText}
      </AsyncButton>
    </div>
  );
};
