import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { RequiredAsterisk } from "@libs/components/UI/RequiredAsterisk";

export interface FormFieldLabelProps {
  content?: ReactNode;
  id?: string;
  required?: boolean;
  className?: string;
  disabled?: boolean;
  error?: string;
  edit?: boolean;
  inline?: boolean;
}

const cxStyle = {
  label: ({
    disabled,
    error,
    id,
    edit,
  }: {
    disabled?: boolean;
    error?: string;
    id?: string;
    edit?: boolean;
    inline?: boolean;
  }) =>
    cx(
      "flex w-full",
      !disabled && edit && id && "cursor-pointer",
      !disabled && error && edit && "text-red",
      disabled && edit && "text-slate-400"
    ),
};

export const FormFieldLabel: React.FC<FormFieldLabelProps> = ({
  disabled,
  id,
  content,
  required,
  error,
  className,
  inline,
  edit = true,
}) => {
  const computedClassName = cx(cxStyle.label({ disabled, error, id, edit, inline }), className);

  return edit ? (
    <label className={computedClassName} htmlFor={id}>
      {content}
      {required && !disabled && <RequiredAsterisk />}
    </label>
  ) : (
    <span className={computedClassName} id={id}>
      {content}
    </span>
  );
};
