import { useTranslation } from "react-i18next";
import { ButtonInternalLink, ButtonLink } from "@libs/components/UI/ButtonLink";

import { FC } from "react";
import { redirect, redirectDocument } from "react-router-dom";
import { getApiClient } from "@libs/api/clients";
import { UserAccountVO } from "@libs/api/generated-api";
import { captureException } from "@sentry/react";
import { getTokensForApi, getTokensForAuthCheck } from "@libs/auth/getTokens";
import { normal14 } from "assets/styles/textSize";
import { SignInWrapper } from "components/SignIn/SignInWrapper";
import { headers } from "api/headers";
import { PatientRouterContext } from "router/types";
import { paths } from "router/paths";
import { getPatientActivityStorage } from "storage/activity";

export const loader =
  ({ baseUrl, storage }: PatientRouterContext) =>
  async () => {
    const tokens = await getTokensForAuthCheck(storage.localStorage);

    if (!tokens.identity) {
      return redirectDocument(paths.signIn());
    }

    const activityStorage = getPatientActivityStorage(storage.localStorage);

    if (!activityStorage.isRecentlyActive()) {
      return redirect(paths.signOut());
    }

    const apiClient = getApiClient({
      baseUrl,
      headers,
      onRequestTokens: () => getTokensForApi(storage.localStorage),
    });

    let accounts: UserAccountVO[] = [];

    try {
      const accountsResponse = await apiClient.user.getUserAccounts({ userTypes: ["PATIENT"] });

      accounts = accountsResponse.data.data;
    } catch (e) {
      captureException(e);

      return redirect(paths.signOut({ signOutReason: "GET_USER_ACCOUNTS_ERROR" }));
    }

    if (accounts.length > 0) {
      return redirect(paths.selectAccount());
    }

    return null;
  };

export const SignInAccountNotFound: FC = () => {
  const { t } = useTranslation();

  return (
    <SignInWrapper title={t("accountNotFound.title")} includeBranding={false}>
      <div className="flex flex-col space-y-4">
        <div className={normal14}>{t("accountNotFound.body")}</div>
        <ButtonLink href={paths.selectAccount()}>{t("Retry")}</ButtonLink>
        <ButtonInternalLink to={paths.signOut()} theme="secondary">
          {t("app.page.labels.signOut")}
        </ButtonInternalLink>
      </div>
    </SignInWrapper>
  );
};
