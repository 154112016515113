import { PracticeInfoVO } from "@libs/api/generated-api";
import { AnonPatientHeader } from "components/UI/AnonPatientHeader";

export const FormTaskChrome: React.FC<{
  practice: PracticeInfoVO;
  children: React.ReactNode;
  patientName?: string;
}> = ({ children, practice, patientName }) => {
  return (
    <div className="relative min-h-0 h-full flex flex-col">
      <div className="flex-1 flex flex-col items-center bg-white">
        <AnonPatientHeader practiceInfo={practice} patientName={patientName} />
        <div className="max-w-2xl w-full">{children}</div>
      </div>
    </div>
  );
};
