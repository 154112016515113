import { FormPatientInfoElementVO, FormVO } from "@libs/api/generated-api";
import { useMemo } from "react";
import { findElement, isPatientInfoElement } from "components/PatientForms/utils";

export const useOptionalFields = (form: FormVO | undefined) => {
  const optionalFields = useMemo(() => {
    if (form) {
      const match = findElement(form, "tag", "PATIENT_INFO");

      if (match) {
        const { element } = match;

        if (isPatientInfoElement(element)) {
          return element.optionalFields;
        }
      }
    }

    const fallBack: FormPatientInfoElementVO["optionalFields"] = ["MIDDLE_NAME", "PREFERRED_PRONOUNS"];

    return fallBack;
  }, [form]);

  return optionalFields;
};
