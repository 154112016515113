import { ReactNode } from "react";
import { SafelyRenderedContent } from "@libs/components/UI/SafelyRenderedContent";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { LoadingContent } from "@libs/components/UI/LoadingContent";

interface Props {
  isDark?: boolean;
  isLoading: boolean;
  isLoadingError: boolean;
  // Custom content to display before the first data loaded
  loading?: ReactNode;

  // Custom content to display when any data fails to load at all
  loadError?: ReactNode;
  children?: ReactNode;
}

export const AsyncSection: React.FC<Props> = ({
  isLoading,
  isLoadingError,
  loadError,
  loading,
  children,
  isDark,
}) => {
  return (
    <>
      {isLoadingError ? (
        <SafelyRenderedContent fallback={<ErrorContent />}>{loadError}</SafelyRenderedContent>
      ) : isLoading ? (
        <SafelyRenderedContent fallback={<LoadingContent isDark={isDark} />}>{loading}</SafelyRenderedContent>
      ) : (
        children
      )}
    </>
  );
};
