import { InsuranceDetailsVO } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const addPatientInsurance = makeMutation({
  mutationKey: ["practices", "addPatientInsurance"],
  formatParams: (args: { practiceId: number; patientId: number; data: InsuranceDetailsVO }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { patientId, practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurances"),
        {
          practiceId,
          patientId,
        },
      ]);
    },
  }),
});
