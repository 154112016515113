import { useTranslation } from "react-i18next";
import React from "react";
import { FormVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as DownloadPdf } from "@libs/assets/icons/download.svg";
import {
  PatientPreviewAdjustAttributesModal,
  PatientPreviewFormAttributes,
} from "components/PatientForms/PatientPreviewAdjustAttributesModal";

type Props = {
  formData: FormVO;
  patientAttributes: PatientPreviewFormAttributes;
  onClickPdf: Func;
  onChangePatientAttributes: (newAttributes: Partial<PatientPreviewFormAttributes>) => void;
};

export const PatientFormPreviewHeader: React.FC<Props> = ({
  formData,
  onChangePatientAttributes,
  onClickPdf,
  patientAttributes,
}) => {
  const { t } = useTranslation();
  const modalOpen = useBoolean(false);
  const { gender, age } = patientAttributes;
  const genderLabel = t(`genderOption.${gender ?? "UNKNOWN"}`) as string;

  return (
    <>
      {modalOpen.isOn && (
        <PatientPreviewAdjustAttributesModal
          onChangePatientAttributes={(newAttributes) => {
            modalOpen.off();
            onChangePatientAttributes(newAttributes);
          }}
          patientAttributes={patientAttributes}
        />
      )}
      <div
        className={`
          h-16
          flex
          flex-row
          items-center
          justify-between
          px-4
          gap-1
          bg-secondaryTheme
          fixed
          z-20
          top-0
          left-0
          right-0
        `}
      >
        <div className="flex gap-2 text-white font-sansSemiBold">
          {formData.title}
          <ButtonIcon
            tooltip={{ content: "Download PDF", theme: "SMALL" }}
            theme="darkBackground"
            SvgIcon={DownloadPdf}
            onClick={onClickPdf}
          />
        </div>
        <div className="flex items-center gap-2">
          <span className="text-white font-sansSemiBold text-sm">{t("pages.formPreview.previewTitle")}</span>
          <Button size="small" className="bg-white" theme="tertiary" onClick={modalOpen.on}>
            {genderLabel}, {age}
          </Button>
        </div>
      </div>
      <div className="h-16" />
    </>
  );
};
