import ReactDOM from "react-dom";

export const REGISTRATION_FOOTER_ID = "registration-footer";
export const RegistrationFooterPortalDestination = () => <div id={REGISTRATION_FOOTER_ID} />;

export const RegistrationFooterPortal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const elementId = document.querySelector(`#${REGISTRATION_FOOTER_ID}`);

  if (!elementId) {
    return null;
  }

  return ReactDOM.createPortal(children, elementId);
};
