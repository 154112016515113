import { FC, useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { getFullUrl } from "@libs/utils/location";
import { SIGNED_IN_IDLE_TIMEOUT } from "config/react";
import { IdleTimer } from "components/Main/IdleTimer";
import { paths } from "router/paths";

const SIGNED_IN_PROMPT_TIMEOUT = 300_000;
const SIGNED_IN_THROTTLE_ACTIVITY = 60_000;
const SIGNED_IN_IDLE_VALUES = {
  timeout: SIGNED_IN_IDLE_TIMEOUT,
  promptBeforeIdle: SIGNED_IN_PROMPT_TIMEOUT,
  eventsThrottle: SIGNED_IN_THROTTLE_ACTIVITY,
  name: "session",
};

export const SessionIdleTimer: FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const locationRef = useRef(location);

  // referencing locationRef vs location
  // because we need to keep this function a stable reference
  locationRef.current = location;

  const handleIdle = useCallback(() => {
    navigate(paths.signOut({ returnUrl: getFullUrl(locationRef.current) }));
  }, [navigate]);

  return <IdleTimer onIdle={handleIdle} {...SIGNED_IN_IDLE_VALUES} />;
};
