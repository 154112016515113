import { useSnackbar } from "notistack";
import { captureException as sentryCaptureException } from "@sentry/react";
import { useTranslation } from "react-i18next";
import { PaymentProfileVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { createPaymentProfileByToken } from "api/billing/mutations";
import { useHandleError } from "api/handleErrorResponse";
import { defaultSnackbarOptions } from "utils/snackbar";

export const useCreatePaymentProfile = ({
  onSavedNewCardHandler,
  isDefaultCard,
}: {
  isDefaultCard: boolean;
  onSavedNewCardHandler: (paymentProfile: PaymentProfileVO) => void;
}) => {
  const { t } = useTranslation();
  const handleError = useHandleError();
  const { enqueueSnackbar } = useSnackbar();
  const [createPaymentProfileByTokenMutation] = useApiMutations([createPaymentProfileByToken]);

  const { practiceId, id: patientId } = useAccount();

  const createPaymentProfile: FinixFormSubmissionCallback = (error, response) => {
    if (error) {
      sentryCaptureException(new Error("Error occured while submitting credit card details to Finix"));
      enqueueSnackbar(t("billing.addCardFailed"), defaultSnackbarOptions);

      return;
    }

    createPaymentProfileByTokenMutation.mutate(
      {
        practiceId,
        patientId,
        data: {
          owner: { id: patientId, type: "PATIENT" },
          type: "CARD",
          token: response.data.id,
          gateway: "finix",
          markAsDefault: isDefaultCard,
        },
      },
      {
        onError: handleError,
        onSuccess: (data) => {
          onSavedNewCardHandler(data.data.data);
        },
      }
    );
  };

  return {
    createPaymentProfile,
    isSaving: createPaymentProfileByTokenMutation.isLoading,
  };
};
