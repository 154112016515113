import React from "react";
import { FamilyRelationVO, PatientInsuranceVO } from "@libs/api/generated-api";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { useAccount } from "@libs/contexts/AccountContext";
import { getPatientInsuranceQuery } from "api/patientInsurance/queries";

export type SubscriberInfo = {
  idType?: "ssn" | "subscriberId";
  subscriberId?: string;
  ssn?: string;
  ssnLastFour?: string;
  carrierId?: number;
  employer?: string;
};
export type EditableInsurance = {
  type: PatientInsuranceVO["type"];
  primarySubscriber: SubscriberInfo;
  dependentSubscriber: {
    firstName?: string;
    lastName?: string;
    dob?: string;
    relation?: FamilyRelationVO["relation"];
  } & SubscriberInfo;
};

const initialDraftState: EditableInsurance = {
  type: "PRIMARY_SUBSCRIBER",
  primarySubscriber: {
    idType: "ssn",
  },
  dependentSubscriber: {
    idType: "subscriberId",
  },
};

export const useInsuranceDraft = (insuranceId?: number) => {
  const { id: patientId, practiceId } = useAccount();
  const isCreating = !insuranceId;

  const [{ data: insurance, isLoading }] = useApiQueries([
    getPatientInsuranceQuery({
      args: { patientId, practiceId, insuranceId: insuranceId as number },
      queryOptions: { enabled: !isCreating },
    }),
  ]);

  const [draftInsurance, handleUpdateInsuranceDraft, setInsuranceState] =
    useObjectState<EditableInsurance>(initialDraftState);

  useSyncOnce((data) => {
    const { patientInsurance } = data;
    const { subscriber, relationshipWithSubscriber } = patientInsurance;
    const baseInfo = {
      carrierId: subscriber.carrierId,
      idType: subscriber.ssnLastFour ? "ssn" : ("subscriberId" as SubscriberInfo["idType"]),
      subscriberId: subscriber.externalMemberId,
      ssnLastFour: subscriber.ssnLastFour,
      employer: subscriber.employer,
    };

    handleUpdateInsuranceDraft({
      type: patientInsurance.type,
      primarySubscriber: {
        ...baseInfo,
      },
      dependentSubscriber: {
        firstName: subscriber.firstName,
        lastName: subscriber.lastName,
        relation: relationshipWithSubscriber,
        dob: subscriber.dob,
        ...baseInfo,
      },
    });
  }, insurance);

  return {
    draftInsurance,
    existingInsurance: insurance,
    handleUpdateInsuranceType: React.useCallback(
      (type: PatientInsuranceVO["type"]) => {
        handleUpdateInsuranceDraft({ type });
      },
      [handleUpdateInsuranceDraft]
    ),
    handleUpdatePrimarySubscriber: React.useCallback(
      (primarySubscriber: EditableInsurance["primarySubscriber"]) => {
        setInsuranceState({
          ...draftInsurance,
          primarySubscriber: {
            ...draftInsurance.primarySubscriber,
            ...primarySubscriber,
          },
        });
      },
      [setInsuranceState, draftInsurance]
    ),
    handleUpdateDependentSubscriber: React.useCallback(
      (dependentSubscriber: EditableInsurance["dependentSubscriber"]) => {
        setInsuranceState({
          ...draftInsurance,
          dependentSubscriber: {
            ...draftInsurance.dependentSubscriber,
            ...dependentSubscriber,
          },
        });
      },
      [setInsuranceState, draftInsurance]
    ),
    isLoading: isLoading && !isCreating,
    isCreating,
    insuranceId,
    reset: React.useCallback(() => {
      setInsuranceState(initialDraftState);
    }, [setInsuranceState]),
  };
};
export type UseInsuranceDraftResult = ReturnType<typeof useInsuranceDraft>;
export type InsuranceDraftFields = UseInsuranceDraftResult["draftInsurance"];
