import React from "react";
import { useTranslation } from "react-i18next";
import { FormNumberInputElementVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { FormatReadOnlyValue } from "@libs/components/UI/FormFieldInput";
import { FormFieldNumberInput } from "@libs/components/UI/FormFieldNumberInput";
import { PatientResponses, ResponseChangedCallback } from "components/PatientForms/hooks/usePatientResponses";

type Props = {
  id: string;
  element: FormNumberInputElementVO;
  edit: boolean;
  responsesById: PatientResponses;
  onChangeResponse: ResponseChangedCallback;
  error?: string;
  formatReadOnlyValue: FormatReadOnlyValue;
};

export const PatientFormNumberElement: React.FC<Props> = ({
  element,
  id,
  edit,
  responsesById,
  formatReadOnlyValue,
  onChangeResponse,
  error,
}) => {
  const { t } = useTranslation();
  const { title, settings, uuid, min, max } = element;
  const required = settings.includes("REQUIRED");

  // Disclosure is meant to immediately tell the user they're entering a number out of the allowed range
  const response = responsesById[uuid];
  const handleNumberChange = React.useCallback(
    (newValue: number | undefined) => {
      onChangeResponse(uuid, {
        type: "NUMBER",
        response: isDefined(newValue) ? newValue : undefined,
      });
    },
    [onChangeResponse, uuid]
  );
  const rangeDisclosure = React.useMemo(() => {
    if (isDefined(min) && isDefined(max)) {
      return t("validation.range", { min, max }) as string;
    } else if (isDefined(min)) {
      return t("validation.minimum", { min }) as string;
    } else if (isDefined(max)) {
      return t("validation.maximum", { max }) as string;
    }

    return undefined;
  }, [min, max, t]);

  return (
    <div className="flex flex-col gap-1">
      <FormFieldNumberInput
        id={id}
        edit={edit}
        label={title}
        formatReadOnlyValue={formatReadOnlyValue}
        required={required}
        onValueChange={handleNumberChange}
        error={error}
        min={min}
        clamp
        max={max}
        value={response?.type === "NUMBER" ? response.response : undefined}
      />
      {!error && rangeDisclosure && <div className="text-xs text-greyLight">{rangeDisclosure}</div>}
    </div>
  );
};
