import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { PaymentProfileVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { deletePaymentProfile, markPaymentProfileAsDefault } from "api/billing/mutations";
import { ResponsiveActionModal } from "components/UI/ResponsiveActionModal";
import { getPaymentProfiles } from "api/billing/queries";

import { TitleBar } from "components/UI/TitleBar";
import { LoadingPaymentProfiles, PaymentProfile } from "components/UI/PaymentProfile";
import { AddButton } from "components/UI/AddButton";
import { StickySubmissionFooter } from "components/UI/StickySubmissionFooter";
import { paths } from "router/paths";

const FooterButtons = ({
  isDefaultSelected,
  isSettingDefault,
  onClickDelete,
  onClickSetDefault,
  selectedCardCount,
  isHsaCardSelected,
}: {
  isDefaultSelected?: boolean;
  isHsaCardSelected?: boolean;
  selectedCardCount: number;
  isSettingDefault: boolean;
  onClickDelete: Func;
  onClickSetDefault: Func;
}) => {
  const { t } = useTranslation();

  if (selectedCardCount === 0) {
    return null;
  }

  return (
    <StickySubmissionFooter
      secondaryText={t("Mark as default")}
      primaryText={t("Delete card")}
      onClickSecondary={onClickSetDefault}
      onClickPrimary={onClickDelete}
      primaryButtonDisabled={isDefaultSelected || isSettingDefault}
      secondaryButtonDisabled={isDefaultSelected || selectedCardCount > 1 || isHsaCardSelected}
      isSubmittingSecondary={isSettingDefault}
      className="sticky"
    />
  );
};

export const ManagePaymentPage = () => {
  const { practiceId, id: patientId } = useAccount();

  const { t } = useTranslation();

  const [selectedCards, setSelectedCards] = useState(new Set<string>([]));
  const deleteConfirmation = useBoolean(false);
  const [{ data: savedCards, isLoading }] = useApiQueries([
    getPaymentProfiles({ args: { patientId, practiceId } }),
  ]);
  const [
    { mutate: setProfileAsDefaultMutate, isLoading: isSettingDefault },
    { mutateAsync: deletePaymentProfileMutate, isLoading: isDeleting },
  ] = useApiMutations([markPaymentProfileAsDefault, deletePaymentProfile]);
  const isLoaded = savedCards && !isLoading;
  const cardSelections = React.useMemo(() => {
    return {
      isDefaultSelected: savedCards?.some((item) => item.isDefault && selectedCards.has(item.uuid)),
      isHsaCardSelected: savedCards?.some(
        (item) => item.card?.cardType === "HSA_FSA" && selectedCards.has(item.uuid)
      ),
    };
  }, [savedCards, selectedCards]);

  const onCardClicked = React.useCallback(
    (card: PaymentProfileVO) => {
      const newCards = new Set(selectedCards);

      if (newCards.has(card.uuid)) {
        newCards.delete(card.uuid);
      } else {
        newCards.add(card.uuid);
      }

      setSelectedCards(newCards);
    },
    [selectedCards]
  );

  const deselectAllCards = React.useCallback(() => {
    setSelectedCards(new Set([]));
  }, []);

  const deleteSelectedCards = React.useCallback(async () => {
    await Promise.all(
      (savedCards ?? [])
        .filter((item) => {
          return !item.isDefault && selectedCards.has(item.uuid);
        })
        .map((profile) =>
          deletePaymentProfileMutate({
            patientId,
            practiceId,
            paymentProfileUuid: profile.uuid,
          })
        )
    );
    deselectAllCards();
  }, [deletePaymentProfileMutate, deselectAllCards, patientId, practiceId, savedCards, selectedCards]);

  const markCardAsDefault = React.useCallback(() => {
    const nomination = savedCards?.find((item) => selectedCards.has(item.uuid));

    if (nomination) {
      setProfileAsDefaultMutate(
        {
          patientId,
          practiceId,
          paymentProfileUuid: nomination.uuid,
        },
        { onSuccess: deselectAllCards }
      );
    }
  }, [deselectAllCards, patientId, practiceId, savedCards, selectedCards, setProfileAsDefaultMutate]);

  return (
    <div className="relative flex flex-col h-full min-h-0">
      <TitleBar
        backTo={paths.account()}
        title={t("Payment Methods")}
        responsiveBackButton
        titleDisclosure={
          selectedCards.size > 0 && savedCards
            ? `(${selectedCards.size}/${savedCards.length} ${t("Selected")})`
            : ""
        }
        rightContent={
          selectedCards.size > 0 && (
            <Button theme="link" onClick={deselectAllCards}>
              {t("Deselect")}
            </Button>
          )
        }
      />
      <div className="flex-1 w-full overflow-y-auto pb-4 max-w-lg self-center">
        <div className="pt-4 px-4 space-y-4">
          {isLoaded ? (
            <>
              {savedCards.map((profile) => (
                <PaymentProfile
                  layout="checkbox"
                  selected={selectedCards.has(profile.uuid)}
                  key={profile.uuid}
                  profile={profile}
                  onClick={onCardClicked}
                />
              ))}
              <AddButton to={paths.addPaymentMethod()}>{t("Add Payment Method")}</AddButton>
            </>
          ) : (
            <LoadingPaymentProfiles />
          )}
        </div>

        {deleteConfirmation.isOn && (
          <ResponsiveActionModal
            text={
              selectedCards.size > 1
                ? t("Are you sure you want to delete these cards?")
                : t("Are you sure you want to delete this card?")
            }
            onClickPrimaryButton={deleteSelectedCards}
            onClose={deleteConfirmation.off}
            secondaryButtonLabel={t("No")}
            primaryButtonLabel={t("Yes")}
            isLoading={isDeleting}
            completedContent={t("Card deleted")}
            onClickSecondaryButton={deleteConfirmation.off}
          />
        )}

        <div className="h-[4rem]" />
      </div>

      {selectedCards.size > 0 && (
        <FooterButtons
          onClickDelete={deleteConfirmation.on}
          onClickSetDefault={markCardAsDefault}
          isSettingDefault={isSettingDefault}
          selectedCardCount={selectedCards.size}
          {...cardSelections}
        />
      )}
    </div>
  );
};
