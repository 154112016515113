import { FC, ReactNode } from "react";
import { AddressVO } from "@libs/api/generated-api";
import { MenuOptions } from "@libs/components/UI/MenuOptions";
import { formatAddress } from "@libs/utils/address";
import { isDefined } from "@libs/utils/types";
import { AsyncSection } from "@libs/components/UI/AsyncSection";
import { SelectMenuMessage } from "@libs/components/UI/SelectMenuMessage";

interface Props {
  onOptionClick: (option: { label: string; address: AddressVO; value: number }) => void;
  addresses: AddressVO[];
  title: ReactNode;
  isLoading: boolean;
  isLoadingError: boolean;
}

export const DefaultAddressMenuOptions: FC<Props> = ({
  onOptionClick,
  title,
  isLoading,
  isLoadingError,
  addresses,
}) => {
  return (
    <>
      <div className="px-3 py-2 font-sansSemiBold">{title}</div>
      <div className="min-h-8">
        <AsyncSection
          isLoading={isLoading}
          loading={<SelectMenuMessage>Loading...</SelectMenuMessage>}
          isLoadingError={isLoadingError}
        >
          {addresses.length ? (
            <MenuOptions
              onOptionClick={onOptionClick}
              options={addresses.map((option, index) => {
                const { lineOne, lineTwo } = formatAddress(option);
                const formatted = [lineOne, lineTwo].filter(isDefined).join(", ");

                return {
                  label: formatted,
                  address: option,
                  value: index,
                };
              })}
            />
          ) : (
            <SelectMenuMessage>No Family Members Found</SelectMenuMessage>
          )}
        </AsyncSection>
      </div>
    </>
  );
};
