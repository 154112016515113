import { cx } from "@libs/utils/cx";
import { semibold16 } from "assets/styles/textSize";
import { Card } from "components/UI/Cards";

export const EmptyContent: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Card inCarousel={false}>
      <div className="flex flex-col justify-center py-8">
        <p className={cx("text-center text-greyLight", semibold16)}>{children}</p>
      </div>
    </Card>
  );
};
