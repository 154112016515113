import {
  format,
  differenceInMinutes,
  formatRelative,
  fromUnixTime,
  addHours,
  addMinutes,
  getDate,
  getMonth,
  getYear,
  set,
  startOfDay,
  endOfDay,
  addWeeks,
  isAfter,
  isValid,
  isEqual,
  isSameDay,
} from "date-fns";
import { formatInTimeZone, utcToZonedTime } from "date-fns-tz";
import { enUS } from "date-fns/locale";
import { WorkingHourItemVO } from "@libs/api/generated-api";
import { isoTimeStringRegex } from "@libs/utils/regex";
import { sentenceCase } from "@libs/utils/casing";

export const YEAR_IN_DAYS = 365;
export const YEAR_IN_MONTHS = 12;
export const YEAR_IN_HOURS = 8760;
export const YEAR_IN_WORKING_HOURS = 2080; // 40 hour week
export const MONTH_IN_HOURS = 744; // 31 day month
export const WEEK_IN_HOURS = 168;
export const WEEK_IN_DAYS = 7;
export const DAY_IN_HOURS = 24;
export const HOUR_IN_MINUTES = 60;
export const MINUTE_IN_SECONDS = 60;
export const SECOND_IN_MS = 1000;
export const DAY_IN_MS = SECOND_IN_MS * MINUTE_IN_SECONDS * HOUR_IN_MINUTES * DAY_IN_HOURS;

export const TIME_PLACES = 2;

const AMPM_DIVIDE = 12;

export const YEAR_IN_MS = YEAR_IN_DAYS * DAY_IN_HOURS * HOUR_IN_MINUTES * MINUTE_IN_SECONDS * SECOND_IN_MS;

export const TWO_MONTHS = 2;
export const THREE_MONTHS = 3;
export const FOUR_MONTHS = 4;
export const SIX_MONTHS = 6;

// a date used when only the time matters e.g. formatToISOTimeString(getLocalDate(ANY_DAY,time))
// NOTE: This can be any date that is not a leap year day or a day that daylight savings time changes
export const ANY_DAY = "2022-12-25";

const LONG_DAY_OF_MONTH_FORMAT = "EEEE, MMMM d";
const SHORT_DAY_OF_MONTH_FORMAT = "EEE, MMM d";

/**
 * Formats a date to a long day of year format like "April 29, 1453".
 */
export const LONG_DAY_OF_YEAR_FORMAT = "MMMM d, yyyy";

/**
 * Formats a date to a month date format like "April 29".
 */
const MONTH_DATE_FORMAT = "LLL d";

export const LONG_LOCALIZED_DATE_TIME = "P p"; // 04/29/1453 12:00 AM
export const LONG_LOCALIZED_DATE = "P"; // 04/29/1453
export const SHORT_LOCALIZED_DATE = "MM/dd"; // 04/29
export const ISO_DATE = "yyyy-MM-dd";
export const ISO_TIME = "HH:mm:ss";
export const ISO_DATE_TIME = "yyyy-MM-dd'T'HH:mm:ss";

export type DayOfWeek = WorkingHourItemVO["dayOfWeek"];

export const getTimezoneName = (tz: string) => {
  // Only pulls the timezone, in US english.  No date/time extracted
  return formatInTimeZone(Date.now(), tz, "zzzz", { locale: enUS });
};

/****** Date Conversions ******/

export const getTimeValues = (time: string) => {
  if (time.trim() === "") {
    return { hours: 0, minutes: 0, seconds: 0 };
  }

  const [hours, minutes, seconds] = time.split(":").map((part) => Number.parseInt(part, 10));

  return {
    hours,
    minutes,
    seconds,
  };
};

/**
 * Formats an 03/22/2021 to an ISO Date string like 2021-03-22.
 * @param date formatted date (MM/DD/YYYY)
 * @returns The ISO date string (YYYY-MM-dd)
 */
export const formattedDateToISODate = (date: string) => {
  const [month, day, year] = date.split("/");

  return `${year}-${month}-${day}`;
};

/****** API I/O date formats ******/

export const formatAsISODate = (date: Date) => format(date, ISO_DATE);
export const formatAsISOTime = (date: Date) => format(date, ISO_TIME);
export const formatAsISODateTime = (date: Date) => format(date, ISO_DATE_TIME);

export const isISOTime = (time: string) =>
  isoTimeStringRegex.test(time) && !Number.isNaN(Date.parse(`${ANY_DAY}T${time}`));

export const nowInTimezone = (timezone: string) => utcToZonedTime(new Date(), timezone);
export const startOfDayInTimezone = (timezone: string, date: Date) =>
  startOfDay(utcToZonedTime(date, timezone));

export const endOfDayInTimezone = (timezone: string, date: Date) => endOfDay(utcToZonedTime(date, timezone));

export function getLocalDate(date: string, time?: string) {
  // handle the case where date has the format "2021-03-22T00:00:00"
  if (date.includes("T")) {
    // If time is passed it overrides the time in the date string
    if (time) {
      const day = date.split("T")[0];

      return new Date(`${day}T${time}`);
    }

    return new Date(date);
  }

  return new Date(`${date}T${time ?? "00:00:00"}`);
}

/****** Date Operations ******/

// time comes in format HH:MM:SS
export const setTimeOnDate = (date: Date, time: string) => {
  const { hours, minutes, seconds } = getTimeValues(time);

  return set(date, { hours, minutes, seconds });
};

export const isLeftISOTimeAfterRightISOTime = (left: string, right: string) => {
  return getLocalDate(ANY_DAY, left).getTime() > getLocalDate(ANY_DAY, right).getTime();
};

export const isLeftISODateAfterRightISODate = (left: string, right: string) => {
  return isAfter(getLocalDate(left), getLocalDate(right));
};

export const isLeftISODateAfterOrEqualRightISODate = (left: string, right: string) => {
  return isAfter(getLocalDate(left), getLocalDate(right)) || isEqual(getLocalDate(left), getLocalDate(right));
};

export const getISOTimeDiffInMinutes = (endTime: string, startTime: string) => {
  return differenceInMinutes(getLocalDate(ANY_DAY, endTime), getLocalDate(ANY_DAY, startTime));
};

export const addMinutesToISOTime = (time: string, minutes: number) => {
  return formatAsISOTime(addMinutes(getLocalDate(ANY_DAY, time), minutes));
};

export const addHoursToISOTime = (time: string, hours: number) => {
  return formatAsISOTime(addHours(getLocalDate(ANY_DAY, time), hours));
};

/****** UI Display formats ******/

/**
 * Formats an ISO Date string like 2021-03-22 into 03/22/2021.
 * @param date The ISO Date String. (YYYY-MM-dd)
 * @returns The date string formatted in MM/DD/YYYY.
 */
export const formatISODate = (date: string, pattern?: string) =>
  format(getLocalDate(date), pattern ?? LONG_LOCALIZED_DATE);

export const formatDate = (date: Date, pattern?: string) => format(date, pattern ?? LONG_LOCALIZED_DATE);

export const formatDateWindow = ({
  startDate,
  endDate,
  format: formatString,
  delimiter = "to",
}: {
  startDate: Date;
  endDate: Date;
  format: string;
  delimiter?: string;
}) => {
  return isSameDay(startDate, endDate)
    ? format(startDate, formatString)
    : `${format(startDate, formatString)} ${delimiter} ${format(endDate, formatString)}`;
};

export const formatLongDayOfMonth = (date: Date) => {
  return format(date, LONG_DAY_OF_MONTH_FORMAT);
};

export const formatShortDayOfMonth = (date: Date) => {
  return format(date, SHORT_DAY_OF_MONTH_FORMAT);
};

export const formatLongDayOfYear = (date: Date) => {
  return format(date, LONG_DAY_OF_YEAR_FORMAT);
};

export const formatMonthDate = (date: Date) => {
  return format(date, MONTH_DATE_FORMAT);
};

/**
 * Formats a Date object as a short time string in the format "10 AM" or "10:15
 * AM", without displaying the minutes if they are zero.
 *
 * @param {Date} date - The Date object to format.
 * @returns {string} A string representing the formatted time.
 *
 * @example
 * const date = new Date();
 * const timeString = formatShortAmPmTime(date); // e.g. "10 AM" or "10:15 AM"
 */
export const formatShortAmPmTime = (date: Date) => {
  return format(date, "h:mm a").replace(":00", "");
};

/**
 * Converts a unix time to a to a human-readable date time format relative to
 * the current time.
 *
 * @param {number} unixTime
 * @param {string} timezone
 * @param {Date} now
 * @param {Record<string, string>} customFormatRelativeLocale
 * @return {string} A human-readable date time string.
 *
 * @example
 *
 *   formatUnixTimestampRelative(...) // 2:00 PM
 *   formatUnixTimestampRelative(...) // Yesterday 7:24 PM
 *   formatUnixTimestampRelative(...) // Wed 9:00 AM
 *   formatUnixTimestampRelative(...) // 01/28/2022
 */
export const formatUnixTimestampRelative = (
  unixTime: number,
  timezone: string,
  customFormatRelativeLocale?: Record<string, string>
) => {
  const formatRelativeLocale = customFormatRelativeLocale ?? {
    today: "'Today' h:mm a",
    tomorrow: "'Tomorrow' h:mm a",
    nextWeek: "eee",
    yesterday: "'Yesterday' h:mm a",
    lastWeek: "eee h:mm a",
    other: "MM/dd/yyyy h:mm a",
  };

  const locale: Locale = {
    ...enUS,
    formatRelative: (token: keyof typeof formatRelativeLocale) => formatRelativeLocale[token],
  };

  return formatRelative(utcToZonedTime(fromUnixTime(unixTime), timezone), nowInTimezone(timezone), {
    locale,
  });
};

export const formatUnixTimestamp = (unixTime: number, timezone: string, pattern?: string) =>
  formatInTimeZone(fromUnixTime(unixTime), timezone, pattern ?? LONG_LOCALIZED_DATE_TIME);

const FOUR_LETTER_DAYS: Set<DayOfWeek> = new Set(["TUESDAY", "THURSDAY"]);

/**
 * Returns an abbreviated day of the week.
 *
 * @param {DayOfWeek} day - The day of the week.
 *
 * @returns {string} The abbreviated day of the week.
 */
export const abbreviatedDayOfWeek = (day: DayOfWeek) => {
  const numAbbreviated = FOUR_LETTER_DAYS.has(day) ? 4 : 3;

  return sentenceCase(day).slice(0, numAbbreviated);
};

/**
 * Formats an ISO time string into AM/PM format.
 *
 * @param {string} time - The ISO time string to format.
 *
 * @returns {string} The formatted time in AM/PM format.
 */
export const formatISOTimeAsAmPm = (time: string) =>
  format(setTimeOnDate(getLocalDate(ANY_DAY), time), "hh:mm a");

export const formatISOTimeAsAmPmShortHr = (time: string) =>
  format(setTimeOnDate(getLocalDate(ANY_DAY), time), "h:mm a");

/**
 * Formats a short ISO time string into AM/PM format with short hour.
 *
 * @param {string} isoTime - The short ISO time string to format.
 *
 * @returns {string} The formatted time in AM/PM format with short hour.
 * formatShortISOTimeAsAmPmShortHr("12:00:00") => "12 PM"
 * formatShortISOTimeAsAmPmShortHr("12:30:00") => "12:30 PM"
 */
export const formatShortISOTimeAsAmPmShortHr = (time: string) =>
  formatShortAmPmTime(setTimeOnDate(getLocalDate(ANY_DAY), time));

/**
 * Formats a range of ISO time strings into AM/PM format.
 *
 * @param {string} startTime - The start time of the range.
 * @param {string} endTime - The end time of the range.
 *
 * @returns {string} The formatted time range in AM/PM format.
 */
export const formatISOTimeRangeAsAmPm = (startTime: string, endTime: string) =>
  `${formatISOTimeAsAmPm(startTime)} - ${formatISOTimeAsAmPm(endTime)}`;

/**
 * Formats a range of ISO time strings into AM/PM format with short hour.
 *
 * @param {string} startTime - The start time of the range.
 * @param {string} endTime - The end time of the range.
 *
 * @returns {string} The formatted time range in AM/PM format with short hour.
 */
export const formatISOTimeRangeAsAmPmShort = (startTime: string, endTime: string) => {
  const start = getLocalDate(ANY_DAY, startTime);
  const end = getLocalDate(ANY_DAY, endTime);
  const startMinutes = start.getMinutes();
  const endMinutes = end.getMinutes();
  const startHasMinutes = startMinutes > 0;
  const endHasMinutes = endMinutes > 0;
  const hasSameAMPM =
    (start.getHours() < AMPM_DIVIDE && end.getHours() < AMPM_DIVIDE) ||
    (start.getHours() >= AMPM_DIVIDE && end.getHours() >= AMPM_DIVIDE);
  const startTimeFormat = `h${startHasMinutes ? ":mm" : ""}${hasSameAMPM ? "" : " a"}`;
  const endTimeFormat = `h${endHasMinutes ? ":mm" : ""} a`;

  return `${format(start, startTimeFormat)} - ${format(end, endTimeFormat)}`;
};

/**
 * Sets the year, month, and date of a date to match another date.
 *
 * @param {Date} date - The date to modify.
 * @param {Date} day - The date to match.
 *
 * @returns {Date} The modified date.
 */
export const setToDay = (date: Date, day: Date) =>
  set(date, { year: getYear(day), month: getMonth(day), date: getDate(day) });

export type DaysOfWeekEnum =
  | "MONDAY"
  | "TUESDAY"
  | "WEDNESDAY"
  | "THURSDAY"
  | "FRIDAY"
  | "SATURDAY"
  | "SUNDAY";
export type DaysOfWeek = Capitalize<Lowercase<DaysOfWeekEnum>>;

/**
 * Formats a date into a day of the week.
 *
 * @param {Date} date - The date to format.
 *
 * @returns {DaysOfWeek} The formatted day of the week.
 */
const formatDayOfWeek = (date: Date) => format(date, "EEEE") as DaysOfWeek;

export const formatEnumDayOfWeek = (date: Date) => formatDayOfWeek(date).toUpperCase() as DaysOfWeekEnum;

export const weekdays: DaysOfWeekEnum[] = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"];
export const allDays: DaysOfWeekEnum[] = ["SUNDAY", ...weekdays, "SATURDAY"];
export const mondayFirstWeek: DaysOfWeekEnum[] = [...weekdays, "SATURDAY", "SUNDAY"];

/**
 * Determines the occurrence of a specific day of the week in a month for a given date.
 *
 * @param {Date} date - The date to check.
 *
 * @returns {number} The occurrence of the day of the week in the month (e.g., 1 for the first occurrence, 2 for the second, etc.).
 */
export const getOccurrenceOfDayOfWeekInMonth = (date: Date) => {
  const dayOfMonth = getDate(date);

  return {
    occurrence: Math.floor(dayOfMonth / WEEK_IN_DAYS) + (dayOfMonth % WEEK_IN_DAYS === 0 ? 0 : 1),
    dayOfWeek: formatDayOfWeek(date),
  };
};

export const MAX_DAY_OCCURRENCES_IN_MONTH = 5;

const MIN_DAY_OCCURRENCES_IN_MONTH = 4;

/**
 * Checks if a given date is the last occurrence of its weekday in its month.
 *
 * @param {Date} date - The date to check.
 *
 * @returns {boolean} True if the date is the last occurrence of its weekday in its month, false otherwise.
 */
export const isLastOccurenceOfWeekdayInMonth = (date: Date) => {
  const { occurrence } = getOccurrenceOfDayOfWeekInMonth(date);

  if (occurrence < MIN_DAY_OCCURRENCES_IN_MONTH) {
    return false;
  }

  if (occurrence === MAX_DAY_OCCURRENCES_IN_MONTH) {
    return true;
  }

  return getOccurrenceOfDayOfWeekInMonth(addWeeks(date, 1)).occurrence !== MAX_DAY_OCCURRENCES_IN_MONTH;
};

/**
 * Formats a date into the day of the year.
 *
 * @param {Date} date - The date to format.
 *
 * @returns {number} The day of the year (1-365 or 1-366 for leap years).
 */
export const formatDayOfYear = (date: Date) => format(date, "--MM-dd");

/**
 * Calculates the duration between two dates in days.
 *
 * @param {Date} startDate - The start date of the duration.
 * @param {Date} endDate - The end date of the duration.
 *
 * @returns {number} The duration in days.
 */
export const getDurationDays = ({ start, end }: { start: Date; end: Date }) => {
  return end.getTime() / DAY_IN_MS - start.getTime() / DAY_IN_MS;
};

export type DaysOfWeekRangesFormatter = (start: DayOfWeek, end: DayOfWeek | null) => string;

const defaultFormatter: DaysOfWeekRangesFormatter = (start, end) => (end ? `${start} - ${end}` : start);

/**
 * Given an array of opening days, such as `["MONDAY", "TUESDAY", "THURSDAY", "FRIDAY"]`, return an
 * array of sequence contiguous ranges, such as `["MONDAY - TUESDAY", "THURSDAY - FRIDAY"]`. The
 * input array is assumed to have a unique set of days. If the input array is empty, return an empty
 * array. If the input array contains only one element, return an array containing that element. If
 * a formatter function is provided, it will be called with the start and end days of each range,
 * and the return value will be used instead of the default string. If the end day is `null`, it
 * means that the range is a single day and the formatter should handle that.
 * @param daysOfWeek An array of unique days of the week.
 * @param formatter An optional function to format the range strings.
 * @returns An array of strings representing the ranges.
 *
 * @example
 * ```ts
 * const daysOfWeek = ["MONDAY", "TUESDAY", "WEDNESDAY" "THURSDAY", "SATURDAY", "SUNDAY"];
 * const result = formatOpeningDays(daysOfWeek);
 * // result = ["MONDAY - THURSDAY", "SATURDAY - SUNDAY"]
 * ```
 *
 * @example
 * ```ts
 * const daysOfWeek = ["MONDAY", "TUESDAY", "THURSDAY", "FRIDAY", "SUNDAY"];
 * const formatter = (start: DayOfWeek, end: DayOfWeek | null) => {
 *   if (end === null) {
 *     return `${start.toLowerCase()}`;
 *   }
 *   return `${start.toLowerCase()} to ${end.toLowerCase()}`;
 * };
 * const result = formatOpeningDays(daysOfWeek, formatter);
 * // result = ["Monday to Tuesday", "Thursday to Friday", "Sunday"]
 * ```
 */
export const getDaysOfWeekRanges = (
  daysOfWeek: DayOfWeek[],
  formatter?: DaysOfWeekRangesFormatter
): string[] => {
  // If the input array is empty, return an empty array.
  if (daysOfWeek.length === 0) {
    return [];
  }

  // If no formatter is provided, use the default formatter.
  const fmt = formatter ?? defaultFormatter;

  // If the input array contains only one element, return an array containing
  // that element.
  if (daysOfWeek.length === 1) {
    return [fmt(daysOfWeek[0], null)];
  }

  const sortedDaysOfWeek = sortDaysOfWeek(daysOfWeek, "MONDAY");

  // Otherwise, we need to find the ranges. We'll start by creating an array of
  // ranges, where each range is an array containing the first day of the range
  // and the last day of the range. We'll then merge the ranges.
  const ranges: [DayOfWeek, DayOfWeek | null][] = [];

  // The first range starts with the first day of the input array.
  let start = sortedDaysOfWeek[0];
  let end = start;

  // We'll iterate over the input array, starting at the second element.
  for (let i = 1; i < sortedDaysOfWeek.length; i++) {
    const nextDay = sortedDaysOfWeek[i];

    // If the current day is the next day of the previous day, then we can
    // extend the range.
    if (nextDay === mondayFirstWeek[mondayFirstWeek.indexOf(end) + 1]) {
      end = nextDay;
    } else {
      // Otherwise, we need to start a new range.
      ranges.push([start, end === start ? null : end]);
      start = nextDay;
      end = start;
    }
  }

  // Add the last range.
  ranges.push([start, end === start ? null : end]);

  // Finally, we'll format the ranges and return the result.
  return ranges.map(([rangeStart, rangeEnd]) => fmt(rangeStart, rangeEnd));
};

const RANKED_DAYS_MONDAY: Record<DayOfWeek, number> = {
  MONDAY: 0,
  TUESDAY: 1,
  WEDNESDAY: 2,
  THURSDAY: 3,
  FRIDAY: 4,
  SATURDAY: 5,
  SUNDAY: 6,
};

const RANKED_DAYS_SUNDAY: Record<DayOfWeek, number> = {
  SUNDAY: 0,
  MONDAY: 1,
  TUESDAY: 2,
  WEDNESDAY: 3,
  THURSDAY: 4,
  FRIDAY: 5,
  SATURDAY: 6,
};

/**
 * Sorts an array of days of the week in the specified order.
 *
 * @param daysOfWeek - An array of days of the week to sort.
 * @param firstDay - The first day of the week.
 * @returns The sorted array of days of the week.
 *
 * @example
 * const daysOfWeek = ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY", "SUNDAY"];
 * const sortedDaysOfWeek = sortDaysOfWeek(daysOfWeek, "SUNDAY");
 * console.log(sortedDaysOfWeek); // ["SUNDAY", "MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY", "SATURDAY"]
 */
export const sortDaysOfWeek = (
  daysOfWeek: DayOfWeek[],
  firstDay: Extract<DayOfWeek, "MONDAY" | "SUNDAY">
) => {
  const daysRanked = firstDay === "MONDAY" ? RANKED_DAYS_MONDAY : RANKED_DAYS_SUNDAY;

  return [...daysOfWeek].sort((a, b) => daysRanked[a] - daysRanked[b]);
};

export const getDateIfValid = (dateStr?: string) =>
  dateStr && isValid(new Date(dateStr)) ? getLocalDate(dateStr) : undefined;
