import { RefAttributes, useMemo, useCallback, useRef, useEffect } from "react";
import ReactSelect, {
  components as Components,
  DropdownIndicatorProps,
  Options,
  SingleValue,
  ActionMeta,
  ClearIndicatorProps,
  InputProps,
  OptionsOrGroups,
  OptionProps,
} from "react-select";

import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import SelectComponent, { FormatOptionLabelMeta } from "react-select/dist/declarations/src/Select";
import { StateManagerProps } from "react-select/dist/declarations/src/stateManager";
import CreatableSelect from "react-select/creatable";
import { Accessors } from "react-select/dist/declarations/src/useCreatable";
import { cx } from "@libs/utils/cx";
import { usePortalElement } from "@libs/contexts/PortalContext";
import { ReactComponent as ClearIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-caret.svg";

import { mergeSelectStyles, getBaseStyles, getDarkBaseStyles } from "@libs/components/UI/selectStyles";

export type SelectProps<V extends SelectOptionValue, T extends SelectOption<V>> = Omit<
  StateManagerProps<T, false, GroupedSelectOption<V, T>>,
  "options" | "value" | "menuPortalTarget"
> &
  RefAttributes<SelectComponent<T, false, GroupedSelectOption<V, T>>> & {
    options: Options<T> | GroupedSelectOptions<V, T>;
    value?: V;
    display?: SelectDisplayType;
    onItemChanged?: (value?: V) => void;
    onItemSelected?: (value: V) => void;
    allowCreateWhileLoading?: boolean;
    createOptionPosition?: "first" | "last";
    isDark?: boolean;
    /**
     * Determines whether the "create new ..." option should be displayed based on
     * the current input value, select value and options array.
     */
    isValidNewOption?: (
      inputValue: string,
      value: Options<T>,
      options: OptionsOrGroups<T, GroupedSelectOption<V, T>>,
      accessors: Accessors<T>
    ) => boolean;
    /**
     * Returns the data for the new option when it is created. Used to display the
     * value, and is passed to `onChange`.
     */
    getNewOptionData?: (inputValue: string, optionLabel: React.ReactNode) => T;
    /**
     * If provided, this will be called with the input value when a new option is
     * created, and `onChange` will **not** be called. Use this when you need more
     * control over what happens when new options are created.
     */
    onCreateOption?: (inputValue: string) => void;
    formatCreateLabel?: (inputValue: string) => React.ReactNode;
  };

export const DisplayValue = <V extends SelectOptionValue, T extends SelectOption<V>>({
  option,
  display,
}: {
  option: T;
  display?: SelectDisplayType;
}) => {
  return (
    <>
      {display === "value" ? (
        option.value
      ) : display === "label" ? (
        option.label
      ) : (
        <>
          {option.value} - {option.label}
        </>
      )}
    </>
  );
};

export const handleFilterOption = <V extends SelectOptionValue, T extends SelectOption<V>>(
  option: FilterOptionOption<T>,
  searchText: string
) => {
  const searchTextLowered = searchText.toLowerCase();
  const label = option.data.label;

  return Boolean(
    option.data.value.toString().toLowerCase().includes(searchTextLowered) ||
      (typeof label === "string" && label.toLowerCase().includes(searchTextLowered))
  );
};

export const getOptionLabelRenderer = (display: SelectDisplayType) => {
  return function Label(
    option: SelectOption<SelectOptionValue>,
    meta: FormatOptionLabelMeta<SelectOption<SelectOptionValue>>
  ) {
    const isMenu = meta.context === "menu";

    return isMenu ? (
      display === "both" ? (
        <div className="flex w-full">
          <p className="w-1/3">{option.value}</p>
          <p className="pl-3 w-2/3">{option.label}</p>
        </div>
      ) : display === "label" ? (
        option.label
      ) : (
        option.value
      )
    ) : (
      <DisplayValue option={option} display={display} />
    );
  };
};

export const defaultComponents = {
  Option: <V extends SelectOptionValue, T extends SelectOption<V>, MultiSelect extends boolean>(
    props: OptionProps<T, MultiSelect, GroupedSelectOption<V, T>>
  ) => {
    const ref = useRef<HTMLElement | null>(null);

    useEffect(() => {
      props.isSelected && ref.current?.scrollIntoView();
    }, [props.isSelected]);

    return <Components.Option {...props} innerRef={(el) => (ref.current = el)} />;
  },
  DropdownIndicator: <V extends SelectOptionValue, T extends SelectOption<V>, MultiSelect extends boolean>(
    props: DropdownIndicatorProps<T, MultiSelect, GroupedSelectOption<V, T>>
  ) => {
    return (
      <Components.DropdownIndicator {...props}>
        <DropDownIcon className={cx("transition-transform", props.selectProps.menuIsOpen && "rotate-180")} />
      </Components.DropdownIndicator>
    );
  },
  ClearIndicator: <V extends SelectOptionValue, T extends SelectOption<V>, MultiSelect extends boolean>(
    props: ClearIndicatorProps<T, MultiSelect, GroupedSelectOption<V, T>>
  ) => {
    return (
      <Components.ClearIndicator {...props}>
        <ClearIcon aria-label="Clear" />
      </Components.ClearIndicator>
    );
  },
  Input: <V extends SelectOptionValue, T extends SelectOption<V>, MultiSelect extends boolean>(
    props: InputProps<T, MultiSelect, GroupedSelectOption<V, T>>
    // prevents pw managers from using this control
  ) => <Components.Input {...props} data-lpignore="true" />,
};

export const isGroupOption = <V extends SelectOptionValue, T extends SelectOption<V>>(
  val: SelectOption<V> | GroupedSelectOption<V, T>
): val is GroupedSelectOption<V, T> => {
  return "options" in val;
};

export const findSelectedOption = <V extends SelectOptionValue, T extends SelectOption<V>>(
  options: Options<T> | GroupedSelectOptions<V, T>,
  value: V | undefined
) => {
  for (const option of options) {
    if (isGroupOption(option)) {
      for (const groupedOption of option.options) {
        if (groupedOption.value === value) {
          return groupedOption;
        }
      }
    } else if (option.value === value) {
      return option;
    }
  }

  return null;
};

export const Select = <V extends SelectOptionValue, T extends SelectOption<V>>({
  components,
  value,
  onItemSelected,
  onItemChanged,
  onChange,
  styles,
  createOptionPosition,
  isValidNewOption,
  getNewOptionData,
  onCreateOption,
  isDark = false,
  display = "label",
  ...props
}: SelectProps<V, T>) => {
  const isCreatable = Boolean(onCreateOption || isValidNewOption || getNewOptionData);
  const { customComponents, labelRenderer } = useMemo(() => {
    return {
      customComponents: components
        ? {
            ...defaultComponents,
            ...components,
          }
        : defaultComponents,
      labelRenderer: getOptionLabelRenderer(display),
    };
  }, [components, display]);

  const selectedOption = useMemo(() => {
    return findSelectedOption(props.options, value);
  }, [props.options, value]);

  const handleOnChange = useCallback(
    (option: SingleValue<T>, actionMeta: ActionMeta<T>) => {
      if (onChange) {
        onChange(option, actionMeta);
      }

      if (onItemSelected && actionMeta.action === "select-option" && option) {
        onItemSelected(option.value);
      }

      if (onItemChanged) {
        onItemChanged(option?.value);
      }
    },
    [onItemSelected, onChange, onItemChanged]
  );

  const selectStyles = useMemo(() => {
    const baseStyles = isDark ? getDarkBaseStyles<V, T, false>() : getBaseStyles<V, T, false>();

    return mergeSelectStyles<V, T, false>(baseStyles, styles, true);
  }, [isDark, styles]);

  const menuPortalTarget = usePortalElement();

  const selectProps = {
    filterOption: handleFilterOption,
    formatOptionLabel: labelRenderer,
    isSearchable: true,
    closeMenuOnSelect: true,
    placeholder: "",
    isValidNewOption,
    getNewOptionData,
    onCreateOption,
    ...props,
    styles: selectStyles,
    onChange: handleOnChange,
    value: selectedOption,
    components: customComponents,
    createOptionPosition,
    menuPortalTarget,
  };

  return isCreatable ? <CreatableSelect {...selectProps} /> : <ReactSelect {...selectProps} />;
};
