import { keys } from "@libs/utils/object";
import { hasValue } from "@libs/utils/validators";

export const stripBlankKeys = <T extends string, R extends Record<T, unknown>>(obj: R): Partial<R> => {
  const result: Partial<R> = {};

  keys(obj).forEach((key) => {
    const value = obj[key];

    if (hasValue(value)) {
      result[key] = value;
    }
  });

  return result;
};
