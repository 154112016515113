import { Svg, Rect } from "@react-pdf/renderer";

export const SvgHorizontalLine: React.FC<{ fill: string; width: number }> = ({ fill, width }) => {
  const svgProps = {
    width: `${width}`,
    viewBox: `0 0 ${width} 1`,
  };

  return (
    <Svg height="1" {...svgProps}>
      <Rect x={0} y={0} width="100%" height="100%" fill={fill} />
    </Svg>
  );
};
