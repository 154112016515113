import React from "react";
import { useTranslation } from "react-i18next";
import { CognitoErrorCode, isCognitoErrorCode } from "@libs/utils/cognito";
import { useHandleError } from "api/handleErrorResponse";

type ErrorOrigin = "resend";

export const useAuthErrorHandler = (
  setErrorMessage: (err: string, cognitoError: CognitoErrorCode) => void
) => {
  const { t } = useTranslation();
  const handleError = useHandleError();

  const handleAuthError = React.useCallback(
    (err: unknown, errorOrigin?: ErrorOrigin) => {
      const cognitoError = err as { code?: CognitoErrorCode };

      if (cognitoError.code === "InvalidParameterException" && errorOrigin === "resend") {
        setErrorMessage(t(`cognito.resend.InvalidParameterException`), cognitoError.code);
      } else if (cognitoError.code && isCognitoErrorCode(cognitoError.code)) {
        setErrorMessage(t(`cognito.${cognitoError.code}`), cognitoError.code);
      } else {
        handleError(err);
        setErrorMessage(t(`cognito.Unknown`), "Unknown");
      }
    },
    [handleError, setErrorMessage, t]
  );

  return {
    handleAuthError,
  };
};
