import { FC, useMemo } from "react";
import { $createParagraphNode, $createTextNode, $getRoot, EditorState } from "lexical";
import { HeadingNode } from "@lexical/rich-text";
import { ListNode, ListItemNode } from "@lexical/list";
import { $isRootTextContentEmpty } from "@lexical/text";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { InitialConfigType, LexicalComposer } from "@lexical/react/LexicalComposer";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";

interface Props {
  initialConfig: Omit<InitialConfigType, "editorState">;
  initialEditorState?: string;
}

const RTE_THEME = {
  heading: {
    h1: "text-lg font-sansSemiBold mb-1",
    h2: "text-md font-sansSemiBold mb-1",
  },
  list: {
    ol: "ml-8 my-2 list-decimal list-outside",
    listitem: "mb-1",
    ul: "ml-8 my-2 list-disc list-outside",
  },
  text: {
    underline: "underline",
    strikethrough: "line-through",
    bold: "font-sansSemiBold",
    italic: "italic",
  },
};

export const getContent = async (editorState: EditorState | undefined) => {
  if (!editorState) {
    return "";
  }

  const isEmpty = await new Promise((resolve) => {
    editorState.read(() => {
      resolve($isRootTextContentEmpty(false, true));
    });
  });

  return isEmpty ? "" : JSON.stringify(editorState);
};

const isValidJSON = (value: string) => {
  try {
    const json = JSON.parse(value) as unknown;

    if (json && typeof json === "object") {
      return true;
    }

    return false;
  } catch {
    return false;
  }
};

export const RichTextContent: FC<Props> = ({ initialConfig, initialEditorState }) => {
  const safeInitialState = useMemo(() => {
    if (initialEditorState === undefined) {
      return initialEditorState;
    }

    if (isValidJSON(initialEditorState)) {
      return initialEditorState;
    }

    return () => {
      const root = $getRoot();
      const paragraphNode = $createParagraphNode();
      const textNode = $createTextNode(initialEditorState);

      paragraphNode.append(textNode);
      root.append(paragraphNode);

      return root;
    };
  }, [initialEditorState]);

  return (
    <LexicalComposer
      initialConfig={{
        ...initialConfig,
        nodes: [HeadingNode, ListNode, ListItemNode],
        editorState: safeInitialState,
        theme: RTE_THEME,
        editable: false,
      }}
    >
      <div className="relative flex-1 min-h-0">
        <RichTextPlugin
          contentEditable={<ContentEditable className="text-sm" />}
          placeholder={<div className="absolute text-xs top-2 left-0 text-greyLight">Enter text...</div>}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </div>
      <TabIndentationPlugin />
      <ListPlugin />
      <HistoryPlugin />
    </LexicalComposer>
  );
};
