import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { CenteredResponsiveLayout } from "components/UI/CenteredResponsiveLayout";
import { Header } from "components/Main/Header/Header";
import { PatientOnboardingWizardRoute } from "components/Onboarding/PatientOnboardingWizard/PatientOnboardingWizardRoute";
import { AccountsMenu } from "components/Main/Header/AccountsMenu";
import { getProfileImageQuery } from "api/patient/queries";

export const OnboardingPages = () => {
  const { practiceId, id: userId } = useAccount();
  const practice = useCurrentPractice();

  const [profileImageQuery] = useApiQueries([
    getProfileImageQuery({
      args: {
        practiceId,
        userId,
      },
    }),
  ]);

  return (
    <div className="relative min-h-0 h-full pt-[50px]">
      <Header practice={practice}>
        <AccountsMenu thumbnail={profileImageQuery.data?.thumbnail} />
      </Header>
      <CenteredResponsiveLayout size="2xl" className="bg-white border-b border-b-greyLighter">
        <PatientOnboardingWizardRoute />
      </CenteredResponsiveLayout>
    </div>
  );
};
