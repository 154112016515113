import React from "react";
import { cx } from "@libs/utils/cx";

type Props = {
  step: number;
  totalSteps: number;
  className?: string;
};
export const TaskProgressHeader: React.FC<Props> = ({ totalSteps, step, className }) => {
  const steps = React.useMemo(() => {
    return Array.from({ length: totalSteps });
  }, [totalSteps]);

  if (steps.length <= 1) {
    return null;
  }

  return (
    <div className={cx("flex flex-row items-center text-xs", className)}>
      {steps.map((_, i) => (
        <React.Fragment key={`step-${i}`}>
          <div
            className={cx(
              `rounded-full
               h-6
               w-6
               flex
               items-center
               justify-center
               text-center`,
              step >= i ? "bg-blueLight text-blueDark" : "bg-white outline outline-greyLightest"
            )}
          >
            {i + 1}
          </div>
          {i < steps.length - 1 ? (
            <div className={cx("flex-1 h-[1px]", step > i ? "bg-blueLight" : "bg-greyLightest")} />
          ) : null}
        </React.Fragment>
      ))}
    </div>
  );
};
