import React, { useEffect, useMemo } from "react";

import { useTranslation } from "react-i18next";
import {
  ContactDetailsVO,
  FormPatientInfoElementVO,
  MessageSubscriptionVO,
  OnboardingOptionsVO,
  PatientVO,
  PersonalDetailsVO,
  UpdateMessageSubscriptionRequest,
  UpdatePatientRequest,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useAccount } from "@libs/contexts/AccountContext";
import { useValidation } from "@libs/hooks/useValidation";
import { Form } from "@libs/components/UI/Form";
import { semibold16 } from "assets/styles/textSize";
import { PersonalDetailFields } from "components/Account/PersonalDetailFields";
import { ContactDetailFields } from "components/Account/ContactDetailFields";
import { EmailPreferencesFields } from "components/Account/EmailPreferencesFields";
import { getAccountValidationSchema } from "components/Account/validation";

type Props = {
  onSubmit: (generalInfo: UpdatePatientRequest, preferences: UpdateMessageSubscriptionRequest) => void;
  id: string;
  info: PatientVO;
  form: "contact" | "personal";
  edit: boolean;
  preferences: MessageSubscriptionVO;
  onboardingOptions: OnboardingOptionsVO;
  optionalFields: FormPatientInfoElementVO["optionalFields"];
};

export const AccountForm: React.FC<Props> = ({
  onSubmit,
  id,
  info,
  optionalFields,
  edit,
  form,
  preferences,
  onboardingOptions,
}) => {
  const { t } = useTranslation();
  const { id: patientId } = useAccount();

  const [userInfo, updateUserInfo] = useObjectState({
    personalDetails: info.personalDetails,
    contactDetails: info.contactDetails,
    additionalInformation: info.additionalInformation ?? {},
  });

  const [emailPreferences, setEmailPreferences] = React.useState<UpdateMessageSubscriptionRequest>({
    unsubscribeAll: preferences.unsubscribeAll,
    unsubscribeMarketing: preferences.unsubscribeMarketing,
    unsubscribeTransactional: preferences.unsubscribeTransactional,
  });

  const { personalDetails, contactDetails } = userInfo;

  const handlePersonalDetailsUpdated = React.useCallback(
    (partial: Partial<PersonalDetailsVO>) => {
      updateUserInfo({ personalDetails: { ...personalDetails, ...partial } });
    },
    [personalDetails, updateUserInfo]
  );
  const handleContactDetailsUpdated = React.useCallback(
    (partial: Partial<ContactDetailsVO>) => {
      updateUserInfo({ contactDetails: { ...contactDetails, ...partial } });
    },
    [contactDetails, updateUserInfo]
  );

  const schema = useMemo(() => {
    const { personalDetails: personalDetailsSchema, contactDetails: contactDetailsSchema } =
      getAccountValidationSchema({
        contact: info.contact,
        contactDetails,
        translate: t,
      });

    return {
      contactDetails: { ...contactDetailsSchema, $ignore: form !== "contact" },
      personalDetails: { ...personalDetailsSchema, $ignore: form !== "personal" },
    };
  }, [contactDetails, form, info.contact, t]);

  const { validate, result, reset } = useValidation(userInfo, schema);

  useEffect(() => {
    if (!edit) {
      // User has stopped editing, reset the form
      reset();
      updateUserInfo({
        personalDetails: info.personalDetails,
        contactDetails: info.contactDetails,
        additionalInformation: info.additionalInformation ?? {},
      });
    }
  }, [edit, info.additionalInformation, info.contactDetails, info.personalDetails, reset, updateUserInfo]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    (e) => {
      e.preventDefault();

      if (validate().$isValid) {
        onSubmit(userInfo, emailPreferences);
      }
    },
    [validate, onSubmit, userInfo, emailPreferences]
  );

  return (
    <Form id={id} className="flex flex-col gap-5 p-5 h-full min-h-0" onSubmit={handleSubmit}>
      <div className="flex flex-col gap-4 max-w-xl">
        {form === "contact" ? (
          <>
            <div className={cx("text-secondaryTheme", semibold16)}>{t("Contact Details")}</div>
            <ContactDetailFields
              contact={info.contact}
              canEditEmail={!info.contactDetails.email}
              contactDetails={contactDetails}
              onContactDetailsUpdated={handleContactDetailsUpdated}
              edit={edit}
              validation={result.contactDetails}
            />
            <div className={cx("text-secondaryTheme mt-4", semibold16)}>{t("Email Preferences")}</div>
            <EmailPreferencesFields
              edit={edit}
              preferences={emailPreferences}
              onUpdate={setEmailPreferences}
            />
          </>
        ) : (
          <>
            <div className={cx("text-secondaryTheme", semibold16)}>{t("Personal Details")}</div>
            <PersonalDetailFields
              edit={edit}
              onPersonalDetailsUpdated={handlePersonalDetailsUpdated}
              personalDetails={personalDetails}
              patientId={patientId}
              onboardingOptions={onboardingOptions}
              optionalFields={optionalFields}
              validation={result.personalDetails}
            />
          </>
        )}
        <div className="h-32" />
      </div>
    </Form>
  );
};
