import React from "react";
import { noop } from "@libs/utils/noop";

export const useNavigationBlocker = ({ when }: { when?: boolean }) => {
  React.useEffect(() => {
    if (when) {
      const onBeforeUnload = (event: BeforeUnloadEvent) => {
        // Cancel the event as stated by the standard.
        event.preventDefault();
        // We can't specify the message here.  Browser will say  "Changes you made may not be saved."
        event.returnValue = "";
      };

      window.addEventListener("beforeunload", onBeforeUnload);

      return () => {
        window.removeEventListener("beforeunload", onBeforeUnload);
      };
    }

    return noop;
  }, [when]);
};
