/* eslint-disable @typescript-eslint/naming-convention */
import { StylesConfig, CSSObjectWithLabel } from "react-select";
import { StylesProps } from "react-select/dist/declarations/src/styles";
import designConfig from "@libs/design.config";

export const createSelectStyles = <
  V extends SelectOptionValue,
  T extends SelectOption<V>,
  IsMulti extends boolean = false,
>(
  styles: StylesConfig<T, IsMulti, GroupedSelectOption<V, T>>
) => styles;

const mergeStylesFunc = <
  V extends SelectOptionValue,
  T extends SelectOption<V>,
  IsMulti extends boolean,
  K extends keyof StylesConfig<T, IsMulti, GroupedSelectOption<V, T>>,
>(
  baseStyles: StylesConfig<T, IsMulti, GroupedSelectOption<V, T>>,
  overrides: StylesConfig<T, IsMulti, GroupedSelectOption<V, T>> | undefined,
  key: K,
  mergeBase?: boolean
) => {
  const overrideFunc = overrides?.[key];
  const baseFunc = baseStyles[key];

  if (overrideFunc && baseFunc) {
    return (base: CSSObjectWithLabel, props: StylesProps<T, IsMulti, GroupedSelectOption<V, T>>[K]) => ({
      ...(mergeBase ? base : undefined),
      ...baseFunc(base, props),
      ...overrideFunc(base, props),
    });
  } else if (baseFunc) {
    return (base: CSSObjectWithLabel, props: StylesProps<T, IsMulti, GroupedSelectOption<V, T>>[K]) => ({
      ...(mergeBase ? base : undefined),
      ...baseFunc(base, props),
    });
  }

  return undefined;
};

const populateOverridesFunc = <
  V extends SelectOptionValue,
  T extends SelectOption<V>,
  IsMulti extends boolean,
  K extends keyof StylesConfig<T, IsMulti, GroupedSelectOption<V, T>>,
>(
  overrides: StylesConfig<T, IsMulti, GroupedSelectOption<V, T>>,
  key: K,
  mergeBase?: boolean
) => {
  const overrideFunc = overrides[key];

  return (base: CSSObjectWithLabel, props: StylesProps<T, IsMulti, GroupedSelectOption<V, T>>[K]) => ({
    ...(mergeBase ? base : undefined),
    ...overrideFunc?.(base, props),
  });
};

export const mergeSelectStyles = <
  V extends SelectOptionValue,
  T extends SelectOption<V>,
  IsMulti extends boolean,
>(
  baseStyles?: StylesConfig<T, IsMulti, GroupedSelectOption<V, T>>,
  overrides?: StylesConfig<T, IsMulti, GroupedSelectOption<V, T>>,
  mergeBase?: boolean
) => {
  if (!baseStyles && !overrides) {
    return undefined;
  }

  const baseStyleKeys = baseStyles ? Object.keys(baseStyles) : [];
  const overridesKeys = overrides ? Object.keys(overrides).filter((key) => !baseStyleKeys.includes(key)) : [];
  const overridesProps = overridesKeys as (keyof StylesProps<T, IsMulti, GroupedSelectOption<V, T>>)[];
  const baseStyleProps = baseStyleKeys as (keyof StylesProps<T, IsMulti, GroupedSelectOption<V, T>>)[];
  const merged: StylesConfig<T, IsMulti, GroupedSelectOption<V, T>> = {};

  if (baseStyles) {
    for (const baseStyleProp of baseStyleProps) {
      const mergedFunc = mergeStylesFunc(baseStyles, overrides, baseStyleProp, mergeBase);

      if (mergedFunc) {
        merged[baseStyleProp] = mergedFunc;
      }
    }
  }

  if (overrides) {
    for (const overridesProp of overridesProps) {
      merged[overridesProp] = populateOverridesFunc(overrides, overridesProp, mergeBase);
    }
  }

  return merged;
};

export const getBaseStyles = <
  V extends SelectOptionValue,
  T extends SelectOption<V>,
  MultiSelect extends boolean,
>() => {
  return createSelectStyles<V, T, MultiSelect>({
    option: (_, props) => {
      const isSelected = props.isSelected;
      const isFocused = props.isFocused;

      return {
        ":active": {
          backgroundColor: designConfig.colors.slate["100"],
        },
        ":first-child": {
          borderTopRightRadius: "4px",
          borderTopLeftRadius: "4px",
        },
        ":last-child": {
          borderBottomRightRadius: "4px",
          borderBottomLeftRadius: "4px",
        },
        color: props.isDisabled ? designConfig.colors.slate[400] : "inherit",
        cursor: "pointer",
        paddingTop: "0.5rem",
        paddingBottom: "0.5rem",
        paddingLeft: "1rem",
        paddingRight: "1rem",
        ...(isSelected
          ? {
              fontFamily: designConfig.fontFamilies.sansSemiBold.join(", "),
              fontWeight: designConfig.fontWeight.semiBold,
              backgroundColor: designConfig.colors.slate["100"],
            }
          : isFocused
            ? {
                backgroundColor: designConfig.colors.slate["50"],
              }
            : undefined),
      };
    },
    menu: (_, props) => ({
      width: "max-content",
      minWidth: "100%",
      fontSize: "0.75rem",
      ...(props.selectProps.isDisabled
        ? {
            color: designConfig.colors.slate[400],
          }
        : undefined),
    }),
    dropdownIndicator: (_, props) => ({
      fill: props.isDisabled ? designConfig.colors.slate[400] : designConfig.colors.primaryTheme,
      cursor: "pointer",
      height: "1rem",
      width: "1rem",
      padding: 0,
      marginLeft: "0.5rem",
      svg: {
        fill: "inherit",
        height: "100%",
        width: "100%",
      },
    }),
    clearIndicator: (_, props) => ({
      fill: props.selectProps.isDisabled ? designConfig.colors.slate[400] : designConfig.colors.primaryTheme,
      cursor: "pointer",
      height: "1rem",
      width: "1rem",
      padding: 0,
      marginLeft: "0.5rem",
      svg: {
        fill: "inherit",
        height: "100%",
        width: "100%",
      },
    }),
    indicatorSeparator: () => ({
      display: "none",
    }),
    control: (_, props) => ({
      background: "transparent",
      borderWidth: 0,
      outline: 0,
      boxShadow: "none",
      minHeight: "unset",
      ...(props.selectProps.isSearchable
        ? undefined
        : {
            cursor: "pointer",
          }),
    }),
    placeholder: () => ({
      color: designConfig.colors.slate[400],
      margin: 0,
      whiteSpace: "nowrap",
    }),
    container: (_, props) => ({
      fontSize: "0.75rem",
      ...(props.isDisabled
        ? {
            color: designConfig.colors.slate[400],
          }
        : undefined),
    }),
    input: () => ({
      color: "inherit",
      padding: 0,
      margin: 0,
    }),
    valueContainer: () => ({
      padding: 0,
      alignSelf: "stretch",
    }),
    menuList: () => ({
      padding: 0,
    }),
    singleValue: () => ({
      color: "inherit",
      margin: 0,
    }),
    multiValueLabel: () => ({
      color: "inherit",
      padding: 0,
    }),
    group: () => ({
      paddingTop: 0,
      ":last-child": {
        paddingBottom: 0,
      },
      ":first-child > :first-child": {
        border: "none",
      },
      "> :first-child": {
        borderTopWidth: "1px",
        borderColor: designConfig.colors.greyLighter,
      },
    }),
    groupHeading: (_, props) => ({
      fontWeight: designConfig.fontWeight.semiBold,
      fontFamily: designConfig.fontFamilies.sansSemiBold.join(", "),
      fontSize: "0.75rem",
      textTransform: "none",
      color: designConfig.colors.greyDark,
      paddingTop: props.children ? "1rem" : "0",
      paddingBottom: props.children ? "0.5rem" : "0",
      paddingLeft: 0,
      paddingRight: 0,
      margin: "0 16px",
    }),
    multiValueRemove: (_, props) => ({
      fill:
        props.selectProps.isDisabled || props.isDisabled
          ? designConfig.colors.slate[400]
          : designConfig.colors.primaryTheme,
      backgroundColor: "transparent",
      cursor: "pointer",
      marginLeft: "0.25rem",
      padding: 0,
      height: "0.75rem",
      width: "0.75rem",
      svg: {
        fill: "inherit",
        height: "100%",
        width: "100%",
      },
      ":hover": {
        backgroundColor: "transparent",
        color: "inherit",
      },
    }),
    multiValue: (_, props) => ({
      marginRight: "0.25rem",
      marginBottom: "0.25rem",
      marginTop: "0.25rem",

      // this is an odd value because we want the
      // text of a multivalue item to be 12 px
      // The child element of the element that receives these styles
      // has font-size: 85% and can't be overridden so we need to style
      // this element in a way that gets us to 12px.
      // 16px (body font size) * 0.8825 = 14.12px, 14.12 * 0.85 = 12px
      fontSize: "0.8825rem",
      marginLeft: 0,
      display: "flex",
      alignItems: "center",
      borderRadius: "1rem",
      backgroundColor: "#fff",
      borderWidth: "1px",
      borderStyle: "solid",
      paddingTop: "0.25rem",
      paddingBottom: "0.25rem",
      paddingLeft: "0.75rem",
      paddingRight: "0.75rem",
      borderColor:
        props.selectProps.isDisabled || props.isDisabled
          ? designConfig.colors.greyLighter
          : designConfig.colors.greyLight,
    }),
    menuPortal: () => ({ zIndex: 10 }),
  });
};

export const getDarkBaseStyles = <
  V extends SelectOptionValue,
  T extends SelectOption<V>,
  MultiSelect extends boolean,
>() => {
  const baseStyles = getBaseStyles<V, T, MultiSelect>();
  const darkStyles = mergeSelectStyles<V, T, MultiSelect>(baseStyles, {
    option: (_, props) => {
      const isSelected = props.isSelected;
      const isFocused = props.isFocused;

      return {
        ":active": {
          backgroundColor: isSelected ? designConfig.colors.slate[700] : designConfig.colors.slate[600],
        },
        color: props.isDisabled ? designConfig.colors.slate[400] : "inherit",

        ...(isSelected
          ? {
              backgroundColor: designConfig.colors.slate[700],
            }
          : isFocused
            ? {
                backgroundColor: designConfig.colors.slate[600],
              }
            : undefined),
      };
    },

    menu: (_, props) => ({
      backgroundColor: designConfig.colors.slate[800],
      ...(props.selectProps.isDisabled
        ? {
            color: designConfig.colors.slate[400],
          }
        : {
            color: designConfig.colors.white,
          }),
    }),
    multiValue: () => ({
      borderColor: designConfig.colors.slate[500],
    }),
    groupHeading: () => ({
      color: designConfig.colors.white,
    }),
    group: () => ({
      "> :first-child": {
        borderColor: designConfig.colors.slate[500],
      },
    }),
    dropdownIndicator: (_, props) => ({
      fill: props.isDisabled ? designConfig.colors.slate[800] : designConfig.colors.slate[300],
    }),
  });

  return darkStyles;
};
