import { FC } from "react";
import { Navigate } from "react-router-dom";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";
import { paths } from "router/paths";

export const AccountRoute: FC = () => {
  const { screenMatches } = useResponsiveBreakpoint();

  return screenMatches.has("sm") ? <Navigate to={paths.profile()} replace /> : null;
};
