import { useTranslation } from "react-i18next";
import { NamedField } from "components/UI/NamedField";

export const SignatureRequiredDisclosure: React.FC<{ className?: string }> = ({ className }) => {
  const { t } = useTranslation();

  return (
    <NamedField containerClassName={className} title={t("Authorized Signature of covered person")} required>
      ({t("For minor, Parent or Guardian")})
    </NamedField>
  );
};
