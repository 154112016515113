import { ApiClientProvider } from "@libs/contexts/ApiClientContext";
import { useCallback } from "react";

import { getTokensForApi } from "@libs/auth/getTokens";
import { getTokenErrorReason } from "@libs/utils/cognito";
import { captureException } from "@sentry/react";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { Outlet } from "react-router-dom";
import { env } from "env";
import { headers } from "api/headers";
import { useSignoutWithReason } from "hooks/useSignoutWithReason";

export const PatientApiClientProvider = () => {
  const { localStorage } = useStorageContext();
  const signOutWithReason = useSignoutWithReason();
  const handleRequestTokens = useCallback(async () => {
    try {
      return await getTokensForApi(localStorage);
    } catch (e) {
      const reason = getTokenErrorReason(e);

      if (!reason) {
        captureException(e);
      }

      signOutWithReason(reason);
      throw e;
    }
  }, [localStorage, signOutWithReason]);

  return (
    <ApiClientProvider
      onRequestTokens={handleRequestTokens}
      baseUrl={env.REACT_APP_API_HOST}
      headers={headers}
    >
      <Outlet />
    </ApiClientProvider>
  );
};
