import { cx } from "@libs/utils/cx";
import { IconSizes, cxIconSizes } from "@libs/components/UI/Icon";

export const cxIconStyles = {
  container: (args?: { clickable?: boolean }) =>
    cx("absolute right-0 top-1/2 -translate-y-1/2 z-0 pr-2.5", !args?.clickable && "pointer-events-none"),
  element: ({
    disabled,
    error,
    size,
    actionable,
  }: {
    actionable?: boolean;
    disabled?: boolean;
    error?: string;
    size?: IconSizes;
  }) =>
    cx(
      cxIconSizes[size ?? "sm"],
      disabled ? "text-slate-400" : error ? "text-red" : actionable ? "text-primaryTheme" : "text-slate-700"
    ),
};

export const cxFormFieldStyle = {
  wrapper: "relative",
  input: `
    placeholder:text-slate-400
    focus-visible:outline-none
    dark:bg-slate-800
    dark:text-white
  `,
  controlValueOnly: `
    block
    text-sm
    md:text-xs
    w-full
    py-2
    border-y
    border-transparent
  `,

  control: ({ hasIcon, fontSizeClassName }: { hasIcon: boolean; fontSizeClassName?: string }) =>
    cx(
      `text-left
       w-full
       disabled:text-slate-400
       disabled:bg-white
       rounded
       block
       pl-3
       py-2`,
      fontSizeClassName ?? "text-sm md:text-xs",
      hasIcon ? "pr-9" : "pr-3"
    ),
  iconContainer: (args?: { clickable?: boolean }) => cxIconStyles.container(args),
  icon: (args: { actionable?: boolean; disabled?: boolean; error?: string; size?: IconSizes }) =>
    cxIconStyles.element(args),
};
