import { formatCurrency } from "@libs/utils/currency";
import { cx } from "@libs/utils/cx";
import { useTranslation } from "react-i18next";

type Props = {
  className?: string;
  total: number;
  subtotal?: number;
  surchargeAmount?: number;
};

const ValueCell: React.FC<{ children: number; className?: string }> = ({ children, className }) => (
  <div className={cx("text-right", className)}>{formatCurrency(children)}</div>
);

export const SurchargeSummary: React.FC<Props> = ({ total, subtotal, surchargeAmount, className }) => {
  const { t } = useTranslation();
  const surchargeValue = surchargeAmount ?? 0;
  const subtotalValue = subtotal ?? 0;

  return (
    <div className={cx("grid grid-cols-2 gap-y-3 mt-4", className)}>
      {surchargeValue > 0 && subtotalValue > 0 && (
        <>
          <div>{t("Subtotal")}</div>
          <ValueCell>{subtotalValue}</ValueCell>
          <div>{t("Credit Card Surcharge")}</div>
          <ValueCell>{surchargeValue}</ValueCell>
        </>
      )}
      <div className="font-sansSemiBold">Total</div>
      <ValueCell className="font-sansSemiBold">{total}</ValueCell>
    </div>
  );
};
