import { ApiErrorResponse } from "@libs/@types/api";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { FORBIDDEN } from "@libs/utils/statusCodes";

// When submitting kiosk code/DOB to acquire submission token, it will throw the below errors if submission is invalid
export const getIsKioskCodeOrDOBError = (resp?: ApiErrorResponse | null) => {
  if (!resp) {
    return undefined;
  }

  const errorCode = isHttpResponseError(resp) ? resp.error.errors?.[0].errorCode : ("UNKNOWN_ERROR" as const);

  return errorCode === "RESOURCE_NOT_FOUND" || errorCode === "FIELD_VALIDATION_ERROR";
};

export const hasPatientTokenExpired = (resp?: ApiErrorResponse | null) => {
  return isHttpResponseError(resp)
    ? resp.status === FORBIDDEN && resp.error.errors?.[0].message === "The patient token has expired"
    : false;
};

export const hasExceededAttempts = (resp?: ApiErrorResponse | null) => {
  return isHttpResponseError(resp)
    ? resp.status === FORBIDDEN && resp.error.errors?.[0].message === "Max attempts exceeded"
    : false;
};
