import { useTranslation } from "react-i18next";
import React from "react";
import { PersonalDetailsVO } from "@libs/api/generated-api";
import { useObjectState } from "@libs/hooks/useObjectState";
import { Button } from "@libs/components/UI/Button";
import { RadioList } from "@libs/components/UI/RadioList";
import { Form } from "@libs/components/UI/Form";
import { FormFieldNumberInput } from "@libs/components/UI/FormFieldNumberInput";
import { Modal } from "components/UI/Modal";
import { ModalContent } from "components/UI/ModalComponents";
import { useGenderOptions } from "hooks/useGenderOptions";
import { ExistingPatientInfo } from "components/PatientForms/FormElements/types";

export type PatientPreviewFormAttributes = {
  age: number;
  gender: ExistingPatientInfo["gender"];
};

type Props = {
  patientAttributes: PatientPreviewFormAttributes;
  onChangePatientAttributes: (newAttributes: Partial<PatientPreviewFormAttributes>) => void;
};
export const PatientPreviewAdjustAttributesModal: React.FC<Props> = ({
  patientAttributes,
  onChangePatientAttributes,
}) => {
  const genderOptions = useGenderOptions();
  const { t } = useTranslation();
  const [newAttributes, updateNewAttributes] = useObjectState(patientAttributes);
  const handleClose = React.useCallback(
    (didCancel?: boolean) => onChangePatientAttributes(didCancel ? patientAttributes : newAttributes),
    [newAttributes, onChangePatientAttributes, patientAttributes]
  );

  return (
    <Modal size="xs" onClose={() => handleClose(true)} aria-labelledby="Adjust Patient Attributes">
      <ModalContent padding="sm" className="flex flex-col gap-y-4">
        <div className="text-xl font-sansSemiBold text-archyBlue-800">Preview As</div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            handleClose(false);
          }}
        >
          <RadioList
            label={t("Gender")}
            layout="vert"
            className="mb-4"
            selectedValue={newAttributes.gender}
            onChange={(e) => {
              updateNewAttributes({ gender: e.target.value as PersonalDetailsVO["gender"] });
            }}
            options={genderOptions}
          />
          <FormFieldNumberInput
            edit
            label={t("Age")}
            value={newAttributes.age}
            displayErrorMessage={false}
            error={newAttributes.age ? undefined : "Age required"}
            placeholder="Patient Age"
            onValueChange={(value) => {
              updateNewAttributes({ age: value });
            }}
          />
          <Button className="mt-4 w-full" disabled={!newAttributes.age} onClick={() => handleClose(false)}>
            Update
          </Button>
        </Form>
      </ModalContent>
    </Modal>
  );
};
