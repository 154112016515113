import React from "react";
import { useTranslation } from "react-i18next";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { ExternalLink } from "components/UI/Link";
import { semibold16 } from "assets/styles/textSize";
import { Card } from "components/UI/Cards";
import { CallPractice, EmailPractice } from "components/UI/ContactPractice";
import { paths } from "router/paths";

export type TimelineTypes = "PAST" | "FUTURE";

type Props = {
  practice?: PracticeInfoVO;
  appointmentType: "PAST" | "FUTURE";
  selfBookingEnabled: boolean;
  className?: string;
};

export const NoAppointmentsCard: React.FC<Props> = ({
  appointmentType = "FUTURE",
  selfBookingEnabled,
  className,
}) => {
  const practice = useCurrentPractice();
  const { t } = useTranslation();

  const cxStyles = {
    contactContiner: "flex flex-col gap-2",
    contactItem: "flex items-center flex-row gap-2",
  };

  return (
    <Card className={className} inCarousel={false}>
      {appointmentType === "FUTURE" && (
        <div className="flex flex-col gap-3 justify-center">
          <p className={semibold16}>{t("No Upcoming Appointments")}</p>
          <p>
            {t("no.upcoming.appointment.message", {
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              book: selfBookingEnabled ? ` ${t("no.upcoming.appointment.message.bookonline")},` : "",
            })}
          </p>
          <div className={cxStyles.contactContiner}>
            {selfBookingEnabled ? (
              <div className={cxStyles.contactItem}>
                <ExternalLink
                  to={paths.selfBooking({ practiceUuid: practice.uuid }, { allowSignedIn: true })}
                >
                  {t("no.upcoming.appointment.message.bookonlineLink")}
                </ExternalLink>
              </div>
            ) : null}
            <CallPractice phoneNumber={practice.phoneNumber} />
            <EmailPractice email={practice.email} />
          </div>
        </div>
      )}

      {appointmentType === "PAST" && (
        <div className="flex flex-col justify-center py-8">
          <p className={cx("text-center text-greyLight", semibold16)}>{t("No Past Appointments")}</p>
        </div>
      )}
    </Card>
  );
};
