// See https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
const SUPPORTED_UPLOAD_MIME_TYPES = {
  // P1
  ["application/pdf"]: ["pdf"],
  ["image/jpeg"]: ["jpg", "jpeg"],
  ["image/png"]: ["png"],

  // P2
  ["application/msword"]: ["doc"],
  ["application/vnd.openxmlformats-officedocument.wordprocessingml.document"]: ["docx"],
  ["application/vnd.oasis.opendocument.text"]: ["odt"],
  ["application/vnd.ms-excel"]: ["xls"],
  ["application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]: ["xlsx"],
  ["application/vnd.ms-powerpoint"]: ["ppt"],
  ["application/vnd.openxmlformats-officedocument.presentationml.presentation"]: ["pptx"],
  ["text/plain"]: ["txt"],
  ["application/zip"]: ["zip"],
  ["image/gif"]: ["gif"],
} as const;

export type SupportedUploadMimeTypes = keyof typeof SUPPORTED_UPLOAD_MIME_TYPES;
export type SupportedExtentions = (typeof SUPPORTED_UPLOAD_MIME_TYPES)[SupportedUploadMimeTypes][number];
type SupportedMimeWildcards = "*/*" | "image/*";
export type AllSupportedUploadMimes = SupportedUploadMimeTypes | SupportedMimeWildcards;

export const allSupportedUploadMimeTypes = () =>
  Object.keys(SUPPORTED_UPLOAD_MIME_TYPES) as SupportedUploadMimeTypes[];

export const allSupportedUploadExtensions = () => Object.values(SUPPORTED_UPLOAD_MIME_TYPES).flat();

const generateExtentionToMimeTypeMap = () => {
  const allMimeTypes = allSupportedUploadMimeTypes();

  return Object.assign(
    {},
    ...allMimeTypes.flatMap((mimeType) =>
      SUPPORTED_UPLOAD_MIME_TYPES[mimeType].map((ext) => ({ [ext]: mimeType }))
    )
  ) as Record<SupportedExtentions, SupportedUploadMimeTypes>;
};

const EXTENSION_TO_MIME_TYPES = generateExtentionToMimeTypeMap();

const mimeWildcardToMimeTypes = (mimeWildcard: SupportedMimeWildcards) => {
  const allMimeTypes = allSupportedUploadMimeTypes();

  if (mimeWildcard === "*/*") {
    return allMimeTypes;
  }

  const [prefix] = mimeWildcard.split("/");

  return allMimeTypes.filter((mimeType) => mimeType.startsWith(`${prefix}/`));
};

const mimeWildcardToExtensions = (mimeWildcard: SupportedMimeWildcards) => {
  const matchingMimeTypes = mimeWildcardToMimeTypes(mimeWildcard);

  return matchingMimeTypes.flatMap((mimeType) => SUPPORTED_UPLOAD_MIME_TYPES[mimeType]);
};

const isMimeWildcard = (mimeType: AllSupportedUploadMimes): mimeType is SupportedMimeWildcards =>
  mimeType.endsWith("/*");

const mimeTypeToExtensions = (typeOrWildcard: AllSupportedUploadMimes) =>
  isMimeWildcard(typeOrWildcard)
    ? mimeWildcardToExtensions(typeOrWildcard)
    : SUPPORTED_UPLOAD_MIME_TYPES[typeOrWildcard];

export const mimeTypesToExtensions = (typeOrWildcards: AllSupportedUploadMimes[]) => [
  ...new Set(typeOrWildcards.flatMap((typeOrWildcard) => mimeTypeToExtensions(typeOrWildcard))),
];

const expandMimeType = (typeOrWildcard: AllSupportedUploadMimes) =>
  isMimeWildcard(typeOrWildcard) ? mimeWildcardToMimeTypes(typeOrWildcard) : typeOrWildcard;

export const expandMimeTypes = (typeOrWildcards: AllSupportedUploadMimes[]) => [
  ...new Set(typeOrWildcards.flatMap((typeOrWildcard) => expandMimeType(typeOrWildcard))),
];

const isSupportedExtension = (extension: string): extension is SupportedExtentions => {
  return extension in EXTENSION_TO_MIME_TYPES;
};

const extensionToMimeType = (extension: string) => {
  return isSupportedExtension(extension) ? EXTENSION_TO_MIME_TYPES[extension] : "application/octet-stream";
};

export const filenameToMimeType = (filename: string) => {
  const extension = filename.split(".").pop() ?? "";

  return extensionToMimeType(extension.toLowerCase());
};

/**
 * Mime types we can display on the Frontend.
 */
export const MIME_TYPES = {
  IMAGE: ["image/jpeg", "image/jpg", "image/png", "image/gif", "image/svg+xml", "image/webp"],
  VIDEO: ["video/mpeg", "video/ogg", "video/webm", "video/mp4", "video/quicktime"],
  AUDIO: [
    "audio/aac",
    "audio/midi",
    "audio/x-midi",
    "audio/mpeg",
    "audio/ogg",
    "audio/opus",
    "audio/wav",
    "audio/webm",
    "audio/3gpp",
    "audio/3gpp2",
    "audio/mp3",
  ],
};

export const isImage = (type: string): boolean => MIME_TYPES.IMAGE.includes(type);
export const isVideo = (type: string): boolean => MIME_TYPES.VIDEO.includes(type);
export const isAudio = (type: string): boolean => MIME_TYPES.AUDIO.includes(type);
