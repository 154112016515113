import { validateEmail } from "@libs/utils/formatString";
import { password as validatePassword, required } from "@libs/utils/validators";
import { ValidationResult } from "@libs/hooks/useValidation";
import { CognitoErrorCode } from "@libs/utils/cognito";
import { OTP_LENGTH } from "components/ForgotPassword/ResetPasswordForm";

export enum StepType {
  SIGN_IN = "signIn",
  ONE_TIME_CODE = "oneTimeCode",
}
export type BaseSignInState = {
  email: string;
  password: string;
  errorMessage?: string;
  cognitoErrorCode?: CognitoErrorCode;
  isLoading: boolean;
};

export type SignInState = BaseSignInState & {
  step: StepType;
};

export type SignUpState = SignInState & {
  confirmPassword: string;
};

export type ForgotPasswordValidationResults = ValidationResult<
  { email: string; password: string },
  {
    email: {
      $validations: {
        $v: (value: unknown) => boolean;
        $error: string;
      }[];
    };
  }
>;

export type ResetPasswordValidationResults = ValidationResult<
  {
    confirmPassword: string;
    otp: string;
    newPassword: string;
  },
  {
    confirmPassword: {
      $validations: {
        $v: (value: unknown) => boolean;
        $error: string;
      }[];
    };
    otp: {
      $validations: {
        $v: (value: unknown) => boolean;
        $error: string;
      }[];
    };
    newPassword: {
      $validations: {
        $v: (value: unknown) => boolean;
        $error: string;
      }[];
    };
  }
>;

export enum SignInErrors {
  CREDENTIALS_INVALID = "Incorrect email or password.",
  EMAIL_MISSING = "Missing email.",
  EMAIL_INVALID = "Invalid email.",
  OTP_MISSING = "Missing verification code.",
  PASSWORD_INVALID = "Password does not meet the requirements.",
  PASSWORD_MISSING = "Password missing.",
  PASSWORD_MISMATCH = "Passwords do not match.",
  UNKNOWN = "Something went wrong.",
}

export const getEmailValidationSchema = (email: string) => {
  return {
    $validations: [
      { $v: required, $error: SignInErrors.EMAIL_MISSING },
      { $v: () => validateEmail(email), $error: SignInErrors.EMAIL_INVALID },
    ],
  };
};

export const getPasswordValidationSchema = ({
  password,
  comparisonPassword,
  validatePwRules = false,
}: {
  password: string;
  comparisonPassword?: string;
  validatePwRules?: boolean;
}) => {
  const validations = [{ $v: required, $error: SignInErrors.PASSWORD_MISSING }];

  if (validatePwRules) {
    validations.push({
      $v: () => validatePassword(password),
      $error: SignInErrors.PASSWORD_INVALID,
    });
  }

  if (comparisonPassword) {
    validations.push({
      $v: () => password === comparisonPassword,
      $error: SignInErrors.PASSWORD_MISMATCH,
    });
  }

  return { $validations: validations };
};

export const getOtpValidationSchema = (otp: string, mismatchError: string, otpLength?: number) => {
  const length = otpLength ?? OTP_LENGTH;

  return {
    $validations: [
      { $v: required, $error: SignInErrors.OTP_MISSING },
      {
        $v: () => otp.length === length,
        $error: mismatchError,
      },
    ],
  };
};

export const getSignupSchema = ({
  password,
  confirmPassword,
  email,
}: {
  password: string;
  confirmPassword: string;
  email: string;
}) => {
  return {
    confirmPassword: getPasswordValidationSchema({
      password: confirmPassword,
      comparisonPassword: password,
      validatePwRules: true,
    }),
    email: getEmailValidationSchema(email),
    password: getPasswordValidationSchema({
      password,
    }),
  };
};
export type SignUpValidationResult = ValidationResult<SignUpState, ReturnType<typeof getSignupSchema>>;
