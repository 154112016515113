import React from "react";
import { useNavigate } from "react-router-dom";
import { InsuranceDetailsVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { addPatientInsurance } from "api/patientInsurance/mutations";
import { InsuranceDraftFields } from "components/PatientInsurances/hooks/useInsuranceDraft";
import { useHandleError } from "api/handleErrorResponse";
import { getDraftPatientInsuranceFormSchema } from "components/PatientInsurances/formData";
import { paths } from "router/paths";

type UseSubmitInsurance = {
  draftInsurance: InsuranceDraftFields;
};

export const getAddInsuranceFormFields = (draftInsurance: InsuranceDraftFields): InsuranceDetailsVO => {
  const { type, primarySubscriber, dependentSubscriber } = draftInsurance;
  const subscriber = type === "PRIMARY_SUBSCRIBER" ? primarySubscriber : dependentSubscriber;
  const isDependent = type === "DEPENDENT";

  return {
    type,
    primarySubscriber: {
      carrierId: subscriber.carrierId,
      subscriberId: subscriber.idType === "subscriberId" ? subscriber.subscriberId : undefined,
      ssn: subscriber.idType === "ssn" ? subscriber.ssn : undefined,
      employer: subscriber.employer,
      dob: isDependent ? dependentSubscriber.dob : undefined,
      relation: isDependent ? dependentSubscriber.relation : undefined,
      firstName: isDependent ? dependentSubscriber.firstName : undefined,
      lastName: isDependent ? dependentSubscriber.lastName : undefined,
    },
  };
};

export const useSubmitInsurance = ({ draftInsurance }: UseSubmitInsurance) => {
  const { practiceId, id: patientId } = useAccount();
  const navigate = useNavigate();
  const schema = getDraftPatientInsuranceFormSchema(draftInsurance);
  const { result: validation, validate, reset } = useValidation(draftInsurance, schema);
  const [{ mutate: mutateAddPatientInsurance, isLoading: isAdding }] = useApiMutations([addPatientInsurance]);
  const handleError = useHandleError();
  const handleSubmit = React.useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      mutateAddPatientInsurance(
        {
          patientId,
          practiceId,
          data: getAddInsuranceFormFields(draftInsurance),
        },
        {
          onSuccess: () => {
            navigate(paths.insurances());
          },
          onError: handleError,
        }
      );
    },
    [validate, mutateAddPatientInsurance, patientId, practiceId, draftInsurance, handleError, navigate]
  );

  return { handleSubmit, resetValidation: reset, validation, validate, isSubmitting: isAdding };
};

export type UseSubmitInsuranceResult = ReturnType<typeof useSubmitInsurance>;
