import { useCallback, useEffect, useMemo, useState } from "react";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { SelfBookingV1, getSelfBookingStorage } from "storage/selfBooking";

export const useSelfBookingState = () => {
  const { sessionStorage } = useStorageContext();
  const selfBookingStorage = useMemo(() => getSelfBookingStorage(sessionStorage), [sessionStorage]);

  const [localState, setLocalState] = useState(() => selfBookingStorage.getSelfBookingData() ?? {});

  const setState = useCallback((partialState: Partial<SelfBookingV1>) => {
    setLocalState((last) => ({ ...last, ...partialState }));
  }, []);

  const resetState = useCallback(() => {
    setLocalState({});
  }, []);

  useEffect(() => {
    selfBookingStorage.setSelfBookingData(Object.keys(localState).length ? localState : null);
  }, [localState, selfBookingStorage]);

  return useMemo(
    () => ({
      state: localState,
      setState,
      resetState,
    }),
    [localState, setState, resetState]
  );
};
