import React from "react";
import { useTranslation } from "react-i18next";

import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { TaskProgressHeader } from "@libs/components/UI/TaskProgressHeader";
import { semibold16 } from "assets/styles/textSize";
import { OnboardingStep, useOnboardingOrder } from "components/Onboarding/hooks/useOnboardingOrder";

const useOnboardingSections = (currentOnboardingState: OnboardingStep) => {
  const { t } = useTranslation();
  const onboardOrder = useOnboardingOrder();

  return React.useMemo(() => {
    // Last section isn't viewable (PDF_SUBMISSION)
    const viewableSections = [...onboardOrder];

    viewableSections.pop();

    const currentIndex = viewableSections.indexOf(currentOnboardingState);

    return viewableSections.map((onboardingState, index) => ({
      onboardingState,
      isLast: index === viewableSections.length - 1,
      label: t(`onboard.${onboardingState}` as const),
      completed: index < currentIndex,
      index,
      active: onboardingState === currentOnboardingState,
    }));
  }, [onboardOrder, currentOnboardingState, t]);
};

type Props = {
  onboardingState: OnboardingStep;
  onClickSkip: Func;
  showSkip: boolean;
};
export const OnboardingSectionsBar: React.FC<Props> = ({
  onboardingState,
  onClickSkip,
  showSkip = false,
}) => {
  const sections = useOnboardingSections(onboardingState);
  const currentSection = sections.find((item) => item.active);
  const { t } = useTranslation();

  if (!currentSection) {
    return <Skeleton />;
  }

  return (
    <div
      className={`
        flex
        flex-col
        p-4
        gap-4
        shadow-[0_5px_5px_-5px_rgba(188,188,188,0.75)]
      `}
    >
      <TaskProgressHeader totalSteps={sections.length} step={sections.indexOf(currentSection)} />
      <div className="flex justify-between">
        <div className={cx("text-secondaryTheme", semibold16)}>{currentSection.label}</div>
        {showSkip ? (
          <Button theme="link" onClick={onClickSkip}>
            {t("app.pages.paymentInfo.card.skip")}
          </Button>
        ) : null}
      </div>
    </div>
  );
};
