import { QueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";

export const invalidateInvoices = ({
  queryClient,
  patientId,
  practiceId,
  invoiceUUids = [],
}: {
  queryClient: QueryClient;
  patientId: number;
  practiceId: number;
  invoiceUUids?: string[];
}) => {
  queryClient.invalidateQueries({
    queryKey: [getQueryKey("practices", "getInvoices"), { patientId, practiceId }],
    exact: false,
  });

  for (const invoiceUuid of invoiceUUids) {
    queryClient.invalidateQueries([
      getQueryKey("practices", "getInvoice"),
      { patientId, practiceId, invoiceUuid },
    ]);
  }
};
