import { MutableRefObject, ForwardedRef } from "react";

export const getNamespaceId = (formName: string) => (id: string | number) => `${formName}-${id}`;

export const mirroredOptions = <V extends SelectOptionValue>(items: V[]) =>
  items.map((val) => ({ label: val, value: val }));

/**
 * Convenience function for passing multiple refs to a component.
 * @param refs a list of refs that all receive the same type.
 * @returns a function that takes a value of the type and passes it to all the refs.
 *
 * const Component = forwardRef<HTMLInputElement, Props>((
    props,
    ref
  ) => {
    const elRef = useRef<HTMLInputElement | null>(null);


    return (
      <input
        ref={passRefs([ref, elRef])}
      />
    );
  }
);
 */
export const passRefs = <T, M extends MutableRefObject<T | null>, F extends ForwardedRef<T>>(
  refs: (M | F | null)[]
) => {
  return (currentRef: T) => {
    for (const ref of refs) {
      if (typeof ref === "function") {
        ref(currentRef);
      } else if (ref) {
        ref.current = currentRef;
      }
    }
  };
};
