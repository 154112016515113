import React, { FormEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { GuestCheckoutSummaryVO, PracticeInfoVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { semibold14 } from "assets/styles/textSize";
import { NewCardForm } from "components/Account/NewCardForm";
import { getEmailValidationSchema } from "components/SignIn/validationUtil";
import { PatientBalanceAsOf } from "components/Billing/PatientBalanceAsOf";
import { CallPracticeToCompletePayment } from "components/Billing/ProcessPayment/CallPracticeToCompletePayment";
import { UseCardDraft } from "hooks/useCardDraft";
import { UsePatientFinixForm } from "hooks/usePatientFinixForm";
import { paths } from "router/paths";

export const AddPaymentMethodForm: React.FC<{
  checkoutSummaryQuery: ApiQueryResult<GuestCheckoutSummaryVO>;
  practice: PracticeInfoVO;
  cardDraftProps: UseCardDraft;
  email: string;
  handleSetEmail: (email: string) => void;
  finixUseFormProps: UsePatientFinixForm;
  isCreatingPaymentProfile: boolean;
}> = ({
  checkoutSummaryQuery,
  finixUseFormProps,
  isCreatingPaymentProfile,
  practice,
  handleSetEmail,
  email,
  cardDraftProps,
}) => {
  const { t } = useTranslation();
  const outstandingBalance = checkoutSummaryQuery.data?.outstandingBalance ?? 0;

  const schema = React.useMemo(() => {
    return {
      email: getEmailValidationSchema(email),
    };
  }, [email]);
  const contactValidation = useValidation({ email }, schema);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (contactValidation.validate().$isValid && cardDraftProps.validation.validate().$isValid) {
        finixUseFormProps.submitForm?.();
      }
    },
    [cardDraftProps.validation, contactValidation, finixUseFormProps]
  );
  const { loadingFinix, isFormValid, isSubmitting } = finixUseFormProps;

  return (
    <>
      {checkoutSummaryQuery.data && <PatientBalanceAsOf value={outstandingBalance} asOfDate={new Date()} />}
      {practice.onboardedWithPaymentProvider ? (
        <Form onSubmit={handleSubmit}>
          <NewCardForm
            title={<div className={semibold14}>{t("pages.guestCheckout.addPaymentMethod")}</div>}
            className="w-full"
            {...finixUseFormProps}
            {...cardDraftProps}
          />

          <div className="flex flex-col gap-4">
            <FormFieldInput
              type="email"
              disabled={loadingFinix}
              error={contactValidation.result.email.$error}
              label={t("Email")}
              placeholder={t("app.page.labels.placeholder.email")}
              onChange={(e) => {
                handleSetEmail(e.target.value);
              }}
            />

            {checkoutSummaryQuery.data?.passCreditCardFees && (
              <div className="text-sm">{t("billing.surchargeWarning")}</div>
            )}

            <AsyncButton
              type="submit"
              disabled={
                !isFormValid ||
                [contactValidation.result, cardDraftProps.validation.result].some(
                  (item) => item.$isValid === false
                )
              }
              isLoading={isSubmitting || isCreatingPaymentProfile}
            >
              {t("Next")}
            </AsyncButton>
            <ButtonInternalLink size="small" theme="link" to={paths.signIn()}>
              {t("app.signInForStatement")}
            </ButtonInternalLink>
          </div>
        </Form>
      ) : (
        <CallPracticeToCompletePayment practice={practice} />
      )}
    </>
  );
};
