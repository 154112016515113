import React, { useEffect, useRef } from "react";
import { getSsn } from "@libs/api/user/queries";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatSSNInput, getFormattedSSNHidden, isSSN } from "@libs/utils/ssn";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { cxIconStyles } from "@libs/components/UI/formFieldStyle";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { useAccount } from "@libs/contexts/AccountContext";
import { ApiErrorResponse } from "@libs/@types/api";
import { SsnRevealButton } from "@libs/components/UI/SsnRevealButton";
import { FormFieldSsnInput, FormFieldSsnProps } from "@libs/components/UI/FormFieldSsnInput";

export type FormFieldRevealSsnInputProps = Omit<
  FormFieldSsnProps,
  "placeholder" | "formatReadOnlyValue" | "children" | "ssnLastFour"
> & {
  ssnLastFour: string;
  onSsnError: (error: ApiErrorResponse) => void;
  userId: number;
};

export const SSN_ERROR_MESSAGE = "There was an error loading your SSN";

const LAST_FOUR = -4;

export const getValue = (
  ssnLastFour: string,
  revealSsn: boolean | undefined,
  value: FormFieldRevealSsnInputProps["value"],
  isSsnLoading: boolean
) => {
  if (revealSsn) {
    if (isSsnLoading) {
      // while the ssn is loading show this value
      // to avoid the field from jumping around
      return getFormattedSSNHidden(ssnLastFour);
    }

    // if the user has chosen to reveal the ssn, the input is enabled
    // and open for edit
    return value ? formatSSNInput(value) : "";
  }

  // If the ssn is concealed and not yet edited the field, show the last four
  // digits of the ssn
  if (!value) {
    return getFormattedSSNHidden(ssnLastFour);
  }

  // If the ssn is concealed the value must be an SSN
  // whether it's the original or an edited one
  return getFormattedSSNHidden(value.slice(LAST_FOUR));
};

export const FormFieldRevealSsnInput: React.FC<FormFieldRevealSsnInputProps> = ({
  edit = true,
  userId,
  ssnLastFour,
  onSsnError,
  ...props
}) => {
  const { practiceId } = useAccount();
  const revealSsn = useBoolean(false);
  const hasLoadedOnce = useRef(false);

  const [{ data: ssnData, isLoading: isSsnLoading, error: ssnError }] = useApiQueries([
    getSsn({
      args: { userId, practiceId },
      queryOptions: {
        enabled: revealSsn.isOn,
        onSuccess: (data) => {
          const body = data.data.data;
          const serverSsn = body.ssn;

          // If there is no ssn loaded from the server
          // there is nothing to update.

          // If the ssn has already been loaded
          // don't update the UI again if the query
          // becomes stale and refetches. This avoids
          // overwriting a user's edits.
          if (edit && serverSsn && !hasLoadedOnce.current) {
            props.onValueChange?.(serverSsn);
          }

          hasLoadedOnce.current = true;
        },
        onError: onSsnError,
      },
    }),
  ]);

  const serverSsn = ssnData?.ssn;

  const editRef = useRef(edit);
  const displayValue = getValue(ssnLastFour, revealSsn.isOn, props.value, isSsnLoading);
  const concealDisabled = (!props.value || !isSSN(props.value)) && revealSsn.isOn;
  const editModeRevealButtonDisabled = props.disabled || concealDisabled;
  const formFieldError = ssnError ? SSN_ERROR_MESSAGE : props.error;

  const reveal = () => {
    // Handles case when ssn is already loaded
    // and user clicks reveal button
    // and the user doesn't already have an ssn
    // in draft.
    if (edit && serverSsn && !props.value) {
      props.onValueChange?.(serverSsn);
    }

    revealSsn.on();
  };

  const conceal = revealSsn.off;

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    props.onBlur?.(e);

    // There is no need to conceal the value
    // if it's not a proper ssn
    if (!editModeRevealButtonDisabled) {
      conceal();
    }
  };

  const handleFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    props.onFocus?.(e);

    if (!editModeRevealButtonDisabled) {
      reveal();
    }
  };

  // When switching between editing and read only always conceal.
  // Overrides anything disabling reveal actions.
  useEffect(() => {
    if (edit !== editRef.current) {
      conceal();
    }

    editRef.current = edit;
  }, [conceal, edit]);

  const handleRevealClick = revealSsn.isOn ? conceal : reveal;

  return (
    <FormFieldSsnInput
      {...props}
      error={formFieldError}
      edit={edit}
      className="w-full"
      disableFormatting={revealSsn.isOff || isSsnLoading || props.disabled}
      readOnly={isSsnLoading}
      value={displayValue}
      onFocus={handleFocus}
      onBlur={handleBlur}
      formatReadOnlyValue={() => (
        <>
          <div className="flex items-center">
            <div className="w-20">
              {revealSsn.isOn && serverSsn ? formatSSNInput(serverSsn) : getFormattedSSNHidden(ssnLastFour)}
            </div>
            <SsnRevealButton
              disabled={props.disabled}
              isSsnLoading={isSsnLoading}
              revealSsn={revealSsn.isOn}
              onClick={handleRevealClick}
            />
          </div>
          {ssnError ? <FormFieldError>{SSN_ERROR_MESSAGE}</FormFieldError> : null}
        </>
      )}
    >
      {edit ? (
        <div className={cxIconStyles.container({ clickable: true })}>
          <SsnRevealButton
            disabled={editModeRevealButtonDisabled}
            isSsnLoading={isSsnLoading}
            revealSsn={revealSsn.isOn}
            onClick={handleRevealClick}
          />
        </div>
      ) : null}
    </FormFieldSsnInput>
  );
};
