import { useContext, useMemo } from "react";
import { UseInfiniteQueryResult, useInfiniteQuery } from "@tanstack/react-query";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import type { ApiErrorResponse, ApiResponse } from "@libs/@types/api";
import type { InfiniteQuery, MappedInfiniteQuery } from "@libs/@types/apiQueries";

export const unwrapInfiniteQuery = <D>(result: UseInfiniteQueryResult<ApiResponse<D>, ApiErrorResponse>) => {
  // HACK: Make react-query v4 behave like react-query v3 when it comes to
  // enabled/isLoading. Revert this PR when we upgrade to react-query v5:
  // https://github.com/grindfoundryinc/grindfoundry-ui/pull/1831. More info is
  // included in the PR description and comments.
  const isLoadingV3 = result.isLoading && result.fetchStatus !== "idle";

  return {
    ...result,
    data: result.data && {
      ...result.data,
      pages: result.data.pages.map((page) => ({
        ...page,
        data: page.data.data,
        apiResponse: page,
      })),
    },
    isLoading: isLoadingV3,
  };
};

export const useInfiniteApiQuery = <T extends InfiniteQuery>(getInfiniteQueryOptions: T) => {
  const { httpClient } = useContext(ApiClientContext);
  const options = getInfiniteQueryOptions({
    api: httpClient,
  });
  const infiniteQuery = useInfiniteQuery(options);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return useMemo(() => unwrapInfiniteQuery(infiniteQuery) as any as MappedInfiniteQuery<T>, [infiniteQuery]);
};
