import React from "react";
import { PatientFormBooleanElement } from "components/PatientForms/FormPDFElements/PatientFormBooleanElement";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { PatientFormSelectElement } from "components/PatientForms/FormPDFElements/PatientFormSelectElement";
import { FormTextElement } from "components/PatientForms/FormPDFElements/FormTextElement";
import { FormInputElement } from "components/PatientForms/utils";

type Props = {
  element: FormInputElement;
  responsesById: PatientResponses;
  edit?: boolean;
};

export const PatientFormInputElement: React.FC<Props> = ({ element, responsesById, edit = false }) => {
  const id = `question-${element.uuid}`;
  const { title, settings, uuid } = element;
  const required = settings.includes("REQUIRED");
  const sharedProps = { required, id, label: title, edit };
  const response = responsesById[uuid];

  switch (element.type) {
    case "BOOLEAN_INPUT": {
      return <PatientFormBooleanElement {...sharedProps} element={element} responsesById={responsesById} />;
    }
    case "SELECT_INPUT": {
      return <PatientFormSelectElement {...sharedProps} element={element} responsesById={responsesById} />;
    }
    case "TEXT_INPUT":
    case "NUMBER_INPUT":
    case "DATE_INPUT": {
      return <FormTextElement edit={edit} element={element} response={response} />;
    }
    default: {
      return null;
    }
  }
};
