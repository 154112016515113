import React from "react";
import { cx } from "@libs/utils/cx";

export const DividerWithText: React.FC<{ children: string; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div className={cx("flex items-center gap-2 space-between", className)}>
      <div className="bg-[#D9D9D9] h-[1px] flex-1" />
      {children}
      <div className="bg-[#D9D9D9] h-[1px] flex-1" />
    </div>
  );
};
