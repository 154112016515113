import { createContext, useContext } from "react";

export interface AccountContextValue {
  id: number;
  practiceId: number;
  practiceUuid: string;
  email: string;
}

const Context = createContext<AccountContextValue>({
  id: 0,
  practiceId: 0,
  practiceUuid: "",
  email: "",
});

Context.displayName = "AccountContext";
export const AccountContext = Context;
export const useAccount = () => useContext(AccountContext);
