import { makeQueryOptionalArgs, makeQuery } from "@libs/utils/queries";
import { cacheForSession, oneMinuteCache } from "@libs/utils/queryCache";

export const getUserAccounts = makeQueryOptionalArgs({
  queryKey: ["user", "getUserAccounts"],
  formatParams: () => [{ userTypes: ["PATIENT" as const] }],
  queryOptions: oneMinuteCache,
});

export const getPracticeInfoByUuid = makeQuery({
  queryKey: ["public", "getPracticeInfoByUuid"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});
