import React from "react";
import { FormTextBlockElementVO } from "@libs/api/generated-api";
import { PatientFormLexicalRootNode } from "components/PatientForms/FormPDFElements/PatientFormLexicalRootNode";
import { Box } from "components/PatientForms/FormPDFElements/Box";

type Props = {
  element: FormTextBlockElementVO;
};

export const PatientFormTextBlock: React.FC<Props> = ({ element }) => {
  const { content: lexicalJson } = element;

  return (
    <Box>
      <PatientFormLexicalRootNode lexicalJson={lexicalJson} />
    </Box>
  );
};
