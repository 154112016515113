import { createContext, FC, PropsWithChildren, useMemo } from "react";
import { getApiClient, getFetchApi, getFetchBlobApi } from "@libs/api/clients";
import { ErrorResponse, HttpResponse } from "@libs/api/generated-api";
import { ApiInstance } from "@libs/@types/api";
import { asyncNoop } from "@libs/utils/noop";

interface ApiClientContextValue {
  httpClient: ApiInstance;
  fetchData: <D = unknown>(url: string, request?: RequestInit) => Promise<D>;
  fetchBlob: (url: string, request?: RequestInit) => Promise<Blob>;
}

const Context = createContext<ApiClientContextValue>({
  httpClient: {} as ApiInstance,
  fetchData: asyncNoop as unknown as ApiClientContextValue["fetchData"],
  fetchBlob: asyncNoop as unknown as ApiClientContextValue["fetchBlob"],
});

Context.displayName = "ApiClientContext";
export const ApiClientContext = Context;

export const ApiClientProvider: FC<
  PropsWithChildren<{
    baseUrl: string;
    headers: Record<string, string>;
    onRequestTokens: () => Promise<{ identity: string; account?: string }>;
    onErrorResponse?: (response: HttpResponse<unknown, ErrorResponse | undefined>) => void;
  }>
> = ({ children, onErrorResponse, onRequestTokens, baseUrl, headers }) => {
  const apiClient = useMemo(() => {
    return {
      fetchBlob: getFetchBlobApi({ baseUrl, headers, onRequestTokens, onErrorResponse }),
      fetchData: getFetchApi({ baseUrl, headers, onRequestTokens, onErrorResponse }),
      httpClient: getApiClient({ baseUrl, headers, onRequestTokens, onErrorResponse }),
    };
  }, [baseUrl, headers, onErrorResponse, onRequestTokens]);

  return <Context.Provider value={apiClient}>{children}</Context.Provider>;
};
