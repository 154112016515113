import React from "react";
import { Draft, produce } from "immer";
import {
  FormBooleanResponseVO,
  FormConsentResponseVO,
  FormDateResponseVO,
  FormNumberResponseVO,
  FormSelectInputElementVO,
  FormSelectResponseVO,
  FormStringResponseVO,
  FormVO,
} from "@libs/api/generated-api";
import { isDefined, isNullish } from "@libs/utils/types";
import { useBoolean } from "@libs/hooks/useBoolean";
import { findElement, getAllInputAndConsentElementIds } from "components/PatientForms/utils";

export type ResponseValue =
  | FormConsentResponseVO
  | FormBooleanResponseVO
  | FormDateResponseVO
  | FormNumberResponseVO
  | FormSelectResponseVO
  | FormStringResponseVO;

export type ResponseChangedCallback = (uuid: string, response: ResponseValue) => void;
export type PatientResponses = Record<string, ResponseValue | undefined>;

const cleanSelectResponse = (response: Draft<FormSelectResponseVO>, element: FormSelectInputElementVO) => {
  // if question no longer accepts other drop the "other" response
  if (isDefined(response.other) && !element.settings.includes("ALLOW_ADDITIONAL_OPTION")) {
    delete response.other;
  }

  const options = new Set(element.options);

  const responseOptionIds = Object.keys(response.responses);

  for (const responseOptionId of responseOptionIds) {
    if (!options.has(responseOptionId)) {
      delete response.responses[responseOptionId];
    }
  }

  // question changes the selection setting need to clean up previous responses
  if (
    !element.settings.includes("ALLOW_MULTIPLE_SELECTIONS") &&
    !element.settings.includes("ENFORCE_EXPLICIT_CONSENT")
  ) {
    // if multiple  selections were made before ignore and prompt user to select a response
    const selectionCount = Object.keys(response.responses).length + (isNullish(response.other) ? 0 : 1);

    if (selectionCount > 1) {
      response.responses = {};
      delete response.other;
    }
  }
};

const getCompatibleResponses = (
  filteredForm: FormVO,
  latestResponses: PatientResponses,
  reconsent?: boolean
) => {
  return produce(latestResponses, (draft) => {
    const formIds = new Set(getAllInputAndConsentElementIds(filteredForm));
    const responseIds = Object.keys(draft);

    for (const responseId of responseIds) {
      if (formIds.has(responseId)) {
        const response = draft[responseId];

        if (response?.type === "CONSENT" && reconsent) {
          // Only difference from practice portal, if user is editing via their account, they must re-consent to changes
          delete draft[responseId];
          continue;
        }

        if (response?.type !== "SELECT") {
          continue;
        }

        const match = findElement(filteredForm, "uuid", responseId);
        const element = match?.element;

        if (element?.type !== "SELECT_INPUT") {
          continue;
        }

        cleanSelectResponse(response, element);
      } else {
        delete draft[responseId];
      }
    }
  });
};

export const usePatientResponses = (params: {
  latestForm: FormVO;
  latestResponses: PatientResponses;
  onChangeResponses?: (updated: PatientResponses) => void;
  reconsent?: boolean;
}) => {
  const { latestForm, latestResponses, onChangeResponses, reconsent } = params;

  const responsesDirty = useBoolean(false);
  const [responsesById, setResponsesById] = React.useState(
    getCompatibleResponses(latestForm, latestResponses, reconsent)
  );
  const turnOnResponsesDirty = responsesDirty.on;
  const handleResponseChanged = React.useCallback(
    (uuid: string, response: ResponseValue) => {
      turnOnResponsesDirty();

      const updatedResponses = {
        ...responsesById,
        [uuid]: response,
      };

      if (onChangeResponses) {
        onChangeResponses(updatedResponses);
      }

      setResponsesById((latest) => ({
        ...latest,
        [uuid]: response,
      }));
    },
    [onChangeResponses, responsesById, turnOnResponsesDirty]
  );

  return {
    responsesById,
    setResponsesById,
    handleResponseChanged,
    responsesDirty: responsesDirty.isOn,
    clearResponses: React.useCallback(() => setResponsesById({}), []),
  };
};
