import { useApiQueries } from "@libs/hooks/useApiQueries";
import { DownloadAssetLink } from "components/UI/DownloadAssetLink";
import { printPayment } from "api/billing/queries";

export const DownloadPaymentLink: React.FC<{
  invoiceUuid: string;
  practiceId: number;
  patientId: number;
}> = ({ practiceId, patientId, invoiceUuid }) => {
  const [printPaymentQuery] = useApiQueries([
    printPayment({
      args: {
        practiceId,
        patientId,
        paymentUuid: invoiceUuid,
      },
      queryOptions: { enabled: false },
    }),
  ]);

  return <DownloadAssetLink onClickGetAssetLink={printPaymentQuery.refetch} />;
};
