import { Trans } from "react-i18next";

export const PasswordGuidelines = () => {
  return (
    <div className="font-sans text-sm">
      <Trans
        i18nKey="app.page.labels.passwordGuidelines"
        components={{
          b: <strong />,
        }}
      />
    </div>
  );
};
