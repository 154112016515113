import React, { FormEvent, useCallback } from "react";
import { Auth } from "aws-amplify";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import { useValidation } from "@libs/hooks/useValidation";
import { useObjectState } from "@libs/hooks/useObjectState";
import { CognitoErrorCode } from "@libs/utils/cognito";
import { ForgotPasswordForm } from "components/ForgotPassword/ForgotPasswordForm";
import {
  BaseSignInState,
  getEmailValidationSchema,
  getOtpValidationSchema,
  getPasswordValidationSchema,
} from "components/SignIn/validationUtil";
import { ResetPasswordForm } from "components/ForgotPassword/ResetPasswordForm";
import { useAuthErrorHandler } from "hooks/useAuthErrorHandler";
import { OneTimeCodeForm } from "components/ForgotPassword/OneTimeCodeForm";
import { ConfirmEmailToSignIn } from "components/SignIn/ConfirmEmailToSignIn";
import { defaultSnackbarOptions } from "utils/snackbar";

enum StepType {
  FORGOT_PASSWORD = "forgotPassword",
  RESET_PASSWORD = "resetPassword",
  ONE_TIME_CODE = "oneTimeCode",
}

export interface ForgotPasswordProps extends BaseSignInState {
  cognitoErrorCode?: CognitoErrorCode;
  confirmPassword: string;
  newPassword: string;
  otp: string;
  step: StepType;
}

const initialState = {
  cognitoErrorCode: undefined,
  confirmPassword: "",
  email: "",
  errorMessage: "",
  isLoading: false,
  newPassword: "",
  password: "",
  otp: "",
  step: StepType.FORGOT_PASSWORD,
};

export type ForgotPasswordInputType = "confirmPassword" | "email" | "newPassword" | "otp";

export const useForgotPasswordState = () => {
  const [forgotPasswordState, handleUpdateForgotPasswordState] =
    useObjectState<ForgotPasswordProps>(initialState);
  const { t } = useTranslation();

  return React.useMemo(() => {
    let stepTitle = t("app.page.labels.forgotPassword");

    switch (forgotPasswordState.step) {
      case StepType.RESET_PASSWORD: {
        stepTitle = t("app.page.labels.resetPassword");
        break;
      }
      case StepType.ONE_TIME_CODE: {
        stepTitle = t("forgotPassword.emailConfirmTitle");
        break;
      }
      default: {
        break;
      }
    }

    return {
      stepTitle,
      forgotPasswordState,
      handleUpdateForgotPasswordState,
    };
  }, [forgotPasswordState, handleUpdateForgotPasswordState, t]);
};
export const ForgotPassword: React.FC<{
  onPasswordReset: Func;
  forgotPasswordState: ForgotPasswordProps;
  onUpdateForgotPasswordState: (state: Partial<ForgotPasswordProps>) => void;
}> = ({ onPasswordReset, forgotPasswordState, onUpdateForgotPasswordState }) => {
  const { t } = useTranslation();

  const { enqueueSnackbar } = useSnackbar();

  const passwordStep = forgotPasswordState.step;
  const updateErrorData = React.useCallback(
    (errorMessage: string, cognitoErrorCode: CognitoErrorCode) => {
      onUpdateForgotPasswordState({
        cognitoErrorCode,
        errorMessage,
        isLoading: false,
        step: cognitoErrorCode === "InvalidParameterException" ? StepType.ONE_TIME_CODE : passwordStep,
      });
    },
    [onUpdateForgotPasswordState, passwordStep]
  );
  const { handleAuthError } = useAuthErrorHandler(updateErrorData);

  const forgotPasswordForm = useValidation(
    { email: forgotPasswordState.email },
    {
      email: getEmailValidationSchema(forgotPasswordState.email),
    }
  );

  const resetPasswordForm = useValidation(
    {
      confirmPassword: forgotPasswordState.confirmPassword,
      otp: forgotPasswordState.otp,
      newPassword: forgotPasswordState.newPassword,
    },
    {
      confirmPassword: getPasswordValidationSchema({
        password: forgotPasswordState.confirmPassword,
        comparisonPassword: forgotPasswordState.newPassword,
        validatePwRules: true,
      }),
      otp: getOtpValidationSchema(forgotPasswordState.otp, t("cognito.CodeMismatchException")),
      newPassword: getPasswordValidationSchema({
        password: forgotPasswordState.newPassword,
        comparisonPassword: forgotPasswordState.confirmPassword,
        validatePwRules: true,
      }),
    }
  );

  const handleInputChange = useCallback(
    (inputName: ForgotPasswordInputType, value: string) => {
      forgotPasswordForm.reset();
      resetPasswordForm.reset();
      onUpdateForgotPasswordState({
        cognitoErrorCode: undefined,
        errorMessage: "",
        [inputName]: value,
      });
    },
    [forgotPasswordForm, resetPasswordForm, onUpdateForgotPasswordState]
  );

  const handleForgotPassword = useCallback(
    async (e?: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (e) {
        e.preventDefault();
      }

      const validate = forgotPasswordForm.validate();

      if (!validate.$isValid) {
        return;
      }

      onUpdateForgotPasswordState({ isLoading: true });

      try {
        await Auth.forgotPassword(forgotPasswordState.email);
        onUpdateForgotPasswordState({
          isLoading: false,
          step: StepType.RESET_PASSWORD,
        });
      } catch (error) {
        handleAuthError(error);
      }
    },
    [forgotPasswordForm, onUpdateForgotPasswordState, forgotPasswordState.email, handleAuthError]
  );

  const handleResetPassword = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validate = resetPasswordForm.validate();

      if (!validate.$isValid) {
        return;
      }

      try {
        await Auth.forgotPasswordSubmit(
          forgotPasswordState.email,
          forgotPasswordState.otp,
          forgotPasswordState.newPassword
        );
        onUpdateForgotPasswordState({ isLoading: false });
        onPasswordReset();
      } catch (error) {
        handleAuthError(error);
      }
    },
    [
      resetPasswordForm,
      forgotPasswordState.email,
      forgotPasswordState.otp,
      forgotPasswordState.newPassword,
      onUpdateForgotPasswordState,
      onPasswordReset,
      handleAuthError,
    ]
  );

  return forgotPasswordState.cognitoErrorCode === "InvalidParameterException" ? (
    <ConfirmEmailToSignIn
      text={t("forgotPassword.confirmEmailToSignIn")}
      onClickReConfirm={() => {
        Auth.resendSignUp(forgotPasswordState.email);
        onUpdateForgotPasswordState({
          step: StepType.ONE_TIME_CODE,
          cognitoErrorCode: undefined,
          errorMessage: undefined,
        });
      }}
      onClickCancel={() => {
        onUpdateForgotPasswordState({
          step: StepType.FORGOT_PASSWORD,
          cognitoErrorCode: undefined,
          errorMessage: undefined,
        });
      }}
    />
  ) : forgotPasswordState.step === StepType.FORGOT_PASSWORD ? (
    <ForgotPasswordForm
      handleForgotPassword={handleForgotPassword}
      handleInputChange={handleInputChange}
      forgotPasswordState={forgotPasswordState}
      validateResult={forgotPasswordForm.result}
    />
  ) : forgotPasswordState.step === StepType.RESET_PASSWORD ? (
    <ResetPasswordForm
      handleForgotPassword={handleForgotPassword}
      handleInputChange={handleInputChange}
      handleResetPassword={handleResetPassword}
      forgotPasswordState={forgotPasswordState}
      validateResult={resetPasswordForm.result}
    />
  ) : (
    <OneTimeCodeForm
      email={forgotPasswordState.email}
      onEmailConfirmed={() => {
        enqueueSnackbar(t("forgotPassword.emailConfirmed"), defaultSnackbarOptions);
        handleForgotPassword();
      }}
    />
  );
};
