import React from "react";
import { FormVO, PracticeInfoVO } from "@libs/api/generated-api";
import { canPatientViewSection, filterForm } from "components/PatientForms/utils";
import { ExistingPatientInfo } from "components/PatientForms/FormElements/types";

export const useFilteredForm = ({
  form,
  patientAttributes,
  practice,
}: {
  form: FormVO;
  patientAttributes: Pick<ExistingPatientInfo, "dob" | "gender">;
  practice: PracticeInfoVO;
}) => {
  return React.useMemo(() => {
    return filterForm(form, (element) => {
      if (element.type === "SECTION") {
        return canPatientViewSection(patientAttributes, element, practice.timezoneId);
      }

      return true;
    });
  }, [form, patientAttributes, practice.timezoneId]);
};
