import React from "react";
import { useTranslation } from "react-i18next";

export const useGenderOptions = () => {
  const { t } = useTranslation();

  return React.useMemo(() => {
    return ["MALE" as const, "FEMALE" as const, "OTHER" as const, "UNKNOWN" as const].map((value) => ({
      value,
      label: t(`genderOption.${value}`) as string,
    }));
  }, [t]);
};
