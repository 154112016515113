import { View } from "@react-pdf/renderer";
import React from "react";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { PDFRadio } from "components/PatientForms/FormPDFElements/PDFRadio";
import { Box } from "components/PatientForms/FormPDFElements/Box";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { FormElementTitle } from "components/PatientForms/FormPDFElements/FormElementTitle";

type Props<V extends ValueTypes, T extends OptionInputOption<V>> = {
  label?: string;
  selectedValue?: V;
  options: T[];
  required?: boolean;
};
export const PDFRadioList = <V extends ValueTypes, T extends OptionInputOption<V>>({
  label,
  required,
  options,
  selectedValue,
}: Props<V, T>) => {
  return (
    <Box wrap={false}>
      <FormElementTitle required={required}>{label}</FormElementTitle>
      <View style={{ flexDirection: "column" }}>
        {options.map((option) => {
          return (
            <PDFRadio
              key={typeof option.value === "boolean" ? String(option.value) : option.value}
              checked={selectedValue === option.value}
            >
              <Text bold={selectedValue === option.value}>{option.label}</Text>
            </PDFRadio>
          );
        })}
      </View>
    </Box>
  );
};
