import React, { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { ForgotPasswordInputType, ForgotPasswordProps } from "components/ForgotPassword";
import { ForgotPasswordValidationResults } from "components/SignIn/validationUtil";
import { cxSignInStyles } from "components/SignIn/cxSignInStyles";

interface Props {
  handleForgotPassword: (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  handleInputChange: (inputName: ForgotPasswordInputType, value: string) => void;
  forgotPasswordState: ForgotPasswordProps;
  validateResult: ForgotPasswordValidationResults;
}

export const ForgotPasswordForm: React.FC<Props> = ({
  handleForgotPassword,
  handleInputChange,
  forgotPasswordState,
  validateResult,
}) => {
  const { t } = useTranslation();

  const emailError = React.useMemo(() => {
    if (validateResult.email.$error) {
      return validateResult.email.$error;
    }

    return forgotPasswordState.cognitoErrorCode ? t(`cognito.${forgotPasswordState.cognitoErrorCode}`) : "";
  }, [validateResult.email.$error, forgotPasswordState.cognitoErrorCode, t]);

  return (
    <Form id="forgot-password-form" className="space-y-6" onSubmit={(e) => handleForgotPassword(e)}>
      <div className="font-sans text-sm">
        Please enter the email address linked to your Archy account below. We&apos;ll send you a verification
        code through email to help you reset your password.
      </div>
      <FormFieldInput
        error={emailError}
        id="email"
        label="Email"
        autoComplete="username"
        name="email"
        onChange={(event) => handleInputChange("email", event.target.value)}
        placeholder="example@gmail.com"
        type="email"
        value={forgotPasswordState.email}
      />
      <AsyncButton
        className={cxSignInStyles.button}
        displayLoadingText
        isLoading={forgotPasswordState.isLoading}
        type="submit"
      >
        {forgotPasswordState.isLoading ? "Sending" : "Confirm Email"}
      </AsyncButton>
    </Form>
  );
};
