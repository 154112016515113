import React, { PropsWithChildren } from "react";
import { Link } from "react-router-dom";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { PracticeBranding } from "components/UI/PracticeBranding";
import { paths } from "router/paths";

export const cxHeaderStyles = {
  container: `
    w-full
    left-0
    top-0
    right-0
    h-[50px]
    flex-none
    bg-slate-50
    border-b
    border-b-slate-300
    flex
    items-center
    justify-between
    py-0
    px-4
    z-10
    fixed
  `,
};
export const Header: React.FC<PropsWithChildren<{ practice: PracticeInfoVO }>> = ({ children, practice }) => {
  return (
    <div className={cxHeaderStyles.container}>
      <Link className="flex items-center" to={paths.home()}>
        <div className="flex">
          <PracticeBranding size="sm" practice={practice} />
        </div>
      </Link>
      {children}
    </div>
  );
};
