import { CenteredResponsiveLayout } from "components/UI/CenteredResponsiveLayout";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";

type Props = {
  leftSideOnLargeScreens?: React.ReactNode;
  children: React.ReactNode;
  className?: string;
};

export const TwoColumnResponsiveLayout: React.FC<Props> = ({
  leftSideOnLargeScreens,
  children,
  className,
}) => {
  const { screenMatches } = useResponsiveBreakpoint();

  return (
    <div className="flex flex-row items-center h-full relative">
      {leftSideOnLargeScreens && <div className="h-full hidden flex-1 md:flex">{leftSideOnLargeScreens}</div>}
      <CenteredResponsiveLayout centerContents={screenMatches.has("md")} className={className}>
        {children}
      </CenteredResponsiveLayout>
    </div>
  );
};
