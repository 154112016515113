import React from "react";
import { formatSSNInput, getFormattedSSNHidden, parseSSN } from "@libs/utils/ssn";
import {
  FormFieldFormattedInput,
  FormFieldFormattedInputProps,
} from "@libs/components/UI/FormFieldFormattedInput";

export type FormFieldSsnProps = Omit<
  FormFieldFormattedInputProps,
  "cleanValue" | "formatValue" | "formattedCharacters" | "inputMode"
> & {
  ssnLastFour?: string;
};

const formattedCharacters = ["-"];

export const FormFieldSsnInput: React.FC<FormFieldSsnProps> = ({ ssnLastFour, ...props }) => {
  const handleFormatReadOnlyValue = () => (ssnLastFour ? getFormattedSSNHidden(ssnLastFour) : "-");

  return (
    <FormFieldFormattedInput
      formatReadOnlyValue={handleFormatReadOnlyValue}
      placeholder={getFormattedSSNHidden(ssnLastFour)}
      {...props}
      inputMode="numeric"
      formattedCharacters={formattedCharacters}
      cleanValue={parseSSN}
      formatValue={formatSSNInput}
    />
  );
};
