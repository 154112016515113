import { makeQuery } from "@libs/utils/queries";
import { cacheForSession, oneMinuteCache } from "@libs/utils/queryCache";
import { formatPatientAuthorization } from "api/forms/formatPatientAuthToken";

export type UUIDOrSlug = string;

export const getOnboardingInfoQuery = makeQuery({
  queryKey: ["practices", "getOnboardingInfo"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    query: {
      onboardingState:
        | "GENERAL_INFO"
        | "INSURANCE_INFO"
        | "DENTAL_HISTORY_INFO"
        | "MEDICAL_HISTORY_INFO"
        | "PAYMENT_INFO"
        | "CONSENT"
        | "COMPLETED";
    };
  }) => [args.practiceId, args.patientId, args.query],
  queryOptions: cacheForSession,
});

export const getPatientPublishedFormQuery = makeQuery({
  queryKey: ["practices", "getPatientPublishedForm"],
  // The patient id is for caching purposes only, so that we have a way to decache the form when the patient changes
  formatParams: (args: { practiceId: number; patientId: number; uuidOrSlug: UUIDOrSlug }) => [
    args.practiceId,
    args.patientId,
    args.uuidOrSlug,
  ],
  queryOptions: cacheForSession,
});

export const getLatestFormSubmissionQuery = makeQuery({
  queryKey: ["practices", "getLatestFormSubmission"],
  formatParams: (args: { practiceId: number; patientId: number; uuidOrSlug: UUIDOrSlug }) => [
    args.practiceId,
    args.patientId,
    args.uuidOrSlug,
  ],
  queryOptions: cacheForSession,
});

export const getFormPreviewQuery = makeQuery({
  queryKey: ["public", "getFormPreview"],
  formatParams: (args: { practiceUuid: string; uuid: string }) => [args.practiceUuid, args.uuid],
  queryOptions: cacheForSession,
});

export const getPatientFormTasks = makeQuery({
  queryKey: ["public", "getPatientFormTasks"],
  formatParams: (args: {
    practiceUuid: string;
    formTaskUuids: string[];
    dob: string;
    patientFormToken: string;
  }) => [
    args.practiceUuid,
    { formTaskUuids: args.formTaskUuids },
    { headers: { Authorization: formatPatientAuthorization(args.dob, args.patientFormToken) } },
  ],
  queryOptions: oneMinuteCache,
});

export const getPatientReferredByOptions = makeQuery({
  queryKey: ["public", "getPatientReferredByOptions"],
  formatParams: (args: { practiceUuid: string; dob: string; patientFormToken: string }) => [
    args.practiceUuid,
    { headers: { Authorization: formatPatientAuthorization(args.dob, args.patientFormToken) } },
  ],
  queryOptions: oneMinuteCache,
});

export const resolveKioskCode = makeQuery({
  queryKey: ["public", "resolveKioskCode"],
  formatParams: (args: { practiceUuid: string; kioskCode: string }) => [args.practiceUuid, args.kioskCode],
  queryOptions: oneMinuteCache,
});

export const getOnboardingOptions = makeQuery({
  queryKey: ["practices", "getOnboardingOptions"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: cacheForSession,
});
