import {
  autoUpdate,
  ComputePositionConfig,
  flip,
  Placement,
  shift,
  useFloating,
} from "@floating-ui/react-dom";
import { MutableRefObject, ReactNode, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { offset } from "@floating-ui/react-dom-interactions";
import { createPortal } from "react-dom";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ClickOutside } from "@libs/contexts/ClickOutsideListenerContext";
import { usePortalElement } from "@libs/contexts/PortalContext";
import { useKeyEventCallback } from "@libs/hooks/useKeyEventCallback";

type MenuThemes = "default";

export interface MenuState<T> {
  open: () => void;
  close: () => void;
  isOpen: boolean;
  toggle: () => void;
  triggerRef: MutableRefObject<T | null>;
}

const cxStyles = {
  theme: (theme?: MenuThemes) => cx(theme === "default" && "bg-white border shadow-main rounded py-1"),
};

export type InteractiveElement =
  | HTMLInputElement
  | HTMLButtonElement
  | HTMLTextAreaElement
  | HTMLSelectElement;

export const useMenu = <T extends InteractiveElement>(initialMenuState = false) => {
  const { on: open, off: close, isOn: isOpen, toggle } = useBoolean(initialMenuState);

  return {
    open,
    close,
    isOpen,
    toggle,
    triggerRef: useRef<T | null>(null),
  } as MenuState<T>;
};

export interface MenuProps extends Omit<Partial<ComputePositionConfig>, "platform"> {
  onRequestClose: (e?: MouseEvent) => void;
  closeOnOutsideClick?: boolean;
  className?: string;
  triggerRef: React.MutableRefObject<InteractiveElement | null>;
  placement?: Placement;
  floatingOffset?: number;
  portalTarget?: Element | null;
  matchTriggerWidth?: boolean;
  theme?: MenuThemes;
  children?: ReactNode;
  ["data-testid"]?: string;
}

const DEFAULT_MENU_OFFSET = 2;

export const Menu: React.FC<MenuProps> = ({
  onRequestClose,
  triggerRef,
  className,
  portalTarget,
  placement = "bottom",
  floatingOffset = DEFAULT_MENU_OFFSET,
  matchTriggerWidth = false,
  children,
  closeOnOutsideClick = true,
  theme,
  "data-testid": dataTestId,
  ...rest
}) => {
  const { x, y, reference, floating, update, strategy, refs } = useFloating({
    placement,
    // Padding allows for small amount of buffer when there is overflow offscreen, and menu is shifted
    middleware: [offset(floatingOffset), shift({ padding: 12 }), flip()],
    ...rest,
  });

  useLayoutEffect(
    () => {
      reference(triggerRef.current);
    },

    // Disabling rule as suggested in
    // https://floating-ui.com/docs/react-dom#external-reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerRef.current]
  );

  useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return;
    }

    // Only call this when the floating element is rendered
    autoUpdate(refs.reference.current, refs.floating.current, update);
  }, [refs.reference, refs.floating, update]);

  const handleKeyCloseEvent = useCallback(() => {
    onRequestClose();
  }, [onRequestClose]);

  useKeyEventCallback("keydown", "Escape", handleKeyCloseEvent);

  const defaultPortalTarget = usePortalElement();
  const target = portalTarget === undefined ? defaultPortalTarget : portalTarget;

  return (
    <ClickOutside handler={closeOnOutsideClick ? onRequestClose : undefined}>
      {(tracker) =>
        target &&
        createPortal(
          <div
            role="menu"
            className={cx("z-10 absolute", cxStyles.theme(theme), className)}
            ref={floating}
            {...(dataTestId ? { ["data-testid"]: dataTestId } : undefined)}
            style={{
              position: strategy,
              top: y ?? "",
              left: x ?? "",
              width: matchTriggerWidth ? triggerRef.current?.offsetWidth : "auto",
            }}
            {...tracker}
          >
            {children}
          </div>,
          target
        )
      }
    </ClickOutside>
  );
};
