import { View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { pdfStyles } from "components/PatientForms/FormPDFElements/styles";

export const Box: React.FC<{ children: React.ReactNode; wrap?: boolean; style?: Style }> = ({
  children,
  wrap,
  style,
}) => {
  return (
    <View style={style ? [pdfStyles.box, style] : pdfStyles.box} wrap={wrap}>
      {children}
    </View>
  );
};
