import { setTags as setSentryTags, setExtras as setSentryExtras } from "@sentry/react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { usePick } from "@libs/hooks/usePick";
import { FC, useEffect } from "react";

interface Props {
  practice: PracticeInfoVO;
}

export const PublicPracticeInstrumentation: FC<Props> = ({ practice }) => {
  const { id, uuid, timezoneId, name } = usePick(practice, ["id", "uuid", "timezoneId", "name"]);

  useEffect(() => {
    // These values will never change during a page load

    setSentryTags({
      ["practice.id"]: String(id),
      ["practice.timezoneId"]: timezoneId,
    });
    setSentryExtras({
      ["practice.uuid"]: uuid,
      ["practice.name"]: name,
    });
  }, [id, uuid, timezoneId, name]);

  return null;
};
