import { FC, PropsWithChildren, ReactNode } from "react";

interface Props {
  title: ReactNode;
}

export const PatientInfoFormSection: FC<PropsWithChildren<Props>> = ({ children, title }) => {
  return (
    <div>
      <h3 className="font-sansSemiBold text-sm mb-7">{title}</h3>
      <div className="grid grid-cols-1 gap-y-6">{children}</div>
    </div>
  );
};
