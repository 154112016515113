import { Path, Svg, View } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { colors } from "@libs/design.config";
import { half } from "@libs/utils/math";
import { PAGE_PADDING, pdfStyles } from "components/PatientForms/FormPDFElements/styles";

const PDFCheckboxIcon: React.FC<{
  width?: string;
  height?: string;
  checked?: boolean;
  style?: Style;
}> = ({ height = "12", width = "12", checked = false, style }) => {
  const shared = {
    fill: colors.greyDark,
  };

  return (
    <Svg width={width} height={height} viewBox="0 0 12 12" style={style}>
      {checked ? (
        <Path
          d="M2.35417 0.75C1.4734 0.75 0.75 1.4734 0.75 2.35417V9.64583C0.75 10.5266 1.4734 11.25 2.35417 11.25H9.64583C10.5266 11.25 11.25 10.5266 11.25 9.64583V2.35417C11.25 1.4734 10.5266 0.75 9.64583 0.75H2.35417ZM2.35417 1.625H9.64583C10.0536 1.625 10.375 1.94635 10.375 2.35417V9.64583C10.375 10.0536 10.0536 10.375 9.64583 10.375H2.35417C1.94635 10.375 1.625 10.0536 1.625 9.64583V2.35417C1.625 1.94635 1.94635 1.625 2.35417 1.625ZM9.05395 3.66268C8.94033 3.66599 8.83246 3.71339 8.75317 3.79484L4.97917 7.56885L3.53849 6.12817C3.49818 6.08619 3.44989 6.05266 3.39646 6.02957C3.34303 6.00648 3.28553 5.99428 3.22732 5.99369C3.16912 5.9931 3.11138 6.00412 3.05749 6.02613C3.0036 6.04813 2.95465 6.08066 2.91349 6.12182C2.87233 6.16298 2.83979 6.21194 2.81779 6.26583C2.79579 6.31972 2.78476 6.37745 2.78536 6.43566C2.78595 6.49386 2.79815 6.55136 2.82124 6.6048C2.84433 6.65823 2.87785 6.70651 2.91984 6.74683L4.66984 8.49683C4.75189 8.57884 4.86315 8.62492 4.97917 8.62492C5.09518 8.62492 5.20644 8.57884 5.28849 8.49683L9.37183 4.41349C9.43494 4.35202 9.47803 4.27293 9.49545 4.18657C9.51288 4.1002 9.50382 4.01059 9.46947 3.92946C9.43512 3.84832 9.37708 3.77945 9.30294 3.73185C9.2288 3.68425 9.14202 3.66014 9.05395 3.66268V3.66268Z"
          {...shared}
        />
      ) : (
        <Path
          d="M2.35417 0.75C1.4734 0.75 0.75 1.4734 0.75 2.35417V9.64583C0.75 10.5266 1.4734 11.25 2.35417 11.25H9.64583C10.5266 11.25 11.25 10.5266 11.25 9.64583V2.35417C11.25 1.4734 10.5266 0.75 9.64583 0.75H2.35417ZM2.35417 1.625H9.64583C10.0536 1.625 10.375 1.94635 10.375 2.35417V9.64583C10.375 10.0536 10.0536 10.375 9.64583 10.375H2.35417C1.94635 10.375 1.625 10.0536 1.625 9.64583V2.35417C1.625 1.94635 1.94635 1.625 2.35417 1.625Z"
          {...shared}
        />
      )}
    </Svg>
  );
};

type Props = {
  checked?: boolean;
  style?: Style;
  children: React.ReactNode;
};
export const PDFCheckbox: React.FC<Props> = ({ checked = false, children, style }) => {
  return (
    <View style={[pdfStyles.option, { marginVertical: 2 }, style ?? {}]}>
      <PDFCheckboxIcon style={{ marginBottom: -1 }} checked={checked} />

      {
        // The page padding for some-reason isn't applying to the content of checkboxes, this marginCorrects for that
      }
      <View style={{ paddingLeft: 4, marginRight: half(PAGE_PADDING) }}>{children}</View>
    </View>
  );
};
