import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ReactComponent as BlueCheck } from "@libs/assets/icons/check-circle-2.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { rescheduleAppointment } from "api/scheduling/mutations";
import { ResponsiveActionModal } from "components/UI/ResponsiveActionModal";

export const RescheduleAppointmentModal: React.FC<{ onClose: Func; goToAppointments: Func }> = ({
  onClose,
  goToAppointments,
}) => {
  const [{ mutateAsync: mutateRescheduleAppointment, isLoading }] = useApiMutations([rescheduleAppointment]);
  const { t } = useTranslation();
  const { appointmentId } = useParams();

  const { practiceId, id: patientId } = useAccount();
  const practice = useCurrentPractice();

  const onClickConfirm = React.useCallback(() => {
    if (appointmentId) {
      return mutateRescheduleAppointment({
        practiceId,
        patientId,
        appointmentId: Number.parseInt(appointmentId, 10),
      });
    }

    return Promise.resolve();
  }, [appointmentId, mutateRescheduleAppointment, patientId, practiceId]);

  return (
    <ResponsiveActionModal
      isLoading={isLoading}
      text={
        <Trans
          i18nKey="app.pages.labels.reschedule.confirm.message"
          values={{ practiceName: practice.name }}
          tOptions={{ interpolation: { escapeValue: true } }}
          shouldUnescape
        />
      }
      completedContent={t("Reschedule request successfully submitted")}
      tertiaryButtonLabel={t("Back to Upcoming Appointments")}
      onClickPrimaryButton={onClickConfirm}
      onClose={onClose}
      secondaryButtonLabel={t("No")}
      primaryButtonLabel={t("Yes")}
      onClickSecondaryButton={onClose}
      onClickTertiaryButton={goToAppointments}
      icon={<BlueCheck />}
    />
  );
};
