import { FC, useEffect } from "react";
import { Hub } from "aws-amplify";
import { HubCallback } from "@aws-amplify/core";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useNavigate } from "react-router-dom";
import { CustomSignInState } from "@libs/auth/customState";
import { postAuthChannelMessage } from "hooks/useAuthChannelListeners";
import { paths } from "router/paths";

export const OauthSignInRoute: FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const onMessage: HubCallback = (listener) => {
      const payload = listener.payload;

      if (payload.event === "customOAuthState") {
        const params = JSON.parse(listener.payload.data as string) as CustomSignInState;

        postAuthChannelMessage({ type: "signIn" });
        navigate(paths.selectAccount(params));
      }
    };

    return Hub.listen("auth", onMessage);
  }, [navigate]);

  return <LoadingOverlaySpinner centerVertically loadingText="Signing In" />;
};
