export const isNullish = <T>(val: T | null | undefined): val is null | undefined => {
  // By not doing a strict equality check, it will check only for null and undefined
  return val == null;
};

export const isDefined = <T>(val: T | null | undefined): val is T => {
  // By not doing a strict equality check, it will check only for null and undefined
  return val != null;
};

export const isString = (value: unknown): value is string => {
  return typeof value === "string";
};

export const isNumber = (value: unknown): value is number => {
  return typeof value === "number";
};

export const isObject = (value: unknown): value is Record<string | number, unknown> => {
  return typeof value === "object" && value !== null;
};

export const isError = (value: unknown): value is Error => {
  return value instanceof Error;
};
