import React from "react";
import { useTranslation } from "react-i18next";
import { FormProcedureTableElementVO } from "@libs/api/generated-api";
import { semibold16 } from "assets/styles/textSize";
import { useResponsiveBreakpoint } from "hooks/useResponsiveBreakpoint";
import { MobileProcedureTableElement } from "components/PatientForms/FormElements/PatientFormProcedureTableElement/MobileProcedureTableElement";
import { TabletProcedureTableElement } from "components/PatientForms/FormElements/PatientFormProcedureTableElement/TabletProcedureTableElement";

type Props = {
  element: FormProcedureTableElementVO;
};

// Read only element
export const PatientFormProcedureTableElement: React.FC<Props> = ({ element }) => {
  const { treatmentPlanName } = element;
  const { t } = useTranslation();
  const { screenMatches } = useResponsiveBreakpoint();
  const extraSmallScreen = !screenMatches.has("sm");

  return (
    <div className="flex flex-col gap-4">
      <div className={semibold16}>
        {treatmentPlanName
          ? t("patientForms.treatmentPlan.treatmentEstimate", { treatmentPlanName })
          : t("patientForms.treatmentPlan.treatmentEstimateNoName")}
      </div>
      {extraSmallScreen ? (
        <MobileProcedureTableElement element={element} />
      ) : (
        <TabletProcedureTableElement element={element} />
      )}
    </div>
  );
};
