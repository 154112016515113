import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon, IconThemes } from "@libs/components/UI/Icon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as SuccessIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";

const Icons = {
  success: SuccessIcon,
  warning: WarningIcon,
  error: WarningIcon,
  info: WarningIcon,
} as const;

type Theme = keyof typeof Icons;

const cxThemesClassName: Record<Theme, { container: string; icon: IconThemes }> = {
  success: {
    container: "bg-greenLight",
    icon: "success",
  },
  warning: {
    container: "bg-orangeLight",
    icon: "warning",
  },
  error: {
    container: "bg-redLight",
    icon: "error",
  },
  info: {
    container: "bg-whiteLight",
    icon: "slate700",
  },
};

const cxIconAlignment = {
  center: "items-center",
  start: "items-start",
};

interface Props {
  theme: Theme;
  className?: string;
  paddingClassName?: string;
  includeIcon?: boolean;
  children?: ReactNode;
  onRequestClose?: Func;
  contentAlignment?: keyof typeof cxIconAlignment;
}

export const Banner: FC<Props> = ({
  theme,
  children,
  className,
  contentAlignment = "center",
  paddingClassName = "px-3 py-2",
  includeIcon = true,
  onRequestClose,
}) => {
  return (
    <div
      role="banner"
      className={cx(
        "flex justify-between gap-x-3",
        cxIconAlignment[contentAlignment],
        cxThemesClassName[theme].container,
        paddingClassName,
        className
      )}
    >
      <div className={cx("flex gap-x-3", cxIconAlignment[contentAlignment])}>
        {includeIcon ? (
          <Icon SvgIcon={Icons[theme]} theme={cxThemesClassName[theme].icon} className="flex-none" />
        ) : null}
        {children}
      </div>

      {onRequestClose ? (
        <ButtonIcon SvgIcon={CloseIcon} onClick={onRequestClose} theme={cxThemesClassName[theme].icon} />
      ) : null}
    </div>
  );
};
