import { getFullUrl } from "@libs/utils/location";
import { LoaderFunctionArgs, redirect } from "react-router-dom";
import { getTokensForAuthCheck } from "@libs/auth/getTokens";
import { PatientRouterContext } from "router/types";
import { paths } from "router/paths";

export const ensureSignedOut =
  ({ storage }: PatientRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);

    if (url.searchParams.get("allowSignedIn")) {
      return null;
    }

    const { identity } = await getTokensForAuthCheck(storage.localStorage);

    if (identity?.token) {
      return redirect(paths.signOut({ to: getFullUrl(new URL(request.url)) }));
    }

    return null;
  };

export const redirectAuthUser =
  ({ storage }: PatientRouterContext) =>
  async () => {
    const { identity } = await getTokensForAuthCheck(storage.localStorage);

    if (identity?.token) {
      return redirect(paths.selectAccount());
    }

    return null;
  };
