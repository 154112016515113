import { ReactNode } from "react";
import { UpdateMessageSubscriptionRequest } from "@libs/api/generated-api";
import { Icon } from "@libs/components/UI/Icon";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { ReactComponent as DisabledIcon } from "@libs/assets/icons/cancel-circle.svg";
import { ReactComponent as EnabledIcon } from "@libs/assets/icons/check-circle-1.svg";

const options: { label: string; value: keyof UpdateMessageSubscriptionRequest }[] = [
  { label: "Appointment Related Emails", value: "unsubscribeTransactional" },
  {
    label: "Marketing Emails",
    value: "unsubscribeMarketing",
  },
];

const getUnsubscribeKeys = (state: UpdateMessageSubscriptionRequest) =>
  Object.keys(state) as (keyof UpdateMessageSubscriptionRequest)[];

const unsubscribeSetToRequest = (
  newSet: Set<keyof UpdateMessageSubscriptionRequest>,
  current: UpdateMessageSubscriptionRequest
) => {
  const value = getUnsubscribeKeys(current).reduce((next, key) => {
    next[key] = !newSet.has(key);

    return next;
  }, {} as Partial<UpdateMessageSubscriptionRequest>);

  return value as UpdateMessageSubscriptionRequest;
};

const EmailPreference: React.FC<{ enabled: boolean; label: ReactNode }> = ({ enabled, label }) => (
  <div className="flex items-center gap-x-3">
    <Icon theme={enabled ? "success" : "error"} SvgIcon={enabled ? EnabledIcon : DisabledIcon} />
    <span>{label}</span>
  </div>
);

export const EmailPreferencesFields: React.FC<{
  onUpdate: (formState: UpdateMessageSubscriptionRequest) => void;
  preferences: UpdateMessageSubscriptionRequest;
  edit?: boolean;
}> = ({ onUpdate, preferences, edit = true }) => {
  return edit ? (
    <>
      <CheckboxList
        label="Categories"
        layout="vert"
        description="Select which emails you would like to receive"
        disabled={preferences.unsubscribeAll}
        options={options}
        selectedValues={
          new Set(
            preferences.unsubscribeAll
              ? []
              : getUnsubscribeKeys(preferences).filter((key) => !preferences[key])
          )
        }
        onChange={(newSet) =>
          onUpdate({
            ...unsubscribeSetToRequest(newSet, preferences),
            unsubscribeAll: preferences.unsubscribeAll,
          })
        }
      />
      <div className="h-px w-full border-t border-slate-200" />
      <CheckboxList
        label="Unsubscribe from all"
        layout="vert"
        description="Select this box to unsubscribe from all email communications"
        options={[{ label: "Unsubscribe from all emails", value: "unsubscribeAll" }]}
        selectedValues={new Set(preferences.unsubscribeAll ? ["unsubscribeAll"] : [])}
        onChange={(newSet) => {
          onUpdate({
            ...preferences,
            unsubscribeAll: newSet.has("unsubscribeAll"),
          });
        }}
      />
    </>
  ) : preferences.unsubscribeAll ? (
    <EmailPreference enabled={false} label="Unsubscribed from all emails" />
  ) : (
    <>
      {options.map((option) => (
        <EmailPreference key={option.value} enabled={!preferences[option.value]} label={option.label} />
      ))}
    </>
  );
};
