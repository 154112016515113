import React, { ReactNode, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FormPatientProcedureVO, FormProcedureTableElementVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { getProcedureDescriptions } from "components/PatientForms/utils";
import { usePatientProcedureTotals } from "components/PatientForms/hooks/usePatientProcedureTotals";
import {
  EMPTY_CELL,
  HeaderCell,
  Row,
  TableGrid,
  TextCell,
  cxGridTableStyles,
} from "components/UI/GridTableComponents";
import { normal10 } from "assets/styles/textSize";
import { useGroupedProcedures } from "components/PatientForms/FormElements/PatientFormProcedureTableElement/useGroupedProcedures";

const ProcedureRow: React.FC<{
  procedure: FormPatientProcedureVO;
  includeUcrFee: boolean;
  showSecondary: boolean;
}> = ({ procedure, includeUcrFee, showSecondary }) => {
  const {
    priority,
    procedureArea,
    ucrRate,
    negotiatedRate,
    insuranceAmount,
    primaryInsuranceAmount,
    secondaryInsuranceAmount,
    patientAmount,
    cdtCode,
  } = procedure;
  const { t } = useTranslation();
  const sharedProps = {
    verticalPadding: "slim" as const,
  };

  return (
    <Row>
      <TextCell {...sharedProps}>{priority ?? EMPTY_CELL}</TextCell>
      <TextCell {...sharedProps}>#{cdtCode}</TextCell>
      <TextCell {...sharedProps}>{procedureArea ?? EMPTY_CELL}</TextCell>
      <TextCell {...sharedProps}>
        {getProcedureDescriptions(procedure, t).map((description, i) => (
          <div key={i}>{description}</div>
        ))}
      </TextCell>
      {includeUcrFee && (
        <TextCell {...sharedProps} className="text-right">
          {formatCurrency(ucrRate)}
        </TextCell>
      )}
      <TextCell {...sharedProps} className="text-right">
        {formatCurrency(negotiatedRate)}
      </TextCell>
      {showSecondary ? (
        <>
          <TextCell {...sharedProps} className="text-right">
            {formatCurrency(primaryInsuranceAmount ?? 0)}
          </TextCell>
          <TextCell {...sharedProps} className="text-right">
            {formatCurrency(secondaryInsuranceAmount ?? 0)}
          </TextCell>
        </>
      ) : (
        <TextCell {...sharedProps} className="text-right">
          {formatCurrency(insuranceAmount)}
        </TextCell>
      )}
      <TextCell {...sharedProps} className="text-right">
        {formatCurrency(patientAmount)}
      </TextCell>
    </Row>
  );
};

export const FooterCell: React.FC<{
  className?: string;
  children?: ReactNode;
  isSubtotal?: boolean;
}> = ({ children, className, isSubtotal }) => {
  return (
    <div
      role="cell"
      className={cx(
        "truncate font-sansSemiBold text-xs border-greyLighter",
        isSubtotal && "border-b-2",
        cxGridTableStyles.headerFooterCell({ size: "short" }),
        cxGridTableStyles.cellPadding(),
        className
      )}
    >
      {children}
    </div>
  );
};

const TreatmentPlanFooterCell: React.FC<{ value: number; label?: string; isSubtotal?: boolean }> = ({
  value,
  label,
  isSubtotal = false,
}) => {
  return (
    <FooterCell isSubtotal={isSubtotal}>
      <div className="w-full text-right">
        {!isSubtotal && <div className={normal10}>{label}</div>}
        <div>{formatCurrency(value)}</div>
      </div>
    </FooterCell>
  );
};

const ProcedureTotals: React.FC<{
  includeUcrFee: boolean;
  showSecondary: boolean;
  ucrRate: number;
  negotiatedRate: number;
  primaryInsuranceAmount: number;
  secondaryInsuranceAmount: number;
  insuranceAmount: number;
  patientAmount: number;
  isSubtotal?: boolean;
}> = ({
  includeUcrFee,
  showSecondary,
  ucrRate,
  negotiatedRate,
  primaryInsuranceAmount,
  secondaryInsuranceAmount,
  insuranceAmount,
  patientAmount,
  isSubtotal = false,
}) => {
  const sharedProps = {
    borderTop: false,
    borderBottom: isSubtotal,
    isSubtotal,
    size: "short" as const,
  };
  const { t } = useTranslation();

  return (
    <Row className={cx(isSubtotal ? "border-b border-b-slate-300" : "bg-slate-400")}>
      <FooterCell {...sharedProps} className="col-span-4" />
      {includeUcrFee && <TreatmentPlanFooterCell {...sharedProps} label={t("UCR Fee")} value={ucrRate} />}
      <TreatmentPlanFooterCell {...sharedProps} label={t("Neg Rate")} value={negotiatedRate} />
      {showSecondary ? (
        <>
          <TreatmentPlanFooterCell
            {...sharedProps}
            label={t("Prim Ins Est")}
            value={primaryInsuranceAmount}
          />
          <TreatmentPlanFooterCell
            {...sharedProps}
            label={t("Sec Ins Est")}
            value={secondaryInsuranceAmount}
          />
        </>
      ) : (
        <TreatmentPlanFooterCell {...sharedProps} label={t("Ins Est")} value={insuranceAmount} />
      )}

      <TreatmentPlanFooterCell {...sharedProps} label={t("Pt Est")} value={patientAmount} />
    </Row>
  );
};

type Props = {
  element: FormProcedureTableElementVO;
};

const useColumns = (includeUcrFee: boolean, showSecondary: boolean) => {
  const { t } = useTranslation();

  return useMemo(() => {
    return [
      { label: t("Priority"), width: "auto" },
      { label: t("Code"), width: "auto" },
      { label: t("Tth/Surf"), width: "auto" },
      { label: t("Description"), width: "1fr" },
      ...(includeUcrFee
        ? [{ label: t("UCR Fee"), width: "auto", id: "ucrRate", className: "text-right" }]
        : []),
      { label: t("Neg Rate"), width: "auto", className: "text-right" },
      ...(showSecondary
        ? [
            { label: t("Prim Ins Est"), width: "auto", className: "text-right" },
            { label: t("Sec Ins Est"), width: "auto", className: "text-right" },
          ]
        : [{ label: t("Ins Est"), width: "auto", className: "text-right" }]),
      { label: t("Pt Est"), width: "auto", className: "text-right" },
    ];
  }, [includeUcrFee, showSecondary, t]);
};

const ProceduresGroup: React.FC<{
  procedures: FormPatientProcedureVO[];
  includeUcrFee: boolean;
  hasSecondary: boolean;
  includeSubtotal: boolean;
}> = ({ procedures, includeUcrFee, hasSecondary, includeSubtotal }) => {
  const totals = usePatientProcedureTotals(procedures);

  return (
    <>
      {procedures.map((procedure) => {
        return (
          <ProcedureRow
            key={procedure.id}
            procedure={procedure}
            includeUcrFee={includeUcrFee}
            showSecondary={hasSecondary}
          />
        );
      })}
      {includeSubtotal && (
        <ProcedureTotals {...totals} isSubtotal includeUcrFee={includeUcrFee} showSecondary={hasSecondary} />
      )}
    </>
  );
};

// Read only element
export const TabletProcedureTableElement: React.FC<Props> = ({ element }) => {
  const { procedures, settings } = element;
  const includeUcrFee = settings.includes("DISPLAY_UCR_RATE");
  const hasSecondary = procedures.some((procedure) => procedure.secondaryInsuranceAmount);
  const columns = useColumns(includeUcrFee, hasSecondary);
  const groupedProcedures = useGroupedProcedures(procedures);
  const totals = usePatientProcedureTotals(procedures);

  return (
    <TableGrid
      columnWidths={columns.map(({ width }) => width)}
      className="border border-greyLighter rounded-md"
    >
      <Row>
        {columns.map(({ label, className }) => {
          return (
            <HeaderCell size="short" key={label} className="rounded-t-md">
              <div className={cx("w-full", className)}>{label}</div>
            </HeaderCell>
          );
        })}
      </Row>
      {groupedProcedures.map((group, i) => {
        return (
          <ProceduresGroup
            key={i}
            procedures={group}
            includeUcrFee={includeUcrFee}
            hasSecondary={hasSecondary}
            includeSubtotal={groupedProcedures.length > 1}
          />
        );
      })}

      <ProcedureTotals {...totals} includeUcrFee={includeUcrFee} showSecondary={hasSecondary} />
    </TableGrid>
  );
};
