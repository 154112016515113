import { PropsWithChildren } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatPhoneNumber } from "@libs/utils/phone";
import { selfBookingCxStyles } from "components/SelfBooking/utils";
import { semibold14 } from "assets/styles/textSize";

export const AnonPatientHeader: React.FC<
  PropsWithChildren<{ practiceInfo: PracticeInfoVO; patientName?: string }>
> = ({ practiceInfo, children, patientName }) => {
  const { logo, addressDetails, phoneNumber } = practiceInfo;

  return (
    <div className="bg-whiteLight w-full flex-none">
      <div className={cx("flex flex-col justify-center p-6", selfBookingCxStyles.container)}>
        {logo?.url && (
          <img
            src={logo.url}
            alt="Practice Logo"
            className={`
              object-contain
              h-[80px]
              w-[80px]
              md:h-[100px]
              md:w-[100px]
              mb-3
            `}
          />
        )}
        <div>
          {children ?? (
            <div className="flex justify-between">
              <div className="flex flex-col">
                <div className="font-sansSemiBold text-sm">{practiceInfo.name}</div>
                {addressDetails && (
                  <>
                    <div>{addressDetails.addressLabel1}</div>
                    <div>
                      {addressDetails.addressLabel2}
                      {phoneNumber && (
                        <a href={`tel:${phoneNumber}`} className="ml-1 whitespace-nowrap">
                          • {formatPhoneNumber(phoneNumber)}
                        </a>
                      )}
                    </div>
                  </>
                )}
              </div>
              {patientName && <div className={semibold14}>{patientName}</div>}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
