import Skeleton from "react-loading-skeleton";
import { useEffect, useRef } from "react";
import { cx } from "@libs/utils/cx";

type Props = {
  loadingFinix: boolean;
  handleFinixFormRef: (element: HTMLDivElement | null) => void;
  elementId: string;
};

// Used in tandem with `useFinixForm` to get its props
export const FinixForm: React.FC<Props> = ({ loadingFinix, handleFinixFormRef, elementId }) => {
  const willUnmount = useRef(false);

  useEffect(() => {
    return () => {
      // We need to notify that the form is being unmounted so the next time it's mounted, it can be re-initialized
      // the first cleanup function is called on the first render, so we need to ignore it
      if (willUnmount.current) {
        handleFinixFormRef(null);
      } else {
        willUnmount.current = true;
      }
    };
  }, [handleFinixFormRef]);

  return (
    <>
      {loadingFinix && <Skeleton containerClassName="mt-6 flex flex-col gap-1" count={3} className="h-10" />}
      <div
        id={elementId}
        ref={handleFinixFormRef}
        className={cx("flex flex-col w-full text-sm", loadingFinix && "hidden")}
      />
    </>
  );
};
