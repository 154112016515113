import { FC } from "react";
import { cx } from "@libs/utils/cx";

export const Backdrop: FC<{ className?: string; onClick?: Func }> = ({ className, onClick }) => {
  return (
    <div
      role="presentation"
      onClick={onClick}
      className={cx("bg-black/50 absolute h-full w-full top-0 left-0", className)}
    />
  );
};
