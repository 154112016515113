import { FC, SelectHTMLAttributes, useMemo } from "react";
import { add, startOfYear } from "date-fns";
import { ReactDatePickerCustomHeaderProps } from "react-datepicker";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as LeftCaret } from "@libs/assets/icons/left-caret.svg";
import { ReactComponent as RightCaret } from "@libs/assets/icons/right-caret.svg";
import { cx } from "@libs/utils/cx";

const MONTHS_PER_YEAR = 12;

const Select: FC<SelectHTMLAttributes<HTMLSelectElement>> = (props) => (
  <select
    {...props}
    className={cx(
      `py-3
       px-5
       flex
       justify-center
       items-center
       text-primaryTheme
       font-sansSemiBold
       border
       border-greyLighter
       rounded
       appearance-none
       text-sm/4
       outline-none
       cursor-pointer
       bg-white`,
      props.className
    )}
  />
);

export const DatePickerSelectMenuHeader: React.FC<{
  params: ReactDatePickerCustomHeaderProps;
  minDate: Date;
  maxDate: Date;
}> = ({ params, minDate, maxDate }) => {
  const monthOptions = useMemo(() => {
    const options = [];
    const startDate = startOfYear(new Date());

    for (let i = 0; i < MONTHS_PER_YEAR; i++) {
      const monthDate = add(startDate, { months: i });
      const optionText = monthDate.toLocaleString(navigator.language, { month: "short" });

      options.push(
        <option key={i} value={i}>
          {optionText}
        </option>
      );
    }

    return options;
  }, []);

  const yearOptions = useMemo(() => {
    const options = [];
    const maxYear = maxDate.getFullYear();

    for (let i = minDate.getFullYear(); i <= maxYear; ++i) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return options;
  }, [minDate, maxDate]);

  return (
    <div className="flex flex-row w-full justify-between mb-6 -mt-2">
      <ButtonIcon
        SvgIcon={LeftCaret}
        theme="primary"
        disabled={params.prevMonthButtonDisabled}
        onClick={() => params.decreaseMonth()}
        tooltip={{ content: "Previous Month", theme: "SMALL" }}
      />
      <Select value={params.date.getMonth()} onChange={(e) => params.changeMonth(Number(e.target.value))}>
        {monthOptions}
      </Select>
      <Select value={params.date.getFullYear()} onChange={(e) => params.changeYear(Number(e.target.value))}>
        {yearOptions}
      </Select>
      <ButtonIcon
        SvgIcon={RightCaret}
        theme="primary"
        disabled={params.nextMonthButtonDisabled}
        onClick={() => params.increaseMonth()}
        tooltip={{ content: "Next Month", theme: "SMALL" }}
      />
    </div>
  );
};
