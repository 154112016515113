import { AddressVO } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";

export const formatAddress = (address?: AddressVO) => {
  if (!address) {
    return {
      lineOne: "",
      lineTwo: "",
    };
  }

  const address2 = address.address2 ? `, ${address.address2}` : "";

  return {
    lineOne: `${address.address1}${address2}`,
    lineTwo: `${address.city}, ${address.state} ${address.zip}`,
  };
};

export const addressSchema = {
  address1: [{ $v: required, $error: "Address is required" }],
  city: [{ $v: required, $error: "City is required" }],
  state: [{ $v: required, $error: "State is required" }],
  zip: [{ $v: required, $error: "Zip code is required" }],
};

export type AddressInputValidation = ValidationResult<AddressVO, typeof addressSchema>;

export const getEmptyAddress = () => {
  return {
    address1: "",
    address2: "",
    city: "",
    state: "",
    zip: "",
    country: "",
  };
};
