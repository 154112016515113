import React from "react";
import { useTranslation } from "react-i18next";
import { FormConsentElementVO, FormPageVO } from "@libs/api/generated-api";
import { toMap } from "@libs/utils/array";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import {
  FormInputElement,
  isEligibleForValidation,
  isInputElement,
  isInputResponsePresent,
} from "components/PatientForms/utils";
import { PatientResponses, ResponseValue } from "components/PatientForms/hooks/usePatientResponses";

type UsePatientFormValidationParams = {
  responsesById: PatientResponses;
  pages: FormPageVO[];
  edit: boolean;
};
export type Invalidation = {
  error?: string;
  element?: FormConsentElementVO | FormInputElement;
  options?: string[];
};
export type PatientFormValidation = {
  [uuid: string]: Invalidation | undefined;
};

const validateInput = (uuid: string, element: FormInputElement, response?: ResponseValue) => {
  const validationMap: PatientFormValidation = {};

  if (element.type === "SELECT_INPUT" && element.settings.includes("ENFORCE_EXPLICIT_CONSENT")) {
    const answerOptions = element.options;
    const responses: Record<string, boolean | undefined> =
      response?.type === "SELECT" ? response.responses : {};
    const unAnsweredOptions = answerOptions.filter((value) => responses[value] === undefined);

    if (unAnsweredOptions.length > 0) {
      validationMap[uuid] = {
        element,
        error: "Please answer each item",
        options: unAnsweredOptions,
      };
    }
  } else if (
    isInputResponsePresent(response) &&
    element.type === "NUMBER_INPUT" &&
    response.type === "NUMBER" &&
    isDefined(response.response)
  ) {
    if (isDefined(element.max) && response.response > element.max) {
      validationMap[uuid] = {
        element,
        error: `${element.max} is the maximum allowed value`,
      };
    } else if (isDefined(element.min) && response.response < element.min) {
      validationMap[uuid] = {
        element,
        error: `${element.min} is the minimum allowed value`,
      };
    }
  } else if (!isInputResponsePresent(response) && element.settings.includes("REQUIRED")) {
    validationMap[uuid] = {
      element,
      error: "Response is required",
    };
  }

  return validationMap;
};

export const usePatientFormValidation = (params: UsePatientFormValidationParams) => {
  const { responsesById, pages } = params;
  const validating = useBoolean(false);
  const { t } = useTranslation();
  const isValidating = validating.isOn;
  const setValidating = validating.set;
  const [validations, setValidations] = React.useState<PatientFormValidation>({});
  const formSchema = React.useMemo(() => {
    const sectionsAndElements = pages.flatMap((page) => page.content);
    const needsValidation = sectionsAndElements.filter(isEligibleForValidation);

    for (const item of sectionsAndElements) {
      if (item.type === "SECTION") {
        const inputs = item.content.filter(isEligibleForValidation);

        for (const input of inputs) {
          needsValidation.push(input);
        }
      } else if (isEligibleForValidation(item)) {
        needsValidation.push(item);
      }
    }

    return toMap(needsValidation, "uuid");
  }, [pages]);

  const validate = React.useCallback(() => {
    setValidating(true);

    let validationMap: PatientFormValidation = {};

    for (const [uuid, element] of Object.entries(formSchema)) {
      const response = responsesById[uuid];

      if (element && isInputElement(element)) {
        validationMap = {
          ...validationMap,
          ...validateInput(uuid, element, response),
        };

        const conditionalElement = element.type === "BOOLEAN_INPUT" ? element.conditionalElement : undefined;

        if (conditionalElement && response?.type === "BOOLEAN" && response.response) {
          validationMap = {
            ...validationMap,
            ...validateInput(uuid, conditionalElement, responsesById[conditionalElement.uuid]),
          };
        }
      } else if (
        (element?.type === "CONSENT" &&
          response?.type === "CONSENT" &&
          (!response.signature || !response.consentedAt)) ||
        (element?.type === "CONSENT" && response?.type !== "CONSENT")
      ) {
        validationMap[uuid] = {
          element,
          error: t("onboard.validation.signature"),
        };
      }
    }
    setValidations(validationMap);

    return {
      isValid: Object.keys(validationMap).length === 0,
    };
  }, [formSchema, responsesById, t, setValidating]);

  React.useEffect(() => {
    if (isValidating) {
      validate();
    }
  }, [isValidating, validate]);

  const reset = React.useCallback(() => {
    setValidating(false);
    setValidations({});
  }, [setValidating]);

  return {
    validate,
    isValid: Object.keys(validations).length === 0,
    result: validations,
    reset,
  };
};
