import React from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";

import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";
import { getPracticeInfoByUuid } from "api/user/queries";
import { getPatientFormTasks } from "api/forms/queries";

import { LoadedPatientFormTasks } from "components/PatientForms/PatientFormTasks/LoadedPatientFormTasks";
import { hasExceededAttempts, hasPatientTokenExpired } from "utils/getIsKioskCodeOrDOBError";
import { FormTaskLinkExpired } from "components/PatientForms/PatientFormTasks/FormTaskLinkExpired";
import { FormTasksCompleted } from "components/PatientForms/PatientFormTasks/FormTasksCompleted";
import { PatientFormTasksDobPage } from "components/PatientForms/PatientFormTasks/PatientFormTasksDobPage";
import { FormMaxAttemptsExceeded } from "components/PatientForms/PatientFormTasks/FormMaxAttemptsExceeded";

export const TakePatientFormTasksRoute = () => {
  const { practiceUuid, patientFormToken } = usePathParams("takeFormTasks");
  const {
    query: { formTaskUuids },
  } = useQueryParams("takeFormTasks");

  const [dob, setDob] = React.useState<string | undefined>();

  const [practiceQuery, tasksQuery] = useApiQueries([
    getPracticeInfoByUuid({
      args: { practiceUuid },
    }),
    getPatientFormTasks({
      args: {
        practiceUuid,
        formTaskUuids: formTaskUuids ?? [],
        patientFormToken,
        dob: dob ?? "",
      },
      queryOptions: {
        enabled: false,
      },
    }),
  ]);

  const fetchFormTasks = tasksQuery.refetch;

  return (
    <QueryResult queries={[practiceQuery]}>
      {practiceQuery.data ? (
        <>
          <PublicPracticeInstrumentation practice={practiceQuery.data} />
          {hasPatientTokenExpired(tasksQuery.error) ? (
            <FormTaskLinkExpired practice={practiceQuery.data} />
          ) : hasExceededAttempts(tasksQuery.error) ? (
            <FormMaxAttemptsExceeded practice={practiceQuery.data} />
          ) : tasksQuery.data ? (
            <LoadedPatientFormTasks
              formTasksResponse={tasksQuery.data}
              practice={practiceQuery.data}
              patientFormToken={patientFormToken}
              reloadAfterIdleMinutes={6}
              completedScreen={<FormTasksCompleted practice={practiceQuery.data} />}
            />
          ) : (
            <PatientFormTasksDobPage
              isLoading={tasksQuery.isFetching}
              onDateChange={setDob}
              onSubmit={fetchFormTasks}
              tasksQueryError={tasksQuery.error}
              practice={practiceQuery.data}
            />
          )}
        </>
      ) : null}
    </QueryResult>
  );
};
