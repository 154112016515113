import { produce } from "immer";
import { UseTranslationResponse } from "react-i18next";
import {
  FormBooleanConditionalElementVO,
  FormBooleanInputElementVO,
  FormConsentElementVO,
  FormDateInputElementVO,
  FormElementVO,
  FormNumberInputElementVO,
  FormPageVO,
  FormPatientInfoElementVO,
  FormPatientProcedureVO,
  FormSectionElementVO,
  FormSectionVO,
  FormSelectInputElementVO,
  FormTextInputElementVO,
  FormVO,
} from "@libs/api/generated-api";
import { isDefined, isNullish } from "@libs/utils/types";
import { isOneOf } from "@libs/utils/isOneOf";
import { formatCurrency } from "@libs/utils/currency";
import { ResponseValue } from "components/PatientForms/hooks/usePatientResponses";
import { ExistingPatientInfo } from "components/PatientForms/FormElements/types";
import { getAge } from "components/PatientForms/hooks/useAge";

export type FormElement = ListItem<FormPageVO["content"]>;
export type FormInputElement =
  | FormBooleanInputElementVO
  | FormDateInputElementVO
  | FormNumberInputElementVO
  | FormSelectInputElementVO
  | FormTextInputElementVO;

type ElementMatch = {
  type: "ELEMENT";
  element: FormElement;
  pageIndex: number;
  elementIndex: number;
};

type ConditionalElementMatch = {
  type: "CONDITIONAL_ELEMENT";
  parent: FormElement;
  element: FormBooleanConditionalElementVO;
  pageIndex: number;
  elementIndex: number;
};

type SectionElementMatch = {
  type: "SECTION_ELEMENT";
  section: FormSectionVO;
  element: FormSectionElementVO;
  pageIndex: number;
  elementIndex: number;
  sectionElementIndex: number;
};

type ConditionalSectionElementMatch = {
  type: "CONDITIONAL_SECTION_ELEMENT";
  section: FormSectionVO;
  parent: FormSectionElementVO;
  element: FormBooleanConditionalElementVO;
  pageIndex: number;
  elementIndex: number;
  sectionElementIndex: number;
};

export const isInputElement = (element: FormElement): element is FormInputElement => {
  return isOneOf(element.type, ["BOOLEAN_INPUT", "DATE_INPUT", "NUMBER_INPUT", "SELECT_INPUT", "TEXT_INPUT"]);
};

export const isPatientInfoElement = (element: FormElementVO): element is FormPatientInfoElementVO => {
  return element.tag === "PATIENT_INFO";
};

export const isEligibleForValidation = (
  value: FormElement
): value is FormInputElement | FormConsentElementVO => {
  return value.type === "CONSENT" || isInputElement(value);
};

export const isInputResponsePresent = (response?: ResponseValue): response is ResponseValue => {
  if (!response) {
    return false;
  }

  switch (response.type) {
    case "SELECT": {
      return Object.keys(response.responses).length > 0 || isDefined(response.other);
    }
    case "DATE":
    case "STRING": {
      return isDefined(response.response) ? response.response.trim() !== "" : false;
    }
    case "BOOLEAN":
    case "NUMBER": {
      return isDefined(response.response);
    }
    // No default
  }

  return false;
};

export const OTHER_ID = "other";

export const canPatientViewSection = (
  personalDetails: Pick<ExistingPatientInfo, "dob" | "gender">,
  section: FormSectionVO,
  timezoneId: string
) => {
  const { conditions } = section;

  if (!conditions) {
    return true;
  }

  const age = getAge(personalDetails.dob, timezoneId);
  const gender = personalDetails.gender;

  const { maxAgeYears, minAgeYears, genders } = conditions;

  const max = maxAgeYears ?? Number.POSITIVE_INFINITY;
  const min = minAgeYears ?? 0;

  const satisfiesAge = isNullish(age) || (min <= age && max > age);
  const safisfiesGender = isNullish(gender) || !genders.length || genders.includes(gender);

  return safisfiesGender && satisfiesAge;
};

export const filterForm = (form: FormVO, filterElement: (element: FormElement) => boolean) => {
  return produce(form, (draft) => {
    const filteredContent: FormPageVO[] = [];

    for (const page of draft.content) {
      if (!page.content.length) {
        continue;
      }

      const elements: typeof page.content = [];

      for (const element of page.content) {
        if (!filterElement(element)) {
          continue;
        }

        if (element.type === "SECTION") {
          const sectionElements = element.content.filter(filterElement);

          if (sectionElements.length) {
            elements.push({
              ...element,
              content: sectionElements,
            });
          }
        } else {
          elements.push(element);
        }
      }

      if (elements.length) {
        filteredContent.push({
          ...page,
          content: elements,
        });
      }
    }
    draft.content = filteredContent;
  });
};

const collectIds = (element: FormInputElement | FormConsentElementVO) => {
  const ids: string[] = [];

  ids.push(element.uuid);

  if (element.type === "BOOLEAN_INPUT" && element.conditionalElement) {
    ids.push(element.conditionalElement.uuid);
  }

  return ids;
};

export const getAllInputAndConsentElementIds = (form: FormVO) => {
  const ids: string[] = [];

  for (const page of form.content) {
    for (const element of page.content) {
      if (isInputElement(element) || element.type === "CONSENT") {
        ids.push(...collectIds(element));
      } else if (element.type === "SECTION") {
        const content = element.content;

        for (const sectionElement of content) {
          if (isInputElement(sectionElement) || sectionElement.type === "CONSENT") {
            ids.push(...collectIds(sectionElement));
          }
        }
      }
    }
  }

  return ids;
};

const getMatch = (
  key: "uuid" | "tag",
  value: string,
  pageIndex: number,
  elementIndex: number,
  element: FormElement
): ElementMatch | ConditionalElementMatch | null => {
  if (element[key] === value) {
    return {
      type: "ELEMENT" as const,
      pageIndex,
      elementIndex,
      element,
    };
  }

  if ("conditionalElement" in element && element.conditionalElement?.[key] === value) {
    return {
      type: "CONDITIONAL_ELEMENT" as const,
      pageIndex,
      elementIndex,
      parent: element,
      element: element.conditionalElement,
    };
  }

  return null;
};
const getSectionMatch = (
  key: "uuid" | "tag",
  value: string,
  pageIndex: number,
  elementIndex: number,
  element: FormSectionElementVO,
  section: FormSectionVO,
  sectionElementIndex: number
): SectionElementMatch | ConditionalSectionElementMatch | null => {
  if (element[key] === value) {
    return {
      type: "SECTION_ELEMENT" as const,
      pageIndex,
      elementIndex,
      sectionElementIndex,
      element,
      section,
    };
  }

  if ("conditionalElement" in element && element.conditionalElement?.[key] === value) {
    return {
      type: "CONDITIONAL_SECTION_ELEMENT" as const,
      pageIndex,
      elementIndex,
      sectionElementIndex,
      element: element.conditionalElement,
      parent: element,
      section,
    };
  }

  return null;
};

type FindElementMatch =
  | ElementMatch
  | SectionElementMatch
  | ConditionalElementMatch
  | ConditionalSectionElementMatch
  | null;

export function findElement(form: FormVO, key: "uuid", value: string): FindElementMatch;
export function findElement(
  form: FormVO,
  key: "tag",
  value: NonNullable<FormElementVO["tag"]>
): FindElementMatch;
export function findElement(form: FormVO, key: "uuid" | "tag", value: string): FindElementMatch {
  for (const [pageIndex, page] of form.content.entries()) {
    for (const [elementIndex, element] of page.content.entries()) {
      const match = getMatch(key, value, pageIndex, elementIndex, element);

      if (match) {
        return match;
      }

      if (element.type !== "SECTION") {
        continue;
      }

      for (const [sectionElementIndex, sectionElement] of element.content.entries()) {
        const sectionElementMatch = getSectionMatch(
          key,
          value,
          pageIndex,
          elementIndex,
          sectionElement,
          element,
          sectionElementIndex
        );

        if (sectionElementMatch) {
          return sectionElementMatch;
        }
      }
    }
  }

  return null;
}

export const getProcedureDescriptions = (
  procedure: FormPatientProcedureVO,
  translate: UseTranslationResponse<"en" | "es", undefined>["t"]
) => {
  const { laymanTerm, description, deductibleAmount } = procedure;
  const descriptions = [laymanTerm ?? description];

  if (deductibleAmount > 0) {
    descriptions.push(
      translate("patientForms.treatmentPlan.deductibleAppied", {
        deductibleAmount: formatCurrency(deductibleAmount),
      })
    );
  }

  return descriptions;
};
