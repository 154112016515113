import React from "react";
import { useTranslation } from "react-i18next";
import { parseISO } from "date-fns";
import { formatAsISODate } from "@libs/utils/date";
import { useFamilyRelations } from "@libs/hooks/useRelations";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { SelectInsuranceSubscriberType } from "components/PatientInsurances/SelectPatientSubscriberType";
import { SelectCarrier } from "components/PatientInsurances/SelectCarrier";
import { SelectInsuranceIdType } from "components/PatientInsurances/SelectSubscriberIdType";

import { FormFieldDatePicker } from "components/UI/FormFieldDatePicker";
import { SelectRelationship } from "components/PatientInsurances/SelectRelationship";
import { UseInsuranceDraftResult } from "components/PatientInsurances/hooks/useInsuranceDraft";
import { UseSubmitInsuranceResult } from "components/PatientInsurances/hooks/useSubmitInsurance";

type Props = {
  children?: React.ReactNode;
  mutableInsurance: UseInsuranceDraftResult;
  id: string;
  insuranceSubmission: UseSubmitInsuranceResult;
};
export const AddPatientInsuranceForm: React.FC<Props> = ({
  mutableInsurance,
  children,
  insuranceSubmission,
  id,
}) => {
  const { t } = useTranslation();
  const {
    draftInsurance,
    handleUpdateInsuranceType,
    handleUpdatePrimarySubscriber,
    handleUpdateDependentSubscriber,
    isCreating,
  } = mutableInsurance;
  const dob = draftInsurance.dependentSubscriber.dob
    ? parseISO(draftInsurance.dependentSubscriber.dob)
    : undefined;
  const relations = useFamilyRelations();
  const { resetValidation, handleSubmit, validation } = insuranceSubmission;

  return (
    <Form id={id} className="space-y-3" onSubmit={handleSubmit}>
      <SelectInsuranceSubscriberType
        disabled={!isCreating}
        insuranceSubscriberType={draftInsurance.type}
        onUpdate={(type) => {
          resetValidation();
          handleUpdateInsuranceType(type);
        }}
      />
      {draftInsurance.type === "PRIMARY_SUBSCRIBER" ? (
        <>
          <SelectCarrier
            label={t("Insurance Carrier")}
            required
            id="carrier"
            error={validation.primarySubscriber.carrierId.$error}
            value={draftInsurance.primarySubscriber.carrierId}
            onChange={(carrier) => {
              handleUpdatePrimarySubscriber({ carrierId: carrier.id });
            }}
          />
          <FormFieldInput
            id="employer"
            label={t("Employer")}
            placeholder="Employer"
            value={draftInsurance.primarySubscriber.employer}
            onChange={(e) => {
              handleUpdatePrimarySubscriber({ employer: e.target.value });
            }}
          />
          <div>
            <SelectInsuranceIdType
              idType={draftInsurance.primarySubscriber.idType}
              validation={validation.primarySubscriber}
              onChangeIdType={(idType) => handleUpdatePrimarySubscriber({ idType })}
              onChangeSsn={(ssn) => handleUpdatePrimarySubscriber({ ssn })}
              onChangeSubscriberId={(subscriberId) => handleUpdatePrimarySubscriber({ subscriberId })}
              subscriberId={draftInsurance.primarySubscriber.subscriberId}
              ssnLastFour={draftInsurance.primarySubscriber.ssnLastFour}
            />
          </div>
        </>
      ) : (
        <>
          <FormFieldInput
            id="firstName"
            label={t("Subscriber First Name")}
            required
            placeholder={t("First Name")}
            value={draftInsurance.dependentSubscriber.firstName}
            error={validation.dependentSubscriber.firstName.$error}
            onChange={(e) => {
              handleUpdateDependentSubscriber({ firstName: e.target.value });
            }}
          />
          <FormFieldInput
            id="lastName"
            label={t("Subscriber Last Name")}
            required
            placeholder={t("Last Name")}
            error={validation.dependentSubscriber.lastName.$error}
            value={draftInsurance.dependentSubscriber.lastName}
            onChange={(e) => {
              handleUpdateDependentSubscriber({ lastName: e.target.value });
            }}
          />
          <FormFieldDatePicker
            id="selectDateOfBirth"
            required
            label={t("Subscriber Date of Birth")}
            selected={dob}
            pickerHiddenOnMobile
            error={validation.dependentSubscriber.dob.$error}
            maxDate={new Date()}
            onChange={(date) => {
              handleUpdateDependentSubscriber({
                dob: date ? formatAsISODate(date) : undefined,
              });
            }}
          />
          <SelectRelationship
            onItemSelected={(val) => {
              handleUpdateDependentSubscriber({ relation: val });
            }}
            error={validation.dependentSubscriber.relation.$error}
            value={draftInsurance.dependentSubscriber.relation}
            patientRelations={relations}
            id="select-relation"
          />
          <SelectCarrier
            id="carrier"
            label={t("Subscriber Insurance Carrier")}
            required
            error={validation.dependentSubscriber.carrierId.$error}
            value={draftInsurance.dependentSubscriber.carrierId}
            onChange={(carrier) => {
              handleUpdateDependentSubscriber({ carrierId: carrier.id });
            }}
          />
          <FormFieldInput
            id="employer"
            label={t("Subscriber Employer")}
            placeholder="Employer"
            value={draftInsurance.dependentSubscriber.employer}
            onChange={(e) => {
              handleUpdateDependentSubscriber({ employer: e.target.value });
            }}
          />
          <div>
            <SelectInsuranceIdType
              validation={validation.dependentSubscriber}
              idType={draftInsurance.dependentSubscriber.idType}
              onChangeIdType={(idType) => handleUpdateDependentSubscriber({ idType })}
              onChangeSsn={(ssn) => handleUpdateDependentSubscriber({ ssn })}
              onChangeSubscriberId={(subscriberId) => handleUpdateDependentSubscriber({ subscriberId })}
              subscriberId={draftInsurance.dependentSubscriber.subscriberId}
              ssnLastFour={draftInsurance.dependentSubscriber.ssnLastFour}
            />
          </div>
        </>
      )}
      {children}
    </Form>
  );
};
