import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import {
  addDays,
  addHours,
  addMinutes,
  format,
  getMinutes,
  isAfter,
  isBefore,
  isSameSecond,
  set,
  startOfHour,
  subMinutes,
} from "date-fns";
import { PracticeInfoVO, ProviderVO, SelfBookingOpenSlotVO } from "@libs/api/generated-api";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { formatAsISODate, formatShortDayOfMonth, getLocalDate, setTimeOnDate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatPhoneNumber } from "@libs/utils/phone";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as PhoneIcon } from "@libs/assets/icons/call.svg";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { ReactComponent as MinusIcon } from "@libs/assets/icons/subtract.svg";
import { Chip } from "components/Chip";
import { formatISODateAsDayDate } from "components/SelfBooking/utils";

const SLOT_CHUNK_MINUTES = 30;

const SLOT_LIMIT = 4;

type SlotData = { value: string; label: string };

const getCustomStartOptions = (slot: SelfBookingOpenSlotVO, duration: number) => {
  let start = getLocalDate(slot.date, slot.startTime);
  const endTime = getLocalDate(slot.date, slot.endTime);
  const startMinutes = getMinutes(start);

  if (startMinutes % SLOT_CHUNK_MINUTES !== 0) {
    start =
      startMinutes > SLOT_CHUNK_MINUTES
        ? addHours(startOfHour(start), 1) // If availability is 1:35 - 3:15pm, startMinutes=35 we would start at 2pm
        : set(start, { minutes: SLOT_CHUNK_MINUTES }); // If availability is 1:25 - 3:15pm, startMinutes=25, we would start at 1:30pm
  }

  // let start = dateInTimezone;
  const end = setTimeOnDate(start, slot.endTime);

  if (isAfter(end, endTime)) {
    // Because end is defined by start which can be adjusted via rounding logic due to SLOT_CHUNK_MINUTES, we need
    // this check to ensure the adjusted end time doesn't go past the intended end time
    return [];
  }

  const startLimit = subMinutes(end, duration);
  const options: SlotData[] = [];

  while (isBefore(start, startLimit) || isSameSecond(startLimit, start)) {
    options.push({
      value: format(start, "HH:mm:ss"),
      label: format(start, "hh:mm a"),
    });
    start = addMinutes(start, SLOT_CHUNK_MINUTES);
  }

  return options;
};

const VerticalDaySlots: React.FC<{
  isoDate: string;
  slots: SlotData[];
  onSetTime: (time: string) => void;
}> = ({ isoDate, slots, onSetTime }) => {
  const showMore = useBoolean(false);
  const { t } = useTranslation();
  const hasMore = slots.length > SLOT_LIMIT;

  const slotsToShow = showMore.isOn ? slots : slots.slice(0, SLOT_LIMIT);

  return (
    <div className="flex flex-col gap-2 w-[90px] px-0.5 items-center">
      <div className="font-sansSemiBold text-xs"> {formatISODateAsDayDate(isoDate)}</div>
      {slots.length === 0 && (
        <Chip size="lg" disabled>
          {t("None")}
        </Chip>
      )}
      {slotsToShow.map((slot) => (
        <Chip key={slot.label} size="lg" onClick={() => onSetTime(slot.value)}>
          {slot.label}
        </Chip>
      ))}
      {hasMore && (
        <Chip size="lg" className="flex items-center" onClick={showMore.toggle}>
          <span className="mr-1">{showMore.isOn ? t("Less") : t("More")}</span>
          <Icon SvgIcon={showMore.isOn ? MinusIcon : PlusIcon} size="xs" />
        </Chip>
      )}
    </div>
  );
};

export const ProviderSlots: React.FC<{
  provider: ProviderVO;
  slots: SelfBookingOpenSlotVO[];
  practice: PracticeInfoVO;
  date: Date;
  displayDays: number;
  duration: number;
  onSelect: (providerId: number, providerName: string, isoDate: string, isoTime: string) => void;
  onSetDate: (date: Date) => void;
  displayInOutNetworkDetail: boolean;
}> = ({
  provider,
  slots,
  practice,
  date,
  displayDays,
  duration,
  onSelect,
  onSetDate,
  displayInOutNetworkDetail,
}) => {
  const { t } = useTranslation();
  const name = provider.name.fullDisplayName;

  // Grouped by ISO Date.
  const slotsByISODate = useMemo(
    () =>
      slots.reduce(
        (accum, slot) => {
          const groupByValue = slot.date;
          let arrayEntry = accum[groupByValue];

          if (!arrayEntry) {
            arrayEntry = [];
            accum[groupByValue] = arrayEntry;
          }

          const chunkedSlots = getCustomStartOptions(slot, duration);

          arrayEntry.push(...chunkedSlots);

          return accum;
        },
        {} as Record<string, SlotData[] | undefined>
      ),
    [duration, slots]
  );

  const isoDate = formatAsISODate(date);

  const todaySlots = slotsByISODate[isoDate] || [];

  const isoDatesArray = useMemo(() => {
    return Array.from({ length: displayDays }, (_, i) => i).map((dayIncrement) =>
      formatAsISODate(addDays(date, dayIncrement))
    );
  }, [date, displayDays]);

  const showNextAvailable = useMemo(() => {
    if (slots.length === 0) {
      return false;
    }

    if (displayDays === 1 && todaySlots.length === 0) {
      return true;
    }

    // If any days have time, then we won't show next available.
    return !isoDatesArray.some((isoDt) => slotsByISODate[isoDt]?.length);
  }, [displayDays, isoDatesArray, slots.length, slotsByISODate, todaySlots.length]);

  return (
    <section key={provider.id}>
      <div className="flex flex-row items-center p-3">
        <div
          className={`
            flex
            items-center
            justify-center
            bg-greyLightest
            w-8
            h-8
            flex-none
            rounded-full
            text-greyDark
            font-sansSemiBold
          `}
        >
          {formatFullNameToInitials({ fullName: provider.name.fullDisplayName })}
        </div>
        <div className="flex flex-col ml-3">
          <div className="text-greyDark font-sansSemiBold text-sm">{name}</div>
          <div>
            {displayInOutNetworkDetail &&
              // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
              ` • ${provider.inNetwork ? t("selfBooking.inNetwork") : t("selfBooking.outOfNetwork")}`}
          </div>
        </div>
      </div>

      {slots.length === 0 && practice.phoneNumber && displayDays === 1 && (
        <a href={`tel:${practice.phoneNumber}`} className="my-2">
          <Chip size="md" className="flex items-center">
            <Icon SvgIcon={PhoneIcon} theme="info" size="md" /> Call{" "}
            <span className="ml-1">{formatPhoneNumber(practice.phoneNumber)}</span>
          </Chip>
        </a>
      )}
      {showNextAvailable && (
        <Chip size="md" onClick={() => onSetDate(getLocalDate(slots[0].date))}>
          {t("selfBooking.nextAvailable", { date: formatShortDayOfMonth(getLocalDate(slots[0].date)) })}
        </Chip>
      )}
      {!showNextAvailable && displayDays === 1 && (
        <div className="flex flex-row gap-x-2 overflow-x-auto py-2 -mr-6 last:pr-6">
          {todaySlots.map((slot) => (
            <Chip key={slot.label} size="md" onClick={() => onSelect(provider.id, name, isoDate, slot.value)}>
              {slot.label}
            </Chip>
          ))}
        </div>
      )}
      {!showNextAvailable && displayDays > 1 && (
        <div className="flex flex-row my-2">
          {isoDatesArray.map((isoDt) => (
            <VerticalDaySlots
              key={isoDt}
              isoDate={isoDt}
              slots={slotsByISODate[isoDt] || []}
              onSetTime={(time) => onSelect(provider.id, name, isoDt, time)}
            />
          ))}
        </div>
      )}
    </section>
  );
};
