import { FC } from "react";
import { Outlet } from "react-router-dom";
import { SessionIdleTimer } from "components/Main/SessionIdleTimer";

export const SessionIdleTimerRoute: FC = () => {
  return (
    <>
      <Outlet />
      <SessionIdleTimer />
    </>
  );
};
