import React from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { updateOnboardingInfo } from "api/user/mutations";
import { NewCardForm } from "components/Account/NewCardForm";
import { useCreatePaymentProfile } from "components/Billing/hooks/useCreatePaymentProfile";
import { RegistrationSubmissionFooter } from "components/Onboarding/RegistrationSubmissionFooter";
import { useHandleError } from "api/handleErrorResponse";
import { useCardDraft } from "hooks/useCardDraft";
import { usePatientFinixForm } from "hooks/usePatientFinixForm";

type Props = {
  onSuccess: Func;
  onPrevious: Func;
};

export const RegistrationAddPayment: React.FC<Props> = ({ onPrevious, onSuccess }) => {
  const { id: patientId, practiceId } = useAccount();
  const [{ mutate: mutateOnboarding, isLoading: isSavingOnboarding }] = useApiMutations([
    updateOnboardingInfo,
  ]);

  const handleError = useHandleError();

  const handleAddPaymentSuccess = React.useCallback(() => {
    mutateOnboarding(
      {
        practiceId,
        patientId,
        onboardingState: "PAYMENT_INFO",
      },
      {
        onSuccess,
        onError: handleError,
      }
    );
  }, [handleError, mutateOnboarding, onSuccess, patientId, practiceId]);

  const cardDraftProps = useCardDraft({
    includeAcknowedgeFuturePayments: true,
  });
  const { isSaving, createPaymentProfile } = useCreatePaymentProfile({
    onSavedNewCardHandler: handleAddPaymentSuccess,
    isDefaultCard: cardDraftProps.cardDraftState.isDefaultCard,
  });

  const finixUseFormProps = usePatientFinixForm({
    formSubmissionHandler: createPaymentProfile,
  });

  return (
    <div>
      <div className="flex justify-center flex-1 overflow-y-auto">
        <div className="w-full flex flex-col max-w-lg p-6">
          <NewCardForm
            includeAcknowedgeFuturePayments
            includeFsaCardPreference
            className="pb-6"
            {...finixUseFormProps}
            {...cardDraftProps}
          />
        </div>
      </div>
      <RegistrationSubmissionFooter
        isSubmitting={finixUseFormProps.isSubmitting || isSaving || isSavingOnboarding}
        onClickPrevious={onPrevious}
        onClickNext={() => {
          if (cardDraftProps.validation.validate().$isValid) {
            finixUseFormProps.submitForm?.();
          }
        }}
        primaryButtonDisabled={
          finixUseFormProps.isSubmitting ||
          isSaving ||
          cardDraftProps.validation.result.$isValid === false ||
          !finixUseFormProps.isFormValid
        }
      />
    </div>
  );
};
