import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useCreatePaymentProfile } from "components/Billing/hooks/useCreatePaymentProfile";
import { NewCardForm } from "components/Account/NewCardForm";
import { TitleBar } from "components/UI/TitleBar";
import { StickySubmissionFooter } from "components/UI/StickySubmissionFooter";
import { useCardDraft } from "hooks/useCardDraft";
import { usePatientFinixForm } from "hooks/usePatientFinixForm";
import { paths } from "router/paths";
import { useQueryParams } from "hooks/useQueryParams";

export const AddPaymentMethodRoute: React.FC = () => {
  const navigate = useNavigate();
  const cardDraftProps = useCardDraft({
    includeAcknowedgeFuturePayments: true,
  });

  const { query } = useQueryParams("addPaymentMethod");

  const successUrl = query.successUrl;
  const fromUrl = query.from;
  const backUrl = fromUrl ?? paths.paymentMethods();
  const { t } = useTranslation();
  const handleAddPaymentMethodSuccess = useCallback(() => {
    navigate(successUrl ?? paths.paymentMethods());
  }, [navigate, successUrl]);

  const handleGoBack = useCallback(() => {
    navigate(backUrl);
  }, [navigate, backUrl]);

  const { createPaymentProfile, isSaving } = useCreatePaymentProfile({
    onSavedNewCardHandler: handleAddPaymentMethodSuccess,
    isDefaultCard: cardDraftProps.cardDraftState.isDefaultCard,
  });
  const finixUseFormProps = usePatientFinixForm({
    formSubmissionHandler: createPaymentProfile,
  });

  return (
    <div className="relative flex flex-col h-full min-h-0 bg-white">
      <TitleBar backTo={backUrl} title={t("Add Payment Method")} />
      <div className="flex justify-center flex-1 overflow-y-auto">
        <div className="w-full flex flex-col max-w-md p-6">
          <NewCardForm
            includeAcknowedgeFuturePayments
            includeDefaultCardPreference
            includeFsaCardPreference
            {...finixUseFormProps}
            {...cardDraftProps}
          />
        </div>
      </div>
      <StickySubmissionFooter
        secondaryText={t("Cancel")}
        primaryText={t("Save")}
        onClickSecondary={handleGoBack}
        secondaryButtonDisabled={finixUseFormProps.isSubmitting || isSaving}
        onClickPrimary={() => {
          if (cardDraftProps.validation.validate().$isValid) {
            finixUseFormProps.submitForm?.();
          }
        }}
        primaryButtonDisabled={
          finixUseFormProps.isSubmitting ||
          isSaving ||
          cardDraftProps.validation.result.$isValid === false ||
          !finixUseFormProps.isFormValid
        }
        isSubmitting={isSaving || finixUseFormProps.isSubmitting}
        className="sticky"
      />
    </div>
  );
};
