import { Text as PDFText, StyleSheet, View } from "@react-pdf/renderer";
import { colors } from "@libs/design.config";
import { formatDate, formatISODate } from "@libs/utils/date";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { SvgHorizontalLine } from "components/PatientForms/FormPDFElements/SvgHorizontalLine";

export const styles = StyleSheet.create({
  cursive: {
    fontSize: 28,
    fontFamily: "Mr Dafoe",
    fontWeight: "normal",
    // Pushes cursive to overlap baseline
    marginBottom: -7,
  },
  signHere: {
    fontSize: 12,
    paddingBottom: 2,
    color: colors.greyMedium,
  },
  signatureContent: { flexDirection: "row", alignItems: "flex-end", gap: 5, minHeight: 27 },
});

export const PDFSignatureText: React.FC<{
  children?: React.ReactNode;
  date?: string;
  acceptsInitials?: boolean;
  edit: boolean;
}> = ({ children, date, edit, acceptsInitials = false }) => {
  return (
    <View
      style={{
        marginLeft: edit ? 0 : 15,
        flexDirection: "column",
      }}
    >
      <View style={[styles.signatureContent, { gap: 20 }]}>
        <View>
          <View style={styles.signatureContent}>
            <PDFText style={styles.signHere}>X</PDFText>
            <PDFText style={styles.cursive}>{children}</PDFText>
          </View>
          <SvgHorizontalLine fill={colors.greyLight} width={acceptsInitials ? 50 : 300} />
        </View>
        {edit && (
          <View style={styles.signatureContent}>
            <Text>Date:</Text>
            <SvgHorizontalLine fill={colors.greyLight} width={80} />
          </View>
        )}
      </View>
      {date && (
        <Text size="sm" style={{ marginTop: 5 }}>
          Date: {date ? formatISODate(date) : formatDate(new Date())}
        </Text>
      )}
    </View>
  );
};
