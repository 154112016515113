import React from "react";
import { useTranslation } from "react-i18next";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ReactComponent as CheckIcon } from "@libs/assets/icons/check-circle-1-filled.svg";
import celebrationUrl from "assets/images/celebration.svg";
import { normal14, semibold24 } from "assets/styles/textSize";
import { CenteredResponsiveLayout } from "components/UI/CenteredResponsiveLayout";
import { paths } from "router/paths";

export const RegistrationSuccess: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CenteredResponsiveLayout
      centerContents
      size="xl"
      className="flex flex-col items-center space-y-6 h-full pt-[20%] px-6"
    >
      <img src={celebrationUrl} alt="Celebration" className="h-1/3" />
      <div className="flex space-x-3 items-center text-secondaryTheme h-6">
        <CheckIcon />
        <div className={semibold24}>{t("registration.success.done")}</div>
      </div>
      <div className={normal14}>{t("registration.success.paragraph")}</div>
      <ButtonInternalLink className="w-full" to={paths.home()}>
        {t("registration.success.action.goToHome")}
      </ButtonInternalLink>
    </CenteredResponsiveLayout>
  );
};
