import { useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import React from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { cancelAppointment } from "api/scheduling/mutations";
import { ResponsiveActionModal } from "components/UI/ResponsiveActionModal";

export const CancelAppointmentModal: React.FC<{ onClose: Func; goToAppointments: Func }> = ({
  onClose,
  goToAppointments,
}) => {
  const [{ mutateAsync: mutateCancelAppointment, isLoading }] = useApiMutations([cancelAppointment]);
  const { t } = useTranslation();

  const { appointmentId } = useParams();
  const { practiceId, id: patientId } = useAccount();
  const practice = useCurrentPractice();

  const onClickConfirm = React.useCallback(() => {
    if (appointmentId) {
      return mutateCancelAppointment({
        practiceId,
        patientId,
        appointmentId: Number.parseInt(appointmentId, 10),
      });
    }

    return Promise.resolve();
  }, [appointmentId, mutateCancelAppointment, patientId, practiceId]);

  return (
    <ResponsiveActionModal
      text={
        <Trans
          i18nKey="app.pages.labels.cancel.confirm.message"
          values={{ practiceName: practice.name }}
          tOptions={{ interpolation: { escapeValue: true } }}
          shouldUnescape
        />
      }
      completedContent={<div className="flex flex-col gap-4">{t("Appointment canceled successfully")}</div>}
      onClickPrimaryButton={onClickConfirm}
      onClose={onClose}
      secondaryButtonLabel={t("No")}
      tertiaryButtonLabel={t("Back to Upcoming Appointments")}
      onClickTertiaryButton={goToAppointments}
      primaryButtonLabel={t("Yes")}
      onClickSecondaryButton={onClose}
      isLoading={isLoading}
    />
  );
};
