import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { SnackbarProvider } from "notistack";
import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { IntlProvider } from "react-intl";
import { FC, StrictMode } from "react";
import { StorageContextProvider } from "@libs/contexts/StorageContext";
import { getLocalStorage, getSessionStorage } from "@libs/storage/getStorage";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { queryClient } from "utils/queryClient";
import { normal14 } from "assets/styles/textSize";
import { PatientRouterProvider } from "router/PatientRouterProvider";

const storage = { localStorage: getLocalStorage(), sessionStorage: getSessionStorage() };

export const App: FC = () => {
  return (
    <StrictMode>
      <SentryErrorBoundary fallback={<ErrorContent />}>
        <SnackbarProvider className={normal14} maxSnack={3}>
          <IntlProvider locale="en">
            <QueryClientProvider client={queryClient}>
              <StorageContextProvider value={storage}>
                <PatientRouterProvider />
              </StorageContextProvider>
              <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
            </QueryClientProvider>
          </IntlProvider>
        </SnackbarProvider>
      </SentryErrorBoundary>
    </StrictMode>
  );
};
