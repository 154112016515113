import React from "react";

import { View } from "@react-pdf/renderer";
import { colors } from "@libs/design.config";

import { Style } from "@react-pdf/types";
import { Box } from "components/PatientForms/FormPDFElements/Box";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { FormElementTitle } from "components/PatientForms/FormPDFElements/FormElementTitle";
import { pdfStyles } from "components/PatientForms/FormPDFElements/styles";

type Props = {
  edit: boolean;
  required?: boolean;
  label: string;
  value?: string;
  lineWidth?: number;
  style?: Style;
};
export const PatientInfoFormTextItem: React.FC<Props> = ({ label, value, required, edit, style }) => {
  return (
    <Box wrap={false} style={style ?? pdfStyles.flexFull}>
      <FormElementTitle required={required}>{label}</FormElementTitle>
      {edit ? (
        <View style={{ height: 25, borderBottom: colors.slate[500], borderBottomWidth: 1 }} />
      ) : (
        <Text bold={false}>{value ?? "-"}</Text>
      )}
    </Box>
  );
};
