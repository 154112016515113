import { UserAccountVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { getAccountTokenStorage } from "@libs/storage/accountToken";
import { getIdentityToken } from "@libs/auth/getTokens";
import { issueAccountToken } from "api/user/mutations";
import { useHandleError } from "api/handleErrorResponse";
import { FamilyMemberUserAccount } from "api/user/types";

export const useSwitchAccount = ({
  onAccountSelected,
}: {
  onAccountSelected: (account: FamilyMemberUserAccount) => void;
}) => {
  const [issueAccountTokenMutation] = useApiMutations([issueAccountToken]);
  const handleError = useHandleError();

  const switchAccount = (selectedAccount: FamilyMemberUserAccount) => {
    issueAccountTokenMutation.mutate(
      { userId: selectedAccount.familyMemberUserId ?? selectedAccount.id, rememberMe: true },
      {
        onSuccess: async (response) => {
          const accountTokenStorage = getAccountTokenStorage(localStorage);
          const { email } = await getIdentityToken();

          accountTokenStorage.setAccountToken(email, {
            ...response.data.data,
            // the userId of the token account may be different than
            // the id of the user that is being selected. e.g.
            // when selecting a family member account
            userId: selectedAccount.id,
            practiceId: selectedAccount.practice.id,
            practiceUuid: selectedAccount.practice.uuid,
          });

          onAccountSelected(selectedAccount);
        },
        onError: handleError,
      }
    );
  };

  return switchAccount;
};

export const mapToFamilyAccounts = (accounts: UserAccountVO[] | undefined): FamilyMemberUserAccount[] =>
  accounts?.flatMap((acc) =>
    acc.familyAccounts
      ? [
          acc,
          ...acc.familyAccounts.map((fm) => ({
            ...fm,
            practice: acc.practice,
            familyMemberUserId: acc.id,
          })),
        ]
      : [acc]
  ) ?? [];
