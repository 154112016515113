import { useTranslation } from "react-i18next";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { TitleBar } from "components/UI/TitleBar";
import { AddPatientInsuranceForm } from "components/PatientInsurances/AddPatientInsuranceForm";
import { useInsuranceDraft } from "components/PatientInsurances/hooks/useInsuranceDraft";
import { useSubmitInsurance } from "components/PatientInsurances/hooks/useSubmitInsurance";
import { paths } from "router/paths";

export const AddPatientInsuranceRoute: React.FC = () => {
  const { t } = useTranslation();

  const mutableInsurance = useInsuranceDraft();
  const insuranceSubmission = useSubmitInsurance({
    draftInsurance: mutableInsurance.draftInsurance,
  });
  const { isSubmitting } = insuranceSubmission;
  const { isLoading } = mutableInsurance;

  return (
    <div className="flex flex-col items-center relative h-full min-h-0">
      <TitleBar backTo={paths.insurances()} title={t("Add Insurance")} />
      <div className="flex flex-col gap-3 w-full max-w-lg flex-1 px-4 mt-6">
        <div className="p-3 overflow-y-auto">
          <AddPatientInsuranceForm
            id="account-add-insurance"
            mutableInsurance={mutableInsurance}
            insuranceSubmission={insuranceSubmission}
          >
            <div className="h-16" />
            <div
              className={`
                fixed
                items-center
                justify-center
                flex
                space-x-2
                h-16
                bg-white
                bottom-0
                border-t
                border-t-greyLighter
                shadow-[2px_-2px_4px_rgba(188,188,188,0.25)]
                left-0
                right-0
              `}
            >
              <ButtonInternalLink className="min-w-32" theme="secondary" to={paths.insurances()}>
                Cancel
              </ButtonInternalLink>
              <AsyncButton
                className="min-w-32"
                disabled={isLoading || isSubmitting}
                isLoading={isLoading || isSubmitting}
                type="submit"
              >
                Submit
              </AsyncButton>
            </div>
          </AddPatientInsuranceForm>
        </div>
      </div>
    </div>
  );
};
