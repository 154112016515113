import { useBoolean } from "@libs/hooks/useBoolean";
import { useTranslation } from "react-i18next";
import { MedicalInformationRouteHeader } from "components/Account/MedicalInformationRouteHeader";

import { PatientForm } from "components/PatientForms/PatientForm";
import { ViewPatientFormResponses } from "components/Account/ViewPatientFormResponses";
import { OnboardingCard } from "components/HomeRoute/OnboardingCard";
import { useCurrentPatient } from "contexts/PatientContext";

export const AccountMedicalHistoryRoute = () => {
  const { t } = useTranslation();
  const editing = useBoolean(false);
  const { onboardingState } = useCurrentPatient();

  return (
    <div
      className={`
        relative
        flex-1
        flex
        flex-col
        border-l
        border-l-greyLighter
        bg-white
      `}
    >
      <MedicalInformationRouteHeader
        onClickEdit={editing.on}
        selectedValue="MEDICAL_HISTORY"
        edit={editing.isOn}
      />
      {onboardingState === "COMPLETED" ? (
        editing.isOn ? (
          <PatientForm
            uuidOrSlug="MEDICAL_HISTORY"
            edit={editing.isOn}
            onSuccess={editing.off}
            reconsent
            onPrevious={editing.off}
          />
        ) : (
          <ViewPatientFormResponses uuidOrSlug="MEDICAL_HISTORY" />
        )
      ) : (
        <div className="max-w-screen-sm self-center mt-8">
          <OnboardingCard onboardingState={onboardingState} body={t("account.onboardingCard")} />
        </div>
      )}
    </div>
  );
};
