import { getLocalDate, nowInTimezone } from "@libs/utils/date";
import { intervalToDuration } from "date-fns";
import { useMemo } from "react";

export const getAge = (dob: string, timezoneId: string) => {
  const now = nowInTimezone(timezoneId);

  return intervalToDuration({ start: getLocalDate(dob), end: now }).years ?? 0;
};

export const useAge = (dob: string, timezoneId: string) => {
  return useMemo(() => getAge(dob, timezoneId), [dob, timezoneId]);
};
