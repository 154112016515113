import { useTranslation } from "react-i18next";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { semibold16 } from "assets/styles/textSize";
import { Card } from "components/UI/Cards";
import { CallPractice, EmailPractice } from "components/UI/ContactPractice";

export const CallPracticeToCompletePayment: React.FC<{
  practice: PracticeInfoVO;
}> = ({ practice }) => {
  const { t } = useTranslation();

  return (
    <Card className="flex flex-col gap-2" inCarousel={false}>
      <div className="flex flex-col gap-3 justify-center">
        <p className={semibold16}>{t("processPayment.page.notRegistered.title")}</p>
        <p>{t("processPayment.page.notRegistered.description")}</p>
        <CallPractice phoneNumber={practice.phoneNumber} />
        <EmailPractice email={practice.email} />
      </div>
    </Card>
  );
};
