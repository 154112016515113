import React from "react";
import { useQueryClient } from "@tanstack/react-query";
import { PatientVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { updateCachedData } from "@libs/utils/queryCache";
import { getQueryKey } from "@libs/utils/queries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { submitFormResponses } from "api/forms/mutations";
import { PatientResponses, ResponseValue } from "components/PatientForms/hooks/usePatientResponses";
import { UUIDOrSlug } from "api/forms/queries";
import { useHandleError } from "api/handleErrorResponse";
import { useCurrentPatient } from "contexts/PatientContext";

export const useAuthorizedFormSubmission = ({
  uuidOrSlug,
  onSuccess,
}: {
  uuidOrSlug: UUIDOrSlug;
  onSuccess?: Func;
  practiceId: number;
  patientId: number;
  onboardingState: PatientVO["onboardingState"];
}) => {
  const { id: patientId, practiceId } = useAccount();
  const practice = useCurrentPractice();
  const patient = useCurrentPatient();
  const { onboardedWithPaymentProvider } = practice;
  const [submitFormResponsesMutation] = useApiMutations([submitFormResponses]);
  const submitFormResponsesMutate = submitFormResponsesMutation.mutate;
  const hasCompletedOnboarding = patient.onboardingState === "COMPLETED";
  const handleError = useHandleError();
  const queryClient = useQueryClient();

  const handleSucessfulSubmission = React.useCallback(() => {
    if (!hasCompletedOnboarding) {
      const nextState: Record<UUIDOrSlug, PatientVO["onboardingState"] | undefined> = {
        DENTAL_HISTORY: "MEDICAL_HISTORY_INFO",
        MEDICAL_HISTORY: "PAYMENT_INFO",
        CONSENT: "PDF_SUBMISSION",
      };

      if (!onboardedWithPaymentProvider) {
        nextState.MEDICAL_HISTORY = "CONSENT";
      }

      const onboardingStateNow = nextState[uuidOrSlug];

      if (onboardingStateNow) {
        updateCachedData<PatientVO>(
          queryClient,
          { queryKey: [getQueryKey("practices", "getPatient"), { patientId, practiceId }] },
          (current) => {
            return {
              ...current,
              onboardingState: onboardingStateNow,
            };
          }
        );
      }
    }
  }, [hasCompletedOnboarding, onboardedWithPaymentProvider, practiceId, patientId, queryClient, uuidOrSlug]);
  const handleSubmit = React.useCallback(
    (submission: { responsesById: PatientResponses; formPublishedContentUuid: string }) => {
      submitFormResponsesMutate(
        {
          practiceId,
          patientId,
          uuidOrSlug,
          data: {
            formPublishedContentUuid: submission.formPublishedContentUuid,
            responses: submission.responsesById as Record<string, ResponseValue>,
          },
        },
        {
          onSuccess: () => {
            handleSucessfulSubmission();
            onSuccess?.();
          },
          onError: handleError,
        }
      );
    },
    [
      handleError,
      handleSucessfulSubmission,
      onSuccess,
      patientId,
      practiceId,
      submitFormResponsesMutate,
      uuidOrSlug,
    ]
  );

  return {
    handleSubmit,
    isSubmitting: submitFormResponsesMutation.isLoading,
  };
};
