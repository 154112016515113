import { generatePath } from "react-router-dom";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { isNullish } from "@libs/utils/types";
import { HasAllValues, SetValues } from "@libs/utils/union";

type PathParamConverterType = "string" | "number" | "boolean" | "enum" | "date" | "new_or_number";
type PathParamConverter<T> = {
  get: (val: string) => T;
  set: (val: T) => string;
  type: PathParamConverterType;
};

type QueryParamConverter<T> = Omit<PathParamConverter<T>, "type"> & {
  defaultValue?: T;
};

export type QueryConfig<T> = Record<string, QueryParamConverter<T>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyQueryConfig = QueryConfig<any>;
export type PathConfig<T> = Record<string, PathParamConverter<T>>;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AnyPathConfig = PathConfig<any>;
export type RouteConfig<N extends string, P, Q> = {
  path: N;
  params?: PathConfig<P>;
  query?: QueryConfig<Q>;
  instrumentation?: { maskedParams: Set<string> };
};
// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AnyRouteConfg<N extends string> = RouteConfig<N, any, any>;

export type ParsedParams<C extends AnyQueryConfig> = {
  [K in keyof C]: C[K] extends { get: (val: string) => unknown; defaultValue: unknown }
    ? ReturnType<C[K]["get"]>
    : C[K] extends { get: (val: string) => unknown }
      ? ReturnType<C[K]["get"]> | undefined
      : never;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RoutesConfigsWithQueryParams<R extends Record<string, RouteConfig<string, any, any>>> = keyof {
  [P in keyof R as R[P] extends { query: AnyQueryConfig } ? P : never]: P;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RoutesConfigsWithPathParams<R extends Record<string, RouteConfig<string, any, any>>> = keyof {
  [P in keyof R as R[P] extends { params: AnyPathConfig } ? P : never]: P;
};

type ParsedQueryResultsMap<P extends ParsedParams<AnyQueryConfig>> = {
  [K in keyof P]: {
    queryValue: string;
    parsedValue: P[K];
  };
};

export type QueryResultsMap<QC extends AnyQueryConfig> = Partial<
  ParsedQueryResultsMap<ParsedParams<NonNullable<QC>>>
>;

export type ParsedPathParams<C extends AnyPathConfig> = {
  [K in keyof C]: C[K] extends { get: (val: string) => unknown } ? ReturnType<C[K]["get"]> : never;
};

export type UrlBuilder<
  T extends Record<string, { path: string; params?: AnyPathConfig; query?: AnyQueryConfig }>,
> = {
  [K in keyof T]: T[K] extends { params: AnyPathConfig; query: AnyQueryConfig }
    ? (params: ParsedParams<T[K]["params"]>, query?: Partial<ParsedParams<T[K]["query"]>>) => string
    : T[K] extends { params: AnyPathConfig }
      ? (params: ParsedParams<T[K]["params"]>) => string
      : T[K] extends { query: AnyQueryConfig }
        ? (query?: Partial<ParsedParams<T[K]["query"]>>) => string
        : () => string;
};

export const upsertQueryParams = (
  keyValues: Record<string, string | undefined>,
  params?: URLSearchParams | null
) => {
  const p = params ?? new URLSearchParams();

  for (const [key, value] of Object.entries(keyValues)) {
    if (value === undefined) {
      p.delete(key);
    } else {
      p.set(key, value);
    }
  }
  p.sort();

  return p;
};

export const formUrl = (pathname: string, queryParams: URLSearchParams) => {
  const queryString = queryParams.toString();

  if (queryString) {
    return `${pathname}?${queryString}`;
  }

  return pathname;
};

export const queryParamsToString = <QC extends AnyQueryConfig>(
  queryConfig: QC,
  params: Partial<ParsedParams<QC>>
) => {
  const parsed: Record<string, string | undefined> = {};

  const keys = Object.keys(params);

  for (const key of keys) {
    const value = params[key];
    const paramConfig = queryConfig[key];

    if (isNullish(value)) {
      // falsey param values need to be included
      // to clear param values from the query string
      parsed[key] = undefined;
    } else {
      const setValue = paramConfig.set(value);

      // allows the ability for query coverters to
      // convert a value being set (e.g. []) to
      // the param being removed from the query string
      parsed[key] = setValue.length ? setValue : undefined;
    }
  }

  return parsed;
};

export const parseQueryParams = <QC extends AnyQueryConfig>(
  queryConfig: QC,
  params: URLSearchParams | null,
  lastResultsMap?: QueryResultsMap<QC>
) => {
  const parsed: Record<string, unknown> = {};
  const newResultsMap: Record<string, unknown> = {};
  const keys = Object.keys(queryConfig);

  for (const key of keys) {
    const value = params?.get(key);
    const paramConfig = queryConfig[key];

    if (isNullish(value)) {
      parsed[key] = paramConfig.defaultValue ?? undefined;
    } else {
      try {
        const lastResult = lastResultsMap?.[key];
        const parsedValue =
          lastResult?.queryValue === value ? lastResult.parsedValue : (paramConfig.get(value) as unknown);

        parsed[key] = parsedValue;
        newResultsMap[key] = {
          queryValue: value,
          parsedValue,
        };
      } catch (error) {
        if (error instanceof TypeError && error.message.startsWith(PARSE_ERROR_PREFIX)) {
          // TODO would be good to log a warning to sentry
          parsed[key] = paramConfig.defaultValue ?? undefined;
        } else {
          throw error;
        }
      }
    }
  }

  return {
    parsed: parsed as ParsedParams<NonNullable<QC>>,
    resultsMap: newResultsMap as QueryResultsMap<QC>,
  };
};

export const paramsToString = <PC extends AnyPathConfig>(
  pathConfig: PC,
  params: ParsedParams<NonNullable<PC>>
) => {
  const parsed: Record<string, string> = {};

  const keys = Object.keys(pathConfig);

  for (const key of keys) {
    const value = params[key];
    const paramConfig = pathConfig[key];

    parsed[key] = paramConfig.set(value);
  }

  return parsed;
};

export const parseParams = <PC extends AnyPathConfig>(
  pathConfig: PC,
  params: Record<string, string | undefined>
) => {
  const parsed: Record<string, unknown> = {};

  const keys = Object.keys(pathConfig);

  for (const key of keys) {
    const value = params[key];
    const paramConfig = pathConfig[key];

    parsed[key] = value === undefined ? undefined : paramConfig.get(value);
  }

  return parsed as ParsedPathParams<NonNullable<PC>>;
};

export const compileRoutesConfig = <T extends Record<string, AnyRouteConfg<string>>>(config: T) => {
  const routeNames = Object.keys(config) as Extract<keyof T, "string">[];
  const paths: Record<string, (params?: Record<string, unknown>, query?: Record<string, unknown>) => string> =
    {};

  const buildUrl = <R extends keyof T>(
    routeName: R,
    args: {
      params?: ParsedParams<NonNullable<T[R]["params"]>>;
      query?: Partial<ParsedParams<NonNullable<T[R]["query"]>>>;
    }
  ) => {
    const routeConfig = config[routeName];

    const serializedParams: Record<string, string> | undefined =
      routeConfig.params && args.params ? paramsToString(routeConfig.params, args.params) : undefined;
    const serializedQuery: Record<string, string | undefined> | undefined =
      routeConfig.query && args.query ? queryParamsToString(routeConfig.query, args.query) : undefined;
    const formattedPath = serializedParams
      ? generatePath(routeConfig.path, serializedParams)
      : routeConfig.path;

    if (serializedQuery) {
      const searchParams = upsertQueryParams(serializedQuery);

      return formUrl(formattedPath, searchParams);
    }

    return formattedPath;
  };

  for (const routeName of routeNames) {
    paths[routeName] =
      config[routeName].query && !config[routeName].params
        ? (query) =>
            buildUrl(routeName, {
              query: query as Partial<ParsedParams<NonNullable<T[typeof routeName]["query"]>>>,
            })
        : (...args) =>
            buildUrl(routeName, {
              params: args[0] as ParsedParams<NonNullable<T[typeof routeName]["params"]>>,
              query: args[1] as Partial<ParsedParams<NonNullable<T[typeof routeName]["query"]>>>,
            });
  }

  return paths as UrlBuilder<T>;
};

export const routesConfigHelper = <N extends string, T extends Record<string, AnyRouteConfg<N>>>(
  config: T
) => {
  return config;
};

export const withDefault = <T>(
  ps: QueryParamConverter<T>,
  defaultValue: T
): QueryParamConverter<T> & { defaultValue: T } => ({ ...ps, defaultValue });

const PARSE_ERROR_PREFIX = "ParseParamError";

export const paramsError = (message: string) => {
  return new TypeError(`${PARSE_ERROR_PREFIX}: ${message}`);
};

export const parseNumber = (val: string) => {
  const parsed = Number.parseInt(val);

  if (Number.isNaN(parsed)) {
    throw paramsError(`${val} is not a number.`);
  }

  return parsed;
};

export const parseEnum = <T extends SetValues>(enumValues: Set<T>, val: string) => {
  if (enumValues.has(val as T)) {
    return val as T;
  }

  const parsed = Number.parseInt(val);

  if (!Number.isNaN(parsed) && enumValues.has(parsed as T)) {
    return parsed as T;
  }

  throw paramsError(`${val} is not one of "[${[...enumValues].join(", ")}]".`);
};

export const NumCSV = {
  get: (val: string) => {
    return val.split(",").map(parseNumber);
  },
  set: (val: number[]) => val.join(","),
};
export const StrVal = {
  get: (val: string) => val,
  set: (val: string) => val,
  type: "string" as PathParamConverterType,
};
export const DateVal = {
  get: (val: string) => getLocalDate(val),
  set: (val: Date) => formatAsISODate(val),
  type: "date" as PathParamConverterType,
};
export const StrCSV = {
  get: (val: string) => {
    return val.split(",");
  },
  set: (val: string[]) => val.join(","),
};
export const NumVal = {
  get: (val: string) => parseNumber(val),
  set: (val: number) => `${val}`,
  type: "number" as PathParamConverterType,
};

// val will always be a string or "new", when creating a new resource representing that id param
export const NumberOrNew = {
  get: (val: string) => (val === "new" ? val : parseNumber(val)),
  set: (val: number | "new") => `${val}`,
  type: "new_or_number" as PathParamConverterType,
};
export const BoolVal = {
  get: (val: string) => val === "1",
  set: (val: boolean) => (val ? "1" : ""),
  defaultValue: false,
  type: "boolean" as PathParamConverterType,
};
export const NullableBoolVal = {
  get: (val: string) => val === "1",
  set: (val: boolean) => (val ? "1" : "0"),
  defaultValue: undefined,
  type: "boolean" as PathParamConverterType,
};

export const Enum = <T extends SetValues>() => ({
  Val: <Values extends T[]>(enumValues: HasAllValues<T, Values>) => ({
    get: (val: string) => parseEnum<T>(new Set(enumValues), val),
    set: (val: T) => val as string,
    type: "enum" as PathParamConverterType,
  }),
  CSV: <Values extends T[]>(enumValues: HasAllValues<T, Values>, emptyValue = "") => ({
    get: (val: string) => {
      if (val === emptyValue) {
        return [];
      }

      return val.split(",").map((enumVal) => parseEnum(new Set(enumValues), enumVal));
    },
    set: (val: T[]) => {
      const value = val.join(",");

      return value === "" ? emptyValue : value;
    },
  }),
});

export const isParamParseError = (error: unknown): error is TypeError => {
  return error instanceof TypeError && error.message.startsWith(PARSE_ERROR_PREFIX);
};
