import { AddressVO } from "@libs/api/generated-api";
import { formatAddress } from "@libs/utils/address";

export const ReadOnlyAddress: React.FC<{ addressDetails: AddressVO; className?: string }> = ({
  addressDetails,
  className,
}) => {
  const addressLines = formatAddress(addressDetails);

  return (
    <div className={className}>
      <p>{addressLines.lineOne}</p>
      <p>{addressLines.lineTwo}</p>
    </div>
  );
};
