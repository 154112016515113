import { format, fromUnixTime } from "date-fns";
import { useMemo } from "react";
import { InvoiceVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { LONG_LOCALIZED_DATE } from "@libs/utils/date";
import { semibold14, semibold16 } from "assets/styles/textSize";

export const InvoiceSummary: React.FC<{ invoice: InvoiceVO }> = ({ invoice }) => {
  const displayAmount: number = useMemo(() => {
    if (invoice.state === "PAID" || invoice.state === "FINALIZED") {
      return invoice.amount;
    }

    return invoice.amount - invoice.amountPaid;
  }, [invoice.state, invoice.amount, invoice.amountPaid]);

  return (
    <div className="flex items-center justify-between">
      <div className="flex flex-col gap-1">
        <div className={semibold14}>#{invoice.invoiceNumber}</div>
        <div className="text-xs">{format(fromUnixTime(invoice.createdAt), LONG_LOCALIZED_DATE)}</div>
      </div>
      <div className={semibold16}>{formatCurrency(displayAmount)}</div>
    </div>
  );
};
