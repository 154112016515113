import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Skeleton from "react-loading-skeleton";
import { enqueueSnackbar } from "notistack";
import { captureMessage } from "@sentry/react";
import { useQueryClient } from "@tanstack/react-query";
import { Navigate } from "react-router-dom";
import { PaymentProfileVO, PaymentVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useObjectState } from "@libs/hooks/useObjectState";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { TaskProgressHeader } from "@libs/components/UI/TaskProgressHeader";
import {
  getInvoicesDryRun,
  getPatientPayment,
  getPaymentProfiles,
  getPracticeBillingSetting,
} from "api/billing/queries";
import { createPatientInitiatedMultiInvoicePaymentRecord } from "api/billing/mutations";
import { useHandleError } from "api/handleErrorResponse";
import { PaymentDraftForm } from "components/Billing/ProcessPayment/PaymentDraftForm";
import { TitleBar } from "components/UI/TitleBar";
import { LoadingPaymentProfiles } from "components/UI/PaymentProfile";
import { PaymentCompleteScreen } from "components/Billing/PaymentCompleteScreen";
import { defaultSnackbarOptions } from "utils/snackbar";
import { invalidateInvoices } from "api/billing/cache";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "router/paths";
import { useCurrentPatient } from "contexts/PatientContext";
import { ConfirmPaymentForm } from "components/Billing/ConfirmPaymentForm";
import { PatientPaymentDraft } from "components/Billing/ProcessPayment/types";

const REFETCH_INTERVAL_WHILE_SAVING_MS = 5000;

const paymentDidFail = (
  paymentState?: PaymentVO["state"]
): paymentState is "VOID" | "DENIED" | "CHARGEBACK" => {
  return paymentState ? isOneOf(paymentState, ["VOID", "DENIED", "CHARGEBACK"]) : false;
};
const MAX_RETRY_COUNT = 10;
const usePollPaymentStatus = ({
  submittedPaymentQuery,
  isPollingPaymentStatus,
  handlePaymentSuccess,
  handlePaymentFailed,
}: {
  submittedPaymentQuery: ApiQueryResult<PaymentVO>;
  isPollingPaymentStatus: boolean;
  handlePaymentSuccess: (payment: PaymentVO) => void;
  handlePaymentFailed: (payment?: PaymentVO) => void;
}) => {
  const { t } = useTranslation();
  const paymentCompleted = useBoolean(false);
  const paymentCompletedOn = paymentCompleted.on;
  const retryCount = useRef(0);

  const submittedPayment = submittedPaymentQuery.data;

  useEffect(() => {
    if (isPollingPaymentStatus && submittedPayment) {
      if (retryCount.current > MAX_RETRY_COUNT) {
        enqueueSnackbar(t("processPayment.page.error.TIMEOUT"), defaultSnackbarOptions);
        captureMessage("Payment timed out");
        handlePaymentFailed();
        retryCount.current = 0;
      } else if (submittedPayment.state === "SETTLED") {
        paymentCompletedOn();
        handlePaymentSuccess(submittedPayment);
        retryCount.current = 0;
      } else if (paymentDidFail(submittedPayment.state)) {
        handlePaymentFailed(submittedPayment);
        retryCount.current = 0;
        enqueueSnackbar(t(`processPayment.page.error.${submittedPayment.state}`), defaultSnackbarOptions);
      } else {
        retryCount.current += 1;
      }
    }
  }, [
    handlePaymentFailed,
    handlePaymentSuccess,
    isPollingPaymentStatus,
    paymentCompletedOn,
    submittedPayment,
    t,
  ]);

  return useMemo(
    () => ({
      paymentCompleted: paymentCompleted.isOn,
      handlePaymentSuccess,
    }),
    [handlePaymentSuccess, paymentCompleted.isOn]
  );
};

export const ProcessPaymentRoute: React.FC = () => {
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const handleError = useHandleError();
  const { id: patientId, practiceId } = useAccount();
  const practice = useCurrentPractice();
  const patient = useCurrentPatient();
  const [paymentToFinalize, setPaymentToFinalize] = useState<
    | { cardSelected: PaymentProfileVO; amount: number; idempotencyUuid: string; invoiceUuids: string[] }
    | undefined
  >();
  const [paymentDraft, handleUpdatePaymentDraft] = useObjectState<PatientPaymentDraft>({
    paymentSize: "full",
    paymentUuid: null,
  });
  const [paymentUuidProcessing, setPaymentUuidProcessing] = useState<string | null>(null);
  const { query } = useQueryParams("pay");

  const invoiceUuids = query.invoiceUuids;
  const backUrl = query.from ?? paths.invoices();
  const [{ mutateAsync: createMultiInvoicePaymentRecordMutateAsync, isLoading: isSubmittingPayment }] =
    useApiMutations([createPatientInitiatedMultiInvoicePaymentRecord]);
  const isPollingPaymentStatus = Boolean(paymentUuidProcessing);
  const hasFetchedDryRun = useRef(false);

  const [invoicesQuery, paymentMethodsQuery, submittedPaymentQuery, billingSettingsQuery] = useApiQueries([
    getInvoicesDryRun({
      args: {
        practiceId,
        patientId,
        data: {
          invoiceUuids: query.invoiceUuids ?? paymentToFinalize?.invoiceUuids,
          payment: paymentToFinalize
            ? {
                type: "CHARGE",
                method: "STORED_PROFILE",
                currencyAmount: {
                  currency: "USD",
                  amount: paymentToFinalize.amount,
                },
                paymentProfileUuid: paymentToFinalize.cardSelected.uuid,
                idempotencyUuid: paymentToFinalize.idempotencyUuid,
              }
            : undefined,
        },
      },
      queryOptions: {
        // We only want this to fetch a single time during this mount
        refetchOnWindowFocus: false,
      },
    }),
    getPaymentProfiles({ args: { patientId, practiceId } }),
    getPatientPayment({
      args: { patientId, practiceId, paymentUuid: paymentUuidProcessing ?? "" },
      queryOptions: {
        enabled: Boolean(paymentUuidProcessing),
        refetchInterval: REFETCH_INTERVAL_WHILE_SAVING_MS,
      },
    }),
    getPracticeBillingSetting({ args: { practiceId } }),
  ]);

  useEffect(() => {
    if (invoicesQuery.isFetchedAfterMount && !hasFetchedDryRun.current) {
      hasFetchedDryRun.current = true;
      handleUpdatePaymentDraft({
        paymentAmount: invoicesQuery.data?.payment.currencyAmount.amount ?? 0,
      });
    }
  }, [
    handleUpdatePaymentDraft,
    invoicesQuery.data?.payment.currencyAmount.amount,
    invoicesQuery.isFetchedAfterMount,
  ]);

  const handleNextClicked = useCallback(
    ({ amount, paymentProfile }: { amount: number; paymentProfile: PaymentProfileVO }) => {
      setPaymentToFinalize({
        cardSelected: paymentProfile,
        amount,
        idempotencyUuid: crypto.randomUUID(),
        invoiceUuids: invoicesQuery.data?.invoices.map((invoice) => invoice.uuid) ?? [],
      });
    },
    [invoicesQuery.data?.invoices]
  );

  const handleSubmitPayment = useCallback(async () => {
    const idempotencyUuid = crypto.randomUUID();

    try {
      const paymentResult = await createMultiInvoicePaymentRecordMutateAsync({
        patientId,
        practiceId,
        data: {
          invoiceUuids: invoicesQuery.data?.invoices.map((invoice) => invoice.uuid) ?? [],
          commit: true,
          payment: {
            type: "CHARGE",
            method: "STORED_PROFILE",
            currencyAmount: {
              currency: "USD",
              amount: paymentToFinalize?.amount ?? 0,
            },
            idempotencyUuid,
            paymentProfileUuid: paymentToFinalize?.cardSelected.uuid,
          },
        },
      });

      setPaymentUuidProcessing(paymentResult.data.data.payment.uuid);
    } catch (e) {
      handleError(e);
    }
  }, [
    createMultiInvoicePaymentRecordMutateAsync,
    handleError,
    invoicesQuery.data?.invoices,
    patientId,
    paymentToFinalize?.amount,
    paymentToFinalize?.cardSelected.uuid,
    practiceId,
  ]);

  const handlePaymentSuccess = useCallback(() => {
    invalidateInvoices({
      queryClient,
      patientId,
      practiceId,
      invoiceUUids: invoicesQuery.data?.invoices.map((invoice) => invoice.uuid) ?? [],
    });
  }, [queryClient, patientId, practiceId, invoicesQuery.data?.invoices]);
  const handlePaymentFailed = useCallback(() => {
    setPaymentUuidProcessing(null);
  }, []);
  const { paymentCompleted } = usePollPaymentStatus({
    submittedPaymentQuery,
    isPollingPaymentStatus,
    handlePaymentSuccess,
    handlePaymentFailed,
  });

  const invoiceDetails = invoicesQuery.data;
  const hasNegativeBalance = invoiceDetails && invoiceDetails.payment.currencyAmount.amount < 0;

  // Patient portal doesn't process discounts, if they have negative balance, the practice handles it for now
  return hasNegativeBalance ? (
    <Navigate to={paths.invoices()} replace />
  ) : paymentCompleted ? (
    <PaymentCompleteScreen email={patient.contactDetails.email ?? ""} practice={practice}>
      <ButtonInternalLink theme="link" to={paths.invoices()}>
        {t("payments.page.backToInvoices")}
      </ButtonInternalLink>
    </PaymentCompleteScreen>
  ) : (
    <div className="relative flex flex-col h-full min-h-0 bg-white">
      <TitleBar backTo={backUrl} title={t("processPayment.page.title")} />

      <div className="h-full flex flex-col items-center pt-6 px-6 overflow-y-auto">
        <div className="flex flex-col gap-6 max-w-xl w-full">
          <TaskProgressHeader className="mx-0.5 mt-0.5" totalSteps={2} step={paymentToFinalize ? 1 : 0} />
          <QueryResult
            loading={
              <>
                <Skeleton containerClassName="w-full" count={5} />{" "}
                <div className="flex flex-col gap-2">
                  <LoadingPaymentProfiles />
                </div>
              </>
            }
            queries={[invoicesQuery, paymentMethodsQuery, billingSettingsQuery]}
          >
            {paymentToFinalize && invoicesQuery.data ? (
              <ConfirmPaymentForm
                paymentProfile={paymentToFinalize.cardSelected}
                isSubmittingPayment={isPollingPaymentStatus || isSubmittingPayment}
                onEditPaymentMethod={() => {
                  setPaymentToFinalize(undefined);
                }}
                onSubmitFormPayment={handleSubmitPayment}
                totalChargeAmount={invoicesQuery.data.payment.totalPaymentAmount}
                surchargeAmount={invoicesQuery.data.payment.surchargeAmount}
                subtotal={invoicesQuery.data.payment.currencyAmount.amount}
              />
            ) : (
              billingSettingsQuery.data && (
                <PaymentDraftForm
                  onSubmit={handleNextClicked}
                  invoiceUuids={invoiceUuids}
                  onChangePaymentDraft={handleUpdatePaymentDraft}
                  paymentDraft={paymentDraft}
                  invoicesQuery={invoicesQuery}
                  paymentMethodsQuery={paymentMethodsQuery}
                  onboardedWithPaymentProvider={practice.onboardedWithPaymentProvider}
                  billingSettings={billingSettingsQuery.data}
                />
              )
            )}
          </QueryResult>
        </div>
      </div>
    </div>
  );
};
