import React, { useCallback, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ReactComponent as BackIcon } from "@libs/assets/icons/left-caret.svg";
import { ReactComponent as SettingsIcon } from "@libs/assets/icons/settings.svg";
import { ReactComponent as CreditCardsMultipleIcon } from "@libs/assets/icons/credit-cards-multiple.svg";
import { ReactComponent as InfoExclamationIcon } from "@libs/assets/icons/info.svg";
import { ReactComponent as SignOutIcon } from "@libs/assets/icons/sign-out.svg";
import { ReactComponent as InsuranceIcon } from "@libs/assets/icons/shield.svg";
import { ReactComponent as MedicalFileIcon } from "@libs/assets/icons/medical-file.svg";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { formatDataUrl } from "@libs/utils/formatDataUrl";
import { useAccount } from "@libs/contexts/AccountContext";
import { useHandleError } from "api/handleErrorResponse";
import { uploadProfileImage, deleteProfileImage } from "api/user/mutations";

import { CameraComponent } from "deprecated/camera-component";
import { getProfileImageQuery } from "api/patient/queries";
import { EditProfileImageModal } from "components/Account/EditProfileImageModal";
import { AccountLinkItem } from "components/Account/AccountOptionItems";
import { paths, routesConfig } from "router/paths";
import { useCurrentPatient } from "contexts/PatientContext";

const Divider = () => <div className="h-px w-full bg-slate-300" />;

const useIsIndexAccountPage = () => {
  const location = useLocation();

  return location.pathname === routesConfig.account.path;
};

export const AccountNavigationRoute: React.FC = () => {
  const { t } = useTranslation();
  const [imageEditing, setImageEditing] = useState<File | undefined>();
  const isAccountIndexPage = useIsIndexAccountPage();

  const { practiceId, id: userId, email: accountEmail } = useAccount();
  const patient = useCurrentPatient();
  const [profileImageQuery] = useApiQueries([getProfileImageQuery({ args: { practiceId, userId } })]);
  const { personalDetails, contactDetails } = patient;
  const email = contactDetails.email;
  const patientName = personalDetails.firstName;
  const [uploadProfileImageMutation, deleteProfileImageMutation] = useApiMutations([
    uploadProfileImage,
    deleteProfileImage,
  ]);

  const handleError = useHandleError();
  const handleDeleteEditingImage = useCallback(() => {
    setImageEditing(undefined);
  }, []);
  const handleImageUpload = useCallback(
    (resizedImage: File) => {
      uploadProfileImageMutation.mutate(
        { practiceId, userId, image: resizedImage },
        {
          onSuccess: () => {
            setImageEditing(undefined);
          },
          onError: handleError,
        }
      );
    },
    [handleError, userId, practiceId, uploadProfileImageMutation]
  );
  const handleImageSelected = useCallback((image: File) => {
    setImageEditing(image);
  }, []);
  const onDeleteProfilePicture = () => {
    deleteProfileImageMutation.mutate({ practiceId, userId }, { onError: handleError });
  };

  const hideOnMobile = cx(!isAccountIndexPage && "hidden sm:block");

  return (
    <>
      {imageEditing && (
        <EditProfileImageModal
          onClose={handleDeleteEditingImage}
          onDone={handleImageUpload}
          initialImage={imageEditing}
          isSaving={uploadProfileImageMutation.isLoading}
        />
      )}
      <div className="h-full flex">
        <div className={cx("flex flex-col w-full sm:w-[18rem]", hideOnMobile)}>
          <div className="min-h-[120]">
            <div className="flex items-center min-h-[104px] w-full flex-row pl-4 pt-4">
              <CameraComponent
                showDelete
                setImage={handleImageSelected}
                profilePicture={formatDataUrl(profileImageQuery.data?.thumbnail)}
                deleteProfilePicture={onDeleteProfilePicture}
                patientName={patientName}
                patientEmail={email}
                showName
              />
            </div>
          </div>
          <div className="flex flex-col pt-2 px-3 gap-1">
            <AccountLinkItem to={paths.home()} SvgIcon={BackIcon} className="hidden sm:flex">
              {t("Go Back")}
            </AccountLinkItem>
            <Divider />

            <AccountLinkItem to={paths.profile({ form: "personal" })} SvgIcon={UserIcon}>
              {t("app.page.labels.account.myprofile")}
            </AccountLinkItem>

            <AccountLinkItem to={paths.medicalHistory()} SvgIcon={MedicalFileIcon}>
              {t("Medical Information")}
            </AccountLinkItem>

            <AccountLinkItem to={paths.insurances()} SvgIcon={InsuranceIcon}>
              {t("app.page.labels.account.myInsurances")}
            </AccountLinkItem>

            <AccountLinkItem to={paths.paymentMethods()} SvgIcon={CreditCardsMultipleIcon}>
              {t("Payment Methods")}
            </AccountLinkItem>
            <Divider />
            {accountEmail === contactDetails.email ? (
              <AccountLinkItem to={paths.changePassword()} SvgIcon={SettingsIcon}>
                {t("Change Password")}
              </AccountLinkItem>
            ) : null}
            <AccountLinkItem SvgIcon={SignOutIcon} to={paths.signOut()}>
              {t("app.page.labels.account.signOut")}
            </AccountLinkItem>
            <Divider />
            <AccountLinkItem external to="https://www.archy.com" SvgIcon={InfoExclamationIcon}>
              {t("app.page.labels.account.about")}
            </AccountLinkItem>
          </div>
        </div>
        <div
          className={`
            h-full
            flex
            flex-col
            min-h-0
            flex-1
            border-l
            border-greyLightest
          `}
        >
          <Outlet />
        </div>
      </div>
    </>
  );
};
