import { OptionsObject } from "notistack";

export const defaultSnackbarOptions: OptionsObject = {
  style: {
    top: "40px",
  },

  anchorOrigin: {
    vertical: "top",
    horizontal: "right",
  },
  autoHideDuration: 3000,
  preventDuplicate: true,
};
