import { format } from "date-fns";
import { getLocalDate } from "@libs/utils/date";

export const selfBookingCxStyles = {
  mainStepContainer: "flex flex-col w-full h-full",
  container: "text-xs md:w-[672px] m-auto w-full h-full",
  button: `
    aspect-square
    rounded
    bg-white
    border-greyLighter
    border
    h-10
    enabled:hover:bg-greyLightest
    enabled:focus:shadow-focusedPrimary
    enabled:focus:outline-none
    enabled:active:bg-whiteLight
    disabled:text-greyLight
    disabled:border-greyLightes
  `,
  continueButton: "w-full md:w-32",
};

export const formatISODateAsDayDate = (isoDate?: string) =>
  isoDate ? format(getLocalDate(isoDate), "EEE d") : "";
