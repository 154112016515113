import { forwardRef } from "react";
import { cx } from "@libs/utils/cx";

export type ChipProps = {
  className?: string;
  isSelected?: boolean;
  size?: keyof typeof cxStyles.sizes;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const cxStyles = {
  sizes: {
    sm: "text-xs/4 py-1 px-2 h-6",
    md: "text-xs/4 py-2 px-3 h-8",
    lg: "text-sm/4 p-3 h-10",
  },
};

/**
 * Similar to a Pill, but is more of a button.
 */
export const Chip = forwardRef<HTMLButtonElement, ChipProps>(
  ({ className, size = "sm", isSelected = false, ...props }, ref) => {
    return (
      <button
        type="button"
        ref={ref}
        className={cx(
          `flex
           flex-row
           items-center
           justify-center
           rounded-full
           ring-1
           whitespace-nowrap
           enabled:focus:shadow-focusedPrimary
           enabled:focus:outline-none
           enabled:hover:bg-greyLightest
           enabled:active:bg-whiteLight
           disabled:text-greyLight
           disabled:ring-greyLightest`,
          className,
          isSelected
            ? "bg-archyBlue-50 ring-primaryTheme disabled:bg-greyLightest "
            : "bg-white ring-greyLighter disabled:bg-greyLightest",
          cxStyles.sizes[size]
        )}
        {...props}
      />
    );
  }
);
