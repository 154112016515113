import React from "react";
import { formatDataUrl } from "@libs/utils/formatDataUrl";
import { ReactComponent as Placeholder32 } from "assets/icons/Placeholder_32x32.svg";

export const UserAvatar: React.FC<{ thumbnail?: string }> = ({ thumbnail }) => {
  return thumbnail ? (
    <img src={formatDataUrl(thumbnail)} alt="Profile" className="rounded-full w-full h-full" />
  ) : (
    <Placeholder32 className="rounded-full w-full h-full" />
  );
};
