import { Link, useLocation } from "react-router-dom";
import { format } from "date-fns";
import { AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { getFullUrl } from "@libs/utils/location";
import { formatISOTimeRangeAsAmPmShort, getLocalDate } from "@libs/utils/date";
import { normal14, semibold14, semibold16 } from "assets/styles/textSize";
import { InternalLink } from "components/UI/Link";
import { paths } from "router/paths";

const cxStyles = {
  card: ({ inCarousel }: { inCarousel?: boolean }) =>
    cx(
      `border
       border-slate-300
       p-3
       rounded-md
       mb-2
       bg-white
       min-w-72`,
      inCarousel ? "last:mr-4 ml-4 md:mr-4" : "mx-4"
    ),
};

export const CardLink: React.FC<{
  children: React.ReactNode;
  className?: string;
  to: string;
  inCarousel?: boolean;
}> = ({ children, className, to, inCarousel }) => {
  return (
    <Link className={cx(cxStyles.card({ inCarousel }), className)} to={to}>
      {children}
    </Link>
  );
};

export const Card: React.FC<{
  children: React.ReactNode;
  className?: string;
  inCarousel?: boolean;
}> = ({ children, className, inCarousel }) => {
  return <div className={cx(cxStyles.card({ inCarousel }), className)}>{children}</div>;
};

export const AppointmentCard: React.FC<{ appointment: AppointmentVO; inCarousel?: boolean }> = ({
  appointment,
  inCarousel,
}) => {
  const location = useLocation();

  return (
    <CardLink
      key={appointment.id}
      className="flex flex-col gap-1 hover:border-secondaryTheme"
      inCarousel={inCarousel}
      to={paths.appointmentDetails(
        { appointmentId: appointment.id },
        { from: getFullUrl(location), fullScreen: true }
      )}
    >
      <div>
        <span className={semibold14}>
          {format(getLocalDate(appointment.date), "MMMM d, yyyy")}&nbsp;-&nbsp;
        </span>
        <span className={normal14}>
          {formatISOTimeRangeAsAmPmShort(appointment.startTime, appointment.endTime)}
        </span>
      </div>
      <div className={normal14}>{appointment.provider.name.shortDisplayName}</div>
    </CardLink>
  );
};

export const NoticeCard: React.FC<{
  title: string;
  body: string;
  linkText: string;
  linkTo: string;
  className?: string;
}> = ({ title, body, linkText, linkTo, className }) => {
  return (
    <Card className={cx("flex flex-col gap-2", className)}>
      <div className={semibold16}>{title}</div>
      <div className={normal14}>{body}</div>
      <InternalLink className={semibold14} to={linkTo}>
        {linkText}
      </InternalLink>
    </Card>
  );
};
