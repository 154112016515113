import React from "react";
import { View } from "@react-pdf/renderer";
import { PatientFormTasksResponse, PracticeInfoVO } from "@libs/api/generated-api";
import { PDFDocument } from "components/PatientForms/FormPDFElements/PDFDocument";
import { FormTaskPDFWithEmptyContent } from "components/PatientForms/PatientFormTasks/FormTaskPDFContent";

type Props = {
  formTasksResponse: PatientFormTasksResponse;
  practice: PracticeInfoVO;
  title: string;
  logo?: {
    w: number;
    h: number;
    url: string;
  };
};

export const PatientFormTasksPDF: React.FC<Props> = ({ formTasksResponse, practice, logo, title }) => {
  return (
    <PDFDocument title={title} practice={practice}>
      {formTasksResponse.tasks.map((item, i) => {
        return (
          <React.Fragment key={item.uuid}>
            <FormTaskPDFWithEmptyContent
              practice={practice}
              patient={formTasksResponse}
              task={item}
              dob={formTasksResponse.dob}
              logo={logo}
            />
            {i < formTasksResponse.tasks.length - 1 ? <View break /> : null}
          </React.Fragment>
        );
      })}
    </PDFDocument>
  );
};
