import { phoneNumberFormatter } from "@libs/utils/phone";
import { ReactComponent as CallIcon } from "@libs/assets/icons/call.svg";
import { ReactComponent as EmailIcon } from "@libs/assets/icons/email.svg";
import { ExternalLink } from "components/UI/Link";

const cxStyles = {
  contactItem: "flex flex-row gap-2 items-center",
};

export const CallPractice: React.FC<{ phoneNumber?: string }> = ({ phoneNumber }) => {
  return phoneNumber ? (
    <div className={cxStyles.contactItem}>
      <CallIcon className="text-primaryTheme" />
      <ExternalLink to={`tel:+1${phoneNumber}`}>{phoneNumberFormatter(phoneNumber)}</ExternalLink>
    </div>
  ) : null;
};

export const EmailPractice: React.FC<{ email?: string }> = ({ email }) => {
  return email ? (
    <div className={cxStyles.contactItem}>
      <EmailIcon className="text-primaryTheme" />
      <ExternalLink to={`mailto:${email}`}>{email}</ExternalLink>
    </div>
  ) : null;
};
