import { FC } from "react";
import { FamilyRelationVO } from "@libs/api/generated-api";
import { PatientRelationship } from "@libs/hooks/useRelations";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";

export const SelectRelationship: FC<{
  patientRelations: PatientRelationship[];
  onItemSelected: (value: FamilyRelationVO["relation"]) => void;
  value?: FamilyRelationVO["relation"];
  error?: string;
  id: string;
  className?: string;
}> = ({ patientRelations, onItemSelected, error, value, className, id }) => (
  <FormFieldSelect
    required
    error={error}
    id={id}
    label="Relationship with Primary Subscriber"
    placeholder="Select"
    options={patientRelations}
    isSearchable={false}
    isClearable={false}
    onItemSelected={onItemSelected}
    value={value}
    className={className}
  />
);
