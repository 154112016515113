import { FC, useEffect, useMemo } from "react";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { isDefined } from "@libs/utils/types";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { postAuthChannelMessage } from "hooks/useAuthChannelListeners";
import { getPostSignOutStorage } from "storage/postSignOut";
import { paths } from "router/paths";

export const PostSignOutRoute: FC = () => {
  const { localStorage } = useStorageContext();
  const postSignOutStorage = useMemo(() => getPostSignOutStorage(localStorage), [localStorage]);

  useEffect(() => {
    const params = postSignOutStorage.getParams();

    postAuthChannelMessage({
      type: "signOut",
      reason: params?.signOutReason,
      addReturnUrl: isDefined(params?.returnUrl),
    });

    window.location.assign(
      params?.to ??
        paths.signIn({
          signOutReason: params?.signOutReason,
          // A return url can only be added if we have the current userId.
          // This allows us to check the next person signing in matches the last
          // preventing users from signing in to multiple accounts on the same
          // machine and returning to a url that doesn't
          // apply to their current account.
          ...(params?.returnUrl && params.lastUserId
            ? { returnUrl: params.returnUrl, lastUserId: params.lastUserId }
            : undefined),
        })
    );

    window.location.assign(params?.to ?? paths.signIn({ returnUrl: params?.returnUrl }));
  }, [postSignOutStorage]);

  return <LoadingOverlaySpinner centerVertically loadingText="Signing Out" />;
};
