import { makeQuery } from "@libs/utils/queries";
import { noCache } from "@libs/utils/queryCache";

export const getPatientAppointmentQuery = makeQuery({
  queryKey: ["practices", "getPatientAppointment"],
  formatParams: (args: { practiceId: number; appointmentId: number; patientId: number }) => [
    args.practiceId,
    args.patientId,
    args.appointmentId,
  ],
  queryOptions: noCache,
});

export const viewAppointmentConfirmationDetails = makeQuery({
  queryKey: ["public", "viewAppointmentConfirmationDetails"],
  formatParams: (args: { practiceUuid: string; confirmToken: string }) => [
    args.practiceUuid,
    { payload: args.confirmToken },
  ],
  queryOptions: noCache,
});
