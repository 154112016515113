import React from "react";
import { captureMessage, ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import {
  PatientFormLexicalNode,
  SerializedRootNode,
} from "components/PatientForms/FormPDFElements/PatientFormLexicalNode";

export const PatientFormLexicalRootNode: React.FC<{
  lexicalJson: string;
}> = ({ lexicalJson }) => {
  const json = React.useMemo(
    () => (lexicalJson ? (JSON.parse(lexicalJson) as { root: SerializedRootNode }) : undefined),
    [lexicalJson]
  );

  if (!json) {
    return null;
  }

  return (
    <SentryErrorBoundary
      fallback={<Text bold>[Error printing text block to PDF]</Text>}
      onError={() => {
        captureMessage("Failed to render Lexical Node", {
          extra: {
            lexicalJson,
          },
        });
      }}
    >
      {json.root.children.map((currentNode, i) => (
        <PatientFormLexicalNode key={i} node={currentNode} />
      ))}
    </SentryErrorBoundary>
  );
};
