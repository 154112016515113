import { UseQueryResult, useQueries } from "@tanstack/react-query";
import { useContext } from "react";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { GetQueryOptions, UseApiQueries } from "@libs/@types/apiQueries";
import { ApiResponse } from "@libs/@types/api";

const unwrapQuery = (result: UseQueryResult<ApiResponse<unknown>, unknown>) => {
  const response = result.data;
  const gfData = response?.data;
  const actualData = gfData?.data;

  // HACK: Make react-query v4 behave like react-query v3 when it comes to
  // enabled/isLoading. Revert this PR when we upgrade to react-query v5:
  // https://github.com/grindfoundryinc/grindfoundry-ui/pull/1831. More info is
  // included in the PR description and comments.
  const isLoadingV3 = result.isLoading && result.fetchStatus !== "idle";

  return {
    ...result,
    data: actualData,
    apiResponse: response,
    isLoading: isLoadingV3,
  };
};

export const useApiQueries = <T extends GetQueryOptions[]>(configs: [...T]) => {
  const { httpClient } = useContext(ApiClientContext);
  const mapped = configs.map((config) => config({ api: httpClient }));
  const queries = useQueries({ queries: mapped });

  return queries.map((item) => unwrapQuery(item)) as unknown as UseApiQueries<T>;
};
