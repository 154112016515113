import { Path, Svg } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";

type Props = {
  fill: string;
  style?: Style;
  width: number;
};
export const ArchyPdfLogo: React.FC<Props> = ({ fill, width, style }) => {
  const aspect = 660 / 254;
  const height = width / aspect;

  return (
    <Svg width="660" height="254" viewBox="0 0 660 254" style={{ ...style, width, height }}>
      <Path
        d="M212.932 177.231H180.861V104.932C180.861 73.2021 206.742 47.3881 238.555 47.3881H248.092V79.3755H238.555C224.423 79.3755 212.932 90.8372 212.932 104.932V177.231Z"
        fill={fill}
      />
      <Path
        d="M455.466 54.5983C444.078 54.5983 433.4 57.7413 424.15 63.2191V0H392.023V177.231H424.15V120.467C424.15 101.822 438.204 86.6457 455.466 86.6457C472.728 86.6457 486.783 101.822 486.783 120.467V177.231H518.91V120.467C518.91 84.1537 490.451 54.6096 455.466 54.6096V54.5983Z"
        fill={fill}
      />
      <Path
        d="M627.873 63.5V113.439C627.873 132.788 613.818 148.526 596.557 148.526C579.295 148.526 565.24 132.788 565.24 113.439V63.5H533.113V113.439C533.113 150.444 561.572 180.55 596.557 180.55C607.944 180.55 618.623 177.342 627.873 171.767V188.189C627.873 206.821 613.818 221.986 596.557 221.986C579.295 221.986 565.24 206.832 565.24 188.189H533.113C533.113 224.477 561.572 254 596.557 254C631.542 254 660 224.477 660 188.189V63.5H627.873Z"
        fill={fill}
      />
      <Path
        d="M315.379 147.883C297.072 147.883 282.169 132.34 282.169 113.246C282.169 94.1524 297.072 78.609 315.379 78.609C329.684 78.609 341.915 88.0996 346.571 101.366H379.713C374.212 70.178 347.462 46.4403 315.379 46.4403C279.317 46.4403 249.986 76.4111 249.986 113.257C249.986 150.104 279.317 180.075 315.379 180.075C347.462 180.075 374.212 156.337 379.713 125.149H346.571C341.915 138.415 329.684 147.906 315.379 147.906V147.883Z"
        fill={fill}
      />
      <Path
        d="M32.1705 166.345C32.1705 139.26 54.2673 117.229 81.4347 117.229C108.602 117.229 130.699 139.26 130.699 166.345V177.231H162.869V81.1786C162.869 67.7537 159.512 54.4299 153.145 42.6789C138.88 16.3571 111.408 0 81.4235 0C79.9023 0 78.3698 0.0449369 76.8486 0.123577C49.3656 1.65143 24.7223 16.7727 10.9075 40.5781C9.97229 42.1846 9.08211 43.8472 8.25953 45.5211C2.78323 56.688 0 68.6861 0 81.1786V177.231H32.1705V166.345ZM32.1705 81.1786C32.1705 73.6067 33.8494 66.3606 37.151 59.6201C37.6468 58.609 38.1877 57.5979 38.7511 56.6318C47.112 42.2183 62.031 33.0624 78.6402 32.1412C79.5642 32.085 80.5107 32.0625 81.4347 32.0625C99.5764 32.0625 116.197 41.9599 124.828 57.9013C128.671 65.0013 130.699 73.045 130.699 81.1786V101.76C117.008 91.3568 99.937 85.1668 81.4347 85.1668C62.9325 85.1668 45.8613 91.3568 32.1705 101.76V81.1786Z"
        fill={fill}
      />
    </Svg>
  );
};
