import { captureException } from "@sentry/react";
import { useEffect } from "react";
import { useRouteError } from "react-router-dom";
import { ErrorContent } from "@libs/components/UI/ErrorContent";

export const SentryRouteErrorFallback = () => {
  const routeError = useRouteError();

  useEffect(() => {
    if (routeError) {
      captureException(routeError, { level: "fatal" });
    }
  }, [routeError]);

  return <ErrorContent />;
};
