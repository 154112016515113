import React from "react";

import { Box } from "components/PatientForms/FormPDFElements/Box";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { Spacer } from "components/PatientForms/FormPDFElements/Spacer";

type Props = {
  title: string;
  children: React.ReactNode;
};

export const PatientInfoFormSection: React.FC<Props> = ({ title, children }) => {
  return (
    <Box style={{ gap: 4 }}>
      <Text bold size="lg">
        {title}
      </Text>
      <Spacer size="md" />
      {children}
    </Box>
  );
};
