import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { ReactComponent as ArchyLogoSmall } from "@libs/assets/components/logo-archy-with-name.svg";
import { normal14, normal10 } from "assets/styles/textSize";

type Props = {
  className?: string;
  textClassName?: string;
  size?: "lg" | "sm";
};

export const PoweredBy: React.FC<Props> = ({ textClassName, className, size = "lg" }) => {
  const { t } = useTranslation();

  return (
    <div className={cx(size === "lg" ? normal14 : normal10, className)}>
      <span className={cx("align-bottom", textClassName)}>{t("app.page.labels.placeholder.poweredBy")}</span>
      <ArchyLogoSmall
        className={cx(
          "inline ml-[1px] text-archyBlue-500 align-bottom",
          size === "lg" ? "h-4 w-12" : "h-3.5 w-10"
        )}
      />
    </div>
  );
};
