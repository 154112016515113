export const tConvert = (time: string) => {
  // Check correct time format and split into components
  const matches = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

  if (matches.length > 1) {
    // If time format correct

    matches[4] = Number(matches[1]) < 12 ? " AM" : " PM"; // Set AM/PM
    matches[1] = `${Number(matches[1]) % 12}` || "12"; // Adjust hours
  }

  return matches.slice(1).join(""); // return adjusted time or original string
};
