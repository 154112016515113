/* eslint-disable @typescript-eslint/naming-convention */
import { useFinixForm } from "@libs/hooks/useFinixForm";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { env } from "env";

export const usePatientFinixForm = (params: { formSubmissionHandler: FinixFormSubmissionCallback }) => {
  const { t } = useTranslation();

  const options: Partial<TokenFormOptions> = useMemo(
    () => ({
      placeholders: {
        name: t("card.details.name.placeholder"),
        number: t("card.details.number.placeholder"),
        expiration_date: t("card.details.date.placeholder"),
        security_code: t("card.details.security_code.placeholder"),
        address_postal_code: t("card.details.zip.placeholder"),
      },
      labels: {
        name: t("card.details.name"),
        number: t("card.details.number"),
        expiration_date: t("card.details.date"),
        security_code: t("card.details.security_code"),
        address_postal_code: t("card.details.zip"),
      },
    }),
    [t]
  );

  return useFinixForm({
    finixEnvironment: env.REACT_APP_FINIX_ENVIRONMENT,
    applicationId: env.REACT_APP_FINIX_APPLICATION_ID,
    ...params,
    options,
  });
};
export type UsePatientFinixForm = ReturnType<typeof usePatientFinixForm>;
