import React, { useMemo } from "react";

import { Trans, useTranslation } from "react-i18next";
import {
  ContactDetailsVO,
  FormPatientInfoElementVO,
  GeneralInfoVO,
  OnboardingOptionsVO,
  PatientSummaryVO,
  PersonalDetailsVO,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useValidation } from "@libs/hooks/useValidation";
import { Form } from "@libs/components/UI/Form";
import { semibold16 } from "assets/styles/textSize";
import { PersonalDetailFields } from "components/Account/PersonalDetailFields";
import { ContactDetailFields } from "components/Account/ContactDetailFields";
import { getAccountValidationSchema } from "components/Account/validation";

type Props = {
  onSubmit: (generalInfo: GeneralInfoVO) => void;
  id: string;
  info: GeneralInfoVO;
  optionalFields: FormPatientInfoElementVO["optionalFields"];
  children?: React.ReactNode;
  canEditEmail: boolean;
  onboardingOptions: OnboardingOptionsVO;
  patient: PatientSummaryVO;
};

export const GeneralInformationForm: React.FC<Props> = ({
  onSubmit,
  id,
  info,
  children,
  canEditEmail,
  patient,
  optionalFields,
  onboardingOptions,
}) => {
  const { t } = useTranslation();
  const { id: patientId } = useAccount();

  const practice = useCurrentPractice();

  const [userInfo, updateUserInfo] = useObjectState(info);
  const { personalDetails, contactDetails, referredBy } = userInfo;

  const handlePersonalDetailsUpdated = React.useCallback(
    (partial: Partial<PersonalDetailsVO>) => {
      updateUserInfo({ personalDetails: { ...personalDetails, ...partial } });
    },
    [personalDetails, updateUserInfo]
  );
  const handleReferredByUpdated = React.useCallback(
    (value: string | undefined) => {
      updateUserInfo({ referredBy: value });
    },
    [updateUserInfo]
  );
  const handleContactDetailsUpdated = React.useCallback(
    (partial: Partial<ContactDetailsVO>) => {
      updateUserInfo({ contactDetails: { ...contactDetails, ...partial } });
    },
    [contactDetails, updateUserInfo]
  );
  const schema = useMemo(() => {
    return getAccountValidationSchema({
      contact: patient.contact,
      translate: t,
      contactDetails,
    });
  }, [contactDetails, patient.contact, t]);

  const { validate, result } = useValidation(userInfo, schema);
  const handleSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    (e) => {
      e.preventDefault();

      if (validate().$isValid) {
        onSubmit(userInfo);
      }
    },
    [onSubmit, userInfo, validate]
  );

  return (
    <>
      {children}
      <Form id={id} className="flex flex-col gap-4" onSubmit={handleSubmit}>
        <PersonalDetailFields
          edit
          onboardingOptions={onboardingOptions}
          onPersonalDetailsUpdated={handlePersonalDetailsUpdated}
          onReferredByUpdated={handleReferredByUpdated}
          personalDetails={personalDetails}
          patientId={patientId}
          referredBy={referredBy}
          optionalFields={optionalFields}
          validation={result.personalDetails}
        />
        <div className={cx("text-secondaryTheme", semibold16)}>{t("Contact Details")}</div>
        <ContactDetailFields
          contact={patient.contact}
          canEditEmail={canEditEmail}
          contactDetails={contactDetails}
          onContactDetailsUpdated={handleContactDetailsUpdated}
          validation={result.contactDetails}
          edit
        />
        <div className="text-xs text-grey-700">
          <Trans
            i18nKey="app.registration.sms.disclaimer"
            values={{ practiceName: practice.name }}
            tOptions={{ interpolation: { escapeValue: true } }}
            shouldUnescape
          />
        </div>
      </Form>
    </>
  );
};
