import { required, min, ssn } from "@libs/utils/validators";
import { ValidationResult } from "@libs/hooks/useValidation";
import { InsuranceDraftFields } from "components/PatientInsurances/hooks/useInsuranceDraft";

export type SubscriberInfo = {
  idType?: "ssn" | "subscriberId";
  subscriberId?: string;
  ssn?: string;
  carrierId?: number;
  employer?: string;
};

const fieldValidators = {
  firstName: [
    {
      $v: required,
      $error: "First name is required",
    },
  ],
  lastName: [
    {
      $v: required,
      $error: "Last name is required",
    },
  ],
  dob: [
    {
      $v: required,
      $error: "Date of birth is required",
    },
  ],
  carrierId: [
    {
      $v: required,
      $error: "Please select an insurance carrier",
    },
    {
      $v: min(1),
      $error: "Please select an insurance carrier",
    },
  ],
  ssn: [
    {
      $v: required,
      $error: "Please enter a social security number",
    },
    {
      $v: ssn,
      $error: "Please enter a valid social security number",
    },
  ],
  subscriberId: [
    {
      $v: required,
      $error: "Please enter a subscriber ID",
    },
  ],
  relationship: [
    {
      $v: required,
      $error: "Please select a relationship",
    },
  ],
};

const subscriberInfoSchema = (subscriberInfo: SubscriberInfo) => ({
  // employer: fieldValidators.employer,
  carrierId: fieldValidators.carrierId,
  ssn: {
    $ignore: subscriberInfo.idType !== "ssn",
    $validations: fieldValidators.ssn,
  },
  subscriberId: {
    $ignore: subscriberInfo.idType !== "subscriberId",
    $validations: fieldValidators.subscriberId,
  },
});
const getPrimarySubscriberSchema = (draftInsurance: InsuranceDraftFields) => {
  return {
    $ignore: draftInsurance.type !== "PRIMARY_SUBSCRIBER",
    ...subscriberInfoSchema(draftInsurance.primarySubscriber),
  };
};

const getDependentSubscriberSchema = (draftInsurance: InsuranceDraftFields) => {
  return {
    $ignore: draftInsurance.type !== "DEPENDENT",
    firstName: fieldValidators.firstName,
    lastName: fieldValidators.lastName,
    relation: fieldValidators.relationship,
    dob: fieldValidators.dob,
    ...subscriberInfoSchema(draftInsurance.dependentSubscriber),
  };
};

export const getDraftPatientInsuranceFormSchema = (draftInsurance: InsuranceDraftFields) => {
  return {
    primarySubscriber: getPrimarySubscriberSchema(draftInsurance),
    dependentSubscriber: getDependentSubscriberSchema(draftInsurance),
  };
};

export type CreateInsuranceValidation = ValidationResult<
  InsuranceDraftFields,
  ReturnType<typeof getDraftPatientInsuranceFormSchema>
>;
