import { PracticeInfoVO } from "@libs/api/generated-api";
import { fetchImageAsBase64, getHeightAndWidthFromDataUrl } from "@libs/utils/dataUrl";

export const getPracticeLogoWithDimensions = async (practice: PracticeInfoVO) => {
  const logoUrl = practice.logo?.url;

  if (practice.logo?.thumbnail && logoUrl) {
    const dataUrl = await fetchImageAsBase64(logoUrl);
    const { height, width } = await getHeightAndWidthFromDataUrl(dataUrl);

    return {
      url: logoUrl,
      w: width,
      h: height,
    };
  }

  return undefined;
};
