import React from "react";
import { SingleValue } from "react-select";
import { PersonalDetailsVO } from "@libs/api/generated-api";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";

type Gender = NonNullable<PersonalDetailsVO["gender"]>;
type GenderOption = Omit<SelectOption<Gender>, "label"> & { label: string };

export type BaseFormFieldSelectGenderProps = FormFieldProps & {
  value: PersonalDetailsVO["gender"];
  placeholder?: string;
  options: GenderOption[];
  menuPlacement?: "auto" | "top" | "bottom";
  onChange: (option: SingleValue<GenderOption>) => void;
};

export const BaseFormFieldSelectGender: React.FC<BaseFormFieldSelectGenderProps> = ({
  label,
  value,
  edit = true,
  onChange,
  menuPlacement = "auto",
  placeholder,
  options,
  required,
  error,
}) => {
  return (
    <FormFieldSelect
      label={label}
      display="label"
      edit={edit}
      required={required}
      value={value}
      error={error}
      menuPlacement={menuPlacement}
      placeholder={placeholder}
      options={options}
      onChange={onChange}
    />
  );
};
