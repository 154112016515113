import { produce } from "immer";
import {
  BatchFormResponseRequest,
  BatchPrepareFileUploadRequest,
  PatientFormTasksResponse,
  PatientSubmitFormTaskRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData } from "@libs/utils/queryCache";
import { UUIDOrSlug } from "api/forms/queries";
import { formatPatientAuthorization } from "api/forms/formatPatientAuthToken";

export const batchPrepareFileUpload = makeMutation({
  mutationKey: ["practices", "batchPrepareFileUpload"],
  formatParams: (args: { practiceId: number; data: BatchPrepareFileUploadRequest }) => [
    args.practiceId,
    args.data,
  ],
});

export const submitFormResponses = makeMutation({
  mutationKey: ["practices", "submitFormResponses"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    uuidOrSlug: UUIDOrSlug;
    data: BatchFormResponseRequest;
  }) => [args.practiceId, args.patientId, args.uuidOrSlug, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: async (_, { patientId, practiceId, uuidOrSlug }) => {
      // Updating form updates patient onboarding to next step

      //Invalidate first, as updating onboarding state may trigger a query to cache
      await queryClient.invalidateQueries([
        getQueryKey("practices", "getLatestFormSubmission"),
        {
          practiceId,
          patientId,
          uuidOrSlug,
        },
      ]);
    },
  }),
});

export const patientSubmitFormTask = makeMutation({
  mutationKey: ["public", "patientSubmitFormTask"],
  formatParams: (args: {
    practiceUuid: string;
    formTaskUuid: string;
    data: PatientSubmitFormTaskRequest;
    patientFormToken: string;
    dob: string;
  }) => [
    args.practiceUuid,
    args.formTaskUuid,
    args.data,
    {
      headers: {
        Authorization: formatPatientAuthorization(args.dob, args.patientFormToken),
      },
    },
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: async (data) => {
      await queryClient.invalidateQueries([getQueryKey("practices", "getLatestFormSubmission")]);

      const { data: task } = data.data;

      updateCachedData<PatientFormTasksResponse>(
        queryClient,
        {
          queryKey: [getQueryKey("public", "getPatientFormTasks")],
        },
        (lastData) => {
          return produce(lastData, (draft) => {
            draft.tasks = lastData.tasks.map((lastTask) => {
              if (lastTask.uuid === task.uuid) {
                return task;
              }

              return lastTask;
            });
          });
        }
      );
    },
  }),
});

export const prepareFileUploadForFormTask = makeMutation({
  mutationKey: ["public", "prepareFileUploadForFormTask"],
  formatParams: (args: {
    practiceUuid: string;
    formTaskUuid: string;
    dob: string;
    patientFormToken: string;
  }) => [
    args.practiceUuid,
    args.formTaskUuid,
    {
      headers: {
        Authorization: formatPatientAuthorization(args.dob, args.patientFormToken),
      },
    },
  ],
});
