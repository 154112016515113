import { PatientInsuranceVO } from "@libs/api/generated-api";
import { makeQuery } from "@libs/utils/queries";
import { cacheForSession, oneMinuteCache } from "@libs/utils/queryCache";

export const getPatientInsurancesQuery = makeQuery({
  queryKey: ["practices", "getPatientInsurances"],
  formatParams: ({
    practiceId,
    patientId,
    includeBenefitCoverage = false,
    insuranceState = ["ACTIVE"],
    includeInsurancePlan = false,
  }: {
    practiceId: number;
    patientId: number;
    includeInsurancePlan?: boolean;
    includeBenefitCoverage?: boolean;
    insuranceState?: PatientInsuranceVO["insuranceState"][];
  }) => [
    practiceId,
    patientId,
    {
      includeState: insuranceState,
      includeInsurancePlan,
      includeBenefitCoverage,
    },
  ],
  queryOptions: oneMinuteCache,
});

export const getPatientInsuranceQuery = makeQuery({
  queryKey: ["practices", "getPatientInsurance"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    includeBenefitCoverage?: boolean;
    includeInsurancePlan?: boolean;
  }) => [
    args.practiceId,
    args.patientId,
    args.insuranceId,
    {
      includeInsurancePlan: args.includeInsurancePlan ?? false,
      includeBenefitCoverage: args.includeBenefitCoverage ?? false,
    },
  ],
  queryOptions: oneMinuteCache,
});
export const getInsuranceCarrierNames = makeQuery({
  queryKey: ["public", "getInsuranceCarrierNames"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});
