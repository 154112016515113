import React from "react";
import {
  BaseFormFieldSelectGender,
  BaseFormFieldSelectGenderProps,
} from "@libs/components/UI/BaseFormFieldSelectGender";
import { useGenderOptions } from "hooks/useGenderOptions";

export const FormFieldSelectGender: React.FC<Omit<BaseFormFieldSelectGenderProps, "options">> = (props) => {
  const options = useGenderOptions();

  return <BaseFormFieldSelectGender {...props} options={options} />;
};
