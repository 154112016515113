import React from "react";
import { PatientFormTaskVO, PracticeInfoVO } from "@libs/api/generated-api";
import { useFilteredForm } from "components/PatientForms/hooks/useFilteredForm";
import { PracticePDFHeader } from "components/PatientForms/FormPDFElements/PracticePDFHeader";
import { PatientFormContentItem } from "components/PatientForms/FormPDFElements/PatientFormContentItem";
import { ExistingPatientInfo } from "components/PatientForms/FormElements/types";

const EMPTY_RESPONSES = {};

type Props = {
  task: PatientFormTaskVO;
  practice: PracticeInfoVO;
  patient: ExistingPatientInfo;
  dob: string;

  logo?: {
    w: number;
    h: number;
    url: string;
  };
};

export const FormTaskPDFWithEmptyContent: React.FC<Props> = ({ task, practice, patient, logo }) => {
  const matchedForm = useFilteredForm({
    practice,
    form: task.form,
    patientAttributes: patient,
  });

  return (
    <>
      <PracticePDFHeader logo={logo} practice={practice} patient={patient} />
      {matchedForm.content.map((item) => {
        return item.content.map((contentItem) => (
          <PatientFormContentItem
            key={item.uuid}
            patient={patient}
            contentItem={contentItem}
            responsesById={EMPTY_RESPONSES}
            practice={practice}
            edit
          />
        ));
      })}
    </>
  );
};
