/* eslint-disable @typescript-eslint/naming-convention */
import { Text as PDFText, StyleSheet } from "@react-pdf/renderer";
import { Style } from "@react-pdf/types";
import { colors } from "@libs/design.config";

type Props = {
  bold?: boolean;
  italic?: boolean;
  size?: "base" | "sm" | "lg" | "xl";
  children?: React.ReactNode;
  style?: Style | Style[];
  ml?: "sm";
};

const shared = {
  color: colors.greyDark,
};

export const textStyles = StyleSheet.create({
  openSans: {
    ...shared,
    fontFamily: "Open Sans",
  },
  cursive: {
    ...shared,
    fontSize: 35,
    fontFamily: "Mr Dafoe",
    fontWeight: "normal",
  },
  bold: {
    fontWeight: 600,
  },
  italic: {
    fontStyle: "italic",
  },
  base: {
    fontSize: 10,
  },
  sm: {
    fontSize: 8,
  },
  lg: {
    fontSize: 12,
  },
  xl: {
    fontSize: 14,
  },
  ml_sm: {
    marginLeft: 8,
  },
  mr_sm: {
    marginRight: 8,
  },
  mt_sm: {
    marginTop: 8,
  },
  mb_sm: {
    marginBottom: 8,
  },
});

export const Text: React.FC<Props> = ({ bold, italic, children, size = "base", style, ml }) => {
  if (!children) {
    return null;
  }

  const fontSizeStyle = textStyles[size];
  const marginLeft = ml ? textStyles[`ml_${ml}`] : undefined;
  const parentStyle = Array.isArray(style) ? style : style ? [style] : [];

  return (
    <PDFText
      style={[
        textStyles.openSans,
        fontSizeStyle,
        bold ? textStyles.bold : {},
        italic ? textStyles.italic : {},
        ...parentStyle,
        marginLeft ?? {},
      ]}
    >
      {children}
    </PDFText>
  );
};
