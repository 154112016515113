import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ToggleHeader } from "components/UI/ToggleHeader";
import { paths } from "router/paths";

type Props = {
  onClickEdit: Func;
  edit?: boolean;
  selectedValue: "MEDICAL_HISTORY" | "DENTAL_HISTORY";
};
export const MedicalInformationRouteHeader: React.FC<Props> = ({ onClickEdit, selectedValue, edit }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const toggleHeaderOptions = useMemo(() => {
    return [
      { label: t("Medical History"), value: "MEDICAL_HISTORY" as const },
      { label: t("Dental History"), value: "DENTAL_HISTORY" as const },
    ];
  }, [t]);

  return (
    <ToggleHeader
      backTo={paths.account()}
      heightClass="h-16"
      options={toggleHeaderOptions}
      selectedValue={selectedValue}
      onChange={(_e, option) => {
        navigate(option.value === "DENTAL_HISTORY" ? paths.dentalHistory() : paths.medicalHistory());
      }}
      disabled={edit}
      rightContent={
        edit ? undefined : <ButtonIcon theme="primary" SvgIcon={EditIcon} onClick={onClickEdit} />
      }
    />
  );
};
