import { useMemo } from "react";
import { FormPatientProcedureVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";

export const useGroupedProcedures = (procedures: FormPatientProcedureVO[]) => {
  return useMemo(() => {
    const grouped = procedures.reduce(
      (accum, procedure) => {
        const priority = procedure.priority ?? Number.POSITIVE_INFINITY;
        let arrayEntry: FormPatientProcedureVO[] | undefined = accum[priority];

        if (!isDefined(arrayEntry)) {
          arrayEntry = [];
          accum[priority] = arrayEntry;
        }

        accum[priority].push(procedure);

        return accum;
      },
      {} as Record<string, FormPatientProcedureVO[]>
    );

    return Object.values(grouped).sort(([a], [b]) => {
      return (a.priority ?? Number.POSITIVE_INFINITY) - (b.priority ?? Number.POSITIVE_INFINITY);
    });
  }, [procedures]);
};
