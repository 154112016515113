import { InputHTMLAttributes, ReactNode, forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";

export type ValueTypes = string | number | boolean;

export interface BaseOptionInputProps extends Omit<InputHTMLAttributes<HTMLInputElement>, "value" | "type"> {
  value?: ValueTypes;
  includeDarkMode?: boolean;
}

export interface CheckboxOptionInputProps extends BaseOptionInputProps {
  type: "checkbox";
}

export interface RadioOptionInputProps extends Omit<BaseOptionInputProps, "name"> {
  type: "radio";
  name: NonNullable<InputHTMLAttributes<HTMLInputElement>["name"]>;
}

type PrivateProps = {
  contentClassName?: string;
  customContent?: ReactNode;
};

type OptionInputProps = (RadioOptionInputProps & PrivateProps) | (CheckboxOptionInputProps & PrivateProps);

const cxStyles = {
  input: "sr-only peer",
};

export const OptionInput = forwardRef<HTMLInputElement, OptionInputProps>(
  ({ className, contentClassName, customContent, id, children, ...props }, ref) => {
    const fieldId = useEnsureId({ customId: id });

    return (
      // This is needed because of the "sr-only" class applied to the input. sr-only makes the input
      // position absolute so that it does not effect the layout flow. However if no position class
      // is applied to the label container the input ends up being positioned relative to the
      // closest ancestor that does have a position class. This can cause scroll bars to appear that
      // shouldn't and scrolled content to jump.  Ensuring that the input is always positioned
      // relative to the label avoids this.
      <label className={cx("base-relative", className)} htmlFor={fieldId}>
        <input
          ref={ref}
          {...props}
          value={typeof props.value === "boolean" ? String(props.value) : props.value}
          id={fieldId}
          className={cxStyles.input}
        />
        {customContent}
        {children ? <span className={contentClassName}>{children}</span> : null}
      </label>
    );
  }
);
