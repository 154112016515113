import { useParams } from "react-router-dom";
import { isDefined } from "@libs/utils/types";

export const useInvoiceUuid = () => {
  const { invoiceUuid: invoiceUuidRaw } = useParams();
  const invoiceUuid = invoiceUuidRaw ?? "";

  if (!isDefined(invoiceUuid)) {
    throw new Error("Invoice uuid undefined");
  }

  return invoiceUuid;
};
