import { FC, useCallback, useState } from "react";
import { useIdleTimer } from "react-idle-timer";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { SECOND_IN_MS } from "@libs/utils/date";
import { Button } from "@libs/components/UI/Button";
import { usePatientActivityStorage } from "storage/activity";

type OverlayProps = {
  onClick?: (event: React.MouseEvent) => void;
  color?: string;
  opacity?: number;
  className?: string;
  bgColorClassName?: string;
};

const Overlay: FC<OverlayProps> = ({ className }) => {
  return (
    <div
      className={cx(
        `z-[1999]
         fixed
         left-0
         top-0
         w-full
         h-full
         animate-halfFadeIn
         bg-greyDark`,
        className
      )}
    />
  );
};

type ModalProps = {
  className?: string;
  children: React.ReactNode;
};

const Modal: FC<ModalProps> = ({ className, children }) => {
  return (
    <div
      className={cx(
        `z-[2000]
         fixed
         top-1/2
         left-1/2
         rounded
         -translate-x-1/2
         -translate-y-1/2
         shadow-main
         bg-white`,
        className
      )}
    >
      {children}
    </div>
  );
};

type Props = {
  timeout: number;
  promptBeforeIdle: number;
  eventsThrottle: number;
  name: string;
  onIdle: Func;
  onTogglePrompt?: (isShowing: boolean) => void;
};
export const IdleTimer: FC<Props> = ({ onIdle, timeout, onTogglePrompt, ...rest }) => {
  const activityStorage = usePatientActivityStorage();
  const { t } = useTranslation();
  const [isIdle, setIsIdle] = useState(false);

  const handleIdlePrompt = useCallback(() => {
    setIsIdle(true);

    if (onTogglePrompt) {
      onTogglePrompt(true);
    }
  }, [onTogglePrompt]);

  const handleAction = useCallback(() => {
    activityStorage.setRecentlyActive();
  }, [activityStorage]);

  const handleActivity = useCallback(() => {
    activityStorage.setRecentlyActive();

    if (onTogglePrompt) {
      onTogglePrompt(false);
    }

    setIsIdle(false);
  }, [onTogglePrompt, activityStorage]);

  const idleTimer = useIdleTimer({
    ...rest,
    timeout,
    crossTab: true,
    syncTimers: SECOND_IN_MS,
    onActive: handleActivity,
    onAction: handleAction,
    onPrompt: handleIdlePrompt,
    onIdle,
  });

  return isIdle ? (
    <>
      <Overlay />
      <Modal className="w-80 text-center h-48 font-sans">
        <div className="h-full flex flex-col justify-between items-center p-5">
          <p className="mt-10">{t("Are you still here?")}</p>
          <div className="">
            <Button
              className="min-w-28"
              onClick={() => {
                setIsIdle(false);
                idleTimer.reset();
              }}
            >
              Yes
            </Button>
          </div>
        </div>
      </Modal>
    </>
  ) : null;
};
