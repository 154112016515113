import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { AnonUserMessagePage } from "components/UI/AnonPatientMessagePage";

interface Props {
  practice: PracticeInfoVO;
}

export const FormTaskLinkExpired: FC<Props> = ({ practice }) => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col h-full items-center">
      <AnonUserMessagePage
        title={t("Your link has expired")}
        subTitle={
          <Trans
            i18nKey="forms.linkExpired"
            values={{ practiceName: practice.name }}
            tOptions={{ interpolation: { escapeValue: true } }}
            shouldUnescape
          />
        }
        logoUrl={practice.logo?.url}
      >
        <div className="flex flex-col items-center mt-2">
          {practice.phoneNumber ? <div>{formatPhoneNumber(practice.phoneNumber)}</div> : null}
          <div>{practice.email}</div>
        </div>
      </AnonUserMessagePage>
    </div>
  );
};
