import React from "react";
import { format, fromUnixTime } from "date-fns";
import { useTranslation } from "react-i18next";
import { FormattedNumber } from "react-intl";

import { InvoiceEntryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { LONG_LOCALIZED_DATE } from "@libs/utils/date";
import { centsToDollars } from "@libs/utils/currency";
import { normal12, normal14, semibold12, semibold14 } from "assets/styles/textSize";
import { EMPTY_CELL } from "components/UI/GridTableComponents";

const ITEM_TYPE_LABELS = {
  APPOINTMENT: "Appointment" as const,
  ADJUSTMENT: "Adjustment" as const,
};

export const Cell: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => <div className={cx("flex justify-between items-center text-sm", className)}>{children}</div>;

export const InvoiceItems: React.FC<{ item: InvoiceEntryVO }> = ({ item }) => {
  const { t } = useTranslation();

  const createdAt = format(fromUnixTime(item.createdAt), LONG_LOCALIZED_DATE);
  const entityTypeLabelRef = item.entryReference.type;
  const entityTypeLabel = t(ITEM_TYPE_LABELS[entityTypeLabelRef]);

  return (
    <>
      <Cell>
        <div className="flex gap-2 items-center">
          <span className={semibold14}>{entityTypeLabel}</span>
          <div className="bg-slate-300 h-5 w-px" />
          <span className={normal12}>{createdAt}</span>
        </div>
        <FormattedNumber
          value={centsToDollars(item.amount)}
          style="currency"
          currencyDisplay="symbol"
          currency={item.currency}
        >
          {(value: string) => <div className={semibold14}>{value}</div>}
        </FormattedNumber>
      </Cell>
      {item.adjustmentNote && (
        <Cell>{t("payments.page.description.note", { note: item.adjustmentNote })}</Cell>
      )}

      {item.lineItems.length > 0 ? (
        <>
          <Cell>
            <div className={semibold12}> {t("payments.page.description")}</div>
            <div className={semibold12}> {t("payments.page.amount")}</div>
          </Cell>
          {item.lineItems.map((lineItem, index) => {
            return (
              <Cell key={index}>
                <div className={normal14}>
                  {lineItem.patientProcedureSnapshot?.laymanName ??
                    lineItem.patientProcedureSnapshot?.name ??
                    EMPTY_CELL}
                </div>
                <div>
                  <div style={{ width: "40px" }} />
                </div>
                <FormattedNumber
                  value={centsToDollars(lineItem.amount)}
                  style="currency"
                  currencyDisplay="symbol"
                  currency={item.currency}
                >
                  {(value: string) => <div className={normal14}>{value}</div>}
                </FormattedNumber>
              </Cell>
            );
          })}
        </>
      ) : null}
    </>
  );
};
