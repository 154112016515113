import { ContactDetailsVO, ContactVO, GeneralInfoVO } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { email, phoneNumber, required } from "@libs/utils/validators";
import { TFunction } from "i18next";

export const getAccountValidationSchema = ({
  translate,
  contact,
  contactDetails,
}: {
  contact: ContactVO;
  contactDetails: ContactDetailsVO;
  translate: TFunction<"en", undefined>;
}) => {
  const { preferredContactMode } = contactDetails;
  const contactPerson = contact.relation === "SELF" ? undefined : contact;
  const requiredContactMethod = !contactPerson;
  const emailRequired = requiredContactMethod && preferredContactMode === "EMAIL";
  const phoneRequired =
    requiredContactMethod && (preferredContactMode === "CALL" || preferredContactMode === "TEXT");

  const requiresValue = {
    $v: required,
    $error: translate("required"),
  };
  const addressValidation = {
    ...requiresValue,
    $ignore: Boolean(contactPerson),
  };

  return {
    contactDetails: {
      email: [
        {
          ...requiresValue,
          $ignore: !emailRequired,
        },
        {
          $v: email,
          $error: translate("validation.email"),
        },
      ],
      phone: [
        {
          ...requiresValue,
          $ignore: !phoneRequired,
        },
        {
          $v: phoneNumber,
          $error: translate("validation.phone"),
        },
      ],
      addressDetails: {
        city: [addressValidation],
        state: [addressValidation],
        zip: [addressValidation],
      },
    },
    personalDetails: {
      gender: [requiresValue],
      dob: [requiresValue],
      firstName: [requiresValue],
      lastName: [requiresValue],
    },
  };
};
export type AccountValidationResult = ValidationResult<
  GeneralInfoVO,
  ReturnType<typeof getAccountValidationSchema>
>;
