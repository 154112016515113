import { SelfBookingOpenSlotsRequest } from "@libs/api/generated-api";
import { makeQuery } from "@libs/utils/queries";
import { cacheForSession, oneMinuteCache } from "@libs/utils/queryCache";

export const getSelfBookingConfig = makeQuery({
  queryKey: ["public", "getSelfBookingConfig"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});

export const getInsuranceCarrierNames = makeQuery({
  queryKey: ["public", "getInsuranceCarrierNames"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: cacheForSession,
});

export const getSelfBookableOpenSlots = makeQuery({
  queryKey: ["public", "getSelfBookableOpenSlots"],
  formatParams: (args: { practiceUuid: string; data: SelfBookingOpenSlotsRequest }) => [
    args.practiceUuid,
    args.data,
  ],
  queryOptions: oneMinuteCache,
});

export const getSelfBookableProviders = makeQuery({
  queryKey: ["public", "getSelfBookableProviders"],
  formatParams: (args: {
    practiceUuid: string;
    query?: { carrierId?: number; appointmentCategoryId?: number };
  }) => [args.practiceUuid, args.query],
  queryOptions: cacheForSession,
});
