import { cx } from "@libs/utils/cx";

const cxStyles = {
  breakpoint: (size: "md" | "lg" | "xl" | "2xl") => {
    return size === "md" ? "max-w-md" : size === "lg" ? "max-w-lg" : size === "xl" ? "max-w-xl" : "max-w-2xl";
  },
};

export const CenteredResponsiveLayout: React.FC<{
  children: React.ReactNode;
  size?: "md" | "lg" | "xl" | "2xl";
  className?: string;
  centerContents?: boolean;
}> = ({ children, size = "md", centerContents, className }) => {
  return (
    <div
      className={`
        flex
        h-full
        items-center
        justify-center
        min-h-0
        flex-1
        overflow-y-auto
      `}
    >
      <div
        className={cx(
          "grid grid-cols-1 gap-4 h-full w-full",
          cxStyles.breakpoint(size),
          centerContents && "items-center justify-center"
        )}
      >
        <div className={cx(cxStyles.breakpoint(size), className)}>{children}</div>
      </div>
    </div>
  );
};
