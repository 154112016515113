import React from "react";
import { parsePhoneNumber, phoneNumberFormatter, formattedCharacters } from "@libs/utils/phone";
import {
  FormFieldFormattedInput,
  FormFieldFormattedInputProps,
} from "@libs/components/UI/FormFieldFormattedInput";

export const FormFieldPhoneInput: React.FC<
  Omit<FormFieldFormattedInputProps, "type" | "cleanValue" | "formatValue" | "formattedCharacters">
> = (props) => {
  return (
    <FormFieldFormattedInput
      {...props}
      type="tel"
      cleanValue={parsePhoneNumber}
      formatValue={phoneNumberFormatter}
      formattedCharacters={formattedCharacters}
    />
  );
};
