import React, { useCallback, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { useLocation } from "react-router-dom";
import { MultiInvoicePaymentVO, PaymentProfileVO, PracticeBillingSettingVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { greaterThan, lessOrEqualTo, required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { pluralize } from "@libs/utils/pluralize";
import { getFullUrl } from "@libs/utils/location";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { RadioList } from "@libs/components/UI/RadioList";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useDebounceWithPendingStatus } from "@libs/hooks/useDebounceWithPendingStatus";
import { Form } from "@libs/components/UI/Form";
import { AddButton } from "components/UI/AddButton";
import { FormFieldCentsInput } from "components/UI/FormFieldCentsInput";
import { PaymentProfile } from "components/UI/PaymentProfile";
import { semibold14, semibold16 } from "assets/styles/textSize";
import { FooterButtons } from "components/Billing/FooterButtons";
import { InvoiceSummary } from "components/Billing/InvoiceSummary";
import { CallPracticeToCompletePayment } from "components/Billing/ProcessPayment/CallPracticeToCompletePayment";
import { paths } from "router/paths";
import { PatientPaymentDraft } from "components/Billing/ProcessPayment/types";

type PaymentSubmission = {
  paymentSize: "full" | "partial";
  paymentUuid: null | string;
  paymentAmount?: number;
  paymentMethodUuid: undefined | string;
};

const useSubmissionSchema = ({
  maxValue,
  paymentSize,
}: {
  maxValue: number;
  paymentSize: PaymentSubmission["paymentSize"];
}) => {
  const { t } = useTranslation();

  return useMemo(
    () => ({
      paymentAmount: [
        { $v: required, $error: t("required") },
        ...(paymentSize === "full"
          ? []
          : [
              { $v: greaterThan(0), $error: t("validation.greaterThan", { value: 0 }) },
              {
                $v: lessOrEqualTo(maxValue),
                $error: t("validation.lessThanOrEqualTo", { value: formatCurrency(maxValue) }),
              },
            ]),
      ],
      paymentMethodUuid: [{ $v: required, $error: t("required") }],
    }),
    [maxValue, paymentSize, t]
  );
};

const cxStyles = {
  cardsContainer: "flex flex-col gap-2",
};

const getFullPaymentAmount = ({ invoiceDetails }: { invoiceDetails?: MultiInvoicePaymentVO }) => {
  return invoiceDetails?.maxPaymentAmount ?? 0;
};

const PAYMENT_AMOUNT_EDIT_DEBOUNCE_MS = 800;

export const PaymentDraftForm: React.FC<{
  invoicesQuery: ApiQueryResult<MultiInvoicePaymentVO>;
  paymentMethodsQuery: ApiQueryResult<PaymentProfileVO[]>;
  invoiceUuids?: string[];
  onboardedWithPaymentProvider: boolean;
  billingSettings: PracticeBillingSettingVO;
  onChangePaymentDraft: (draft: Partial<PatientPaymentDraft>) => void;
  paymentDraft: PatientPaymentDraft;
  onSubmit: (params: { amount: number; paymentProfile: PaymentProfileVO }) => void;
}> = ({
  invoicesQuery,
  paymentMethodsQuery,
  onSubmit,
  paymentDraft,
  onChangePaymentDraft,
  billingSettings,
  invoiceUuids,
  onboardedWithPaymentProvider,
}) => {
  const { t } = useTranslation();
  const { data: invoiceDetails, isLoading: invoiceDetailsLoading } = invoicesQuery;
  const { data: cardList } = paymentMethodsQuery;
  const location = useLocation();
  const practice = useCurrentPractice();

  const soleInvoice =
    invoiceDetails && invoiceDetails.invoices.length === 1 ? invoiceDetails.invoices[0] : undefined;
  const fullPaymentAmountCents = useMemo(() => getFullPaymentAmount({ invoiceDetails }), [invoiceDetails]);
  const updatePaymentAmount = useCallback(
    (newAmount?: number) => {
      onChangePaymentDraft({
        paymentAmount: newAmount,
      });
    },
    [onChangePaymentDraft]
  );
  const { callback: debouncedUpdatePaymentAmount, isPending: isDebouncePending } =
    useDebounceWithPendingStatus(updatePaymentAmount, PAYMENT_AMOUNT_EDIT_DEBOUNCE_MS, {
      leading: false,
      trailing: true,
    });
  const paymentSubmissionSchema = useSubmissionSchema({
    maxValue: fullPaymentAmountCents,
    paymentSize: paymentDraft.paymentSize,
  });

  const validation = useValidation(paymentDraft, paymentSubmissionSchema);

  const isUnpaid = !invoiceUuids || soleInvoice?.state !== "PAID";
  const paymentOptions = React.useMemo(() => {
    return [
      { value: "full" as const, label: t("Full") },
      { value: "partial" as const, label: t("Partial") },
    ];
  }, [t]);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!cardList || !validation.validate().$isValid) {
        return;
      }

      const cardSelected = cardList.find((item) => item.uuid === paymentDraft.paymentMethodUuid);

      if (!cardSelected) {
        return;
      }

      onSubmit({
        paymentProfile: cardSelected,
        amount:
          paymentDraft.paymentSize === "full" ? fullPaymentAmountCents : paymentDraft.paymentAmount ?? 0,
      });
    },
    [
      cardList,
      fullPaymentAmountCents,
      onSubmit,
      paymentDraft.paymentAmount,
      paymentDraft.paymentMethodUuid,
      paymentDraft.paymentSize,
      validation,
    ]
  );

  const handleCardClicked = useCallback(
    (card: PaymentProfileVO) => {
      onChangePaymentDraft({
        paymentMethodUuid: card.uuid,
      });
    },
    [onChangePaymentDraft]
  );

  const cardOptions = useMemo(() => {
    return cardList?.map((card) => ({ value: card.uuid, label: "" })) ?? [];
  }, [cardList]);
  const invoiceCount = invoiceDetails?.invoices.length ?? 0;

  return (
    <Form onSubmit={handleSubmit} className="flex flex-col gap-3 md:gap-6">
      {soleInvoice ? (
        <InvoiceSummary invoice={soleInvoice} />
      ) : (
        <div className="flex justify-between">
          <div className={semibold14}>
            {pluralize(
              invoiceCount,
              t("processPayment.page.invoiceCount"),
              t("processPayment.page.invoiceCount.plural", { invoiceCount })
            )}
          </div>
          <div className={semibold16}>{formatCurrency(fullPaymentAmountCents)}</div>
        </div>
      )}
      <div className="w-full h-px bg-slate-300" />
      {onboardedWithPaymentProvider ? (
        <>
          <div className="flex flex-col gap-2">
            <RadioList
              required
              label={t("payments.page.amountType")}
              selectedValue={paymentDraft.paymentSize}
              options={paymentOptions}
              onChange={(e, op) => {
                validation.reset();
                onChangePaymentDraft({
                  paymentSize: op.value,
                });
              }}
            />
            <FormFieldCentsInput
              id="payment-amount"
              label={t("payments.page.amount")}
              className="max-w-xs"
              required
              max={fullPaymentAmountCents}
              edit={paymentDraft.paymentSize === "partial"}
              error={validation.result.paymentAmount.$error}
              value={
                paymentDraft.paymentSize === "full" ? fullPaymentAmountCents : paymentDraft.paymentAmount
              }
              onValueChange={(value) => {
                debouncedUpdatePaymentAmount(value);
              }}
            />
          </div>
          <div className="text-sm">{billingSettings.passCreditCardFees && t("billing.surchargeWarning")}</div>
          {cardList && cardList.length > 0 ? (
            <RadioList<string, OptionInputOption<string>>
              options={cardOptions}
              required
              optionListClassName={cxStyles.cardsContainer}
              label={t("Select Card")}
              error={validation.result.paymentMethodUuid.$error}
              layout="vert"
              verticalLayout="compact"
              onChange={(e, op) => {
                onChangePaymentDraft({ paymentMethodUuid: op.value });
              }}
              selectedValue={paymentDraft.paymentMethodUuid}
              renderOptionItem={(props) => {
                const profile = cardList.find((item) => item.uuid === props.value);

                if (!profile) {
                  return null;
                }

                return (
                  <PaymentProfile
                    selected={paymentDraft.paymentMethodUuid === props.value}
                    profile={profile}
                    disabled={props.disabled}
                    onClick={handleCardClicked}
                  />
                );
              }}
            />
          ) : (
            <FormFieldLabel required content={t("Select Card")} className={cx("mt-4", semibold14)} />
          )}

          <AddButton to={paths.addPaymentMethod({ successUrl: getFullUrl(location) })}>
            {t("Add Payment Method")}
          </AddButton>

          {!invoiceDetailsLoading && isUnpaid && (
            <FooterButtons className="flex-col">
              <AsyncButton
                isLoading={isDebouncePending}
                type="submit"
                className="w-full max-w-lg"
                displayLoadingText
                disabled={
                  validation.result.$isValid === false || !paymentDraft.paymentMethodUuid || isDebouncePending
                }
              >
                {t("Next")}
              </AsyncButton>
            </FooterButtons>
          )}
        </>
      ) : (
        <CallPracticeToCompletePayment practice={practice} />
      )}
    </Form>
  );
};
