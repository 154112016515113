import { useTranslation } from "react-i18next";
import { RegistrationFooterPortal } from "components/Onboarding/RegistrationFooterPortal";
import { StickySubmissionFooter } from "components/UI/StickySubmissionFooter";

type Props = {
  onClickNext?: Func;
  onClickPrevious?: Func;
  isSubmitting: boolean;
  form?: string;
  primaryButtonDisabled?: boolean;
};
export const RegistrationSubmissionFooter: React.FC<Props> = ({
  onClickNext,
  onClickPrevious,
  isSubmitting,
  primaryButtonDisabled,
  form,
}) => {
  const { t } = useTranslation();

  return (
    <RegistrationFooterPortal>
      <div className="h-16" />

      <StickySubmissionFooter
        form={form}
        secondaryText={t("Previous")}
        primaryText={t("registration.save.next")}
        onClickPrimary={onClickNext}
        onClickSecondary={onClickPrevious}
        isSubmitting={isSubmitting}
        className="fixed"
        primaryButtonDisabled={primaryButtonDisabled}
      />
    </RegistrationFooterPortal>
  );
};
