import { Link, Outlet, useLocation } from "react-router-dom";
import React from "react";
import { useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { ReactComponent as HomeIcon } from "@libs/assets/icons/home.svg";
import { ReactComponent as AppointmentsIcon } from "@libs/assets/icons/schedule.svg";
import { ReactComponent as InvoicesIcon } from "@libs/assets/icons/accounting.svg";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";

import { semibold14 } from "assets/styles/textSize";
import { paths, routesConfig } from "router/paths";

const cxTabStyles = {
  container: (selected: boolean, disabled: boolean) =>
    cx(
      "flex flex-col w-full",
      semibold14,
      selected ? "text-primaryTheme " : "text-greyDark",
      disabled ? "opacity-50" : "hover:text-opacity-70"
    ),
  content: `
    flex
    items-center
    justify-center
    h-full
    space-x-2
    p-4
    border-transparent
    group
  `,
  tabText: "hidden md:block",
  selectedUnderline: (selected: boolean) => cx("h-1 rounded-sm w-full", selected && "bg-primaryTheme"),
};

const tabIsSelected = (currentPath: string, tabPath: string) => {
  if (tabPath === routesConfig.home.path) {
    return currentPath === routesConfig.home.path;
  }

  return currentPath.startsWith(tabPath);
};
const Tab: React.FC<{
  to: string;
  text: string;
  icon: React.ReactNode;
  selected: boolean;
  disabled?: boolean;
}> = ({ to, text, icon, selected, disabled = false }) => {
  return (
    <li className="w-1/4 h-full">
      {disabled ? (
        <div className={cxTabStyles.container(selected, disabled)}>
          <div className={cxTabStyles.content}>
            <div>{icon}</div>
            <div className={cxTabStyles.tabText}>{text}</div>
          </div>
          <div className={cxTabStyles.selectedUnderline(selected)} />
        </div>
      ) : (
        <Link to={to} className={cxTabStyles.container(selected, disabled)}>
          <div className={cxTabStyles.content}>
            <div>{icon}</div>
            <div className={cxTabStyles.tabText}>{text}</div>
          </div>
          <div className={cxTabStyles.selectedUnderline(selected)} />
        </Link>
      )}
    </li>
  );
};

export const MainRouteTabs: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const tabs = React.useMemo(() => {
    return [
      {
        icon: <HomeIcon />,
        to: paths.home(),
        text: t("Home"),
      },
      {
        icon: <AppointmentsIcon />,
        to: paths.appointments(),
        text: t("Appointments"),
      },
      {
        icon: <InvoicesIcon />,
        to: paths.invoices(),
        text: t("Invoices"),
      },
      {
        icon: <UserIcon />,
        to: paths.account(),
        text: t("Account"),
      },
    ];
  }, [t]);

  return (
    <>
      <div className="md:h-[3.7rem]" />
      <Outlet />
      <div className="h-[3.7rem] md:hidden" />
      <div
        className={`
          flex
          justify-center
          fixed
          left-0
          right-0
          bottom-0
          h-[3.7rem]
          bg-white
          border-gray-200
          dark:border-gray-700
          shadow-[2px_-2px_4px_rgba(188,188,188,0.25)]
          md:top-[50px]
          md:bottom-auto
          md:shadow-[2px_2px_4px_rgba(188,188,188,0.25)]
        `}
      >
        <div className="w-full h-full max-w-3xl">
          <ul
            className={`
              flex
              flex-wrap
              items-center
              justify-around
              -mb-px
              text-sm
              h-full
              font-medium
              text-center
              text-gray-500
              dark:text-gray-400
            `}
          >
            {tabs.map(({ to, icon, text }) => {
              return (
                <Tab
                  key={to}
                  to={to}
                  icon={icon}
                  text={text}
                  selected={tabIsSelected(location.pathname, to)}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
