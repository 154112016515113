import { FormEvent, useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { getLocalDate } from "@libs/utils/date";
import { Form } from "@libs/components/UI/Form";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldDatePicker } from "components/UI/FormFieldDatePicker";

type Props = {
  onSubmit: (dob: Date) => void;
  error?: string;
  displayErrorMessage?: boolean;
  initialDate?: string;
  isLoading?: boolean;
  onChange?: (date: Date | null) => void;
};

export const DateOfBirthForm: React.FC<Props> = ({
  onSubmit,
  isLoading = false,
  initialDate,
  error,
  onChange,
  displayErrorMessage,
}) => {
  const [dob, setDob] = useState<Date | null>(initialDate ? getLocalDate(initialDate) : null);
  const { t } = useTranslation();
  const handleDobSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (dob) {
        onSubmit(dob);
      }
    },
    [onSubmit, dob]
  );

  return (
    <Form className="mt-8 flex flex-col gap-8" onSubmit={handleDobSubmit}>
      <FormFieldDatePicker
        required
        onChange={(date) => {
          setDob(date);
          onChange?.(date);
        }}
        error={error}
        selected={dob}
        displayErrorMessage={displayErrorMessage}
        pickerHiddenOnMobile
        maxDate={new Date()}
      />
      <AsyncButton isLoading={isLoading} type="submit" size="large" theme="link" disabled={!dob}>
        {t("Next")}
      </AsyncButton>
    </Form>
  );
};
