import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";

export const PatientFormFieldTitle: React.FC<{ title: string; required?: boolean; error?: string }> = ({
  title,
  required,
  error,
}) => {
  return (
    <FormFieldLabel
      className="text-sm md:text-xs font-sansSemiBold"
      content={title}
      required={required}
      error={error}
    />
  );
};
