import { useMemo } from "react";
import { mirroredOptions } from "@libs/utils/forms";

const statesUS = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const useUSStateOptions = (customValue?: string): SelectOption<string>[] => {
  return useMemo(() => {
    const values = mirroredOptions(statesUS);

    if (customValue && !statesUS.includes(customValue.trim())) {
      return [...values, { value: customValue, label: customValue }];
    }

    return values;
  }, [customValue]);
};
