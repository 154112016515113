import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { captureException as sentryCaptureException } from "@sentry/react";
import { useSnackbar } from "notistack";
import { GuestCheckoutSummaryVO, PaymentProfileVO, PracticeInfoVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { TaskProgressHeader } from "@libs/components/UI/TaskProgressHeader";
import { Header } from "components/Main/Header/Header";
import {
  createGuestCheckoutPaymentProfile,
  createGuestCheckoutPaymentV2,
  updateGuestCheckoutPaymentProfileAsTransient,
} from "api/billing/mutations";
import { useHandleError } from "api/handleErrorResponse";
import { defaultSnackbarOptions } from "utils/snackbar";
import { useCardDraft } from "hooks/useCardDraft";
import { usePatientFinixForm } from "hooks/usePatientFinixForm";
import { AddPaymentMethodForm } from "components/GuestCheckout/AddPaymentMethodForm";
import { ConfirmPaymentForm } from "components/Billing/ConfirmPaymentForm";

export const GuestCheckoutForm: React.FC<{
  checkoutSummaryQuery: ApiQueryResult<GuestCheckoutSummaryVO>;
  practiceUuid: string;
  paymentProfile?: PaymentProfileVO;
  onChangePaymentProfile: (paymentProfile?: PaymentProfileVO) => void;
  patientToken: string;
  canProcessPaymentOnline?: boolean;
  onPaymentComplete: (email: string) => void;
  practice: PracticeInfoVO;
}> = ({
  checkoutSummaryQuery,
  practiceUuid,
  paymentProfile,
  onChangePaymentProfile,
  patientToken,
  onPaymentComplete,
  practice,
}) => {
  const { t } = useTranslation();
  const outstandingBalance = checkoutSummaryQuery.data?.outstandingBalance ?? 0;
  const cardDraftProps = useCardDraft({
    includeAcknowedgeFuturePayments: false,
  });
  const [email, setEmail] = useState("");
  const handleError = useHandleError();
  const { enqueueSnackbar } = useSnackbar();
  const [
    createGuestCheckoutPaymentMutation,
    createGuestCheckoutPaymentProfileMutation,
    updateGuestCheckoutPaymentProfileAsTransientMutation,
  ] = useApiMutations([
    createGuestCheckoutPaymentV2,
    createGuestCheckoutPaymentProfile,
    updateGuestCheckoutPaymentProfileAsTransient,
  ]);

  const createGuestCheckoutPaymentMutateAsync = createGuestCheckoutPaymentMutation.mutateAsync;
  const createGuestCheckoutPaymentProfileMutateAsync = createGuestCheckoutPaymentProfileMutation.mutateAsync;
  const updateGuestCheckoutPaymentProfileAsTransientMutateAsync =
    updateGuestCheckoutPaymentProfileAsTransientMutation.mutateAsync;

  const handleFinixFormSubmission: FinixFormSubmissionCallback = useCallback(
    async (err, response) => {
      if (err) {
        sentryCaptureException(new Error("Error occured while submitting credit card details to Finix"));
        enqueueSnackbar(t("billing.addCardFailed"), defaultSnackbarOptions);

        return;
      }

      try {
        const paymentProfileResult = await createGuestCheckoutPaymentProfileMutateAsync({
          practiceUuid,
          data: {
            paymentToken: response.data.id,
            patientToken,
          },
        });

        onChangePaymentProfile(paymentProfileResult.data.data);
      } catch (e) {
        handleError(e);
      }
    },
    [
      createGuestCheckoutPaymentProfileMutateAsync,
      enqueueSnackbar,
      handleError,
      onChangePaymentProfile,
      patientToken,
      practiceUuid,
      t,
    ]
  );

  const handleRemovePaymentMethod = useCallback(async () => {
    // Not essential that this request is successful.
    if (!paymentProfile) {
      return;
    }

    try {
      await updateGuestCheckoutPaymentProfileAsTransientMutateAsync({
        practiceUuid,
        data: { patientToken, paymentProfileUuid: paymentProfile.uuid },
      });
    } catch (e) {
      // Not essential that this request is successful.
      sentryCaptureException(e);
    }

    onChangePaymentProfile(undefined);
  }, [
    onChangePaymentProfile,
    patientToken,
    paymentProfile,
    practiceUuid,
    updateGuestCheckoutPaymentProfileAsTransientMutateAsync,
  ]);
  const handleSubmitPaymentForm = useCallback(async () => {
    if (!paymentProfile) {
      return;
    }

    try {
      await createGuestCheckoutPaymentMutateAsync({
        practiceUuid,
        data: {
          idempotencyUuid: crypto.randomUUID(),
          paymentProfileUuid: paymentProfile.uuid,
          patientToken,
          email,
          currencyAmount: {
            amount: outstandingBalance,
            currency: "USD",
          },
        },
      });
      onPaymentComplete(email);
    } catch (e) {
      handleError(e);
    }
  }, [
    createGuestCheckoutPaymentMutateAsync,
    email,
    handleError,
    onPaymentComplete,
    outstandingBalance,
    patientToken,
    paymentProfile,
    practiceUuid,
  ]);

  const finixUseFormProps = usePatientFinixForm({
    formSubmissionHandler: handleFinixFormSubmission,
  });

  return (
    <div className="h-full bg-white">
      <Header practice={practice} />
      <div className="h-[50px]" />
      <QueryResult queries={[checkoutSummaryQuery]}>
        {checkoutSummaryQuery.data && (
          <div className="flex flex-col p-4 items-center">
            <div className="flex flex-col gap-4 w-full max-w-md overflow-y-auto">
              <TaskProgressHeader className="mx-0.5 mt-0.5" totalSteps={2} step={paymentProfile ? 1 : 0} />
              {paymentProfile ? (
                <ConfirmPaymentForm
                  totalChargeAmount={checkoutSummaryQuery.data.totalChargeAmount}
                  surchargeAmount={checkoutSummaryQuery.data.surchargeAmount}
                  subtotal={checkoutSummaryQuery.data.outstandingBalance}
                  isSubmittingPayment={createGuestCheckoutPaymentMutation.isLoading}
                  isLoadingEditPaymentMethod={updateGuestCheckoutPaymentProfileAsTransientMutation.isLoading}
                  onSubmitFormPayment={handleSubmitPaymentForm}
                  onEditPaymentMethod={handleRemovePaymentMethod}
                  paymentProfile={paymentProfile}
                />
              ) : (
                <AddPaymentMethodForm
                  checkoutSummaryQuery={checkoutSummaryQuery}
                  practice={practice}
                  cardDraftProps={cardDraftProps}
                  email={email}
                  finixUseFormProps={finixUseFormProps}
                  handleSetEmail={setEmail}
                  isCreatingPaymentProfile={createGuestCheckoutPaymentProfileMutation.isLoading}
                />
              )}
            </div>
          </div>
        )}
      </QueryResult>
    </div>
  );
};
