import { KeyboardEvent, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { isOneOf } from "@libs/utils/isOneOf";

import {
  OptionInputList,
  OptionInputOption,
  CheckboxInputListProps,
  RadioInputListProps,
  RenderOptionItemProps,
  applyOptionClassName,
  Layout as OptionInputListLayout,
} from "@libs/components/UI/OptionInputList";
import { LabelledOptions } from "@libs/components/UI/LabelledOptions";
import { ToggleButton, ToggleButtonShape } from "@libs/components/UI/ToggleButton";

type Layout = "wrap" | "custom";

const cxStyles = {
  container: ({ layout, shape }: { layout: Layout; shape: ToggleButtonShape }) =>
    cx(
      layout === "wrap" ? "flex items-center flex-wrap" : "",
      layout === "wrap"
        ? isOneOf(shape, ["normal", "mediumSquare", "inputLabelOutSquare"])
          ? "gap-1"
          : "gap-2"
        : ""
    ),
};

export interface ToggleButtonCheckboxListProps<V extends ValueTypes, T extends OptionInputOption<V>>
  extends CheckboxInputListProps<V, T> {
  shape?: ToggleButtonShape;
  layout?: Layout;
  containerLayout?: OptionInputListLayout;
  label?: ReactNode;
  required?: boolean;
  error?: string;
  displayErrorMessage?: boolean;
  listClassName?: string;
  labelClassName?: string;
  onOptionKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export interface ToggleButtonRadioListProps<V extends ValueTypes, T extends OptionInputOption<V>>
  extends RadioInputListProps<V, T> {
  shape?: ToggleButtonShape;
  layout?: Layout;
  containerLayout?: OptionInputListLayout;
  label?: ReactNode;
  required?: boolean;
  error?: string;
  displayErrorMessage?: boolean;
  listClassName?: string;
  labelClassName?: string;
  onOptionKeyDown?: (e: KeyboardEvent<HTMLInputElement>) => void;
}

export const ToggleButtonList = <V extends ValueTypes, T extends OptionInputOption<V>>({
  className,
  optionClassName,
  onOptionKeyDown,
  listClassName,
  labelClassName,
  label,
  required,
  error,
  displayErrorMessage = true,
  layout = "wrap",
  containerLayout = "horiz",
  shape = "normal",
  renderOptionItem,
  ...rest
}: (ToggleButtonCheckboxListProps<V, T> | ToggleButtonRadioListProps<V, T>) & {
  renderOptionItem?: (props: RenderOptionItemProps<V>, index: number, option: T) => ReactNode;
}) => {
  return (
    <LabelledOptions
      label={label}
      error={error}
      required={required}
      layout={containerLayout}
      className={className}
      disabled={rest.disabled}
      displayErrorMessage={displayErrorMessage}
      labelClassName={cx("text-xs", labelClassName)}
    >
      <OptionInputList
        {...rest}
        className={cx(listClassName, cxStyles.container({ layout, shape }))}
        optionClassName={(props) => cx(applyOptionClassName(optionClassName, props))}
        renderOptionItem={(props, ...args) =>
          renderOptionItem?.(props, ...args) ?? (
            <ToggleButton {...props} onKeyDown={onOptionKeyDown} shape={shape} />
          )
        }
      />
    </LabelledOptions>
  );
};
