import { minutesToMilliseconds } from "date-fns";
import { QueryClient, QueryFilters, UseInfiniteQueryResult } from "@tanstack/react-query";
import { produce, Draft } from "immer";
import { ErrorResponse, PageDetails } from "@libs/api/generated-api";
import { ApiResponse } from "@libs/@types/api";

type PaginatedResponse<T> = UseInfiniteQueryResult<
  {
    data: {
      pageDetails?: PageDetails;
      data: T;
    };
    apiResponse: ApiResponse<T>;
  },
  ErrorResponse
>["data"];

export const isPagedQueryResponse = <I>(
  response: ApiResponse<I> | PaginatedResponse<I>
): response is NonNullable<PaginatedResponse<I>> => {
  return Boolean(response && "pages" in response);
};

export const isQueryResponse = <I>(
  response: ApiResponse<I> | PaginatedResponse<I>
): response is ApiResponse<I> => {
  return Boolean(response && !("pages" in response));
};

export const updateCachedData = <I>(
  queryClient: QueryClient,
  queryFilters: QueryFilters,
  updater: (cacheEntry: I) => I
) => {
  return queryClient.setQueriesData<ApiResponse<I> | PaginatedResponse<I>>(queryFilters, (data) => {
    if (isPagedQueryResponse(data)) {
      return {
        ...data,
        pages: data.pages.map((page) => ({
          ...page,
          data: {
            ...page.data,
            data: updater(page.data.data),
          },
        })),
      };
    }

    if (isQueryResponse(data) && data.data.data) {
      return {
        ...data,
        data: {
          ...data.data,
          data: updater(data.data.data),
        },
      };
    }

    return data;
  });
};

// REPLACE SINGLE ITEM WITH UPDATED ITEM
export const replaceCachedItemWithUpdatedItem = <I>(
  queryClient: QueryClient,
  queryFilters: QueryFilters,
  newItem: I
) => {
  return updateCachedData<I>(queryClient, queryFilters, () => newItem);
};

export const updateCachedListWithCreatedItem = <I>({
  queryClient,
  queryFilters,
  newItem,
  sortOn,
}: {
  queryClient: QueryClient;
  queryFilters: QueryFilters;
  newItem: I;
  sortOn: keyof I;
}) => {
  return updateCachedData<I[]>(queryClient, queryFilters, (cachedData) =>
    produce(cachedData, (draft) => {
      draft.push(newItem as Draft<I>);
      draft.sort((a, b) => {
        const x = a[sortOn as keyof Draft<I>];
        const y = b[sortOn as keyof Draft<I>];

        return x < y ? -1 : x > y ? 1 : 0;
      });

      return draft;
    })
  );
};

// UPDATE ITEM IN LIST
const findAndReplace = <I>(arr: I[], newItem: I, idKey: keyof I) =>
  arr.map((oldItem) => (newItem[idKey] === oldItem[idKey] ? newItem : oldItem));

export const updateCachedListWithUpdatedItem = <I>(
  queryClient: QueryClient,
  queryFilters: QueryFilters,
  newItem: I,
  idKey: keyof I
) => {
  return updateCachedData<I[]>(queryClient, queryFilters, (cachedData) =>
    findAndReplace(cachedData, newItem, idKey)
  );
};

// DELETE ITEM FROM LIST
const findAndDelete = <I>(arr: I[], idKey: keyof I, idValue: I[keyof I]) =>
  arr.filter((oldItem) => oldItem[idKey] !== idValue);

// The function `updateCachedListWithDeleteItem` must be called twice like so:
//
// ```
// updateCachedListWithDeletedItem<DocumentVO>()( // <-- note the extra call
//   queryClient,
//   queryKey,
//   "id", // key of object to lookup
//   documentId // value of key to match the cached document to delete
// )
// ```
//
// This is a TS hack known as "currying" until TS can support "partial type
// argument inference" (https://github.com/microsoft/TypeScript/issues/26242).
// See full explanation here: https://stackoverflow.com/q/73738734/91710. This
// is done to improve type-safety. Without currying it can work too, but TS
// would say that `idValue` can be of any type held by the object's values. So
// when passed "id" (number) as `idKey`, TS suggests that `idValue` can be of
// `number | string | boolean` (if those are the possible value types on the
// object) instead of enforcing `number`. Currying fixes that but requires an
// extra call.
export const updateCachedListWithDeletedItem =
  <T>() =>
  <K extends keyof T>(queryClient: QueryClient, queryFilters: QueryFilters, idKey: K, idValue: T[K]) => {
    return updateCachedData<T[]>(queryClient, queryFilters, (cachedData) =>
      findAndDelete(cachedData, idKey, idValue)
    );
  };

export const cacheForSession = { staleTime: Number.POSITIVE_INFINITY, cacheTime: Number.POSITIVE_INFINITY };

export const oneMinuteCache = { staleTime: minutesToMilliseconds(1) };

export const fiveMinuteCache = { staleTime: minutesToMilliseconds(5) };

export const noCache = {
  cacheTime: 0,
};

// use this when
// - you never want to load from cache and
// - always want to load from server
// - don't want to reload the query when the cache
//   is invalidated
// - only want to load when the query calls refetch
// Why? This is typically useful when editng a resource
// and don't want to see stale data and don't want your
// query to update while it's being edted.
export const manuallyLoadFromServer = {
  cacheTime: 0,
  enabled: false,
};
