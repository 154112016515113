import Skeleton from "react-loading-skeleton";
import React from "react";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { InsuranceListItem } from "components/PatientInsurances/InsuranceListItem";

type Props = {
  query: ApiQueryResult<PatientInsuranceResponse[]>;
};
export const InsurancesList: React.FC<Props> = ({ query }) => {
  const { isLoading, data: insurances } = query;

  return (
    <>
      {isLoading ? (
        <Skeleton count={5} className="my-1" />
      ) : (
        insurances?.map((item) => {
          return (
            <InsuranceListItem key={item.patientInsurance.id} patientInsurance={item.patientInsurance} />
          );
        })
      )}
    </>
  );
};
