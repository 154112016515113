import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const cancelAppointment = makeMutation({
  mutationKey: ["practices", "cancelAppointment"],
  formatParams: (args: { practiceId: number; patientId: number; appointmentId: number }) => [
    args.practiceId,
    args.patientId,
    args.appointmentId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientAppointments")]);
    },
  }),
});

export const rescheduleAppointment = makeMutation({
  mutationKey: ["practices", "rescheduleAppointment"],
  formatParams: (args: { practiceId: number; patientId: number; appointmentId: number }) => [
    args.practiceId,
    args.patientId,
    args.appointmentId,
  ],
});

export const confirmAppointment = makeMutation({
  mutationKey: ["public", "confirmAppointment"],
  formatParams: (args: { confirmToken: string }) => [{ payload: args.confirmToken }],
});
