import { useTranslation } from "react-i18next";
import React, { useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PatientOutstandingBalanceSummaryVO } from "@libs/api/generated-api";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ParsedParams } from "@libs/router/url";
import { useAccount } from "@libs/contexts/AccountContext";
import { ToggleGroup } from "@libs/components/UI/ToggleGroup";
import {
  fetchPatientOutstandingBalanceSummary,
  getInvoicesInfiniteQuery,
  getStatementsInfiniteQuery,
} from "api/billing/queries";
import { semibold20 } from "assets/styles/textSize";
import { InvoiceList } from "components/Billing/InvoicesRoute/InvoiceList";
import { StatementsList } from "components/Billing/InvoicesRoute/StatementsList";
import { useQueryParams } from "hooks/useQueryParams";
import { paths, routesConfig } from "router/paths";

type InvoicesTab = ParsedParams<typeof routesConfig.invoices.query>["tab"];

const isInvoicesTab = (tab: InvoicesTab) => {
  return tab === "pending" || tab === "completed";
};

const useCanProceedToPayment = ({
  tab,
  paymentSummaryQuery,
}: {
  tab: InvoicesTab;
  paymentSummaryQuery: ApiQueryResult<PatientOutstandingBalanceSummaryVO>;
}) => {
  return tab === "pending" && (paymentSummaryQuery.data?.outstandingBalance ?? 0) > 0;
};

export const InvoicesRoute: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { query } = useQueryParams("invoices");
  const tab = query.tab ?? "pending";
  const { id: patientId, practiceId } = useAccount();
  const sharedParams = {
    patientId,
    practiceId,
    pageNumber: 1,
    pageSize: 20,
    includePayments: true,
    includePayerInfo: true,
    includeOrders: false,
  };
  const [paymentSummaryQuery] = useApiQueries([
    fetchPatientOutstandingBalanceSummary({
      args: {
        practiceId,
        patientId,
      },
    }),
  ]);
  const unpaidInvoicesQuery = useInfiniteApiQuery(
    getInvoicesInfiniteQuery({
      args: {
        ...sharedParams,
        states: ["FINALIZED", "PARTIALLY_PAID"],
      },
    })
  );
  const paidInvoicesQuery = useInfiniteApiQuery(
    getInvoicesInfiniteQuery({
      args: {
        ...sharedParams,
        states: ["PAID"],
      },
    })
  );
  const statementsQuery = useInfiniteApiQuery(
    getStatementsInfiniteQuery({
      args: {
        patientId,
        practiceId,
        pageNumber: 1,
        pageSize: 20,
      },
      queryOptions: {
        enabled: tab === "statements",
      },
    })
  );
  const unpaidCount = getInfiniteQueryPagingDetails(unpaidInvoicesQuery.data)?.totalElements ?? 0;
  const paidCount = getInfiniteQueryPagingDetails(paidInvoicesQuery.data)?.totalElements ?? 0;
  const options = useMemo(
    () => [
      {
        label: t("app.pages.payments.pending", {
          invoiceCount: unpaidInvoicesQuery.isLoading ? "  " : unpaidCount,
        }),
        value: "pending" as const,
      },
      {
        label: t("app.pages.payments.completed", {
          invoiceCount: paidInvoicesQuery.isLoading ? "  " : paidCount,
        }),
        value: "completed" as const,
      },
      {
        label: t("app.pages.payments.statements"),
        value: "statements" as const,
      },
    ],
    [t, unpaidInvoicesQuery.isLoading, unpaidCount, paidInvoicesQuery.isLoading, paidCount]
  );
  const canProceedToPayment = useCanProceedToPayment({ tab, paymentSummaryQuery });

  return (
    <>
      <div className="px-4 mt-6 flex flex-1 justify-center overflow-y-auto">
        <div className="flex flex-col gap-3 w-full max-w-lg">
          <div className={semibold20}>{t("app.pages.payments.page.header")}</div>
          <div className="flex flex-col items-center mb-8">
            <ToggleGroup
              size="lg"
              options={options}
              selectedValue={tab}
              onChange={(_, option) => {
                navigate(paths.invoices({ tab: option.value === "pending" ? undefined : option.value }));
              }}
            />
          </div>
          {isInvoicesTab(tab) ? (
            <InvoiceList
              invoicesQuery={tab === "pending" ? unpaidInvoicesQuery : paidInvoicesQuery}
              emptyContent={
                tab === "pending" ? t("No pending invoices found.") : t("No completed invoices found.")
              }
            />
          ) : (
            <StatementsList statementsQuery={statementsQuery} />
          )}

          <div className="h-16" />
        </div>
      </div>
      {canProceedToPayment && (
        <div
          className={`
            left-0
            right-0
            flex
            gap-8
            z-10
            items-center
            justify-center
            bg-white
            shadow-[2px_-2px_4px_rgba(188,188,188,0.25)]
            px-4
            h-16
            border-b
            border-b-slate-300
            fixed
            bottom-[3.5rem]
            md:bottom-0
          `}
        >
          <ButtonInternalLink className="w-full max-w-md" to={paths.pay()}>
            {t("Pay Balance")}
          </ButtonInternalLink>
        </div>
      )}
    </>
  );
};
