import { ChangeEvent, forwardRef, useMemo, useRef } from "react";
import { identity } from "@libs/utils/functional";
import { passRefs } from "@libs/utils/forms";
import { useFormattedInputString } from "@libs/hooks/useFormattedInputString";
import { FormFieldInput, FormFieldInputProps } from "@libs/components/UI/FormFieldInput";

export type FormFieldFormattedInputProps = Omit<FormFieldInputProps, "value"> & {
  value?: null | undefined | string;
  onValueChange?: (value: string) => void;
  cleanValue?: (value: string) => string;
  formatValue?: (value: string) => string;
  formattedCharacters?: string[];
  disableFormatting?: boolean;
};

export const FormFieldFormattedInput = forwardRef<HTMLInputElement, FormFieldFormattedInputProps>(
  (
    {
      value,
      onChange,
      onKeyDown,
      onValueChange,
      formattedCharacters,
      disableFormatting,
      cleanValue = identity,
      formatValue = identity,
      ...rest
    },
    ref
  ) => {
    const elRef = useRef<HTMLInputElement | null>(null);
    const formattedInput = useFormattedInputString(
      value ?? "",
      elRef,
      formattedCharacters,
      formatValue,
      cleanValue,
      disableFormatting
    );

    const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);

      const values = formattedInput.handleOnChange(e);

      onValueChange?.(values.cleanedValue);
    };

    const handleOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(e);
      formattedInput.handleKeydown(e);
    };

    const refs: (current: HTMLInputElement) => void = useMemo(() => passRefs([ref, elRef]), [ref]);

    return (
      <FormFieldInput
        onChange={handleOnChange}
        onKeyDown={handleOnKeyDown}
        ref={refs}
        value={formattedInput.formattedValue}
        {...rest}
      />
    );
  }
);
