import { useTranslation } from "react-i18next";
import { useCallback, useMemo } from "react";
import { PracticeInfoVO, SelfBookingConfigVO } from "@libs/api/generated-api";
import { required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatPhoneNumber } from "@libs/utils/phone";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Button } from "@libs/components/UI/Button";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ToggleButtonList } from "@libs/components/UI/ToggleButtonList";
import { FormSection } from "components/UI/FormSection";
import { FormSectionTitle } from "components/UI/FormSectionTitle";
import { getInsuranceCarrierNames } from "api/self-booking/queries";
import { selfBookingCxStyles } from "components/SelfBooking/utils";
import { PoweredBy } from "components/UI/PoweredBy";
import { useYesNoOptions } from "hooks/useYesNoOptions";
import { AnonPatientHeader } from "components/UI/AnonPatientHeader";
import { SelfBookingV1 } from "storage/selfBooking";

type CarrierOption = { label: string; value: number };

export const SelfBookingStep1: React.FC<{
  practiceInfo: PracticeInfoVO;
  config: SelfBookingConfigVO;
  onNextStep: () => void;
  state: Partial<SelfBookingV1>;
  setState: (updates: Partial<SelfBookingV1>) => void;
}> = ({ practiceInfo, config, onNextStep, state, setState }) => {
  const { t } = useTranslation();
  const { useCustomCarriers, insuranceIntakeEnabled, appointmentCategories, patientFacingCarrierNames } =
    config;

  const otherCarrier = useBoolean(false);

  const schema = useMemo(() => {
    const requiredError = t("required");

    return {
      existingPatient: [{ $v: required, $error: requiredError }],
      categoryId: [{ $v: required, $error: requiredError }],
      hasInsurance: [{ $v: required, $error: requiredError, $ignore: !insuranceIntakeEnabled }],
      carrierId: [
        {
          $v: required,
          $error: requiredError,
          $ignore: state.hasInsurance !== true || useCustomCarriers || !insuranceIntakeEnabled,
        },
      ],
      carrierName: [
        {
          $v: required,
          $error: requiredError,
          $ignore:
            state.hasInsurance !== true ||
            (!useCustomCarriers && otherCarrier.isOff) ||
            !insuranceIntakeEnabled,
        },
      ],
    };
  }, [insuranceIntakeEnabled, useCustomCarriers, otherCarrier.isOff, state.hasInsurance, t]);

  const categories = useMemo(
    () =>
      appointmentCategories
        .filter((aptCategory) => {
          const patientType = aptCategory.selfBookingConfig?.patientType ?? "ALL";

          if (patientType === "ALL") {
            return true;
          }

          return (
            (state.existingPatient && patientType === "EXISTING") ||
            (!state.existingPatient && patientType === "NEW")
          );
        })
        .map((aptCategory) => ({
          label: aptCategory.externalName || aptCategory.name,
          value: String(aptCategory.id),
        })),
    [appointmentCategories, state.existingPatient]
  );

  const newOrExistingOptions = useMemo(
    () => [
      { label: t("selfBooking.newPatient"), value: "false" },
      { label: t("selfBooking.existingPatient"), value: "true" },
    ],
    [t]
  );

  const insuranceOptions = useYesNoOptions();

  const [carriersQuery] = useApiQueries([
    getInsuranceCarrierNames({
      args: { practiceUuid: practiceInfo.uuid },
      queryOptions: { enabled: !useCustomCarriers },
    }),
  ]);

  const { data: archyCarriers } = carriersQuery;

  const groupedOptions = useMemo(() => {
    const inNetworkOptions = [] as CarrierOption[];
    const outOfNetworkOptions = [] as CarrierOption[];

    if (useCustomCarriers) {
      patientFacingCarrierNames?.forEach((item, index) => {
        if (item.inNetwork) {
          inNetworkOptions.push({ value: index + 1, label: item.name });
        } else {
          outOfNetworkOptions.push({ value: index + 1, label: item.name });
        }
      });
    } else {
      archyCarriers?.forEach((item) => {
        if (item.inNetwork) {
          inNetworkOptions.push({ value: item.id, label: item.name });
        } else {
          outOfNetworkOptions.push({ value: item.id, label: item.name });
        }
      });
    }

    outOfNetworkOptions.push({ value: 0, label: "Other" });

    return [
      {
        label: t("selfBooking.inNetwork"),
        options: inNetworkOptions,
      },
      { label: t("selfBooking.outOfNetwork"), options: outOfNetworkOptions },
    ];
  }, [archyCarriers, patientFacingCarrierNames, useCustomCarriers, t]);

  const handleSelectCarrier = useCallback(
    (label: string | undefined, value: number | undefined) => {
      const otherOptionSelected = label === "Other";

      if (otherOptionSelected) {
        otherCarrier.on();
      } else {
        otherCarrier.off();
      }

      const updates: Partial<SelfBookingV1> = { carrierId: value };

      if (useCustomCarriers) {
        updates.carrierName = otherOptionSelected ? "" : label;
      }

      setState(updates);
    },
    [useCustomCarriers, otherCarrier, setState]
  );

  const { result, validate } = useValidation(state, schema);

  const handleNextStep = () => {
    if (validate().$isValid) {
      onNextStep();
    }
  };

  const disableContinue = result.$isValid === false;

  const phoneNumber = practiceInfo.phoneNumber ?? "";

  const isAvailable = categories.length > 0;

  return (
    <div className={selfBookingCxStyles.mainStepContainer}>
      <AnonPatientHeader practiceInfo={practiceInfo} />
      <div className={selfBookingCxStyles.container}>
        <Form id="step1">
          <FormSection>
            <FormSectionTitle required>{t("selfBooking.newOrExisting")}</FormSectionTitle>
            <ToggleButtonList
              type="radio"
              shape="largePill"
              selectedValue={state.existingPatient?.toString()}
              options={newOrExistingOptions}
              onChange={(e) =>
                setState({ existingPatient: e.target.value === "true", categoryId: undefined })
              }
              error={result.existingPatient.$error}
            />
          </FormSection>
          {isAvailable ? (
            <>
              <FormSection>
                <FormSectionTitle required>{t("selfBooking.appointmentType")}</FormSectionTitle>
                <ToggleButtonList
                  type="radio"
                  shape="largePill"
                  selectedValue={state.categoryId ? String(state.categoryId) : undefined}
                  options={categories}
                  onChange={(e) => setState({ categoryId: Number(e.target.value) })}
                  error={result.categoryId.$error}
                />
              </FormSection>
              {insuranceIntakeEnabled && (
                <>
                  <FormSection>
                    <FormSectionTitle required>{t("selfBooking.hasInsurance")}</FormSectionTitle>
                    <ToggleButtonList
                      type="radio"
                      shape="largePill"
                      selectedValue={state.hasInsurance?.toString()}
                      options={insuranceOptions}
                      onChange={(e) => setState({ hasInsurance: e.target.value === "true" })}
                      error={result.hasInsurance.$error}
                    />
                  </FormSection>
                  {state.hasInsurance && (
                    <FormSection>
                      <FormSectionTitle required>{t("selfBooking.insuranceProvider")}</FormSectionTitle>
                      <QueryResult queries={[carriersQuery]}>
                        <FormFieldSelect
                          id="insuranceProvider"
                          options={groupedOptions}
                          placeholder={t("SelectOne")}
                          value={state.carrierId}
                          onChange={(e) => handleSelectCarrier(e?.label, e?.value)}
                          menuFontSize="xs"
                          isSearchable
                          isClearable
                          error={result.carrierId.$error || result.carrierName.$error}
                          formatGroupLabel={(data) => <span className="text-xxs">{data.label}</span>}
                          filterOption={(option, searchText) => {
                            const match = option.label.toLowerCase().includes(searchText.toLowerCase());

                            if (match || searchText === "") {
                              return true;
                            }

                            return option.label === "Other";
                          }}
                        />
                      </QueryResult>
                    </FormSection>
                  )}
                  {otherCarrier.isOn && (
                    <FormSection>
                      <FormSectionTitle required>{t("selfBooking.specifyProvider")}</FormSectionTitle>
                      <FormFieldInput
                        error={result.carrierName.$error}
                        id="userInputCarrierName"
                        onChange={(e) => setState({ carrierName: e.target.value })}
                        value={state.carrierName}
                      />
                    </FormSection>
                  )}
                </>
              )}

              <FormSection className="relative h-16 md:flex md:justify-center">
                <Button
                  onClick={handleNextStep}
                  className={selfBookingCxStyles.continueButton}
                  disabled={disableContinue}
                >
                  {t("Continue")}
                </Button>
              </FormSection>
            </>
          ) : (
            <>
              <FormSection className="relative text-sm">
                <FormSectionTitle>{t("selfBooking.unavailableTitle")}</FormSectionTitle>
                {t("selfBooking.unavailableText")}{" "}
                <a href={`tel:${phoneNumber}`}>{formatPhoneNumber(phoneNumber)}</a>
              </FormSection>
              <FormSection className="relative h-16 flex">
                <ButtonInternalLink to="/" className={selfBookingCxStyles.continueButton}>
                  {t("Home")}
                </ButtonInternalLink>
              </FormSection>
            </>
          )}

          <div className="flex justify-center p-6 mt-3">
            <PoweredBy className="text-secondaryTheme" />
          </div>
        </Form>
      </div>
    </div>
  );
};
