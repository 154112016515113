import { PatientFormTasksResponse, UpdatePatientDetailsRequest } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { phoneNumber, required } from "@libs/utils/validators";

export const getSchema = ({
  gender,
  hasAddress,
}: Pick<PatientFormTasksResponse, "hasAddress" | "gender"> &
  Pick<UpdatePatientDetailsRequest, "mobilePhone" | "homePhone" | "workPhone">) => ({
  gender: [{ $v: required, $error: "Gender is required", $ignore: Boolean(gender) }],
  address: [{ $v: required, $error: "Address is required", $ignore: hasAddress }],
  mobilePhone: [{ $v: phoneNumber, $error: "Must be a valid phone number" }],
  homePhone: [{ $v: phoneNumber, $error: "Must be a valid phone number" }],
  workPhone: [{ $v: phoneNumber, $error: "Must be a valid phone number" }],
});

export type PatientInfoFormValidation = ValidationResult<
  Omit<UpdatePatientDetailsRequest, "patientFormToken">,
  ReturnType<typeof getSchema>
>;
