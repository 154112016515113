import { Trans, useTranslation } from "react-i18next";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import onboardingUrl from "assets/images/OnboardingHomeBackground.svg";
import { normal14, normal20 } from "assets/styles/textSize";
import { useCurrentPatient } from "contexts/PatientContext";

type Props = {
  onCompleted: Func;
};

export const OnboardingHome: React.FC<Props> = ({ onCompleted }) => {
  const practice = useCurrentPractice();
  const patient = useCurrentPatient();

  const { t } = useTranslation();

  return (
    <div className="flex flex-col w-full px-6 py-8 gap-4">
      <div className="text-secondaryTheme text-2xl">
        <Trans
          i18nKey="app.pages.user.salutation"
          values={{ patientName: patient.personalDetails.fullName }}
          tOptions={{ interpolation: { escapeValue: true } }}
          shouldUnescape
        />
      </div>
      <div className={cx("text-secondaryTheme", normal20)}>
        <Trans
          i18nKey="app.page.messages.welcomeToExclaimed"
          values={{ practiceName: practice.name }}
          tOptions={{ interpolation: { escapeValue: true } }}
          shouldUnescape
        />
      </div>
      <div>
        <img src={onboardingUrl} alt="Onboarding" />
      </div>
      <div>
        <div className={normal14}>{t("app.page.messages.answer.few.questions.for.profile")}</div>
      </div>
      <div className="w-full flex justify-center">
        <Button className="rounded-full w-full mt-4 max-w-lg" onClick={onCompleted}>
          {t("Get Started")}
        </Button>
      </div>
    </div>
  );
};
