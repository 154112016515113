import {
  IssueTokenRequest,
  OnboardingInfoVO,
  PatientVO,
  UpdateOnboardingInfoRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData } from "@libs/utils/queryCache";

const getProfileImageQueryKeysToInvalidate = (userId: number, practiceId: number) => [
  [getQueryKey("practices", "getPatient"), { patientId: userId, practiceId }],
  [getQueryKey("v2", "getFamilyMembersV2")],
  [getQueryKey("practices", "getPatients")],
  [getQueryKey("practices", "lookupPatient")],
  [getQueryKey("practices", "getProfileImage"), { userId, practiceId }],
  [getQueryKey("practices", "getEmployee"), { employeeId: userId, practiceId }],
  [getQueryKey("practices", "getEmployees"), { practiceId }],
];

export const deleteProfileImage = makeMutation({
  mutationKey: ["practices", "deleteProfileImage"],
  formatParams: (args: { userId: number; practiceId: number }) => [args.userId, args.practiceId],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { userId, practiceId }) => {
      const keys = getProfileImageQueryKeysToInvalidate(userId, practiceId);

      for (const key of keys) {
        queryClient.invalidateQueries(key);
      }
    },
  }),
});

export const uploadProfileImage = makeMutation({
  mutationKey: ["practices", "uploadProfileImage"],
  formatParams: (args: { userId: number; practiceId: number; image: File }) => [
    args.userId,
    args.practiceId,
    { image: args.image },
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { userId, practiceId }) => {
      const keys = getProfileImageQueryKeysToInvalidate(userId, practiceId);

      for (const key of keys) {
        queryClient.invalidateQueries(key);
      }
    },
  }),
});

export const updateOnboardingInfo = makeMutation({
  mutationKey: ["practices", "updateOnboardingInfo"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    onboardingState: PatientVO["onboardingState"];
    data?: UpdateOnboardingInfoRequest;
  }) => [args.practiceId, args.patientId, { onboardingState: args.onboardingState }, args.data ?? {}],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { patientId, data: requestData, practiceId }) => {
      updateCachedData<PatientVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getPatient"), { patientId, practiceId }] },
        (current) => {
          return {
            ...current,
            onboardingState: response.data.data.nextOnboardingState,
          };
        }
      );

      if (requestData?.insuranceInfo) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getPatientInsurances"),
          {
            practiceId,
            patientId,
          },
        ]);
      }

      if (requestData?.generalInfo) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getPatient"),
          {
            practiceId,
            patientId,
          },
        ]);

        updateCachedData<OnboardingInfoVO>(
          queryClient,
          {
            queryKey: [getQueryKey("practices", "getOnboardingInfo"), { practiceId, patientId }],
          },
          (lastData) => {
            return {
              ...lastData,
              generalInfo: requestData.generalInfo,
            };
          }
        );
      }
    },
  }),
});

export const issueAccountToken = makeMutation({
  mutationKey: ["user", "issueAccountToken"],
  formatParams: (args: IssueTokenRequest) => [args],
});
