import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useContext } from "react";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { GetMutationOptions, MappedMutationResults } from "@libs/@types/apiMutations";

export const useApiMutations = <T extends GetMutationOptions[]>(configs: [...T]) => {
  const { httpClient } = useContext(ApiClientContext);
  const queryClient = useQueryClient();

  return configs.map((config) => {
    const options = config({ api: httpClient, queryClient });

    // eslint-disable-next-line react-hooks/rules-of-hooks
    return useMutation(...options);
  }) as MappedMutationResults<T>;
};
