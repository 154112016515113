import { getFullUrl } from "@libs/utils/location";
import { useCallback, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { paths } from "router/paths";
import { SignOutReason } from "router/types";

export const useSignoutWithReason = () => {
  const navigate = useNavigate();

  const location = useLocation();
  const locationRef = useRef(location);

  // referencing locationRef vs location
  // because we need to keep this function a stable reference
  locationRef.current = location;

  return useCallback(
    (signOutReason?: SignOutReason) => {
      navigate(
        paths.signOut({
          signOutReason,
          returnUrl: getFullUrl(locationRef.current),
        })
      );
    },
    [navigate]
  );
};
