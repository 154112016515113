import { StyleSheet, View, Text } from "@react-pdf/renderer";
import { SPACE } from "components/PatientForms/FormPDFElements/styles";

type Props = {
  size: keyof typeof SPACE;
  horiz?: boolean;
};

export const horizontal = StyleSheet.create({
  xs: {
    width: SPACE.xs,
    height: "100%",
  },
  sm: {
    width: SPACE.sm,
    height: "100%",
  },
  md: {
    width: SPACE.md,
    height: "100%",
  },
  lg: {
    width: SPACE.lg,
    height: "100%",
  },
  xl: {
    width: SPACE.xl,
    height: "100%",
  },
});
export const vertical = StyleSheet.create({
  xs: {
    height: SPACE.xs,
    width: "100%",
  },
  sm: {
    height: SPACE.sm,
    width: "100%",
  },
  md: {
    height: SPACE.md,
    width: "100%",
  },
  lg: {
    height: SPACE.lg,
    width: "100%",
  },
  xl: {
    height: SPACE.xl,
    width: "100%",
  },
});
export const Spacer: React.FC<Props> = ({ horiz = false, size }) => {
  return (
    <View style={horiz ? horizontal[size] : vertical[size]}>
      <Text />
    </View>
  );
};
