import React from "react";
import { View } from "@react-pdf/renderer";
import { FormBooleanInputElementVO } from "@libs/api/generated-api";
import { PatientFormInputElement } from "components/PatientForms/FormPDFElements/PatientFormInputElement";
import { PatientResponses } from "components/PatientForms/hooks/usePatientResponses";
import { YesNoQuestion } from "components/PatientForms/FormPDFElements/YesNoQuestion";

type Props = {
  element: FormBooleanInputElementVO;
  responsesById: PatientResponses;
  edit: boolean;
};

export const PatientFormBooleanElement: React.FC<Props> = ({ element, responsesById, edit }) => {
  const { uuid, settings, conditionalElement, title } = element;
  const userResponse = responsesById[uuid];
  const required = settings.includes("REQUIRED");
  const booleanResponse = userResponse?.type === "BOOLEAN" ? userResponse : undefined;
  const isOn = booleanResponse?.response;

  return (
    <YesNoQuestion required={required} label={title} selectedValue={isOn}>
      {isOn && conditionalElement ? (
        <View style={{ paddingTop: 4 }}>
          <PatientFormInputElement element={conditionalElement} responsesById={responsesById} edit={edit} />
        </View>
      ) : null}
    </YesNoQuestion>
  );
};
