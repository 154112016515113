import React from "react";
import { cx } from "@libs/utils/cx";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { semibold14, semibold24 } from "assets/styles/textSize";
import { FamilyMemberUserAccount } from "api/user/types";
import { useSwitchAccount } from "hooks/useSwitchAccount";
import { paths } from "router/paths";
import { UserAvatar } from "components/UI/UserAvatar";

type Props = {
  familyUserAccounts: FamilyMemberUserAccount[];
  onAccountSelected: (account: FamilyMemberUserAccount) => void;
};

const AccountItem: React.FC<{
  account: FamilyMemberUserAccount;
  onClick: (account: FamilyMemberUserAccount) => void;
}> = ({ account, onClick }) => {
  const handleUserClick = React.useCallback(() => onClick(account), [account, onClick]);

  return (
    <button
      type="button"
      onClick={handleUserClick}
      className="flex items-center gap-x-3 hover:bg-slate-50 rounded p-4"
    >
      <div className="size-16">
        <UserAvatar thumbnail={account.avatar?.thumbnail} />
      </div>
      <div className={cx("text-secondaryTheme text-left", semibold14)}>
        <div>{account.name.fullDisplayName}</div>
        <div className="text-xs">{account.practice.name}</div>
      </div>
    </button>
  );
};

export const SelectPatient: React.FC<Props> = ({ familyUserAccounts, onAccountSelected }) => {
  const switchAccount = useSwitchAccount({
    onAccountSelected,
  });

  return (
    <div className="flex flex-col h-full p-6">
      <div className={cx("text-secondaryTheme text-center mb-6", semibold24)}>Select Patient</div>
      <div className="min-h-0 flex-1">
        <div
          className={`
            flex
            flex-col
            max-w-md
            mx-auto
            p-1
            bg-white
            border
            border-slate-200
            rounded
            max-h-full
            overflow-y-auto
          `}
        >
          {familyUserAccounts.map((account) => (
            <AccountItem key={account.id} account={account} onClick={switchAccount} />
          ))}
        </div>
      </div>
      <div className="flex justify-center mt-4">
        <ButtonInternalLink theme="link" className="text-sm" to={paths.signOut()}>
          Sign Out
        </ButtonInternalLink>
      </div>
    </div>
  );
};
