import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";

export interface FormFieldContainerProps {
  children?: ReactNode;
  className?: string;
  error?: string;
  disabled?: boolean;
}

const cxStyle = {
  container: ({ error, disabled }: { disabled?: boolean; error?: string }) =>
    cx(
      "rounded border bg-white dark:bg-slate-800 dark:text-white",
      disabled
        ? "border-slate-200"
        : error
          ? "border-red"
          : "focus-within:border-archyBlue-500 hover:border-archyBlue-500 border-slate-200 dark:border-slate-500"
    ),
};

export const FormFieldContainer: React.FC<FormFieldContainerProps> = ({
  disabled,
  children,
  className,
  error,
}) => {
  return <div className={cx(cxStyle.container({ error, disabled }), className)}>{children}</div>;
};
