import React from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { PublicPracticeInstrumentation } from "components/Main/PublicPracticeInstrumentation";
import { getPracticeInfoByUuid } from "api/user/queries";
import { getPatientFormTasks } from "api/forms/queries";
import { PrintedFormTask } from "components/PatientForms/PatientFormTasks/PrintedFormTask";

export const PrintPatientFormTasksRoute = () => {
  const { dob, patientFormToken, practiceUuid } = usePathParams("printFormTasks");

  const {
    query: { formTaskUuids },
  } = useQueryParams("printFormTasks");

  const [tasksQuery, practiceQuery] = useApiQueries([
    getPatientFormTasks({
      args: {
        practiceUuid,
        formTaskUuids: formTaskUuids ?? [],
        patientFormToken,
        dob,
      },
    }),
    getPracticeInfoByUuid({
      args: { practiceUuid },
    }),
  ]);

  return (
    <QueryResult queries={[tasksQuery, practiceQuery]}>
      {practiceQuery.data && tasksQuery.data ? (
        <>
          <PublicPracticeInstrumentation practice={practiceQuery.data} />
          <PrintedFormTask practice={practiceQuery.data} formTasksResponse={tasksQuery.data} />
        </>
      ) : null}
    </QueryResult>
  );
};
