import React from "react";
import { FormTextBlockElementVO } from "@libs/api/generated-api";
import { RichTextContent } from "components/UI/RichTextContent";
import { useHandleError } from "api/handleErrorResponse";

type Props = {
  element: FormTextBlockElementVO;
};
export const PatientFormTextBlock: React.FC<Props> = ({ element }) => {
  const { content: lexicalJson } = element;
  const handleError = useHandleError();

  return (
    <div className="border border-greyLightest p-4">
      <RichTextContent
        initialEditorState={lexicalJson}
        initialConfig={{
          editable: true,
          namespace: "CustomForms",
          onError: handleError,
        }}
      />
    </div>
  );
};
