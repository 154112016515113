import { FC, useMemo } from "react";
import { LoaderFunctionArgs, redirect, redirectDocument } from "react-router-dom";
import { getApiClient } from "@libs/api/clients";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { AccountTokenVO, UserAccountVO } from "@libs/api/generated-api";
import { captureException } from "@sentry/react";
import { getAccountTokenStorage } from "@libs/storage/accountToken";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { useCurriedLoaderData } from "@libs/router/hooks";
import { getTokensForApi, getTokensForAuthCheck } from "@libs/auth/getTokens";
import { parseQueryParams } from "@libs/router/url";
import { headers } from "api/headers";
import { getPatientActivityStorage } from "storage/activity";
import {
  SelectAccountPayload,
  postAuthChannelMessage,
  useAuthChannelListeners,
} from "hooks/useAuthChannelListeners";
import { PatientRouterContext } from "router/types";
import { getRedirectToSignedInUrl, paths, routesConfig } from "router/paths";
import { SelectPatient } from "components/SignIn/SelectPatient";
import { useQueryParams } from "hooks/useQueryParams";
import { mapToFamilyAccounts } from "hooks/useSwitchAccount";

const handleAccountSelected = (storage: Storage, userId: number) => {
  const activityStorage = getPatientActivityStorage(storage);

  activityStorage.setRecentlyActive();

  postAuthChannelMessage({
    type: "selectAccount",
    userId,
  });
};

export const loader =
  ({ baseUrl, storage }: PatientRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { parsed: query } = parseQueryParams(routesConfig.selectAccount.query, url.searchParams);

    const accountTokenStorage = getAccountTokenStorage(storage.localStorage);

    const tokens = await getTokensForAuthCheck(storage.localStorage);

    if (!tokens.identity) {
      return redirectDocument(paths.signIn());
    }

    const activityStorage = getPatientActivityStorage(storage.localStorage);

    if (!activityStorage.isRecentlyActive()) {
      return redirect(paths.signOut());
    }

    const apiClient = getApiClient({
      baseUrl,
      headers,
      onRequestTokens: () => getTokensForApi(storage.localStorage),
    });

    let accounts: UserAccountVO[] = [];

    try {
      const accountsResponse = await apiClient.user.getUserAccounts({ userTypes: ["PATIENT"] });

      accounts = accountsResponse.data.data;
    } catch (e) {
      captureException(e);

      return redirect(paths.signOut({ signOutReason: "GET_USER_ACCOUNTS_ERROR" }));
    }

    if (accounts.length === 0) {
      return redirect(paths.accountNotFound());
    }

    const familyUserAccounts = mapToFamilyAccounts(accounts);

    if (familyUserAccounts.length === 1) {
      let newToken: AccountTokenVO | undefined;

      try {
        const accountTokenResponse = await apiClient.user.issueAccountToken({
          userId: familyUserAccounts[0].id,
          rememberMe: true,
        });

        newToken = accountTokenResponse.data.data;
      } catch (e) {
        captureException(e);
      }

      if (!newToken) {
        return redirect(paths.signOut({ signOutReason: "ISSUE_TOKEN_ERROR" }));
      }

      const accountToken = {
        userId: newToken.userId,
        expiresAt: newToken.expiresAt,
        token: newToken.token,
        practiceUuid: familyUserAccounts[0].practice.uuid,
        practiceId: familyUserAccounts[0].practice.id,
      };

      accountTokenStorage.setAccountToken(tokens.identity.email, accountToken);

      handleAccountSelected(storage.localStorage, familyUserAccounts[0].id);

      return redirectDocument(
        getRedirectToSignedInUrl(accountToken.userId, query.lastUserId, query.returnUrl)
      );
    }

    return {
      familyUserAccounts,
    };
  };

export const SelectAccountRoute: FC = () => {
  const { query } = useQueryParams("selectAccount");
  const { familyUserAccounts } = useCurriedLoaderData<typeof loader>();
  const { localStorage } = useStorageContext();

  const authChannelEvents = useMemo(
    () => ({
      onSelectAccount: (event: SelectAccountPayload) => {
        window.location.assign(getRedirectToSignedInUrl(event.userId, query.lastUserId, query.returnUrl));
      },
      onSignOut: () => {
        window.location.assign(paths.signIn({ returnUrl: query.returnUrl, lastUserId: query.lastUserId }));
      },
    }),
    [query.lastUserId, query.returnUrl]
  );

  useAuthChannelListeners(authChannelEvents);

  usePageTitle("Select Account");

  return (
    <SelectPatient
      familyUserAccounts={familyUserAccounts}
      onAccountSelected={(account) => {
        handleAccountSelected(localStorage, account.id);
        window.location.assign(getRedirectToSignedInUrl(account.id, query.lastUserId, query.returnUrl));
      }}
    />
  );
};
