import React from "react";
import { useTranslation } from "react-i18next";
import { format, fromUnixTime } from "date-fns";
import { FormattedNumber } from "react-intl";
import { PaymentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { LONG_LOCALIZED_DATE } from "@libs/utils/date";
import { centsToDollars } from "@libs/utils/currency";
import { useAccount } from "@libs/contexts/AccountContext";

import { normal12, semibold12 } from "assets/styles/textSize";
import { DownloadPaymentLink } from "components/Billing/DownloadPaymentLink";

const cxGridStyles = {
  header: ({ alignRight }: { alignRight: boolean }) => cx(alignRight && "text-right", semibold12),
  cell: ({ alignRight }: { alignRight: boolean }) =>
    cx(alignRight && "text-right flex justify-end", normal12),
};

export const PaymentHistory: React.FC<{ payments: PaymentVO[]; invoiceAmount: number }> = ({
  payments,
  invoiceAmount,
}) => {
  const { t } = useTranslation();
  const account = useAccount();

  let remainingInvoiceAmount = invoiceAmount;

  return (
    <div className="grid grid-cols-4 gap-y-2">
      <div className={cxGridStyles.header({ alignRight: false })}>{t("payments.page.paymentDate")}</div>
      <div className={cxGridStyles.header({ alignRight: true })}>{t("payments.page.amountPaid")}</div>
      <div className={cxGridStyles.header({ alignRight: true })}>{t("payments.page.amountRemaining")}</div>
      <div className={cxGridStyles.header({ alignRight: true })}>{t("Receipts")}</div>

      {payments.map((field) => {
        remainingInvoiceAmount -= field.currencyAmount.amount;

        return (
          <React.Fragment key={field.uuid}>
            <div className={cxGridStyles.cell({ alignRight: false })}>
              {format(fromUnixTime(field.paymentCreatedAt), LONG_LOCALIZED_DATE)}
            </div>
            <div className={cxGridStyles.cell({ alignRight: true })}>
              <FormattedNumber
                value={centsToDollars(field.currencyAmount.amount)}
                style="currency"
                currencyDisplay="symbol"
                currency={field.currencyAmount.currency}
              />
            </div>
            <div className={cxGridStyles.cell({ alignRight: true })}>
              <FormattedNumber
                value={centsToDollars(remainingInvoiceAmount)}
                style="currency"
                currencyDisplay="symbol"
                currency={field.currencyAmount.currency}
              />
            </div>
            <div className={cxGridStyles.cell({ alignRight: true })}>
              <DownloadPaymentLink
                invoiceUuid={field.uuid}
                practiceId={account.practiceId}
                patientId={account.id}
              />
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};
