const mobileDeviceRegex = /(iPhone|iPad|Android|Windows Phone)/g;

export const DEVICES_ALL = ["iPhone" as const, "iPad" as const, "Android" as const, "Windows Phone" as const];
type MobileDevice = (typeof DEVICES_ALL)[number];
type BrowserType = "Safari" | "Chrome" | "Edge";

export const isDeviceType = (types?: MobileDevice[]) => {
  const devices = new Set(types as string[]);
  const matches = window.navigator.userAgent.match(mobileDeviceRegex);

  return matches?.some((item) => devices.has(item));
};

export const IS_MOBILE_DEVICE = isDeviceType(DEVICES_ALL);

export const isBrowser = (types: BrowserType[]) => {
  const browsers = new Set(types);

  const ua = window.navigator.userAgent;

  return (
    (browsers.has("Safari") && ua.includes("Safari") && !ua.includes("Chrome") && !ua.includes("Edge")) ||
    (browsers.has("Chrome") && ua.includes("Chrome") && !ua.includes("Edge")) ||
    (browsers.has("Edge") && ua.match(/Edge/g))
  );
};
