import { View } from "@react-pdf/renderer";
import React from "react";
import { useTranslation } from "react-i18next";
import { PDFRadio } from "components/PatientForms/FormPDFElements/PDFRadio";
import { Box } from "components/PatientForms/FormPDFElements/Box";
import { Text } from "components/PatientForms/FormPDFElements/Text";
import { FormElementTitle } from "components/PatientForms/FormPDFElements/FormElementTitle";

type Props = {
  label?: string;
  selectedValue?: boolean;
  required?: boolean;
  children?: React.ReactNode;
};
export const YesNoQuestion: React.FC<Props> = ({ selectedValue, label, required, children }) => {
  const { t } = useTranslation();

  return (
    <Box wrap={false}>
      <FormElementTitle required={required}>{label}</FormElementTitle>
      <View style={{ flexDirection: "row" }}>
        <PDFRadio checked={selectedValue === true} style={{ marginRight: 8 }}>
          <Text bold={selectedValue === true}>{t("Yes")}</Text>
        </PDFRadio>
        <PDFRadio checked={selectedValue === false}>
          <Text bold={selectedValue === false}>{t("No")}</Text>
        </PDFRadio>
      </View>
      {children}
    </Box>
  );
};
