import React, { FormEvent, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { PaymentProfileVO } from "@libs/api/generated-api";
import { Form } from "@libs/components/UI/Form";
import { SurchargeSummary } from "components/Billing/ProcessPayment/SurchargeSummary";
import { CreditCardLogo } from "components/Account/CreditCardLogo";
import { useLabelForCardType } from "hooks/useLabelForCardType";

export const ConfirmPaymentForm: React.FC<{
  isSubmittingPayment: boolean;
  onSubmitFormPayment: () => void;
  onEditPaymentMethod: () => void;
  isLoadingEditPaymentMethod?: boolean;
  paymentProfile: PaymentProfileVO;
  totalChargeAmount: number;
  surchargeAmount: number;
  subtotal: number;
}> = ({
  onSubmitFormPayment,
  isSubmittingPayment,
  isLoadingEditPaymentMethod = false,
  onEditPaymentMethod,
  paymentProfile,
  totalChargeAmount,
  surchargeAmount,
  subtotal,
}) => {
  const { t } = useTranslation();

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onSubmitFormPayment();
    },
    [onSubmitFormPayment]
  );

  const total = surchargeAmount === 0 ? subtotal : totalChargeAmount;
  const cardTypeLabel = useLabelForCardType(paymentProfile.card?.cardType ?? "UNKNOWN");

  return (
    <Form onSubmit={handleSubmit} className="flex flex-col gap-4">
      <div
        className={`
          flex
          justify-between
          items-center
          border
          rounded-md
          bg-slate-50
          border-slate-200
          px-6
          py-4
        `}
      >
        <div className="flex flex-col gap-1">
          <div className="font-sansSemiBold">{paymentProfile.billingName}</div>
          <div className="text-xs">
            {cardTypeLabel} {paymentProfile.card?.cardLastFour ?? ""}
          </div>
        </div>
        <CreditCardLogo brand={paymentProfile.card?.cardBrand} className="w-10 h-10" />
      </div>
      <SurchargeSummary
        total={total}
        surchargeAmount={surchargeAmount}
        subtotal={subtotal}
        className="text-sm"
      />
      <AsyncButton type="submit" disabled={isSubmittingPayment} isLoading={isSubmittingPayment}>
        {t("Pay Now")}
      </AsyncButton>
      <AsyncButton
        isLoading={isLoadingEditPaymentMethod}
        size="small"
        theme="link"
        disabled={isSubmittingPayment}
        onClick={onEditPaymentMethod}
      >
        {t("Edit Payment Method")}
      </AsyncButton>
    </Form>
  );
};
