export const DECIMAL_TO_PERCENT = 100;
export const ONE_MILLION = 1_000_000;
export const ONE_HUNDRED = 100;

export const DecimalPlaces = {
  hundredth: 2,
  tenth: 1,
  max: 6,
  integer: 0,
};

export type AdjustMethod = "floor" | "ceil" | "round";

// Function inspired from
// https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Math/floor#decimal_adjustment
export const decimalAdjust = (type: AdjustMethod, value: number, exp: number) => {
  if (Number.isNaN(value) || (exp !== 0 && exp % 1 !== 0)) {
    return Number.NaN;
  }

  if (exp === 0) {
    return Math[type](value);
  }

  const adjustedValue = Math[type](`${value}e+${exp}` as unknown as number);

  // Shift back
  return Number(`${adjustedValue}e-${exp}`);
};

export const round = (num: number, precision: number = DecimalPlaces.max) => {
  return decimalAdjust("round", num, precision);
};

export const toPercent = (num: number, precision: number = DecimalPlaces.hundredth) => {
  return round(num * DECIMAL_TO_PERCENT, precision);
};

export const clamp = (num: number, min: number, max: number) => {
  return Math.min(Math.max(num, min), max);
};

const FULL_ROTATION_DEGREES: SquareDegree = 360;

export const applyRotation = (deltaDegrees: number, currentDegrees = 0) => {
  const rotationDegrees = (currentDegrees + deltaDegrees) % FULL_ROTATION_DEGREES;

  if (rotationDegrees < 0) {
    // Force rotations to always be positive
    return FULL_ROTATION_DEGREES + rotationDegrees;
  }

  return rotationDegrees;
};

export const applySharpen = (level: number) => {
  const mask = [0, 1, 0, 1, 1, 1, 0, 1, 0];
  const uniform = [0, 0, 0, 0, 1, 0, 0, 0, 0];
  // Level is stored as 0 to .3, multiply by 100 to get convolute value
  const SHARPEN_MULTIPLIER = 100;

  return uniform
    .map((item, i) => {
      return item - mask[i] / 5;
    })
    .map((item) => {
      return item * level * SHARPEN_MULTIPLIER;
    })
    .map((item, i) => {
      return uniform[i] + item;
    });
};

export const half = (num: number, precision: number = DecimalPlaces.integer) => round(num / 2, precision);

// Same as Number.parseFloat except NaN is never returned
export const safeParseFloat = (num: string, defaultValue = 0) => {
  const value = Number.parseFloat(num);

  return Number.isNaN(value) ? defaultValue : value;
};

export type SquareDegree = -90 | 0 | 90 | 180 | 270 | 360;

export type Size = {
  width: number;
  height: number;
};

export const DEGREES_90: SquareDegree = 90;
export const DEGREES_180: SquareDegree = 180;
export const DEGREES_270: SquareDegree = 270;
export const RADIAN_PER_DEGREE = Math.PI / DEGREES_180;
export const degreesToRadians = (degrees: number) => degrees * RADIAN_PER_DEGREE;

export const isInRange = (min: number, value: number, max: number) => min <= value && value <= max;
export const distance = ({ x1, y1, x2, y2 }: { x1: number; y1: number; x2: number; y2: number }) => {
  return Math.sqrt(Math.pow(x2 - x1, 2) + Math.pow(y2 - y1, 2));
};

export const getPositiveDegrees = <T extends number>(degrees: T): T => {
  return (
    degrees <= -FULL_ROTATION_DEGREES || degrees >= FULL_ROTATION_DEGREES
      ? Math.abs(degrees % FULL_ROTATION_DEGREES)
      : degrees < 0
        ? FULL_ROTATION_DEGREES + degrees
        : degrees
  ) as T;
};
