import { useMemo } from "react";
import { FormPatientProcedureVO } from "@libs/api/generated-api";

export const usePatientProcedureTotals = (procedures: FormPatientProcedureVO[]) => {
  return useMemo(
    () =>
      procedures.reduce(
        (acc, procedure) => {
          return {
            ucrRate: acc.ucrRate + procedure.ucrRate,
            negotiatedRate: acc.negotiatedRate + procedure.negotiatedRate,
            insuranceAmount: acc.insuranceAmount + procedure.insuranceAmount,
            primaryInsuranceAmount: acc.primaryInsuranceAmount + (procedure.primaryInsuranceAmount ?? 0),
            secondaryInsuranceAmount:
              acc.secondaryInsuranceAmount + (procedure.secondaryInsuranceAmount ?? 0),
            patientAmount: acc.patientAmount + procedure.patientAmount,
          };
        },
        {
          ucrRate: 0,
          negotiatedRate: 0,
          insuranceAmount: 0,
          primaryInsuranceAmount: 0,
          secondaryInsuranceAmount: 0,
          patientAmount: 0,
        }
      ),
    [procedures]
  );
};
